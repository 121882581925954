@import "../../styles/mixins/component-base.css";

:host {
  --focus-ring-color: color-mix(in srgb, var(--core-focus-ring-color) 50%, transparent);

  display: block;
}

.toggle {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  appearance: none;

  svg {
    display: block;
    fill: var(--core-text-20-color);
  }

  &:focus-visible {
    border-radius: var(--core-radius-10);
    outline: var(--core-focus-ring-border-size) solid var(--focus-ring-color);
    outline-offset: calc(var(--core-focus-ring-border-size) * -1);
  }
}
