@import "../../styles/mixins/component-base.css";

:host {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: var(--footer-vertical-padding-size) 0;

  ::slotted(.market-button-group) {
    width: 100%;
  }
}
