@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/modals.css";

:host {
  --modal-dialog-width: 100%; /* TODO: should be a design token? */

  @extend %modal;

  width: var(--modal-dialog-width);
  max-width: var(--modal-dialog-maximum-width-size);
  height: auto;
  padding:
    var(--modal-dialog-vertical-padding-size)
    var(--modal-dialog-horizontal-padding-size);
  border-radius: var(--modal-dialog-border-radius);
  background-color: var(--modal-dialog-background-color);

  /* Enter animation */
  animation:
    var(--modal-dialog-animation-enter-transition-duration)
    market-popup
    forwards
    var(--modal-dialog-animation-enter-transition-easing);

  /* Exit animation */
  &([hidden]) {
    animation-name: market-popdown;
    animation-duration: var(--modal-dialog-animation-exit-transition-duration);
    animation-timing-function: var(--modal-dialog-animation-exit-transition-easing);
  }
}

:host([value="loadingMode"]) {
  display: flex;
  flex-direction: column;
}

.market-activity-indicator {
  position: static;
  top: 24px;
  left: 40px;
  flex: none;
  color: var(--core-emphasis-fill-color);
}

@media only screen and (min-width: 600px) {
  :host {
    --modal-dialog-vertical-padding-size: var(--modal-dialog-medium-viewport-horizontal-padding-size);
    --modal-dialog-horizontal-padding-size: var(--modal-dialog-medium-viewport-vertical-padding-size);
    --modal-dialog-width: var(--modal-dialog-medium-viewport-width);
  }
}

@media only screen and (min-width: 1200px) {
  :host {
    --modal-dialog-vertical-padding-size: var(--modal-dialog-wide-viewport-horizontal-padding-size);
    --modal-dialog-horizontal-padding-size: var(--modal-dialog-wide-viewport-vertical-padding-size);
    --modal-dialog-width: var(--modal-dialog-wide-viewport-width);
  }
}
