import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'market-divider',
  styleUrl: 'market-divider.css',
  shadow: true,
})
export class MarketDivider {
  /**
   * Sets the vertical margin for the divider.
   */
  @Prop({ reflect: true }) readonly margin: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Sets the vertical margin for the divider.
   */
  @Prop({ reflect: true }) readonly size: 'thick' | 'thin' = 'thick';

  render() {
    return <Host class="market-divider"></Host>;
  }
}
