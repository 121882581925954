@import "../../styles/mixins/component-base.css";

:host {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: min-content;
  min-width: var(--code-display-width, 320px);
  min-height: var(--code-display-height, 48px);
  margin: var(--code-display-margin, 12px);
  padding:
    var(--code-display-padding-vertical, 12px)
    var(--code-display-padding-right, 16px)
    var(--code-display-padding-vertical, 12px)
    var(--code-display-zero-value, 0);
  border-radius: var(--code-display-border-radius, 6px);
  background-color: var(--code-display-background-color, var(--core-fill-40-color));
  font-weight: var(--code-display-code-font-weight, var(--core-type-bold-weight));
  font-size: var(--code-display-code-font-size, var(--core-type-heading-20-size));
  font-family: var(--code-display-font-family, var(--core-type-font-family));
  font-feature-settings: "tnum"; /* enable tabular (monospaced) figures */

  &(:not([disabled])) button:hover,
  ::slotted(button:hover),
  ::slotted(a:hover) {
    opacity: var(--code-display-action-interaction-opacity, 60%);
  }

  &(:not([disabled])) button:active,
  ::slotted(button:active),
  ::slotted(a:active) {
    opacity: var(--code-display-action-interaction-opacity, 60%);
  }

  &([disabled]) {
    color: var(--code-display-disabled-text-color, var(--core-text-30-color)); /* Color of the code */
  }

  &([disabled]) ::slotted(button),
  ::slotted(a) {
    color: var(--code-display-disabled-text-color, var(--core-text-30-color)); /* Color of action link text */
  }

  .code-container {
    display: flex;
    flex-grow: 2;
    justify-content: space-between;
    min-width: var(--code-display-code-container-min-width, 269px);
  }

  .code-char {
    flex-basis: 100%;
    font-family: var(--core-type-mono-font-family, monospace);
    text-align: center;
  }

  .actions-container {
    display: flex;
    justify-content: center;
    align-items: center;

    /* Implement nav separator line (Taken from market-banner.css) */
    & ::slotted([slot="actions"]:not(:last-child)) {
      /* add extra right margin to place separator */
      margin-right: var(--code-display-extra-actions-margin-right, 25px);

      &::after {
        /* left and right margins will position separator between this and the next action, and outside of clickable hit area */
        content: "";
        display: inline-block;
        width: var(--code-display-button-separator-width, 1px);
        height: var(--code-display-button-separator-height, 8px);
        margin-right: var(--code-display-separator-margin-right, -13px);
        margin-left: var(--code-display-button-content-spacing, 12px);
        background-color: var(--code-display-button-separator-color, var(--core-fill-10-color));
        opacity: var(--code-display-button-separator-opacity, 30%);
        pointer-events: none;
      }
    }
  }

  button,
  ::slotted(button),
  ::slotted(a) {
    margin: var(--code-display-zero-value, 0);
    padding: var(--code-display-zero-value, 0);
    border: none;
    background-color: transparent;
    color: var(--code-display-button-font-color, var(--core-blue-text-color));
    font-weight: var(--code-display-button-font-weight, var(--core-type-semibold-weight));
    font-size: var(--code-display-button-font-size, var(--core-type-paragraph-20-size));
    font-family: inherit;
    line-height: var(--code-display-button-line-height, var(--core-type-paragraph-20-leading));
    text-decoration: none;
    cursor: pointer;
  }
}

/* Use media query to change width of button when window shrinks below 320px */
@media only screen and (max-width: 320px) {
  :host {
    flex-wrap: wrap;
    width: 100%;
    min-width: var(--code-display-zero-value, 0);

    .code-container {
      min-width: var(--code-display-zero-value, 0);
    }
  }
}
