/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "feeplans": {
        "nested": {
          "model": {
            "nested": {
              "FeePlanProduct": {
                "values": {
                  "FEE_PLAN_PRODUCT_DEFAULT_DO_NOT_USE": 0,
                  "REGISTER": 1,
                  "CASH": 2,
                  "MARKET": 3,
                  "ORDER": 4,
                  "EXTERNAL_API": 5,
                  "BILLING": 6,
                  "APPOINTMENTS": 7,
                  "STORE": 8,
                  "INVOICES": 9,
                  "ONLINE_STORE": 10,
                  "STARBUCKS": 11,
                  "WHITE_LABEL": 12,
                  "PAYROLL": 13,
                  "CAPITAL": 14,
                  "CAPITAL_BUSINESS_FREE": 50,
                  "TERMINAL": 15,
                  "EGIFTING": 16,
                  "EGIFTING_FREE": 49,
                  "REGISTER_HARDWARE": 17,
                  "RESTAURANTS": 18,
                  "RESTAURANTS_PAID": 19,
                  "RETAIL": 20,
                  "RETAIL_PAID": 21,
                  "APPOINTMENTS_PAID": 22,
                  "RESTAURANTS_POS_FREE": 23,
                  "RESTAURANTS_POS_PAID": 24,
                  "RETAIL_POS_FREE": 25,
                  "RETAIL_POS_PAID": 26,
                  "APPOINTMENTS_POS_FREE": 27,
                  "APPOINTMENTS_POS_PAID": 28,
                  "READER_SDK": 29,
                  "REGISTER_T2": 30,
                  "INSTALLMENTS_PREAPPROVAL": 31,
                  "INSTALLMENTS_PROMO_6MO": 35,
                  "INSTALLMENTS_PROMO_12MO": 36,
                  "INSTALLMENTS_PROMO_18MO": 37,
                  "INSTALLMENTS_PROMO_24MO": 38,
                  "INSTALLMENTS_PLUS": 40,
                  "INSTALLMENTS_DEFERRED_PAY": 46,
                  "INSTALLMENTS_12MO_ZERO": 47,
                  "INSTALLMENTS_24MO_ZERO": 48,
                  "FELICA_ID": 32,
                  "FELICA_TRANSPORTATION": 33,
                  "FELICA_QP": 34,
                  "INVOICES_DASHBOARD": 39,
                  "INVOICES_PLUS": 52,
                  "INVOICES_PAY_PAGE_FREE": 55,
                  "INVOICES_PAY_PAGE_PLUS": 56,
                  "ECOM_SOS_FREE": 41,
                  "ECOM_SOS_PROFESSIONAL": 42,
                  "ECOM_SOS_PERFORMANCE": 43,
                  "ECOM_SOS_PREMIUM": 44,
                  "ECOM_SOS_PERSONAL": 45,
                  "RISK_MANAGER": 51,
                  "BUYER_SUBSCRIPTIONS": 53,
                  "CURRENCY_EXCHANGE": 54,
                  "APP_SUBSCRIPTIONS": 57,
                  "APP_SUBSCRIPTIONS_MARKETPLACE": 58,
                  "FNB_KIOSK": 59,
                  "GIFT_CARD_LOAD": 60,
                  "BILLPAY": 61,
                  "PAYMENT_LINKS": 62,
                  "CASH_BUSINESS_ACCOUNTS": 63
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.feeplans?.nested?.model?.nested?.FeePlanProduct) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE FeePlanProduct @ squareup/feeplans/feeplans_model.proto at 6:1
 */
export const FeePlanProduct = $root.lookupEnum('squareup.feeplans.model.FeePlanProduct').values;
