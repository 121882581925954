import { when, makeObservable, observable, action } from 'mobx';
import Logger from './Logger';
import ModalController from './ModalController';
import FeatureFlagStore, {
  KEY_REQUEST_GOOGLE_REVIEW,
  KEY_USE_MESSAGING,
} from 'src/stores/FeatureFlagStore';
import { getEnvironment } from './utils/initUtils';
import setupI18n from './i18n/setupI18n';
import {
  getLoginPageWithReturnToUrl,
  getLaunchPadUrl,
  INBOX_TRANSCRIPT_VIEW_URL_REGEX,
  getUtteranceIdFromQueryString,
  isInboxSubdomainOrDevEnv,
} from './utils/url';
import MessengerNavigationStore, {
  TRANSCRIPT_LIST_TIMEOUT,
} from 'src/stores/navigation/MessengerNavigationStore';
import Services from './services/Services';
import Api from './api/Api';
import UserStore from 'src/stores/UserStore/UserStore';
import EventTrackingStore from 'src/stores/EventTrackingStore';
import SearchContactsStore from 'src/stores/SearchContactsStore';
import SettingsStore from 'src/stores/SettingsStore';
import StatusStore from 'src/stores/StatusStore';
import TranscriptsListsStore from 'src/stores/ui/TranscriptsListsStore';
import TranscriptsStore from 'src/stores/TranscriptsStore';
import NotificationsStore from 'src/stores/NotificationsStore';
import TranscriptViewStore from 'src/stores/ui/TranscriptViewStore';
import CustomersStore from 'src/stores/CustomersStore';
import SubscriptionStore from 'src/stores/SubscriptionStore';
import UnitVerificationStore from 'src/stores/ui/UnitVerificationStore/UnitVerificationStore';
import TooltipStore from 'src/stores/TooltipStore';
import SoundRecordingStore from 'src/stores/SoundRecordingStore';
import SearchStore from 'src/stores/SearchStore';
import ExperimentStore from 'src/stores/ExperimentStore';
import CheckoutLinksStore from 'src/stores/CheckoutLinksStore';
import { LocalUtterancesStore } from 'src/stores/LocalUtterancesStore';
import SearchLoggingStore from 'src/stores/SearchLoggingStore';
import { SessionStore } from 'src/stores/SessionStore';
import UserJourneyStore from 'src/stores/UserJourneyStore';
import { isMobile } from 'src/utils/mobile';
import Transcript from 'src/stores/objects/Transcript';

// TODO(wdetlor): Rename MessengerController to Stores
export default class MessengerController {
  /**
   * Controls the modals in Messenger.
   */
  modal: ModalController;

  /**
   * Controls tooltip state using localStorage and respecting OneTrust.
   */
  tooltip: TooltipStore;

  /**
   * Flag indicating whether the root Messages store is initialized.
   */
  isInitialized = false;

  /**
   * Reference to the object that defines all the services used.
   */
  services: Services;

  /**
   * Reference to the object defining all the API's used.
   */
  api: Api;

  /**
   * The store containing the logic to track user events.
   */
  event: EventTrackingStore;

  /**
   * The store containing all user meta data.
   */
  user: UserStore;

  /**
   * The store containing the feature flags and their values for the current merchant.
   */
  featureFlag: FeatureFlagStore;

  /**
   * The store containing the global app status state.
   */
  status: StatusStore;

  /**
   * Controls the drawer and page navigation.
   */
  navigation: MessengerNavigationStore;

  /**
   * The store containing the state for the search results on the new message page.
   */
  newMessageSearch: SearchContactsStore;

  /**
   * The store containing the state for revamped search feature that combines both
   * utterances and contacts search results.
   */
  search: SearchStore;

  /**
   * The store containing the state for logging search (v2) events.
   */
  searchLogger: SearchLoggingStore;

  /**
   * The store containing the settings states.
   */
  settings: SettingsStore;

  /**
   * The store containing references to all the transcript objects
   */
  transcripts: TranscriptsStore;

  /**
   * The store containing the state of the different transcripts lists.
   */
  transcriptsLists: TranscriptsListsStore;

  /**
   * The store containing the state and lifecycle of push updates for the app.
   */
  notifications: NotificationsStore;

  /**
   * The store containing the shared state of the transcript view and underlying UI components.
   */
  transcriptView: TranscriptViewStore;

  /**
   * The store containing cached data on individual customers.
   */
  customers: CustomersStore;

  /**
   * The store containing Messages Plus subscription states.
   */
  subscription: SubscriptionStore;

  /**
   * The store containing Messages Plus unit verification form and UI states.
   */
  unitVerification: UnitVerificationStore;

  /**
   * The store used to record audio from the user. One use case is for recording new voicemail messages.
   */
  soundRecording: SoundRecordingStore;

  /**
   * The store containing experiments from Optimizely.
   */
  experiments: ExperimentStore;

  /**
   * The store that handles setting up checkout links.
   */
  checkoutLinks: CheckoutLinksStore;

  /**
   * The store that handles locally stored utterances, such as during sending or failed sent.
   */
  localUtterances: LocalUtterancesStore;

  /**
   * The store that handles the current user session.
   */
  session: SessionStore;

  /**
   * Store that handles User Journey instrumentation.
   */
  userJourney: UserJourneyStore;

  /**
   * Promise returned when setting up i18n translations.
   */
  setupI18nPromise: Promise<void>;

  /**
   * A constructor to initialize any variables that need to be initialized after the class
   * is completely loaded.
   *
   * @param {Services} [overrideServices] - overrides the services instance used, intended only as a helper for testing
   * @param {Record<string, boolean>} [overrideFeatureFlags] - overrides the feature flag values, intended only as a helper for testing
   */
  constructor(
    overrideServices?: Services,
    overrideFeatureFlags?: Record<string, boolean>,
  ) {
    makeObservable(this, {
      isInitialized: observable,
      init: action,
    });

    Logger.initSentry(getEnvironment());

    this.services = overrideServices || new Services();
    this.api = new Api(this.services);
    this.session = new SessionStore(this);
    this.user = new UserStore(this);
    this.setupI18nPromise = setupI18n(this.user.locale);
    this.featureFlag = new FeatureFlagStore(this);
    this.experiments = new ExperimentStore(this);
    this.event = new EventTrackingStore(this);
    this.status = new StatusStore(this);
    this.navigation = new MessengerNavigationStore(this);
    this.newMessageSearch = new SearchContactsStore(this);
    this.search = new SearchStore(this);
    this.searchLogger = new SearchLoggingStore(this);
    this.settings = new SettingsStore(this);
    this.transcripts = new TranscriptsStore(this);
    this.transcriptsLists = new TranscriptsListsStore(this);
    this.notifications = new NotificationsStore(this);
    this.transcriptView = new TranscriptViewStore(this);
    this.customers = new CustomersStore(this);
    this.subscription = new SubscriptionStore(this);
    this.unitVerification = new UnitVerificationStore(this);
    this.tooltip = new TooltipStore(this);
    this.modal = new ModalController(this.event);
    this.soundRecording = new SoundRecordingStore(this);
    this.checkoutLinks = new CheckoutLinksStore(this);
    this.localUtterances = new LocalUtterancesStore(this);
    this.userJourney = new UserJourneyStore(this);

    if (overrideFeatureFlags) {
      this.featureFlag.setOverrides(overrideFeatureFlags);
    }

    try {
      if (isInboxSubdomainOrDevEnv()) {
        this._prefetchInitialLoadRequests();
      }
    } catch (error) {
      Logger.logWithDatadog(
        new Error('Failed to prefetch initial load requests'),
        { error },
      );
    }
  }

  private _prefetchInitialLoadRequests = (): void => {
    this.transcriptsLists.active.init(); // Preload the initial list of transcripts

    const isRootPath =
      window.location.pathname === '/' || window.location.pathname === '/inbox';
    if (isRootPath && !isMobile()) {
      this._prefetchFirstTranscriptInList();
      return;
    }

    const isSettingsPath = window.location.pathname === '/settings';
    if (isSettingsPath) {
      this.settings.init(); // Preload the settings data
      return;
    }

    const isTranscriptViewPath = window.location.pathname.match(
      INBOX_TRANSCRIPT_VIEW_URL_REGEX,
    );
    if (isTranscriptViewPath) {
      this._prefetchTranscriptFromUrl();
    }
  };

  private _prefetchFirstTranscriptInList = async (): Promise<void> => {
    try {
      await when(() => this.transcriptsLists.active.status === 'SUCCESS', {
        timeout: TRANSCRIPT_LIST_TIMEOUT,
      });
    } catch {
      // No-op error for this as if the pre-fetch fails, the app falls back to previous behaviour where this is fetched separately
      Logger.error('Transcripts List status never resolved to SUCCESS');
    }

    if (this.transcriptsLists.active.size > 0) {
      const firstTranscriptInListId = this.transcriptsLists.active.ids[0];
      const transcript = this.transcripts.get(firstTranscriptInListId);
      transcript.load();
      this._prefetchConversationHistoryAndPastAppointments(transcript);
    }
  };

  private _prefetchTranscriptFromUrl = (): void => {
    const urlMatchTranscriptView = window.location.pathname.match(
      INBOX_TRANSCRIPT_VIEW_URL_REGEX,
    );
    if (urlMatchTranscriptView) {
      const id = Number.parseInt(urlMatchTranscriptView[1], 10); // Regex extracts ID from path param
      const utteranceId = getUtteranceIdFromQueryString();
      const transcript = this.transcripts.get(id);
      transcript.load(utteranceId); // pre-fetch the transcript for the current URL
      if (!isMobile()) {
        this._prefetchConversationHistoryAndPastAppointments(transcript);
      }
    }
  };

  private _prefetchConversationHistoryAndPastAppointments = async (
    transcript: Transcript,
  ): Promise<void> => {
    // Wait for the customer tokens to be loaded for the transcripts
    // This may come from GetTranscripts early, or in the worst case the GetTranscriptWithUtterances call
    // The status check is to ensure we don't wait indefinitely in the case the transcript has zero customer tokens
    try {
      await when(
        () =>
          transcript.customerTokens.length !== 0 ||
          transcript.status !== 'LOADING',
      );
    } catch {
      // No-op error for this as if the pre-fetch fails, the app falls back to previous behaviour where this is fetched separately
      Logger.error('Customer tokens never resolved for transcript');
    }

    if (
      transcript.status !== 'ERROR' &&
      transcript.customerTokens.length === 1
    ) {
      const customer = this.customers.get(transcript.customerTokens[0]);
      customer.loadTranscriptsHistory();
      customer.loadPastAppointments();
    }
  };

  /**
   * Initialization logic that should only occur after the user and feature flag data is loaded.
   */
  init = async (): Promise<void> => {
    if (this.isInitialized) {
      // No-op if the root store has already been initialized
      return;
    }
    this.isInitialized = true;

    await when(() => this.user.isInitialized && this.featureFlag.isInitialized);

    this.checkoutLinks.init();
    // Fetch the transcripts list only in cases where it has not already been pre-fetched
    if (!isInboxSubdomainOrDevEnv()) {
      this.transcriptsLists.active.init();
    }
    this.tooltip.init();
    this.search.init();
    this.userJourney.init();

    if (this.featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW)) this.settings.init();
  };

  /**
   * Configures the CSRF token in the csrf-token meta tag for use with the ecosystem-header.
   * Only async in development where /mp/status is called to get the initial CSRF token.
   */
  _initCsrfToken = async (): Promise<void> => {
    // Need to handle async case for development mode when CSRF token
    // may be loaded from call to /mp/status
    const token = await this.services.csrfToken;

    // Set CSRF token in meta tag for use by ecosystem header
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
    csrfMetaTag?.setAttribute('content', token || '');
  };

  /**
   * Method containing the initialization logic for the Messages Full Page App.
   *
   * @param {boolean} isMobile
   * Flag indicating if the application is on a mobile sized screen.
   * @returns {boolean} - Flag indicating if the setup was a success.
   */
  setupFullPageApp = async (isMobile: boolean): Promise<boolean> => {
    await when(() => this.user.isInitialized);

    if (!this.user.isAuthenticated) {
      // If the user isn't authenticated, redirect to the login page
      await when(() => this.featureFlag.isInitialized);
      window.location.replace(getLoginPageWithReturnToUrl());
      return false;
    }

    if (!this.user.isAuthorized) {
      // If the user doesn't have permissions to access Messages, redirect to launchpad
      await when(() => this.featureFlag.isInitialized);
      window.location.replace(getLaunchPadUrl);
      return false;
    }

    if (this.user.status === 'ERROR') {
      await this.setupI18nPromise;
      // If the user status is in an error state, we won't have a merchant token to
      // use for subsequent calls so bail out early and display an error to the user
      this.modal.openModal('UNEXPECTED_ERROR');
      return false;
    }

    this.init();

    const environment = getEnvironment();
    this.notifications.init(environment);
    this.session.monitorMultipassStatus();
    this.event.init(environment, 'FULL_PAGE_INBOX');
    this.event.track('View Full Page Inbox');

    await this._initCsrfToken();

    await when(() => this.featureFlag.isInitialized);

    if (!this.featureFlag.get(KEY_USE_MESSAGING)) {
      // If Messages is not launched in a region, redirect to launchpad
      window.location.replace(getLaunchPadUrl);
      return false;
    }

    await when(() => this.subscription.isInitialized);
    await this.setupI18nPromise;

    this.navigation.openMessengerFullPage(isMobile);

    return true;
  };
}
