@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/modals.css";

/* Partial modals display the same as full modals on mobile */
:host {
  @extend %modal-full;
}

@media (min-width: 800px) {
  :host {
    top: 0;
    left: auto;
    width: var(--modal-partial-width);
    height: auto;
    max-height: calc(100vh - var(--modal-partial-vertical-screen-buffer-size) * 2);
    padding-top: var(--modal-partial-vertical-padding-size);
    padding-bottom: var(--modal-partial-vertical-padding-size);
    border-radius: var(--modal-partial-border-radius);

    /* Enter Animation */
    animation:
      var(--modal-partial-animation-enter-transition-duration)
      market-popup
      forwards
      var(--modal-partial-animation-enter-transition-easing);

    ::slotted(*) {
      padding-right: var(--modal-partial-horizontal-padding-size);
      padding-left: var(--modal-partial-horizontal-padding-size);
    }
  }

  /* Exit animation */
  :host([hidden]) {
    animation-name: market-popdown;
    animation-duration: var(--modal-partial-animation-exit-transition-duration);
    animation-timing-function: var(--modal-partial-animation-exit-transition-easing);
  }
}
