import { Component, Prop, Host, h, Element, Event, EventEmitter } from '@stencil/core';

/**
 * @slot - The text used for the choice button label
 * @slot secondary-text - text to the right side of choice button
 */
@Component({
  tag: 'market-choice-button',
  shadow: true,
  styleUrl: 'market-choice-button.css',
})
export class MarketChoiceButton {
  @Element() el: HTMLMarketChoiceButtonElement;

  /**
   * Functionally and visually disables the choice button
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether or not the choice button is in a selected state
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * String for setting choice button size
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Emitted when the choice button is selected
   */
  @Event() marketChoiceButtonSelected: EventEmitter;

  /**
   * Emitted when the choice button is deselected
   */
  @Event() marketChoiceButtonDeselected: EventEmitter;

  onClick(e: Event) {
    if (this.disabled) {
      e.stopImmediatePropagation();
      return;
    }
    if (this.selected) {
      this.selected = false;
      this.marketChoiceButtonDeselected.emit();
    } else {
      this.selected = true;
      this.marketChoiceButtonSelected.emit();
    }
  }

  render() {
    const { disabled } = this;

    return (
      <Host
        class="market-choice-button"
        aria-disabled={disabled}
        role="button"
        tabindex={disabled ? -1 : undefined}
        onClick={(e: Event) => {
          this.onClick(e);
        }}
      >
        <button>
          <slot></slot>
          <slot name="secondary-text"></slot>
        </button>
      </Host>
    );
  }
}
