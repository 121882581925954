import React, { ReactElement, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import { TranscriptMessengerPage, Transition } from 'src/MessengerTypes';
import MessengerEmptyState from 'src/components/MessengerEmptyState/MessengerEmptyState';
import MessengerEmptyAssistantState from 'src/components/MessengerEmptyAssistantState/MessengerEmptyAssistantState';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import OutageBanner from './components/OutageBanner/OutageBanner';
import InGraceSubscriptionBanner from 'src/components/InGraceSubscriptionBanner/InGraceSubscriptionBanner';
import DelinquentSubscriptionBanner from 'src/components/DelinquentSubscriptionBanner/DelinquentSubscriptionBanner';
import MessagesPlusExpiringBanner from 'src/components/MessagesPlusExpiringBanner/MessagesPlusExpiringBanner';
import VerificationFailedRetryableBanner from 'src/pages/TranscriptViewPage/components/VerificationFailedRetryableBanner/VerificationFailedRetryableBanner';
import './TranscriptsListPage.scss';
import TranscriptsList from 'src/components/TranscriptsList/TranscriptsList';
import { Bucket } from 'src/gen/squareup/messenger/v3/messenger_service';
import TranscriptsListItem from 'src/components/TranscriptsListItem/TranscriptsListItem';
import { MarketAccessory } from 'src/components/Market';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';

export type TranscriptsListPageProps = {
  transitionDirection: Transition;
};

const DEFAULT_SCROLL_POSITION = 0;

/**
 * Top level UI component for the Transcripts List Page. It includes the
 * list of transcripts and the "new message" button.
 *
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * If true, the content transitions from the right edge
 * of the drawer into the view. Else, transition from
 * the left edge of the drawer. The logic is handled in
 * App.tsx.
 */
const TranscriptsListPage = observer(
  ({ transitionDirection }: TranscriptsListPageProps): ReactElement => {
    const {
      navigation,
      event,
      tooltip,
      featureFlag,
      status,
      subscription,
      transcriptsLists,
      transcripts,
    } = useMessengerControllerContext();
    const { t } = useTranslation();

    const transcriptsList = transcriptsLists.current;
    const page = navigation.navStoreForUrl
      .currentPage as TranscriptMessengerPage;

    // Initialize the list if not already loading
    useEffect(() => {
      if (transcriptsList.status === 'NOT_STARTED') {
        transcriptsList.init();
      }
    }, [transcriptsList]);

    // Error state
    useEffect(() => {
      if (transcriptsList.status === 'ERROR') {
        status.set({
          action: { action: transcriptsList.init },
          actionLabel: t('StatusScreen.retry'),
          display: 'SCREEN',
          label: t('TranscriptsListPage.error_loading_first'),
          type: 'ERROR',
          scope: 'BLADE',
        });
        // Clean up function to clear status if we navigate away from the list page
        return () => {
          status.clear();
        };
      }
      return undefined;
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptsList.status]);

    // Special CDP event for active bucket null state
    useEffect(() => {
      // NOTE[eblaine, 4/28/22]: The product team is interested in doing a tutorial for
      // new Messages users and would like to know how often users are shown an empty (main)
      // transcripts list.
      if (
        transcriptsList.status === 'SUCCESS' &&
        transcriptsList.size === 0 &&
        transcriptsList.bucket === Bucket.ACTIVE
      ) {
        event.track('View Conversations List Null State');
      }
    }, [
      event,
      transcriptsList.bucket,
      transcriptsList.size,
      transcriptsList.status,
    ]);

    let content: ReactNode;
    if (transcriptsList.size === 0) {
      // Case: Set up null state if the list is empty
      if (transcriptsList.bucket === Bucket.ASSISTANT) {
        content = (
          <div className="TranscriptsListPage__empty-state">
            <div className="TranscriptsListPage__empty-state__container">
              <MessengerEmptyAssistantState track={event.track} />
            </div>
          </div>
        );
      } else if (navigation.secondary.isOpen) {
        // Empty state shown separately for Transcripts List Full Page
        content = <div className="TranscriptsListPage__full-page-empty" />;
      } else {
        content = (
          <div className="TranscriptsListPage__empty-state">
            <div className="TranscriptsListPage__empty-state__container">
              <MessengerEmptyState />
            </div>
          </div>
        );
      }
    } else {
      // Case: We have transcripts (i.e. should show the transcripts list)
      content = (
        <TranscriptsList
          key={transcriptsLists.filter}
          transcriptsList={transcriptsList}
          initialScrollPosition={
            transcriptsList.scrollPosition || DEFAULT_SCROLL_POSITION
          }
          setScrollPosition={transcriptsList.setScrollPosition}
        >
          {transcriptsList.ids.map((id: number) => {
            const transcript = transcripts.get(id);
            const displayName: string =
              transcript.title || t('TranscriptsListItem.unknown_user');
            return (
              <TranscriptsListItem
                key={id}
                className="TranscriptsListPage__item-row"
                transcript={transcript}
                accessory={
                  <MarketAccessory slot="leading-accessory" size="image">
                    <CustomerImage
                      customerInitials={transcript.displayName.initials}
                      imageUrl={transcript.displayName.profileImageUrl}
                      customerName={displayName}
                    />
                  </MarketAccessory>
                }
                title={displayName}
                onClick={() =>
                  navigation.openTranscript(transcript.id, 'conversations_list')
                }
                isSelected={transcript.id === page?.transcriptId}
              />
            );
          })}
        </TranscriptsList>
      );
    }

    return (
      <MessengerContent
        transitionDirection={transitionDirection}
        isLoading={transcriptsList.status === 'LOADING'}
        loadingIndicator={<TranscriptsListSkeletonState />}
        status={status.value}
      >
        <div
          className="TranscriptsListPage__content"
          data-testid="TranscriptsListPage"
        >
          <OutageBanner />
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.unitTokensFailedRetryable.length > 0 &&
            !navigation.secondary.isOpen &&
            transcriptsList.size > 0 && (
              <VerificationFailedRetryableBanner
                unitTokens={subscription.unitTokensFailedRetryable}
              />
            )}
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.inGraceWithActiveSubscription &&
            tooltip.isVisible('SUBSCRIPTION_IN_GRACE_BANNER') && (
              <InGraceSubscriptionBanner
                onClick={() => {
                  event.track('Click Update Payment', {
                    referral_page_name: 'conversations_list',
                  });
                }}
              />
            )}
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.isSubscriptionDelinquent &&
            tooltip.isVisible('SUBSCRIPTION_DELINQUENT_BANNER') && (
              <DelinquentSubscriptionBanner
                onClick={() => {
                  event.track('Click Resubscribe', {
                    referral_page_name: 'conversations_list',
                  });
                }}
              />
            )}
          <MessagesPlusExpiringBanner />
          {content}
        </div>
      </MessengerContent>
    );
  },
);

export default TranscriptsListPage;
