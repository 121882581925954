import { Component, Host, h } from '@stencil/core';

/**
 * @slot - The content of the footer, ex button(s), text
 */
@Component({
  tag: 'market-footer',
  styleUrl: 'market-footer.css',
  shadow: true,
})
export class MarketFooter {
  render() {
    return (
      <Host class="market-footer">
        <slot></slot>
      </Host>
    );
  }
}
