import React, { ReactElement, Component, createRef, RefObject } from 'react';
import { observer } from 'mobx-react';
import {
  Suggestion,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  TranscriptViewItem as TranscriptViewItemType,
  LocalUtterance,
} from 'src/MessengerTypes';
import {
  marketComponentsOnReady,
  scrollElementTo,
} from 'src/utils/renderUtils';
import TranscriptViewItem from 'src/pages/TranscriptViewPage/components/TranscriptViewItem/TranscriptViewItem';
import { PHOTOS_INTERSECTION_OBSERVER_ROOT_ID } from 'src/utils/photoUtils';
import type MessengerController from 'src/MessengerController';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import { areItemsEqual } from 'src/utils/viewItemUtils';
import Suggestions from 'src/pages/TranscriptViewPage/components/Suggestions/Suggestions';
import { MESSAGES_MARKET_COMPONENT_PREFIX } from 'src/components/Market';
import GeneralEventBanner from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBanner';
import AppointmentEventBanner from 'src/pages/TranscriptViewPage/components/AppointmentEventBanner/AppointmentEventBanner';
import CustomerDetailEventBanner from 'src/pages/TranscriptViewPage/components/CustomerDetailEventBanner/CustomerDetailEventBanner';
import AddCustomerBanner from 'src/pages/TranscriptViewPage/components/AddCustomerBanner/AddCustomerBanner';
import JumpToBottomButton from './components/JumpToBottomButton/JumpToBottomButton';
import LoadMoreIndicator from 'src/pages/TranscriptViewPage/components/TranscriptViewItemsList/components/LoadMoreIndicator/LoadMoreIndicator';
import './TranscriptViewItemsList.scss';

/**
 * When the current scroll position to the edge of the content is less than this amount in pixels,
 * getPrevPage() or getNextPage() will be triggered to load more utterances.
 */
const SCROLL_TO_LOAD_MORE = 100;

/**
 * When the user has scrolled at least this many pixels up the chat, we show a button
 * to allow user to jump to the bottom. This has been set to allow for multi-line
 * messages or larger integrations (i.e. coupons) that may take up more space.
 */
const BUFFER_SHOW_JUMP_BUTTON = 300;

/**
 * Pixel buffer to reduce the precision at which we determine the scrollbar to be
 * at the bottom of the transcript.
 */
const BUFFER_SCROLL_AT_BOTTOM = 3;

/**
 * The primary Market component that defines the height of this component.
 */
const MAIN_MARKET_COMPONENT_TAG = `${MESSAGES_MARKET_COMPONENT_PREFIX}-market-content-card`;

export type TranscriptViewItemsListProps = {
  photoInputRef: RefObject<HTMLInputElement>;
};

type TranscriptViewItemsListState = {
  // True if the scroll is at the extreme bottom, to handle scrolling behavior
  // when new messages come in
  isScrollAtBottom: boolean;
  // Tracks the previous last item, i.e. most recent item, to determine if there
  // are new messages when updating, so that we can control the scrolling behavior
  prevLastItem?: TranscriptViewItemType;
  // Tracks the previous suggestion for scrolling purposes
  prevSuggestions?: Suggestion[];
  // Track the previously selected transcript ID. This is used to ensure we scroll
  // to the bottom without an animation anytime a new transcript is selected.
  prevTranscriptId?: number;
  // Tracks the height of the list, for resizing scrolling behavior
  listHeight: number;
  // Tracks whether the scroll is far up enough to show the jump to bottom button.
  showJumpButton?: boolean;
  // Tracks if the browser page is active.
  isPageActive: boolean;
};

/**
 * This component renders a set of utterances and events of a transcript from
 * a list of view items. It also renders the medium headers that
 * separate sets of utterances by medium and time. Contextual events
 * will be rendered with the utterances based on a timestamp, usually when
 * they occur.
 *
 * @example <TranscriptViewItemsList />
 * @param {RefObject} photoInputRef
 * A ref to the photo input used to upload photos.
 */
class TranscriptViewItemsList extends Component<
  TranscriptViewItemsListProps,
  TranscriptViewItemsListState
> {
  static contextType = MessengerControllerContext;
  declare context: MessengerController;

  listRef = createRef<HTMLDivElement>();
  itemRefs: Record<number, RefObject<HTMLDivElement>> = {};
  resizeObserver: ResizeObserver;

  constructor(props: TranscriptViewItemsListProps) {
    super(props);

    this.state = {
      isScrollAtBottom: true,
      listHeight: 0,
      isPageActive: true,
    };

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const newHeight = entry.target.clientHeight;
        if (newHeight !== this.state.listHeight) {
          this.onListResize();
        }
        this.setState({ listHeight: newHeight });
      });
    });
  }

  /**
   * Wait for Market components to hydrate and scroll to the bottom of the list.
   *
   * @param {boolean} [smooth]
   * Set to true to scroll with animation.
   */
  scrollListToBottom(smooth?: boolean): void {
    if (this.listRef && this.listRef.current) {
      marketComponentsOnReady(
        this.listRef.current,
        MAIN_MARKET_COMPONENT_TAG,
      ).then((listElement) => {
        scrollElementTo(listElement, listElement.scrollHeight, smooth);
        this.setState({ isScrollAtBottom: true, showJumpButton: false });
      });
    }
  }

  /**
   * Scrolls the list to the utterance that was seeked to when opening the transcript.
   */
  scrollListToSeekedUtterance = async (): Promise<void> => {
    const { transcript } = this.context.transcriptView;
    if (!transcript.seekUtteranceId) {
      return;
    }

    if (this.listRef.current) {
      await marketComponentsOnReady(
        this.listRef.current,
        MAIN_MARKET_COMPONENT_TAG,
      );
    }

    this.itemRefs[transcript.seekUtteranceId]?.current?.scrollIntoView?.({
      block: 'center',
    });
  };

  componentDidMount(): void {
    const { transcriptView } = this.context;
    const { transcript } = transcriptView;

    if (this.listRef && this.listRef.current) {
      // Attach scroll listener to component to get more utterances when the
      // scroll hits the top.
      this.listRef.current.addEventListener('scroll', this.onListScroll);

      // When the main list is being resized from these actions (non-exhausive):
      // 1. On screen keyboard appear on mobile web
      // 2. Input bar increases in number of lines as user types
      // 3. Input bar shows banner when no contact method or consent
      // We scroll the list down so that the bottom contents are always shown.
      this.resizeObserver.observe(this.listRef.current);

      // The below condition should always be true because this component can only mount
      // in TranscriptViewPage when the transcript is loaded. Else a loading
      // indicator will be rendered instead of this component. Therefore, we can safely
      // assume that at this point of the lifecycle, all TranscriptViewItems are rendered
      // and we can scroll to the bottom, set the latest item, and mark the trancript
      // as read.
      if (transcript.viewItems.length > 0) {
        const lastItem = transcript.viewItems[transcript.viewItems.length - 1];
        this.updatePrevLastItem(lastItem, transcript.id);

        // Always mark as unread the moment we enter into a transcript
        transcriptView.markTranscriptAsReadIfUnread();

        if (transcript.seekUtteranceId) {
          // Auto scroll to the seeked to utterance when the component is first mounted.
          this.scrollListToSeekedUtterance();
        } else {
          // Auto scroll to bottom of the list when the component is first mounted.
          this.scrollListToBottom();
        }

        // Trigger on list scroll so that we can get more items in the case where the
        // vertical height of the view is very long.
        this.onListScroll();
      }
    }

    // Add a listener to check for page visibility changes.
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  componentDidUpdate(): void {
    const { transcriptView } = this.context;
    const { transcript } = transcriptView;
    const {
      isScrollAtBottom,
      prevLastItem,
      prevSuggestions,
      prevTranscriptId,
      isPageActive,
      showJumpButton,
    } = this.state;
    const { listRef } = this;

    if (listRef && listRef.current) {
      // Handle scrolling when new items appear
      if (transcript.viewItems.length > 0) {
        // Get the most recent item
        const lastItem = transcript.viewItems[transcript.viewItems.length - 1];
        const lastItemSendStatus =
          (lastItem?.data as LocalUtterance)?.utterance?.sendStatus ||
          lastItem?.attachedUtterance?.utterance?.sendStatus;
        if (!areItemsEqual(lastItem, prevLastItem)) {
          // If the most recent item is a new item, manage the scrolling here.

          // If the selected transcript changed, always scroll to the bottom
          if (prevTranscriptId !== transcript.id) {
            transcript.seekUtteranceId
              ? this.scrollListToSeekedUtterance()
              : this.scrollListToBottom();
          }
          // If we are not at the bottom, and the user just send a new message,
          // scroll to the bottom. This is done by checking if the latest is a
          // local utterance with a PENDING status.
          else if (
            lastItemSendStatus === Utterance.SendStatus.PENDING ||
            lastItemSendStatus === Utterance.SendStatus.UPLOADING
          ) {
            this.scrollListToBottom();
          }

          this.updatePrevLastItem(lastItem, transcript.id);

          // Because there is a new item, we want to mark it as read if the transcript is still marked as unread.
          // Only mark as read if user is active on the Square Messages page and the jump button is not displayed
          // (i.e. we are not at the bottom of the page to view the latest utterance)
          if (isPageActive && !showJumpButton) {
            transcriptView.markTranscriptAsReadIfUnread();
          }
        }
      }

      // Handle scrolling when new suggestions appear
      if (transcript.suggestions !== prevSuggestions) {
        // Only scroll to bottom if we were at the bottom and if actually have
        // suggestions to show
        if (transcript.suggestions.length > 0 && isScrollAtBottom) {
          this.scrollListToBottom(true);
        }
        this.setState({ prevSuggestions: transcript.suggestions });
      }
    }
  }

  componentWillUnmount(): void {
    if (this.listRef && this.listRef.current) {
      this.listRef.current.removeEventListener('scroll', this.onListScroll);
    }

    this.resizeObserver.disconnect();

    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  /**
   * A wrapping function to prevent eslint from throwing an error when
   * attemping to call setState in componentDidUpdate.
   *
   * @param {TranscriptViewItemType} item
   * @param {number} transcriptId
   */
  updatePrevLastItem = (
    item: TranscriptViewItemType,
    transcriptId: number,
  ): void => {
    this.setState({ prevLastItem: item, prevTranscriptId: transcriptId });
  };

  /**
   * This is called on scroll events for the list. It's responsible
   * for checking if we should try to load more utterances.
   */
  onListScroll = async (): Promise<void> => {
    const { isPageActive } = this.state;
    const { transcriptView } = this.context;
    const { transcript } = transcriptView;

    if (this.listRef && this.listRef.current) {
      const listElement = await marketComponentsOnReady(
        this.listRef.current,
        MAIN_MARKET_COMPONENT_TAG,
      );
      const spaceFromBottom =
        listElement.scrollHeight -
        listElement.scrollTop -
        listElement.offsetHeight;
      const showJumpButton = spaceFromBottom > BUFFER_SHOW_JUMP_BUTTON;

      this.setState({
        // Check if we are at the bottom of page, with a few pixels of threshold to reduce
        // the precision needed for this to take effect
        isScrollAtBottom: spaceFromBottom < BUFFER_SCROLL_AT_BOTTOM,
        // Only show jump button if user has scrolled past a buffer
        showJumpButton,
      });

      // Load more when we have less than SCROLL_TO_LOAD_MORE more to scroll
      // If the list has error (e.g offline), it should show a retry button instead of triggering
      // this scrolling logic.
      if (
        listElement.scrollTop < SCROLL_TO_LOAD_MORE &&
        transcript.loadPrevStatus !== 'ERROR'
      ) {
        this.getPrevItems();
      }

      if (
        spaceFromBottom < SCROLL_TO_LOAD_MORE &&
        transcript.hasNextPage &&
        transcript.loadNextStatus !== 'LOADING' &&
        transcript.loadNextStatus !== 'ERROR'
      ) {
        this.getNextItems();
      }

      if (isPageActive && !showJumpButton) {
        transcriptView.markTranscriptAsReadIfUnread();
      }
    }
  };

  /**
   * Gets more items by calling loadPrevPage() and handles the scrolling
   * after the promise returns. This can be called either when scrolling to the top,
   * or via retry button when it previously failed (e.g offline)
   */
  getPrevItems = (): Promise<void> => {
    const { transcriptView } = this.context;
    const { transcript } = transcriptView;

    if (
      transcript.loadPrevStatus !== 'LOADING' &&
      this.listRef &&
      this.listRef.current
    ) {
      const oldHeight = this.listRef.current.scrollHeight;
      if (transcript.hasPrevPage) {
        return transcript.loadPrevPage().then(() => {
          /**
           * There was a scrolling bug where if the scroll is at the topmost edge,
           * it will be snapped to it, causing loadPrevPage() to be called infinitely
           * until all utterances in the transcript are loaded.
           *
           * To fix this, we manually set the scroll position to the last point before
           * loadPrevPage() is called, if the scroll is at the topmost edge.
           */
          if (
            this.listRef &&
            this.listRef.current &&
            this.listRef.current.scrollTop === 0 &&
            transcript.loadPrevStatus !== 'ERROR'
          ) {
            // We have to wait for the market components to be hydrated first so that the list
            // height is accurate.
            marketComponentsOnReady(
              this.listRef.current,
              MAIN_MARKET_COMPONENT_TAG,
            ).then((listElement) => {
              scrollElementTo(
                listElement,
                listElement.scrollHeight - oldHeight,
              );
            });
          }
        });
      }
    }

    // This is no-op if above conditions fail, so it is okay to just return a resolve
    return Promise.resolve();
  };

  /**
   * Gets the next set of view items by making a paginated call to loadNextPage().
   */
  getNextItems = async (): Promise<void> => {
    const { transcriptView } = this.context;
    const { transcript } = transcriptView;

    await transcript.loadNextPage();
  };

  /**
   * If the scroll position of the list is previously at the bottom, force the list to
   * scroll to the bottom when a list resize happens so that the latest utterance is always visible.
   */
  onListResize = (): void => {
    if (this.listRef && this.listRef.current) {
      if (this.state.isScrollAtBottom) {
        this.scrollListToBottom();
      }
    }
  };

  /**
   * If page is inactive, i.e. if user is on a different tab, we do not mark the open transcript as read.
   * When the user navigates back to this page/tab, we then mark it as read.
   */
  onVisibilityChange = (): void => {
    const { transcriptView } = this.context;

    if (document.visibilityState === 'hidden') {
      this.setState({ isPageActive: false });
    } else {
      this.setState({ isPageActive: true });
      transcriptView.markTranscriptAsReadIfUnread();
    }
  };

  render(): ReactElement {
    const { transcriptView, navigation } = this.context;
    const { transcript, message, setMessage, requestReview, isInputDisabled } =
      transcriptView;
    const { photoInputRef } = this.props;

    let isScrollable = false;
    if (this.listRef?.current) {
      const { scrollHeight, clientHeight } = this.listRef.current;
      isScrollable = scrollHeight > clientHeight;
    }
    const showDivider = isScrollable && !transcript.futureContextualEvent;
    const showCustomerDetailsBanner =
      !navigation.secondary.isOpen &&
      transcript.customerDetailsStatus === 'SUCCESS' &&
      transcript.customerTokens.length > 0;
    return (
      <div
        className={`TranscriptViewItemsList${
          showDivider ? ' TranscriptViewItemsList__divider' : ''
        }`}
        ref={this.listRef}
        data-testid="TranscriptViewItemsList"
        id={PHOTOS_INTERSECTION_OBSERVER_ROOT_ID} // required for photos lazy load via getElementById
      >
        {(showCustomerDetailsBanner || transcript.futureContextualEvent) && (
          <GeneralEventBanner>
            {transcript.futureContextualEvent && (
              <AppointmentEventBanner item={transcript.futureContextualEvent} />
            )}
            {showCustomerDetailsBanner && <CustomerDetailEventBanner />}
          </GeneralEventBanner>
        )}
        {transcript.customerTokens.length === 0 &&
          !navigation.secondary.isOpen && <AddCustomerBanner />}
        <div className="TranscriptViewItemsList__content">
          <LoadMoreIndicator
            loadMore={this.getPrevItems}
            hasMorePages={transcript.hasPrevPage}
            hasError={transcript.loadPrevStatus === 'ERROR'}
          />
          {transcript.viewItems.map((viewItem, index) => {
            const utteranceId =
              viewItem.dataType === 'UTTERANCE'
                ? (viewItem.data as LocalUtterance)?.utterance?.id
                : viewItem.attachedUtterance?.utterance?.id;
            let itemRef;
            if (utteranceId) {
              itemRef = createRef<HTMLDivElement>();
              this.itemRefs[utteranceId] = itemRef;
            }
            return (
              <TranscriptViewItem
                key={`TranscriptViewItem_${viewItem.dataType}_${viewItem.componentType}_${utteranceId}_${viewItem.timestampMillis}_${transcript.id}`}
                item={viewItem}
                isLastItem={index === transcript.viewItems.length - 1}
                itemRef={itemRef}
              />
            );
          })}
          <LoadMoreIndicator
            loadMore={this.getNextItems}
            hasMorePages={transcript.hasNextPage}
            hasError={transcript.loadNextStatus === 'ERROR'}
          />
          {!isInputDisabled &&
            transcript.suggestions.length > 0 &&
            !transcript.hasNextPage && (
              <Suggestions
                suggestions={transcript.suggestions}
                messageInput={message}
                setMessageInput={setMessage}
                requestReview={requestReview}
                photoInputRef={photoInputRef}
              />
            )}
        </div>
        <JumpToBottomButton
          hasUnreadMessages={!transcript.isRead}
          onClick={() => {
            if (transcript.hasNextPage) {
              transcript.clearUtterances();
              transcript.load();
              return;
            }
            this.scrollListToBottom();
          }}
          show={this.state.showJumpButton}
        />
      </div>
    );
  }
}

export default observer(TranscriptViewItemsList);
