import { createPopper } from '@popperjs/core';
import type { Instance, Placement, PositioningStrategy } from '@popperjs/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, h, Watch } from '@stencil/core';

import { supportedDropdownTriggers } from '../../utils/dropdown';
import type { TDropdownTriggerElement } from '../../utils/dropdown';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot trigger - Content slotted here will serve as the "trigger" for user
 * interaction that opens the element in the "content" slot. If it is a
 * `<market-button>`, `<market-filter-button>`, or `<market-link>`,
 * the dropdown will manage their disabled state.
 * @slot popover - Content slotted here will become visible when the slotted
 * trigger content is interacted with. Only tested with `<market-popover>`.
 *
 * To tweak popover position relative to the trigger, you can use the props
 * `popoverPlacement`, popoverSkidding`, and `popoverDistance`.
 */
@Component({
  tag: 'market-dropdown',
  styleUrl: 'market-dropdown.css',
  shadow: true,
})
export class MarketDropdown {
  @Element() el: HTMLMarketDropdownElement;

  /**
   * Defining how the popover should be triggered to open/close. Note that
   * clicks outside the dropdown will always close it.
   *
   * `click`: popover toggles open/closed on clicks to the trigger or popover
   *
   * `hover`: popover opens on trigger mouseover, closes on trigger or popover
   *  mouseout
   *
   * `persistent`: popover toggles open/closed on clicks to the trigger, popover
   * stays open if users click on it or its content
   */
  @Prop() readonly interaction: 'click' | 'hover' | 'persistent' = 'click';

  /**
   * Functionally disables the component, as well as relevant Market components
   * in the "trigger" slot (`<market-button>`, `<market-link>`).
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Determines whether the dropdown is expanded or collapsed
   */
  @Prop({ mutable: true, reflect: true }) expanded: boolean = false;

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the preferred placement of the popper.
   * https://popper.js.org/docs/v2/constructors//#placement
   */
  @Prop() readonly popoverPlacement: Placement = 'bottom';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://popper.js.org/docs/v2/constructors//#strategy
   */
  @Prop() readonly popoverStrategy: PositioningStrategy = 'absolute';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Displaces the popover along the reference element.
   * https://popper.js.org/docs/v2/modifiers/offset/#skidding-1
   */
  @Prop() readonly popoverSkidding: number;

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Displaces the popper away from, or toward, the reference element in the
   * direction of its placement.
   * https://popper.js.org/docs/v2/modifiers/offset/#distance-1
   */
  @Prop() readonly popoverDistance: number = 8;

  /**
   * Fired whenever the dropdown is opened.
   */
  @Event({ bubbles: true, composed: true }) marketDropdownOpened: EventEmitter;

  /**
   * Fired whenever the dropdown is closed.
   */
  @Event({ bubbles: true, composed: true }) marketDropdownClosed: EventEmitter;

  /**
   * Popper instance
   */
  private popperInstance: Instance | null = null;

  /**
   * Clicks outside of the dropdown component will close the popover. This means
   * that only one dropdown can be open on screen at a time.
   */
  @Listen('click', { target: 'window' })
  windowClick(e: MouseEvent) {
    // https://lamplightdev.com/blog/2021/04/10/how-to-detect-clicks-outside-of-a-web-component
    if (this.expanded && !e.composedPath().includes(this.el)) {
      this.closeDropdown();
    }
  }

  /**
   * Toggles the dropdown
   */
  @Watch('expanded')
  onExpandedChange(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) {
      return;
    }
    if (this.expanded) {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  }

  @Watch('disabled')
  syncDisabledState() {
    // this only covers elements slotted directly into market-dropdown, aka in the light DOM
    const slottedTriggerElements = this.el.querySelectorAll(
      supportedDropdownTriggers.map((elName) => getNamespacedTagFor(elName as keyof HTMLElementTagNameMap)).join(','),
    ) as NodeListOf<TDropdownTriggerElement>;
    slottedTriggerElements.forEach((element) => {
      element.disabled = this.disabled;
    });
  }

  /**
   * Toggles the dropdown opened or closed
   */
  @Method()
  toggleDropdown(): Promise<void> {
    if (this.expanded) {
      return this.closeDropdown();
    } else {
      return this.openDropdown();
    }
  }

  /**
   * Opens the dropdown
   */
  @Method()
  openDropdown(): Promise<void> {
    if (this.expanded) {
      return Promise.resolve();
    }
    const { defaultPrevented } = this.marketDropdownOpened.emit();
    if (!defaultPrevented && !this.expanded) {
      this.expanded = true;
    }
    return Promise.resolve();
  }

  /**
   * Closes the dropdown
   */
  @Method()
  closeDropdown(): Promise<void> {
    if (!this.expanded) {
      return Promise.resolve();
    }
    const { defaultPrevented } = this.marketDropdownClosed.emit();
    if (!defaultPrevented && this.expanded) {
      this.expanded = false;
    }
    return Promise.resolve();
  }

  /**
   * Updates the popper's tooltip location
   * https://popper.js.org/docs/v2/lifecycle/#manual-update
   */
  @Method()
  async updateDropdownPosition(): Promise<void> {
    await this.popperInstance?.update();
    return Promise.resolve();
  }

  async handleInteraction(e: MouseEvent) {
    if (this.disabled) {
      return;
    }

    // default behavior (interaction = 'click') is that the popover toggles
    // open/closed when any part of the element is clicked (trigger or popover)
    if (this.interaction === 'click' && e.type === 'click') {
      this.toggleDropdown();
    }

    // when interaction = 'hover', mousing over the trigger opens the popover
    // and mousing out of the trigger OR popover closes the popover
    if (this.interaction === 'hover' && e.type === 'mouseover') {
      this.openDropdown();
    }
    if (this.interaction === 'hover' && e.type === 'mouseout') {
      this.closeDropdown();
    }

    // when interaction = 'persistent', the popover can only be toggled
    // open/closed with clicks to the trigger element
    const trigger = this.el.querySelector('[slot="trigger"]');
    if (this.interaction === 'persistent' && e.type === 'click' && e.composedPath().includes(trigger)) {
      this.toggleDropdown();
    }

    // since slotted content is not visible until this.expanded is true,
    // we need to tell popper.js to more accurately calculate its position once
    // it becomes visible
    if (this.popperInstance) {
      await this.popperInstance.update();
    }
  }

  initializePopper() {
    const {
      el,
      popperInstance,
      popoverPlacement: placement,
      popoverStrategy: strategy,
      popoverSkidding: skidding,
      popoverDistance: distance,
    } = this;

    if (popperInstance) {
      popperInstance.destroy();
      this.popperInstance = null;
    }

    const trigger = el as HTMLElement;
    const popover = el.querySelector('[slot="popover"]') as HTMLElement;

    if (popover === null) {
      return;
    }

    this.popperInstance = createPopper(trigger, popover, {
      // https://popper.js.org/docs/v2/constructors/#options
      placement,
      strategy,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [skidding, distance],
          },
        },
      ],
    });
  }

  @Watch('popoverSkidding')
  @Watch('popoverDistance')
  updatePopoverConfig() {
    const { popperInstance, popoverSkidding: skidding, popoverDistance: distance } = this;
    popperInstance.setOptions({
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [skidding, distance],
          },
        },
      ],
    });
  }

  connectedCallback() {
    // It's possible for connectedCallback to fire when the element is not connected ¯\_(ツ)_/¯
    // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
    if (!this.el.isConnected) {
      return;
    }

    // If this is the initial connection, the popover slot may not exist yet. In that case we'll initialize Popper via
    // the slotchange event handler. If this is a reconnect, we need to reinitialize Popper since it will have been
    // destroyed by disconnectedCallback.
    this.initializePopper();
  }

  disconnectedCallback() {
    // It's possible for disconnectedCallback to fire when the element is still connected ¯\_(ツ)_/¯
    // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
    if (this.el.isConnected) {
      return;
    }

    if (this.popperInstance) {
      // Destroying Popper on disconnect prevents memory leaks
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  render() {
    return (
      <Host
        class="market-dropdown"
        aria-expanded={this.expanded}
        onClick={(e: MouseEvent) => {
          this.handleInteraction(e);
        }}
        onmouseover={(e: MouseEvent) => {
          this.handleInteraction(e);
        }}
        onmouseout={(e: MouseEvent) => {
          this.handleInteraction(e);
        }}
      >
        <slot name="trigger" onSlotchange={() => this.syncDisabledState()}></slot>
        <slot name="popover" onSlotchange={() => this.initializePopper()}></slot>
      </Host>
    );
  }
}
