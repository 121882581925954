/* TODO: add design tokens for table components */

@import "../../styles/mixins/tables.css";

:host {
  --transition-duration: 0.2s; /* TODO: design token when design finalizes spec */

  @extend %table-cell;

  transition: background-color var(--transition-duration);

  @media (hover: hover) {
    /* media query is to suppress hover styles on mobile devices */
    &([interactive]:hover) {
      background-color: var(--table-cell-hover-state-background-color, var(--core-fill-50-color));
    }
  }

  &([interactive]) {
    cursor: pointer;
  }

  &([interactive]:focus) {
    outline: none; /* override default browser focus styles */
  }

  &([interactive]:not([disabled]):focus) {
    background-color: var(--table-cell-focus-state-background-color, var(--core-fill-50-color));
  }

  &([active]),
  &([interactive]:not([disabled]):active) {
    background-color: var(--table-cell-pressed-state-background-color, var(--core-emphasis-40-color));
  }

  &([interactive][disabled]) {
    pointer-events: none;
  }

  &([disabled]) {
    background-color: transparent;
    color: var(--table-cell-disabled-state-text-color, var(--core-text-30-color));
    pointer-events: none;
  }

  &([hidden]) {
    display: none;
  }
}

:host(:first-of-type) {
  padding-left: var(--table-cell-padding, 8px);

  ::slotted(button) {
    /* TODO: These should have design tokens in the future */
    all: unset;
    display: flex; /* stylelint-disable-line order/properties-order */
    justify-content: space-between;
    align-items: center;
    padding-right: var(--core-metrics-spacing-100);
    padding-left:
      calc(
        var(--cell-indent-level) *
        var(--table-row-indentation-padding, 40px) +
        var(--core-metrics-spacing-100)
      );
    color: var(--table-cell-normal-state-content-color, var(--core-fill-black-color));
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }

    &:focus {
      color: var(--table-accordion-focus-state-content-color, var(--core-fill-black-color));
    }

    &:hover {
      color: var(--table-accordion-hover-state-content-color, var(--core-fill-black-color));
    }

    &:active {
      color: var(--table-accordion-pressed-state-content-color, var(--core-fill-black-color));
    }
  }

  ::slotted([slot="nested-row-indicator"]) {
    height: 100%;
  }
}

/* TODO: update pixels to variables once they exist */
::slotted([slot="leading-accessory"][size="image"]) {
  margin-right: 16px;
  margin-left: 12px;
}

::slotted([slot="leading-accessory"][size="icon"]) {
  margin-right: 16px;
  margin-left: 16px;
}

::slotted([slot="trailing-accessory"][size="image"]) {
  margin-right: 12px;
  margin-left: 16px;
}

::slotted([slot="trailing-accessory"][size="icon"]) {
  margin-right: 16px;
  margin-left: 16px;
}

::slotted([slot="nested-row-indicator"]) {
  color: var(--table-cell-normal-state-content-color, var(--core-fill-black-color));
}

::slotted([slot="nested-row-indicator"]:focus-visible) {
  outline: none;
}

::slotted([slot="nested-row-indicator"]:focus) {
  color: var(--table-accordion-focus-state-content-color, var(--core-fill-black-color));
}

::slotted([slot="nested-row-indicator"]:hover) {
  color: var(--table-accordion-hover-state-content-color, var(--core-fill-black-color));
}

::slotted([slot="nested-row-indicator"]:active) {
  color: var(--table-accordion-pressed-state-content-color, var(--core-fill-black-color));
}
