import { Component, Host, Element, State, Watch, Listen, h } from '@stencil/core';
import { isEqual, throttle } from 'lodash-es';

import { getNamespacedTagFor } from '../../utils/namespace';
import { parsedGridTemplateColumnValues } from './utils';

const RESIZE_DEBOUNCE_DURATION = 16; // 60fps

/**
 * @slot - Default slot for all rows
 *
 * @slot header - Slot for header rows at the top of the table. Slotting a header row is required to set custom column
 * widths.
 *
 * **NOTE:** slotting rows into this area will not sticky rows to the top. Use `sticky` or
 * `stick-to="top"` on the row element instead.
 *
 * @slot footer - Slot for footer rows at the bottom of the table
 *
 * **NOTE:** slotting rows into this area will not sticky rows to the bottom. Use `sticky` or
 * `stick-to="bottom"` on the row element instead.
 */
@Component({
  tag: 'market-table',
  styleUrl: 'market-table.css',
  shadow: true,
})
export class MarketTable {
  @Element() el: HTMLMarketTableElement;

  // CSSStyleDeclaration representing the HTMLMarketTableElement's currently computed styles
  @State() styleDeclaration: CSSStyleDeclaration;

  // An array of grid template values set by the consumer on the table itself
  @State() gridColumnTemplate: Array<string>;

  @State() allColumns: Array<HTMLMarketTableColumnElement> = [];
  @State() visibleColumns: Array<HTMLMarketTableColumnElement> = [];
  @State() columnsStuckLeft: Array<HTMLMarketTableColumnElement> = [];
  @State() columnsStuckRight: Array<HTMLMarketTableColumnElement> = [];
  @State() columnsUnstuck: Array<HTMLMarketTableColumnElement> = [];

  @State() allRows: Array<HTMLMarketTableRowElement> = [];
  @State() rowsStuckTop: Array<HTMLMarketTableRowElement> = [];
  @State() rowsStuckBottom: Array<HTMLMarketTableRowElement> = [];
  @State() rowsUnstuck: Array<HTMLMarketTableRowElement> = [];
  @State() hasAccordionRows: boolean = false;

  observers: {
    resize?: ResizeObserver;
    inlineStyle?: MutationObserver;
    content?: MutationObserver;
  } = {};

  /* Everytime the computed CSS style for this element is updated, we want to
    mutate the grid template definition, and save whatever template they have set
    to our grid definition so we know what size the columns are */
  @Watch('styleDeclaration')
  styleDeclarationObserver(newValue: CSSStyleDeclaration, oldValue: CSSStyleDeclaration) {
    if (isEqual(newValue, oldValue)) {
      return;
    }

    /* unset this.gridColumnTemplate value when there is no explicit grid-template-columns style
      in this situation, the computed value of grid-template-columns === the width of the element
      https://developer.mozilla.org/en-US/docs/Web/CSS/computed_value */
    if (newValue.getPropertyValue('grid-template-columns') === newValue.getPropertyValue('width')) {
      this.gridColumnTemplate = [];
      return;
    }

    /* when there is an explicit grid-template-columns style, update this.gridColumnTemplate */
    const gridTemplateColumnsValue = parsedGridTemplateColumnValues(newValue.getPropertyValue('grid-template-columns'));
    if (!isEqual(gridTemplateColumnsValue, this.gridColumnTemplate)) {
      this.gridColumnTemplate = gridTemplateColumnsValue;
    }
  }

  /* When the grid template changes, forward it's value to the child sections.
  Use an observer instead of binding in the template to prevent polluting
  the DOM with unneeded attributes */
  @Watch('gridColumnTemplate')
  gridTemplateObserver(newValue: Array<string>, oldValue?: Array<string>) {
    if (newValue !== oldValue) {
      this.setColumnWidths(newValue);
      this.updateGridLayout();
    }
  }

  /* If the columns change, forward their values to the child rows, and update
  the column count */
  @Watch('allColumns')
  @Watch('visibleColumns')
  allColumnsObserver(columns: Array<HTMLMarketTableColumnElement>, oldValue?: Array<HTMLMarketTableColumnElement>) {
    if (columns && (!oldValue || columns !== oldValue)) {
      this.setColumnWidths(this.gridColumnTemplate);
      this.forwardColumnPropertiesToCells(this.allColumns);
      this.updateGridLayout();
    }
  }

  @Watch('allRows')
  allRowsObserver(rows: Array<HTMLMarketTableRowElement>, oldValue?: Array<HTMLMarketTableRowElement>) {
    if (rows !== oldValue && rows) {
      this.updateGridLayout();
      this.forwardColumnPropertiesToCells(this.allColumns);
      this.updateStickyRows();
    }
  }

  @Watch('rowsStuckTop')
  @Watch('rowsStuckBottom')
  @Watch('rowsUnstuck')
  stuckRowsObserver(newValue: Array<HTMLMarketTableRowElement>, oldValue: Array<HTMLMarketTableRowElement>) {
    if (newValue !== oldValue) {
      this.allRows.map((row) => row.classList.remove('buffer-row'));

      if (this.rowsStuckTop.length > 0) {
        this.rowsStuckTop[this.rowsStuckTop.length - 1].classList.add('buffer-row');
      }
      if (this.rowsUnstuck.length > 0 && this.rowsStuckBottom.length > 0) {
        this.rowsUnstuck[this.rowsUnstuck.length - 1].classList.add('buffer-row');
      }
    }
  }

  @Listen('marketTableHeaderLoaded')
  marketTableHeaderLoadedEventHandler({ detail }) {
    this.allColumns = [...detail.columns];
    this.checkColumnVisibility();
  }

  @Listen('marketTableRowStick')
  marketTableRowStickEventHandler({ target, detail }) {
    switch (detail.position) {
      case 'top':
        target.slot = 'sticky-header';
        break;
      case 'bottom':
        target.slot = 'sticky-footer';
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('could not stick row to an unknown position');
        break;
    }
    this.updateStickyRows();
  }

  @Listen('marketTableRowUnstick')
  marketTableRowUnstickEventHandler({ target }) {
    /* If the row was originally in a named slot, we want to put the
    row back into that slot */
    if (target.originalSlot) {
      target.slot = target.originalSlot;

      /* Otherwise we just remove the slot attribute so it will return
    to the default slot */
    } else {
      target.removeAttribute('slot');
    }
    this.updateStickyRows();
  }

  @Listen('marketTableColumnStick')
  async marketTableColumnStickEventHandler({ target, detail }) {
    // Stick each cell to the correct side in the row
    await Promise.all(
      [...this.allRows].map(async (row) => {
        await row._stickColumn(target.name, detail.position);
      }),
    );

    this.updateGridLayout();
  }

  @Listen('marketTableColumnUnstick')
  async marketTableColumnUnstickEventHandler({ target }) {
    await Promise.all(
      [...this.allRows].map(async (row) => {
        await row._unstickColumn(target.name);
      }),
    );

    this.updateGridLayout();
  }

  @Listen('marketTableColumnVisibilityChange')
  marketTableColumnVisibilityChangeHandler({ detail }) {
    this.checkColumnVisibility();
    this.allRows.forEach((row) => row._syncColumnVisibilityWithCells(detail.columnName, detail.hidden));
    this.detectStyleDeclaration();
  }

  checkColumnVisibility() {
    this.visibleColumns = this.allColumns.filter((column) => !column.hidden);
  }

  setColumnWidths(gridTemplate: Array<string>) {
    if (this.allColumns.length > 0) {
      this.visibleColumns.forEach((column, i) => {
        column.width = gridTemplate[i];
      });
    }
  }

  forwardColumnPropertiesToCells(columns) {
    this.allRows.forEach((row) => (row.tableColumns = columns));
  }

  updateGridLayout() {
    if (this.allColumns.length > 0) {
      this.columnsUnstuck = this.visibleColumns.filter((column) => !column.stickTo);
      this.columnsStuckLeft = this.visibleColumns.filter((column) => column.stickTo === 'left');
      this.columnsStuckRight = this.visibleColumns.filter((column) => column.stickTo === 'right');

      const mainGrid = this.columnsUnstuck.map((column) => column.width);
      const leftGrid = this.columnsStuckLeft.map((column) => column.width);
      const rightGrid = this.columnsStuckRight.map((column) => column.width);

      if (this.allRows.length > 0) {
        this.allRows.forEach((row) => {
          row.gridTemplateMain = mainGrid;
          row.gridTemplateLeft = leftGrid;
          row.gridTemplateRight = rightGrid;
        });
      }
    }
  }

  updateStickyRows() {
    this.rowsStuckTop = this.allRows.filter((row) => (row.sticky && row.header) || row.stickTo === 'top');
    this.rowsStuckBottom = this.allRows.filter((row) => (row.sticky && row.footer) || row.stickTo === 'bottom');
    this.rowsUnstuck = this.allRows.filter((row) => !row.stickTo && !row.sticky);
  }

  /* If the slotted content of the table changes, we need to update
  our saved copy of the section and column children */
  handleSlotChange() {
    // Get all the child rows
    this.allRows = [...this.el.querySelectorAll<HTMLMarketTableRowElement>(getNamespacedTagFor('market-table-row'))];

    // Check for root accordion fields or default to passed in prop
    const hasAccordionElements =
      [...this.el.children].some((element: HTMLMarketAccordionItemElement | HTMLMarketTableRowElement) => {
        return element.tagName.toLowerCase() === getNamespacedTagFor('market-accordion-item');
      }) || this.hasAccordionRows;

    /* Set an index for each row so we have some sort of id and can track it
    this will perhaps come in useful later when we need to add a row re-ordering
    drag & drop feature (although we probably need some conditional, or to set
    the index somewhere else than here) - jbiggs */
    this.allRows.forEach((row) => {
      row.index = Array.prototype.indexOf.call(this.allRows, row);
      row.nested = hasAccordionElements;
    });
  }

  /**
   * Gets current CSSStyleDeclaration object for this.el (see styleDeclarationObserver)
   */
  private detectStyleDeclaration() {
    this.styleDeclaration = window.getComputedStyle(this.el as HTMLElement);
  }

  private throttledDetectStyleDeclaration = throttle(this.detectStyleDeclaration.bind(this), RESIZE_DEBOUNCE_DURATION);

  /**
   * Supports setting dynamic column sizes using CSS media queries by recalculating column width on table resize
   */
  private initResizeObserver() {
    if (!this.observers.resize) {
      this.observers.resize = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          this.throttledDetectStyleDeclaration();
        });
      });
      this.observers.resize.observe(this.el);
    }
  }

  /**
   * Supports setting dynamic column widths by updating inline styles
   */
  private initInlineStyleObserver() {
    if (!this.observers.inlineStyle) {
      this.observers.inlineStyle = new MutationObserver(() => this.detectStyleDeclaration());
      this.observers.inlineStyle.observe(this.el, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
  }

  /**
   * since onSlotchange only fires on changes to the <Host> node itself (not changes to the child slots of the
   * <market-table-area>s), we're using a mutation observer to listen for added rows or changes in row content
   * https://github.com/ionic-team/stencil/issues/232#issuecomment-397871813
   */
  private initContentObserver() {
    if (!this.observers.content) {
      this.observers.content = new MutationObserver(() => this.handleSlotChange());
      this.observers.content.observe(this.el, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }
  }

  /* When the component loads, we need to check for a grid-template-columns
  CSS declaration on the table, and also read the column children
  Setting both of these will trigger watcher functions which forward these
  values to the row children */
  componentWillLoad() {
    this.detectStyleDeclaration();
    this.handleSlotChange();
  }

  componentDidLoad() {
    this.initResizeObserver();
    this.initInlineStyleObserver();
    this.initContentObserver();
  }

  render() {
    const MarketTableAreaTagName = getNamespacedTagFor('market-table-area');

    return (
      <Host class="market-table" role="table" onSlotchange={() => this.handleSlotChange()}>
        <MarketTableAreaTagName orientation="horizontal" stick-to="top" active={this.rowsStuckTop.length > 0}>
          <slot name="sticky-header"></slot>
        </MarketTableAreaTagName>
        <MarketTableAreaTagName orientation="horizontal" active>
          <slot name="header"></slot>
          <slot></slot>
          <slot name="footer"></slot>
        </MarketTableAreaTagName>
        <MarketTableAreaTagName orientation="horizontal" stick-to="bottom" active={this.rowsStuckBottom.length > 0}>
          <slot name="sticky-footer"></slot>
        </MarketTableAreaTagName>
      </Host>
    );
  }

  disconnectedCallback() {
    Object.values(this.observers).forEach((observer) => {
      observer?.disconnect();
    });
  }
}
