import { Component, Host, h, Prop, Element, State } from '@stencil/core';
import { observeAriaAttributes, AriaAttributes } from '../../utils/aria';

/**
 * @slot - The text used for the link.
 */
@Component({
  tag: 'market-link',
  styleUrl: 'market-link.css',
  shadow: true,
})
export class MarketLink {
  @Element() el: HTMLMarketLinkElement;

  /**
   * String that represents the URL the link goes to.
   * If not present, the internal tag will be a `<button>` rather than `<a>` in order to align with a11y best practices.
   */
  @Prop() readonly href: string;

  /**
   * Specifies where to open the linked URL.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details on accepted values.
   */
  @Prop() readonly target: '_blank' | '_self' | '_parent' | '_top' | undefined;

  /**
   * Defines the relationship between a linked resource and the current document.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel) for details on accepted values.
   */
  @Prop() readonly rel: string | undefined;

  /**
   * Gives the link destructive styling.
   */
  @Prop({ reflect: true }) readonly destructive: boolean = false;

  /**
   * Visually disables the link and prevents navigating to the link on click.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Causes the browser to treat the linked URL as a download. Only works for same-origin URLs.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#download) for details on accepted values.
   */
  @Prop() readonly download: string | undefined;

  @State() ariaAttributes: AriaAttributes;

  mutationObserver: MutationObserver;

  onMutationObserved = (ariaAttributes: AriaAttributes) => {
    this.ariaAttributes = ariaAttributes;
  };

  componentWillLoad() {
    this.mutationObserver = observeAriaAttributes(this.el, this.onMutationObserved);
  }

  handleClick(e: Event) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const { disabled, handleClick, href, target, ariaAttributes, rel, download } = this;
    const TagType: string = href !== undefined ? 'a' : 'button';
    const TagTypeAttrs = TagType === 'a' ? { href, target, rel, download } : { disabled };
    const ariaRole = TagType === 'a' ? 'link' : 'button';

    return (
      <Host
        class="market-link"
        role={ariaRole}
        aria-disabled={disabled ? 'true' : null}
        tabindex={disabled ? '-1' : null}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <TagType {...TagTypeAttrs} {...ariaAttributes} aria-disabled={disabled ? 'true' : null}>
          <slot></slot>
        </TagType>
      </Host>
    );
  }

  disconnectedCallback() {
    this.mutationObserver?.disconnect();
  }
}
