import { Component, Host, h, Prop, Element, Event, EventEmitter, Method } from '@stencil/core';
import { getControlInputAriaLabel } from '../../utils/aria';

enum ARIA_VALUE {
  TRUE = 'true',
  FALSE = 'false',
}

@Component({
  tag: 'market-toggle',
  styleUrl: 'market-toggle.css',
  shadow: true,
})
export class MarketToggle {
  @Element() el: HTMLMarketToggleElement;

  /**
   * Whether the toggle is checked or not.
   * If used as a slotted control inside of `market-row`, this will be overridden by the row's `selected` property.
   */
  @Prop({ mutable: true, reflect: true }) checked: boolean = false;

  /**
   * Whether the toggle is disabled.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * Whether the toggle is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Whether the toggle is hovered or not.
   */
  @Prop({ mutable: true, reflect: true }) hovered: boolean = false;

  /**
   * Whether the toggle is active or not.
   */
  @Prop({ mutable: true, reflect: true }) active: boolean = false;

  /**
   * Fired whenever "checked" prop value changes.
   */
  @Event() marketToggleChange: EventEmitter<{ current: boolean; previous: boolean }>;

  innerInput: HTMLInputElement;

  /**
   * Toggles `checked` prop, and emits a change event accordingly.
   * Used by `market-row` to sync its selected state w/ slotted toggles.
   */
  @Method()
  setSelection(newValue: boolean, { silent = false } = {}) {
    const { marketToggleChange, checked: prevValue, innerInput } = this;

    if (typeof newValue !== 'boolean') return Promise.resolve();
    if (prevValue === newValue) return Promise.resolve();

    if (!silent) {
      const { defaultPrevented } = marketToggleChange.emit({
        current: newValue,
        previous: prevValue,
      });
      if (defaultPrevented) {
        return Promise.resolve();
      }
    }

    this.checked = newValue;
    innerInput.checked = newValue;
    return Promise.resolve();
  }

  /**
   * DEPRECATED (3.x): Toggles `selected` state (unrelated to the HTML attribute `value`).
   */
  @Method()
  setValue(newValue: boolean) {
    /* eslint-disable-next-line no-console */
    console.warn("market-toggle's setValue() method has been deprecated. Use setSelection() instead.", this.el);
    this.setSelection(newValue);
    return Promise.resolve();
  }

  /**
   * Sets `active` state. Allows external elements to programmatically
   * trigger active styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setActive(value: boolean) {
    this.active = value;
    return Promise.resolve();
  }

  /**
   * Sets `hovered` state. Allows external elements to programmatically
   * trigger hover styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setHover(value: boolean) {
    this.hovered = value;
    return Promise.resolve();
  }

  /**
   * Sets `disabled` state. Allows external elements to programmatically
   * trigger disabled styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setDisabled(value: boolean) {
    this.disabled = value;
    return Promise.resolve();
  }

  /**
   * Sets `focused` state, except when disabled.
   * Allows external consumers to programmatically
   * trigger focused styling.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.disabled) {
      return Promise.resolve();
    }
    this.focused = value;
    return Promise.resolve();
  }

  componentDidRender() {
    if (!this.innerInput) {
      this.innerInput = this.el.shadowRoot.querySelector('input');
    }
  }

  handleClick(event: MouseEvent) {
    // Always prevent default so we can manually control the selection
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.setFocus();
    this.setSelection(!this.checked);
  }

  render() {
    const ariaChecked = this.checked ? ARIA_VALUE.TRUE : ARIA_VALUE.FALSE;

    return (
      <Host
        class="market-toggle"
        role="switch"
        aria-checked={ariaChecked}
        onBlur={() => {
          this.setFocus(false);
        }}
        onClick={this.handleClick}
        onFocus={() => {
          this.setFocus();
        }}
      >
        <input
          type="checkbox"
          role="switch"
          aria-checked={ariaChecked}
          aria-label={getControlInputAriaLabel(this.el)}
          checked={this.checked}
          disabled={this.disabled}
        />
        {/* SVG for toggle button */}
        {
          <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="38" height="22" rx="11" stroke-width="2" />
            <circle cx="12" cy="12" r="7" />
          </svg>
        }
      </Host>
    );
  }
}
