@import "../../styles/mixins/modals.css";

:host {
  --handle-whitespace: calc(var(--modal-sheet-handle-padding-top-size) + var(--modal-sheet-handle-padding-bottom-size));
  --handle-area-height: calc(var(--handle-whitespace) + var(--modal-sheet-handle-height));
  --max-width-padding: var(--modal-sheet-regular-horizontal-size-class-horizontal-padding);
  --min-width-padding: var(--modal-sheet-compact-horizontal-size-class-horizontal-padding);

  /* todo: replace 200px half-width with design token */
  --padding-width: clamp(var(--min-width-padding), calc(50% - 200px), var(--max-width-padding));
  --padding-height: var(--modal-sheet-regular-vertical-size-class-vertical-padding);

  @extend %modal;

  position: fixed;

  /* start just off screen when added to the dom */
  top: calc(100vh + var(--handle-area-height));
  bottom: auto;
  left: 50%;
  overflow: visible;
  box-sizing: border-box;
  width: 100%;

  /* todo: replace 400px width with design token */
  max-width: calc(400px + calc(var(--max-width-padding) + var(--max-width-padding)));
  max-height: calc(100% - var(--handle-area-height));
  padding-top: var(--padding-height);
  border-radius: var(--modal-sheet-border-radius) var(--modal-sheet-border-radius) 0 0;
  background-color: var(--modal-sheet-background-color);
  opacity: 0%;
  user-select: none;
  transition:
    top var(--core-animation-enter-transition-moderate-speed-duration),
    opacity var(--core-animation-enter-transition-moderate-speed-duration);
  transform: translate(-50%, 0);

  &([state="closed"]) {
    top: calc(100vh + var(--handle-area-height));
    opacity: 0%;
    transition:
      top var(--core-animation-exit-transition-moderate-speed-duration),
      opacity var(--core-animation-exit-transition-moderate-speed-duration);
  }

  &([state="partial-open"]) {
    opacity: 100%;
  }

  &([state="full-open"]) {
    opacity: 100%;
  }

  &([dragging]) {
    transition: opacity var(--core-animation-enter-transition-moderate-speed-duration);
  }

  &([tapdisabled]) {
    pointer-events: none;
  }

  ::slotted([slot="header"]) {
    padding-right: var(--padding-width);
    padding-left: var(--padding-width);
  }

  /*
    this visually extends the sheet below the screen to avoid a gap
    when the sheet is dragged upwards.
  */
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: inherit;
    max-width: inherit;
    height: 100vh;
    background-color: inherit;
  }
}

.handle {
  position: fixed;
  bottom: calc(100% + var(--modal-sheet-handle-padding-bottom-size));
  left: 50%;
  display: block;
  width: var(--modal-sheet-handle-width);
  height: var(--modal-sheet-handle-height);
  border: none;
  border-radius: var(--modal-sheet-border-radius);
  background-color: var(--modal-sheet-handle-background-color);
  user-select: none;
  transform: translate(-50%, 0);

  &::before {
    content: "";
    position: absolute;
    inset: calc(var(--modal-sheet-handle-padding-bottom-size) * -1);
  }

  &:focus {
    outline: var(--button-focus-ring-border-size) solid var(--button-focus-ring-color);
  }
}

.main {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: var(--padding-width);
  padding-bottom: var(--padding-height);
  padding-left: var(--padding-width);
  overscroll-behavior: contain;
}

.main[dragging] {
  overflow-y: hidden;
}
