@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/field.css";
@import "../../styles/mixins/field-states.css";
@import "../../styles/mixins/field-accessories.css";
@import "../../styles/mixins/floating-input.css";

:host {
  @extend %field;
  @extend %field-states;
  @extend %field-accessories;
  @extend %floating-input;

  cursor: pointer;
}

.label-input-container {
  overflow: hidden;
}

.caret {
  flex-shrink: 0;
  margin-right: var(--field-float-phase-horizontal-padding-size);
  pointer-events: none;
  transition: transform var(--field-input-animation-speed);

  path {
    fill: var(--select-caret-color);
  }

  :host([aria-expanded="true"]) & {
    transform: rotate(180deg);
  }
}

.placeholder {
  overflow: hidden;
  height: var(--field-input-leading);
  color: var(--field-placeholder-text-color);
  font-size: var(--field-input-size);
  line-height: var(--field-input-leading);
  text-overflow: ellipsis;
  white-space: nowrap;
  visibility: hidden;

  :host([focused]) &,
  :host([size="small"]) &,
  :host([size="medium"]) & {
    visibility: visible;
  }

  :host([size="small"]) & {
    height: var(--core-type-paragraph-20-leading);
    font-size: var(--core-type-paragraph-20-size);
    line-height: var(--core-type-paragraph-20-leading);
  }
}

::slotted(label) {
  cursor: pointer;
}

::slotted([slot="displayed-selection"]) {
  /* Override default market-row min-height to use correct height */
  min-height: 0;
  margin: 0;

  /* Hide the inset bottom border and focus ring for rows */
  &::before,
  &::after {
    display: none;
  }
}

/* Hide the slotted list options */
::slotted([slot="list"]) {
  display: none;
}
