@import "../../styles/mixins/component-base.css";

:host {
  display: flex;
  flex-direction: column;
  gap: var(--empty-state-button-group-vertical-spacing);
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--empty-state-vertical-padding) var(--empty-state-horizontal-padding);
  border-width: calc(var(--empty-state-border-width) * 1px);
  border-style: solid;
  border-color: var(--core-fill-40-color);

  /* update --empty-state-border-color to this value */
  border-radius: var(--empty-state-border-radius);
  background-color: var(--empty-state-background-color);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--empty-state-paragraph-vertical-spacing);
  width: 100%;
  max-width: var(--empty-state-text-maximum-width, 600px); /* TODO: design token */
  margin: 0 auto;
  text-align: center;

  ::slotted([slot="primary-text"]),
  ::slotted([slot="secondary-text"]) {
    margin: 0;
    font-family: inherit;
  }

  ::slotted([slot="primary-text"]) {
    color: var(--empty-state-heading-color);
    font-weight: var(--empty-state-heading-text-weight);
    font-size: var(--empty-state-heading-text-size);
    line-height: var(--empty-state-heading-text-leading);
  }

  ::slotted([slot="secondary-text"]) {
    color: var(--empty-state-paragraph-color);
    font-weight: var(--empty-state-paragraph-text-weight);
    font-size: var(--empty-state-paragraph-text-size);
    line-height: var(--empty-state-paragraph-text-leading);
  }
}

.actions {
  display: flex;
  flex-direction: row;
  gap: var(--empty-state-actions-spacing, var(--core-metrics-spacing-200));  /* TODO: design token */
}
