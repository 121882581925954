import { Component, Host, h, Element, Event, EventEmitter, Method, Prop, Watch } from '@stencil/core';

import { TMarketTabSelectedChangedEventDetail } from '../../events';

/**
 * @slot - (Default slot) The text used for the button label
 * @part button - The `<button>` in the shadow DOM
 */
@Component({
  tag: 'market-tab',
  styleUrl: 'market-tab.css',
  shadow: true,
})
export class MarketTab {
  @Element() el: HTMLMarketTabElement;
  buttonEl: HTMLButtonElement;

  /**
   * Whether or not the tab is disabled
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Tab's size
   *
   * @default 'medium'
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Whether or not the tab is selected
   *
   * @default false
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * Fired when the tab selection has changed
   *
   * `market-tab-list` listens to this event and stops further propagation
   */
  @Event({ bubbles: true, composed: true })
  marketTabSelectedChanged: EventEmitter<TMarketTabSelectedChangedEventDetail>;

  /**
   * Select the tab and emits a `marketTabSelectedChanged` event
   */
  @Method()
  select() {
    if (this.selected || this.disabled) {
      return Promise.resolve();
    }
    const { defaultPrevented } = this.marketTabSelectedChanged.emit({
      panelId: this.el.getAttribute('aria-controls'),
      prevValue: this.selected,
      tabId: this.el.id,
      value: true,
    });
    if (!defaultPrevented) {
      this.selected = true;
    }
    return Promise.resolve();
  }

  /**
   * Deselects the tab and emits a `marketTabSelectedChanged` event
   */
  @Method()
  deselect() {
    if (!this.selected) {
      return Promise.resolve();
    }
    const { defaultPrevented } = this.marketTabSelectedChanged.emit({
      panelId: this.el.getAttribute('aria-controls'),
      prevValue: this.selected,
      tabId: this.el.id,
      value: false,
    });
    if (!defaultPrevented) {
      this.selected = false;
    }
    return Promise.resolve();
  }

  @Watch('disabled')
  disabledWatcher() {
    this.selected = false;
  }

  handleClick() {
    this.select();
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // prevents scroll down when Space is pressed
      this.select();
    }
  }

  render() {
    return (
      <Host
        aria-disabled={this.disabled.toString()}
        aria-selected={this.selected.toString()}
        class="market-tab"
        onClick={this.handleClick.bind(this)}
        onKeydown={this.handleKeydown.bind(this)}
        role="tab"
        tabindex="0"
      >
        <button disabled={this.disabled} part="button" ref={(el) => (this.buttonEl = el)} tabindex="-1">
          <slot></slot>
        </button>
      </Host>
    );
  }
}
