import { Component, Host, Prop, Element, Watch, Event, EventEmitter, Method, State, h, Listen } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';
import { MarketCheckboxCustomEvent, MarketToggleCustomEvent } from '../../components';

export type TCell = HTMLMarketTableCellElement | HTMLMarketTableColumnElement;

/**
 * @slot - Default slot for all cells
 * @slot control - Intended for use with a market table cell or market table column that contain a control element.
 */
@Component({
  tag: 'market-table-row',
  styleUrl: 'market-table-row.css',
  shadow: true,
})
export class MarketTableRow {
  private tableAreaLeft!: HTMLMarketTableAreaElement;
  private tableAreaRight!: HTMLMarketTableAreaElement;
  private tableAreaMain!: HTMLMarketTableAreaElement;
  private slottedControl: HTMLMarketCheckboxElement | HTMLMarketToggleElement;

  @Element() el: HTMLMarketTableRowElement;

  // ----------- Consumer-defined props -------------

  /**
   * Optional: Level of leading indentation
   * This will be multiplied by the default indentation size (40px) for uniform indentation
   * levels
   */
  @Prop({ mutable: true, reflect: true }) leadingIndentation: number = 0;

  /**
   * Optional: The edge of the table to fix this row to.
   */
  @Prop({ mutable: true, reflect: true }) stickTo: false | 'top' | 'bottom' = false;

  /**
   * Optional: When present, can be used instead of `stickTo` in combination
   * with `header` or `footer` to determine the edge of the table to stick this
   * row to. (`header` elements with `[sticky]` will be attached to the top, and
   * `footer` elements to the bottom)
   */
  @Prop({ mutable: true, reflect: true }) sticky: boolean;

  /**
   * Whether the row is currently active.
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * Whether or not the row is interactive. Results in row receiving
   * hover and active styling when hovered/clicked.
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Whether the row is disabled.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether the row is selected. Used by control element.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  // --------------- Internal props -----------------

  /**
   * Gives this row header styling
   */
  @Prop({ mutable: true, reflect: true }) header: boolean = false;

  /**
   * Gives this row footer styling
   */
  @Prop({ mutable: true, reflect: true }) footer: boolean = false;

  /**
   * The slot this row was originally placed in
   */
  @Prop({ mutable: true, reflect: false }) originalSlot: string;

  /**
   * **INTERNAL [do not use directly]**
   * The order of this row in the DOM
   */
  @Prop({ reflect: false }) readonly index: number = 0;

  /**
   * **INTERNAL [do not use directly]**
   * A list of the market-table-column elements, set from the parent table so
   * we can assign this row's cells some properties based on the columns
   */
  @Prop({ reflect: false }) readonly tableColumns: Array<HTMLMarketTableColumnElement>;

  /**
   * **INTERNAL [do not use directly]**
   * This row's slotted market-table-cell elements
   */
  @Prop({ mutable: true, reflect: false }) cells: NodeListOf<TCell>;

  /**
   * **INTERNAL [do not use directly]**
   * Used to set the CSS grid template for the main column group (market-table-area)
   * in the row. Set by the parent table element
   */
  @Prop({ mutable: false, reflect: false }) readonly gridTemplateMain: Array<string> = [];

  /**
   * **INTERNAL [do not use directly]**
   * Used to set the CSS grid template for the fixed left column group (market-table-area)
   * in the row. Set by the parent table element
   */
  @Prop({ mutable: false, reflect: false }) readonly gridTemplateLeft: Array<string> = [];

  /**
   * **INTERNAL [do not use directly]**
   * Used to set the CSS grid template for the fixed right column group (market-table-area)
   * in the row. Set by the parent table element
   */
  @Prop({ mutable: false, reflect: false }) readonly gridTemplateRight: Array<string> = [];

  /**
   * **INTERNAL [do not use directly]**
   * Used to set aria-expanded on the nested button for animation
   */
  @Prop({ mutable: true, reflect: false }) expanded: boolean = false;

  /**
   * Used to determine if the table has accordion rows. When true,
   * it will add extra spacing at the beginning of the row. This will
   * keep the row's contents aligned with the accordion rows. This is
   * set from the market-table component.
   *
   * This property can be overriden when the content does not need
   * the extra accordion spacing.
   */
  @Prop({ mutable: true, reflect: true }) nested: boolean = false;

  /** This is a CSSStyleDeclaration object
   * https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleDeclaration
   */
  @State() styleDeclaration: any;

  /**
   * Used to set the CSS grid template for the row itself
   */
  @State() gridTemplate: Array<string> = [];

  /**
   * Used to update the button's aria-expanded
   */
  @State() nestedRowToggleButton: HTMLButtonElement;

  /**
   * **INTERNAL [do not use directly]**
   * If this is a header row with column children, emit an event when this row loads
   * so the parent table can read the column data
   */
  @Event() marketTableHeaderLoaded: EventEmitter<{ columns: NodeListOf<HTMLMarketTableColumnElement> }>;

  /**
   * Fired whenever an interactive row is clicked.
   */
  @Event({ bubbles: true, composed: true }) marketTableRowClicked: EventEmitter;

  /**
   * Emitted when this row is stuck to a table edge
   * Can be fired when stick-to or sticky changes, the .stick() method is called directly
   * or when this row is first rendered or slotted
   */
  @Event() marketTableRowStick: EventEmitter<{
    position: 'left' | 'right';
    index: number;
  }>;

  /**
   * Emitted when this row is unstuck from a table edge
   * Can be fired when stick-to or sticky changes, the .unstick() method is called directly
   * or when this row is first rendered or slotted
   */
  @Event() marketTableRowUnstick: EventEmitter<{
    position: 'left' | 'right';
    index: number;
  }>;

  /**
   * Emitted when the nested row button is toggled
   */
  @Event() marketAccordionToggled: EventEmitter<{ expanded: boolean }>;

  /**
   * Emitted when the nested row button is toggled
   */
  @Event() marketNestedRowToggled: EventEmitter<{ expanded: boolean }>;

  @Watch('gridTemplateMain')
  @Watch('gridTemplateLeft')
  @Watch('gridTemplateRight')
  formNewGridTemplate() {
    this.gridTemplate = [...this.gridTemplateLeft, ...this.gridTemplateMain, ...this.gridTemplateRight];

    if (this.tableAreaLeft) {
      this.tableAreaLeft.placement = [1, this.gridTemplateLeft.length];
    }

    if (this.tableAreaMain) {
      this.tableAreaMain.placement = [this.gridTemplateLeft.length + 1, this.gridTemplateMain.length];
    }

    if (this.tableAreaRight) {
      this.tableAreaRight.placement = [
        this.gridTemplateLeft.length + this.gridTemplateMain.length + 1,
        this.gridTemplateRight.length,
      ];
    }
  }

  @Watch('gridTemplate')
  gridTemplateObserver(newValue: Array<string>, oldValue: Array<string>) {
    if (newValue !== oldValue) {
      this.el.style.gridTemplateColumns = newValue.join(' ');
    }
  }

  @Watch('stickTo')
  @Watch('sticky')
  stickyObserver(newValue: string | boolean, oldValue: string | boolean) {
    if (newValue !== oldValue) {
      this.emitStickyEvents();
    }
  }

  @Watch('tableColumns')
  columnsObserver(columns: Array<HTMLMarketTableColumnElement>) {
    this.setCellColumnProperties(columns);
  }

  @Watch('cells')
  updateCellProperties(
    oldCellList: NodeListOf<HTMLMarketTableCellElement>,
    newCellList: NodeListOf<HTMLMarketTableCellElement>,
  ) {
    if (oldCellList !== newCellList) {
      this.setCellColumnProperties(this.tableColumns);
    }
  }

  @Watch('leadingIndentation')
  updateFirstCellProperties() {
    this._setFirstCellProperties();
  }

  @Watch('expanded')
  updateNestedRowButton() {
    if (this.nestedRowToggleButton) {
      this.nestedRowToggleButton.ariaExpanded = `${this.expanded}`;

      // We cannot access the svg from the css files in the current state,
      // so we have to do it through JS. I figured this was the best place
      // to do it since the rotation depends on expansion for now. We should
      // find a better way to access this svg in the style sheets. -lindamr
      const svgElement = this.nestedRowToggleButton.querySelector('svg');
      if (svgElement) {
        if (this.expanded) {
          svgElement.style.transform = 'rotate(-180deg)';
        } else {
          svgElement.style.transform = 'rotate(0deg)';
        }
      }
    }
  }

  @Watch('selected')
  updateSlottedControlCheckedValue() {
    this.slottedControl?.setSelection(this.selected);
  }

  @Watch('disabled')
  updateSlottedControlDisabledValue() {
    this.slottedControl?.setDisabled(this.disabled);
  }

  @Listen('marketCheckboxValueChange')
  @Listen('marketToggleChange')
  handleMarketCheckboxValueChange(
    event: MarketCheckboxCustomEvent<{ current: boolean }> | MarketToggleCustomEvent<{ current: boolean }>,
  ) {
    // Update selected value if event is triggered by slottedControl
    if (event.target !== this.slottedControl) {
      return;
    }
    this.selected = event.detail.current;
  }

  /**
   * Sticks this row to the provided edge (position) of the table
   */
  @Method()
  stick(position?: 'top' | 'bottom') {
    if (position) {
      this.stickTo = position;
    } else if (this.header || this.footer) {
      this.sticky = true;
    }
    return Promise.resolve();
  }

  /**
   * Unsticks this row from any edge of the table
   */
  @Method()
  unstick() {
    this.sticky = false;
    this.stickTo = false;
    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Used by the parent table to support fixing columns to either side of the table
   */
  @Method()
  async _stickColumn(column: string, position: 'left' | 'right') {
    const cell = this.el.querySelector(`[name="${column}"], [column="${column}"]`) as HTMLMarketTableCellElement;
    if (cell) {
      await cell._stickSelf(position);
    } else {
      console.warn('cannot stick cell to unknown position'); // eslint-disable-line no-console
    }
  }

  /**
   * **INTERNAL [do not use directly]**
   * Used by the parent table to support fixing columns to either side of the table
   */
  @Method()
  async _unstickColumn(column: string) {
    const cell = this.el.querySelector(`[name="${column}"], [column="${column}"]`) as HTMLMarketTableCellElement;
    if (cell) {
      await cell._unstickSelf();
    } else {
      console.warn('cannot unstick cell from unknown position'); // eslint-disable-line no-console
    }
  }

  /**
   * **INTERNAL [do not use directly]**
   * Sets the hidden prop on market-table-cell. Used by market-table to allow market-table-column
   * to control the hidden/visible state of its associated table cells.
   */
  @Method()
  _syncColumnVisibilityWithCells(columnName, hidden) {
    const cell = this.el.querySelector(
      `[name="${columnName}"], [column="${columnName}"]`,
    ) as HTMLMarketTableCellElement;
    if (cell) {
      cell.hidden = hidden;
    }
    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Sets properties computed or specified on the row on the first
   * cell to keep the table rows from shifting
   */
  @Method()
  _setFirstCellProperties() {
    // Setting indentation on the first cell of the row to not mess
    // with the table grid
    if (this.cells?.length && this.isStylableCell(this.cells[0])) {
      this.cells[0]._updateFirstCellProperties?.(this.el);
    }
    return Promise.resolve();
  }

  /**
   * Sets the leadingIndentation
   * @param leadingIndentation
   */
  @Method()
  setLeadingIndentation(leadingIndentation: number) {
    this.leadingIndentation = leadingIndentation;
    return Promise.resolve();
  }

  /**
   * Sets expanded property and emits nested row toggle event
   */
  @Method()
  toggleNestedRow() {
    this.expanded = !this.expanded;
    this.marketAccordionToggled.emit({ expanded: this.expanded });
    this.marketNestedRowToggled.emit({ expanded: this.expanded });
    return Promise.resolve();
  }

  /**
   * Checks type of cell to make Typescript happy about using functions that are only on
   * HTMLMarketTableCellElement and not HTMLMarketTableColumnElement
   * @param cell
   * @returns
   */
  isStylableCell(cell: TCell): cell is HTMLMarketTableCellElement {
    return (
      (cell as HTMLMarketTableCellElement)._updateColumnRelatedProperties !== undefined &&
      (cell as HTMLMarketTableCellElement)._updateFirstCellProperties !== undefined
    );
  }

  setCellColumnProperties(columns: Array<HTMLMarketTableColumnElement>) {
    if (this.cells && columns && columns.length > 0) {
      this.cells.forEach((cell, i) => {
        if (this.isStylableCell(cell)) {
          const column = columns[i];
          cell._updateColumnRelatedProperties(column);
        }
      });
    }
  }

  emitStickyEvents() {
    let position;

    if (this.stickTo) {
      position = this.stickTo;
    } else if (this.header) {
      position = 'top';
    } else if (this.footer) {
      position = 'bottom';
    }

    if (this.sticky || this.stickTo) {
      // emit a stick event
      this.marketTableRowStick.emit({
        position,
        index: this.index,
      });
    } else if (this.componentLoaded) {
      // Emit an unstick event
      this.marketTableRowUnstick.emit({
        position,
        index: this.index,
      });
    }
  }

  componentWillLoad() {
    // setting row properties based on whether row is using a named slot
    this.originalSlot = this.el.slot;
    this.header = this.originalSlot === 'header';
    this.footer = this.originalSlot === 'footer';
    // prettier wants (typeof this.cells)[0] but that change seems wrong
    // prettier-ignore
    this.cells = this.el.querySelectorAll<typeof this.cells[0]>(getNamespacedTagFor('market-table-cell'));
  }

  handleSlotChange() {
    this.cells = this.el.querySelectorAll(
      `${getNamespacedTagFor('market-table-cell')}, ${getNamespacedTagFor('market-table-column')}`,
    );

    // If this is our header row, meaning we have column children, then emit an
    // event that sends the columns to the table parent
    if (this.header) {
      this.marketTableHeaderLoaded.emit({
        columns: this.el.querySelectorAll<HTMLMarketTableColumnElement>(getNamespacedTagFor('market-table-column')),
      });
    }

    this.emitStickyEvents();
  }

  _getMarketRowElement(element: any) {
    return element.querySelector(getNamespacedTagFor('market-table-row'));
  }

  // prevents unstick events from being fired on the slotchange before componentDidLoad
  componentLoaded: Boolean = false;

  _addCaretButtonToFirstCell() {
    if (this.cells && this.cells[0]) {
      this.nestedRowToggleButton = document.createElement('button');
      Object.assign(this.nestedRowToggleButton, {
        slot: 'nested-row-indicator',
        type: 'button',
        ariaExpanded: `${this.expanded}`,
        onclick: () => this.toggleNestedRow(),
      });
      this.nestedRowToggleButton.innerHTML = `<svg class="caret" width="14" height="8" viewBox="0 0 14 8" fill="none" style="transition-duration:300ms;" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70715 7.70711C7.31663 8.09763 6.68346 8.09763 6.29294 7.70711L0.29294 1.70711L1.70715 0.292892L7.00005 5.58579L12.2929 0.292893L13.7072 1.70711L7.70715 7.70711Z"
            fill="currentColor"
          />
        </svg>`;

      // Get the first table cell child element and append the this.nestedRowToggleButtonElement to it. Because it has the nested-row-indicator slot, it should appear in the correct place
      this.cells[0].append(this.nestedRowToggleButton);
    }
  }

  _registerSlottedControl() {
    this.slottedControl = this.el.querySelector<typeof this.slottedControl>(
      [
        `[slot="control"] ${getNamespacedTagFor('market-checkbox')}`,
        `[slot="control"] ${getNamespacedTagFor('market-toggle')}`,
      ].join(','),
    );
    if (this.slottedControl) {
      this.slottedControl.setDisabled(this.disabled);
      this.slottedControl.setSelection(this.selected);
    }
  }

  componentDidRender() {
    // Get accordion parent
    const accordionElement = this.el.closest(getNamespacedTagFor('market-accordion-item'));
    if (accordionElement) {
      let parentRow: HTMLMarketTableRowElement;
      if (this.el.slot === 'custom-trigger') {
        // If current row has nested row, we need to look a level above for
        // the correct indentation.
        const parentAccordionElement = accordionElement.parentElement?.closest(
          getNamespacedTagFor('market-accordion-item'),
        );

        // If there is a parent accordion element, find the trigger row
        // to get previous level indentation and set the current one.
        // Otherwise, the current row is at top level and indentaion will
        // remain as 0.
        if (parentAccordionElement) {
          parentRow = [...parentAccordionElement.children].find(
            (child) => child.slot === 'custom-trigger',
          ) as HTMLMarketTableRowElement;
        }
      } else {
        // Find the the trigger row within same level to set indentation
        parentRow = [...accordionElement.children].find(
          (child) => child.slot === 'custom-trigger',
        ) as HTMLMarketTableRowElement;
      }

      // Set indentation
      this.leadingIndentation = parentRow ? (parentRow.leadingIndentation ?? 0) + 1 : 0;
    }

    // Set indentation on the first cell of the row to not mess
    // with the table grid
    this._setFirstCellProperties();
  }

  componentDidLoad() {
    this.componentLoaded = true;

    if (this.el.slot === 'custom-trigger') {
      // Add caret button
      this._addCaretButtonToFirstCell();

      // If accordion is expanded when component is loaded, we have to
      // we have to make sure the caret is facing the correct way
      const svgElement = this.nestedRowToggleButton.querySelector('svg');
      if (svgElement && this.expanded) {
        svgElement.style.transform = 'rotate(-180deg)';
      }
    }
  }

  handleClick(e) {
    const ignoredElementTagNames = [
      getNamespacedTagFor('market-accessory'),
      getNamespacedTagFor('market-button'),
      getNamespacedTagFor('market-checkbox'),
      getNamespacedTagFor('market-link'),
      getNamespacedTagFor('market-toggle'),
      'button',
      'a',
      // add more interactive element tag names here
    ];

    // If the element clicked was one of the ignoredElementTagNames or anything inside of them,
    // do not trigger marketTableRowClicked
    const shouldIgnoreClick = ignoredElementTagNames.some((tagname) => e.target.closest(tagname));
    if (shouldIgnoreClick) {
      return;
    }
    if (this.interactive) {
      this.marketTableRowClicked.emit();
    }
  }

  handleKeydown(e) {
    // don't intercept keydown of descendant elements
    // e.g. when typing into nested input fields (gross)
    if (e.target !== this.el) {
      return;
    }

    switch (e.key) {
      case 'Enter':
        this.handleClick(e);
        break;
      case ' ':
        this.handleClick(e);
        e.preventDefault(); // spacebar should not scroll page
        break;
      default:
        break;
    }
  }

  render() {
    const {
      disabled,
      footer,
      gridTemplateLeft,
      gridTemplateMain,
      gridTemplateRight,
      header,
      interactive,
      selected,
      slottedControl,
    } = this;
    const MarketTableAreaTagName = getNamespacedTagFor('market-table-area');

    return (
      <Host
        aria-selected={slottedControl ? Boolean(selected).toString() : null}
        class="market-table-row"
        role="row"
        tabindex={interactive && !disabled ? '0' : null}
        header={header}
        footer={footer}
        onClick={(e) => this.handleClick(e)}
        onKeydown={(e) => this.handleKeydown(e)}
      >
        <MarketTableAreaTagName
          orientation="vertical"
          stick-to="left"
          gridTemplate={gridTemplateLeft}
          ref={(el) => (this.tableAreaLeft = el)}
        >
          <slot name="sticky-left"></slot>
        </MarketTableAreaTagName>
        <MarketTableAreaTagName
          orientation="vertical"
          gridTemplate={gridTemplateMain}
          ref={(el) => (this.tableAreaMain = el)}
          active
        >
          <slot name="control" onSlotchange={() => this._registerSlottedControl()}></slot>
          <slot onSlotchange={() => this.handleSlotChange()}></slot>
        </MarketTableAreaTagName>
        <MarketTableAreaTagName
          orientation="vertical"
          stick-to="right"
          gridTemplate={gridTemplateRight}
          ref={(el) => (this.tableAreaRight = el)}
        >
          <slot name="sticky-right"></slot>
        </MarketTableAreaTagName>
      </Host>
    );
  }
}
