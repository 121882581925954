import { Component, Event, EventEmitter, Host, h, Method, Prop, Element } from '@stencil/core';
import { getControlInputAriaLabel } from '../../utils/aria';

enum ARIA_VALUE {
  TRUE = 'true',
  FALSE = 'false',
}

@Component({
  tag: 'market-radio',
  styleUrl: 'market-radio.css',
  shadow: true,
})
export class MarketRadio {
  @Element() el: HTMLMarketRadioElement;

  /**
   * Whether the radio button is selected (analogous to the HTML input attribute `checked`).
   * If used as a slotted control inside of `market-row`, this will be overridden by the row's `selected` property.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * Whether the radio button is disabled.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * Whether the radio button is invalid.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * Whether the radio is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Whether the radio is hovered or not.
   */
  @Prop({ mutable: true, reflect: true }) hovered: boolean = false;

  /**
   * Whether the radio is active or not.
   */
  @Prop({ mutable: true, reflect: true }) active: boolean = false;

  /**
   * Fired whenever "selected" prop value changes.
   */
  @Event() marketRadioValueChange: EventEmitter<{ current: boolean; previous: boolean }>;

  innerInput: HTMLInputElement;

  /**
   * Toggles `selected` prop, and emits a change event accordingly.
   * Used by `market-row` to sync its selected state w/ slotted radio buttons.
   */
  @Method()
  setSelection(newValue: boolean, { silent = false } = {}) {
    const { marketRadioValueChange, selected: prevValue, innerInput } = this;

    if (typeof newValue !== 'boolean') return Promise.resolve();
    if (prevValue === newValue) return Promise.resolve();

    if (!silent) {
      const { defaultPrevented } = marketRadioValueChange.emit({
        current: newValue,
        previous: prevValue,
      });
      if (defaultPrevented) {
        return Promise.resolve();
      }
    }

    this.selected = newValue;
    innerInput.checked = newValue;
    return Promise.resolve();
  }

  /**
   * DEPRECATED (3.x): Toggles `selected` state (unrelated to the HTML attribute `value`).
   */
  @Method()
  setValue(newValue: boolean) {
    /* eslint-disable-next-line no-console */
    console.warn("market-radio's setValue() method has been deprecated. Use setSelection() instead.", this.el);
    this.setSelection(newValue);
    return Promise.resolve();
  }

  /**
   * Sets `active` state. Allows external elements to programmatically
   * trigger active styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setActive(value: boolean) {
    this.active = value;
    return Promise.resolve();
  }

  /**
   * Sets `hovered` state. Allows external elements to programmatically
   * trigger hover styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setHover(value: boolean) {
    this.hovered = value;
    return Promise.resolve();
  }

  /**
   * Sets `disabled` state. Allows external elements to programmatically
   * trigger disabled styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setDisabled(value: boolean) {
    this.disabled = value;
    return Promise.resolve();
  }

  /**
   * Sets `focused` state, except when disabled.
   * Allows external consumers to programmatically
   * trigger focused styling.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.disabled) {
      return Promise.resolve();
    }
    this.focused = value;
    return Promise.resolve();
  }

  componentDidRender() {
    if (!this.innerInput) {
      this.innerInput = this.el.shadowRoot.querySelector('input');
    }
  }

  onFocus() {
    if (this.disabled) {
      return;
    }

    this.focused = true;
    this.el.shadowRoot.querySelector('input').focus();
  }

  handleClick(event: MouseEvent) {
    // Always prevent default so we can manually control the selection
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    // once a radio is selected, it shouldn't be togglable/deselectable on click
    if (!this.selected) {
      this.setFocus();
      this.setSelection(true);
    }
  }

  render() {
    const ariaChecked = this.selected ? ARIA_VALUE.TRUE : ARIA_VALUE.FALSE;

    return (
      <Host
        class="market-radio"
        role="radio"
        aria-checked={ariaChecked}
        onBlur={() => {
          this.setFocus(false);
        }}
        onClick={this.handleClick}
        onFocus={() => {
          this.setFocus();
        }}
      >
        <input
          type="radio"
          aria-checked={ariaChecked}
          aria-label={getControlInputAriaLabel(this.el)}
          checked={this.selected}
          disabled={this.disabled}
        />
        {this.selected && (
          <svg width="6" height="6" viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" />
          </svg>
        )}
      </Host>
    );
  }
}
