import { use } from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import { SupportedLocale } from 'src/MessengerTypes';
import resourcesToBackend from 'i18next-resources-to-backend';

// Keep track of whether we've already finished setting up i18n
let i18nInitialized = false;

/**
 * Setup internationalization. See detectionUtils for how a language is
 * chosen.
 *
 * @param {SupportedLocale} locale
 */
export default function setup(locale: SupportedLocale): Promise<void> {
  if (i18nInitialized) {
    // Case: Initialized internationalization in an earlier setup() call.
    // See race in index.tsx
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    // Set up internationalization
    use(initReactI18next) // passes i18n down to react-i18next
      .use(ICU)
      .use(
        resourcesToBackend(async () => {
          const localeSnakeCase = locale.replace('-', '_').toLowerCase();
          let file;
          if (localeSnakeCase === 'en_us') {
            file = await import(`./translations/en.json`);
          } else {
            file = await import(
              `./translations/${localeSnakeCase}/${localeSnakeCase}.json`
            );
          }
          // This is because webpack bundles the json file as a module with a default export
          return file.default.translation;
        }),
      )
      .init(
        {
          fallbackLng: 'en',
          lowerCaseLng: true,
          returnNull: false,
          react: {
            useSuspense: false,
          },
        },
        (err) => {
          if (err == null) {
            i18nInitialized = true;
            resolve();
          } else {
            reject(err);
          }
        },
      );
  });
}
