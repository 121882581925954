import { Component, Element, Event, EventEmitter, h, Host, Listen, Prop } from '@stencil/core';

import { getNamespacedTagFor } from '../../../../utils/namespace';
import { TMarketPaginationPageSizeChangeEventDetail } from '../../events';

@Component({
  tag: 'market-pagination-page-size',
})
export class MarketPaginationPageSize {
  @Element() el: HTMLMarketPaginationPageSizeElement;

  /**
   * The number of results displayed per page.
   */
  @Prop() readonly value!: string;

  /**
   * Options for page sizes (comma separated list).
   */
  @Prop() readonly pageSizeOptions!: string;

  /**
   * Fired when the selected page size value changes
   *
   * @property {string} value - new selected option
   */
  @Event() marketInternalPaginationPageSizeChange: EventEmitter<TMarketPaginationPageSizeChangeEventDetail>;

  @Listen('marketListSelectionsDidChange')
  listSelectionEventHander(e: CustomEvent) {
    const { newSelectionValue } = e.detail;
    this.marketInternalPaginationPageSizeChange.emit({
      value: newSelectionValue,
    });
  }

  render() {
    const MarketButtonDropdownTagName = getNamespacedTagFor('market-button-dropdown');
    const MarketFilterButtonTagName = getNamespacedTagFor('market-filter-button');
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketRowTagName = getNamespacedTagFor('market-row');

    return (
      <Host class="market-pagination-page-size">
        <MarketButtonDropdownTagName no-caret popover-placement="bottom-start" persist-list>
          <MarketFilterButtonTagName size="small" slot="trigger">
            <span>
              <slot name="page-size-label">Results per page</slot>
            </span>
            <span slot="feedback">
              <slot name="page-size-feedback">{this.value}</slot>
            </span>
          </MarketFilterButtonTagName>
          <MarketListTagName value={this.value} slot="content">
            {this.pageSizeOptions.split(',').map((v) => (
              <MarketRowTagName key={`pageSize_${v}`} value={v}>
                {v}
              </MarketRowTagName>
            ))}
          </MarketListTagName>
        </MarketButtonDropdownTagName>
      </Host>
    );
  }
}
