import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  ConversationHistoryPage as ConversationHistoryMessengerPage,
  Transition,
} from 'src/MessengerTypes';
import TranscriptsHistoryList from 'src/pages/CustomerDetailPage/components/TranscriptsHistoryList/TranscriptsHistoryList';
import ListPage from 'src/components/ListPage/ListPage';

export type ConversationHistoryPageProps = {
  transitionDirection: Transition;
};

/**
 * Page used to display a list of the conversation history for a customer
 *
 * @param {Transition} transitionDirection
 * The direction of the transition animation.
 */
const ConversationHistoryPage = observer(
  ({
    transitionDirection,
  }: ConversationHistoryPageProps): ReactElement | null => {
    const { navigation, customers } = useMessengerControllerContext();
    const { t } = useTranslation();

    const page = navigation.tertiaryNav
      .currentPage as ConversationHistoryMessengerPage;

    if (!page || !page.customerToken) {
      return null;
    }

    const { history } = customers.get(page.customerToken);

    return (
      <ListPage
        transitionDirection={transitionDirection}
        title={t('CustomerDetailPage.conversation_history_title')}
        status={history.status}
        retry={history.init}
      >
        <TranscriptsHistoryList history={history} />
      </ListPage>
    );
  },
);

export default ConversationHistoryPage;
