import { autorun, makeAutoObservable, runInAction, toJS, when } from 'mobx';
import type MessengerController from 'src/MessengerController';
import NavigationStore from 'src/stores/navigation/NavigationStore';
import {
  ContactMethod,
  EditVoicemailPage,
  isMessengerPage,
  MESSENGER_SHEET_PAGE_NAMES,
  MessengerApplication,
  MessengerPage,
  MessengerSheetPageName,
  MessengerSheetSectionName,
  MessengerUrlState,
  OpenTranscriptEventLoggingSource,
  StatusType,
  TranscriptMessengerPage,
  UnitsToVerifyMessengerPage,
  UnitVerificationFormMessengerPage,
} from 'src/MessengerTypes';
import { t } from 'i18next';
import { isAuthError } from 'src/utils/transcriptUtils';
import Logger from 'src/Logger';
import {
  KEY_MESSAGES_PLUS,
  KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED,
} from 'src/stores/FeatureFlagStore';
import {
  getEditVoicemailUrl,
  getInboxTranscriptViewUrl,
  getSourceFromQueryString,
  getUnitTokensFromQueryString,
  getUnitVerificationFormUrl,
  getUtteranceIdFromQueryString,
  INBOX_BUSINESS_NUMBERS_URL,
  INBOX_EDIT_VOICEMAIL_URL_REGEX,
  INBOX_HOME_URL,
  INBOX_MESSAGES_PLUS_PRICING_URL,
  INBOX_MESSAGES_PLUS_START_VERIFICATION_URL,
  INBOX_MESSAGES_PLUS_SUCCESS_NUMBERS_URL,
  INBOX_MESSAGES_PLUS_SUCCESS_URL,
  INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_REGEX,
  INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_SUCCESS_URL,
  INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL,
  INBOX_NEW_URL,
  INBOX_SEARCH_URL,
  INBOX_SETTINGS_URL,
  INBOX_SQ_ONLINE_SETTINGS_URL,
  INBOX_TRANSCRIPT_VIEW_URL_REGEX,
  LINK_PLUGIN_URL,
  setUrl,
  SHEET_PAGE_ID_TO_URL,
} from 'src/utils/url';
import Transcript from 'src/stores/objects/Transcript';

export const TRANSCRIPT_LIST_TIMEOUT = 45000;

/**
 * Singleton responsible for managing the various navigation states of the Messages application.
 *
 * Contains the logic to maintain the URL across application state changes. Specifically, there are three parts to this:
 * 1) Update the URL value each time the current page changes (i.e. if the user navigates from the new message page to the
 * settings page, the URL should be updated from /new to /settings). This is handled by the mobx autorun defined in the constructor,
 * by calling history.pushState() to add a new URL to the browser history stack.
 * 2) Update the application state any time the user navigates using the browser back/forward buttons (i.e. if the user hits back
 * in the browser, we should update the app state to the previous page). This is handled by the popstate listener defined in the constructor.
 * 3) On app startup, set the state to match the initial URL (i.e. if the user first visits Messages using
 * the inbox.squareup.com/new URL, we should set the initial page to the new message page and not the conversation view). This is
 * handled by the _openPageFromUrl helper in openMessengerFullPage.
 */
class MessengerNavigationStore {
  private _stores: MessengerController;

  /**
   * Represents the navigation for the primary view.
   * - The entire page on the Messages Blade or Mobile Full Page app.
   * - The left most content view of the Full Page app on desktop/tablet.
   */
  primary: NavigationStore;

  /**
   * Represents the navigation for the secondary view.
   * - The right most content view of the Full Page app on desktop/tablet.
   */
  secondary: NavigationStore;

  /**
   * Represents the navigation for the tertiary view.
   * - The collapsible blade on the right of the screen in the Full Page app.
   */
  tertiary: NavigationStore;

  /**
   * Represents the navigation for the sheet view.
   */
  sheet: NavigationStore<MessengerSheetSectionName>;

  application: MessengerApplication = 'BLADE';

  /**
   * Tracks whether the navigation state has been initialized. Used to ensure we don't remove
   * any URL path before we have initialized the app state from the initial URL.
   */
  _isFullPageInitialized = false;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this.primary = new NavigationStore(stores, ['SINGLE_CONVERSATION']);
    this.secondary = new NavigationStore(stores);
    this.tertiary = new NavigationStore(stores);
    this.sheet = new NavigationStore(stores);

    // Updates the page URL to match the navigation state everytime the navigation state changes
    // autorun automatically re-runs any time one of the observable/computed values used in the function changes
    autorun(() => {
      if (!this.isInboxUrlsEnabled || !this._isFullPageInitialized) {
        return;
      }
      if (this.sheet.isOpen) {
        const page = this.sheet.currentPageName;
        if (page && SHEET_PAGE_ID_TO_URL[page]) {
          setUrl(SHEET_PAGE_ID_TO_URL[page], { page });
        } else if (page === 'UNITS_TO_VERIFY') {
          const pageObj = this.sheet.currentPage as UnitsToVerifyMessengerPage;
          setUrl(INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL, {
            page,
            id: toJS(pageObj.unitTokens),
          });
        } else if (page === 'UNIT_VERIFICATION_FORM') {
          const page = this.sheet
            .currentPage as UnitVerificationFormMessengerPage;
          setUrl(getUnitVerificationFormUrl(page.unitToken), {
            page: 'UNIT_VERIFICATION_FORM',
            id: page.unitToken,
          });
        } else if (page === 'EDIT_VOICEMAIL') {
          const page = this.sheet.currentPage as EditVoicemailPage;
          setUrl(getEditVoicemailUrl(page.unitToken), {
            page: 'EDIT_VOICEMAIL',
            id: page.unitToken,
          });
        }
        return;
      }

      switch (this.navStoreForUrl.currentPage?.name) {
        case 'NEW_MESSAGE':
          setUrl(INBOX_NEW_URL, { page: 'NEW_MESSAGE' });
          break;
        case 'TRANSCRIPT_VIEW': {
          const page = this.navStoreForUrl
            .currentPage as TranscriptMessengerPage;
          setUrl(getInboxTranscriptViewUrl(page?.transcriptId), {
            page: 'TRANSCRIPT_VIEW',
            id: page?.transcriptId,
          });
          break;
        }
        case 'TRANSCRIPTS_LIST':
          setUrl(INBOX_HOME_URL, { page: 'TRANSCRIPTS_LIST' });
          break;
          break;
        case 'SEARCH':
          setUrl(INBOX_SEARCH_URL, { page: 'SEARCH' });
          break;
        case 'NO_SELECTED_TRANSCRIPT':
          setUrl('/', { page: 'NO_SELECTED_TRANSCRIPT' });
          break;
        case 'DEFAULT':
        default: {
          setUrl('/', { page: 'DEFAULT' });
          break;
        }
      }
    });

    // Each time the browser back/forward button is clicked, update the navigation state to match the latest URL
    window.addEventListener('popstate', (event: PopStateEvent) => {
      if (!this.isInboxUrlsEnabled) {
        return;
      }
      this._navigateToPageOnUrlChange(event.state || {});
    });
  }

  private _navigateToPageOnUrlChange = (state: MessengerUrlState): void => {
    switch (state.page) {
      case 'SETTINGS':
        // TODO(wdetlor): Also fallthrough once KEY_MESSAGES_PLUS is removed
        this.openSheet('SETTINGS');
        break;
      case 'MESSAGES_PLUS_PRICING':
      case 'BUSINESS_NUMBERS':
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this.openSheet(state.page);
        }
        break;
      case 'UNIT_VERIFICATION_SUCCESS':
        if (
          this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
          this._stores.subscription.isSubscribed
        ) {
          this.openSheet(state.page);
        }
        break;
      case 'UNITS_TO_VERIFY':
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this.openSheet({
            name: state.page,
            unitTokens: state.id,
          } as UnitsToVerifyMessengerPage);
          return;
        }
        break;
      case 'UNIT_VERIFICATION_FORM':
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this.openSheet({
            name: state.page,
            unitToken: state.id,
          } as UnitVerificationFormMessengerPage);
        }
        break;
      case 'EDIT_VOICEMAIL':
        if (
          this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
          this._stores.subscription.isUnitSubscribed(`${state.id || ''}`) &&
          this._stores.user.units.get(`${state.id || ''}`)?.subscription
            ?.dedicatedNumber
        ) {
          this.openSheet({
            name: state.page,
            unitToken: state.id,
          } as EditVoicemailPage);
        }
        break;
      case 'TRANSCRIPT_VIEW':
        this.openTranscript(state.id as number, 'web_link');
        break;
      case 'NEW_MESSAGE':
      case 'TRANSCRIPTS_LIST':
      case 'DEFAULT':
        this.navStoreForUrl.navigateTo(state.page);
        break;
      case 'SEARCH':
        this.primary.navigateTo(state.page);
        break;
      case 'MESSAGES_PLUS_START_VERIFICATION':
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this.openSheet(state.page);
        }
        break;
      case 'SQ_ONLINE_SETTINGS':
        if (
          this._stores.featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED)
        ) {
          this.openSheet(state.page);
        }
        break;
      case 'NO_SELECTED_TRANSCRIPT':
        this.secondary.navigateTo(state.page);
        break;
      default:
        break;
    }

    if (
      !(MESSENGER_SHEET_PAGE_NAMES as readonly string[]).includes(state.page) &&
      this.sheet.isOpen
    ) {
      this.sheet.close();
    }
  };

  get isInboxUrlsEnabled(): boolean {
    return this.application === 'FULL_PAGE';
  }

  /**
   * On mobile, the navigation store associated with the URL is the primary one, but it is the secondary one on desktop.
   * This computed returns the navigation store to use for changes related to the URL.
   */
  get navStoreForUrl(): NavigationStore {
    return this.secondary.isOpen ? this.secondary : this.primary;
  }

  /**
   * Returns the tertiary navigation store to use in the UI, which is the primary store if the multi-nav store view is not open.
   * On mobile web, only the primary navigation store is open and thus should be used there.
   * On desktop, the primary, secondary, and tertiary nav stores are all used.
   */
  get tertiaryNav(): NavigationStore {
    return this.secondary.isOpen ? this.tertiary : this.primary;
  }

  /**
   * Method to set the Messages Application.
   *
   * @param {MessengerApplication} application
   * The Messages application currently shown to the user.
   */
  setMessengerApplication = (application: MessengerApplication): void => {
    this.application = application;
  };

  /**
   * Flag indicating whether the full page application is open.
   *
   * @returns {boolean}
   */
  get isFullPageMessenger(): boolean {
    return this.application === 'FULL_PAGE';
  }

  /**
   * Opens the sheet and navigates it to a specific page.
   *
   * @param {MessengerSheetPageName | MessengerPage} page
   * The page to open the sheet to.
   * @param {MessengerSheetSectionName} [section]
   * (Optional) The section to open to, if any.
   */
  openSheet = (
    page: MessengerSheetPageName | MessengerPage,
    section?: MessengerSheetSectionName,
  ): void => {
    if (!this._hasPermissionToOpenPage(page)) {
      this._stores.status.setError({
        label: t('common.error.permission'),
      });
      return;
    }

    this.sheet.open();
    this.sheet.navigateTo(page, section);
  };

  /**
   * Closes the sheet.
   */
  closeSheet = (): void => {
    this.sheet.clearNavigation();
    this.sheet.close();
  };

  /**
   * Opens the conversation view to a given transcript ID.
   *
   * @param {number} id
   * The ID of the transcript to open the conversation view to.
   * @param {OpenTranscriptEventLoggingSource} source
   * The source used for logging where the transcript was opened from.
   * @param {number} [utteranceId]
   * The ID of the utterance to open the transcript to
   */
  openTranscript = (
    id: number,
    source: OpenTranscriptEventLoggingSource,
    utteranceId?: number,
  ): void => {
    const transcript = this._stores.transcripts.get(id);

    if (transcript.status === 'NOT_STARTED') {
      transcript.load(utteranceId);
    } else if (
      (utteranceId && utteranceId !== transcript.seekUtteranceId) ||
      (!utteranceId && transcript.hasNextPage)
    ) {
      // transcript was previously loaded from the latest utterances,
      // and now we need to reset in order to load from a specific utteranceId
      // OR transcript was previously seeked to a specific utterance (and thus has a next page), and we need to reset for next load
      transcript.clearUtterances();
      transcript.load(utteranceId);
    }

    this.navStoreForUrl.navigateTo({
      name: 'TRANSCRIPT_VIEW',
      transcriptId: id,
    });

    this._stores.event.track('Open Conversation', {
      transcript_id: id,
      utterance_id: utteranceId,
      open_source: source,
    });
  };

  /**
   * Opens Messages to a transcript associated with a contact method.
   *
   * @param {ContactMethod} contactMethod
   * The contact method of the transcript that should be opened.
   * @param {OpenTranscriptEventLoggingSource} source
   * The source used for logging where the transcript was opened from.
   */
  openTranscriptByContactMethod = async (
    contactMethod: ContactMethod,
    source: OpenTranscriptEventLoggingSource,
  ): Promise<Transcript | undefined> => {
    // Navigates to the status page while the transcript loads
    this.navStoreForUrl.navigateTo('STATUS');
    try {
      const transcript = await this._stores.transcripts.getByAlternativeId(
        contactMethod,
      );
      // Don't navigate further if the user navigated away from the status page
      if (this.navStoreForUrl.currentPageName === 'STATUS') {
        this.navStoreForUrl.navigateBack();
        this.openTranscript(transcript.id, source);
      }
      return transcript;
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          'MessengerNavigationStore:openTranscriptByContactMethod - There was an error attempting to open the transcript for the provided contact method.',
          'error',
          { contactMethod, error },
        );
      }
      if (this.navStoreForUrl.currentPageName === 'STATUS') {
        this.navStoreForUrl.navigateTo('NEW_MESSAGE');
        this._stores.status.setError();
      }
      return undefined;
    }
  };

  /**
   * Common error handling logic for when opening a conversation
   * fails.
   *
   * @param {string} label
   * Message to display to the user.
   * @param {StatusType} type
   * Status type for the message (i.e. WARNING, ERROR, etc.).
   */
  _handleErrorOpeningConversation = (
    label: string,
    type: StatusType = 'WARNING',
  ): void => {
    this.primary.openAndClearNavigation();
    this.primary.navigateTo('TRANSCRIPTS_LIST');
    this._stores.status.setError({
      label,
      type,
    });
  };

  /**
   * Opens messenger drawer and navigate to the list page if there is
   * no page already selected.
   */
  openMessenger = (): void => {
    this._stores.session.isUsingMessages = true;
    this.primary.open();
    if (!this.primary.hasPages) {
      this.primary.navigateTo('TRANSCRIPTS_LIST');
    }
  };

  /**
   * Opens the Full Page Messenger app.
   *
   * @param {boolean} isMobile
   * Flag indicating if the application is on a mobile sized screen.
   */
  openMessengerFullPage = async (isMobile: boolean): Promise<void> => {
    this._stores.session.isUsingMessages = true;
    this.setMessengerApplication('FULL_PAGE');

    this.primary.open();

    if (!isMobile) {
      this.secondary.open();
    }

    // Get a copy of the current path before we navigate to the TRANSCRIPTS_LIST
    const path = window.location.pathname;
    this.primary.navigateTo('TRANSCRIPTS_LIST');

    this._openPageFromUrl(path);

    if (!isMobile) {
      await this.openSecondaryView();
    }

    runInAction(() => {
      this._isFullPageInitialized = true;
    });
  };

  /**
   * Opens a page based on the URL on app startup.
   * Call history.replaceState() to ensure that the page and ID state is included in the
   * history API for later reference when states are popped.
   *
   * @param {string} path
   * The path of the current URL.
   */
  private _openPageFromUrl = (path: string): void => {
    switch (path) {
      case LINK_PLUGIN_URL:
      case INBOX_SETTINGS_URL:
        this.openSheet('SETTINGS');
        window.history.replaceState(
          { page: 'SETTINGS' },
          '',
          INBOX_SETTINGS_URL,
        );
        return;
      case INBOX_MESSAGES_PLUS_PRICING_URL:
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this._stores.subscription.checkOnboardingSource();
          this.openSheet('MESSAGES_PLUS_PRICING');
          window.history.replaceState(
            { page: 'MESSAGES_PLUS_PRICING' },
            '',
            INBOX_MESSAGES_PLUS_PRICING_URL,
          );
        }
        return;
      case INBOX_BUSINESS_NUMBERS_URL:
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          this.openSheet('BUSINESS_NUMBERS');
          window.history.replaceState(
            { page: 'BUSINESS_NUMBERS' },
            '',
            INBOX_BUSINESS_NUMBERS_URL,
          );
        }
        return;
      case INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL:
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          const unitTokens = getUnitTokensFromQueryString();
          this.openSheet({ name: 'UNITS_TO_VERIFY', unitTokens });
          window.history.replaceState(
            { page: 'UNITS_TO_VERIFY', id: unitTokens },
            '',
            INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL,
          );
          return;
        }
        return;
      case INBOX_MESSAGES_PLUS_SUCCESS_URL:
      case INBOX_MESSAGES_PLUS_SUCCESS_NUMBERS_URL:
      case INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_SUCCESS_URL:
        if (
          this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
          this._stores.subscription.isSubscribed
        ) {
          this.openSheet('UNIT_VERIFICATION_SUCCESS');
          window.history.replaceState(
            { page: 'UNIT_VERIFICATION_SUCCESS' },
            '',
            INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_SUCCESS_URL,
          );
        }
        return;
      case INBOX_SEARCH_URL:
        this.primary.navigateTo('SEARCH');
        window.history.replaceState({ page: 'SEARCH' }, '', INBOX_SEARCH_URL);
        return;
      case INBOX_NEW_URL:
        this.navStoreForUrl.navigateTo('NEW_MESSAGE');
        window.history.replaceState({ page: 'NEW_MESSAGE' }, '', INBOX_NEW_URL);
        return;
      case INBOX_MESSAGES_PLUS_START_VERIFICATION_URL:
        if (this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) {
          const unitTokens = this._stores.subscription.checkOnboardingUnits();
          if (unitTokens && unitTokens.length > 0) {
            this.resumeMessagesPlusOnboarding(unitTokens);
            return;
          }

          this.openSheet('MESSAGES_PLUS_START_VERIFICATION');
          window.history.replaceState(
            { page: 'MESSAGES_PLUS_START_VERIFICATION' },
            '',
            INBOX_MESSAGES_PLUS_START_VERIFICATION_URL,
          );
        }
        return;
      case INBOX_SQ_ONLINE_SETTINGS_URL:
        if (
          this._stores.featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED)
        ) {
          this.openSheet('SQ_ONLINE_SETTINGS');
          window.history.replaceState(
            { page: 'SQ_ONLINE_SETTINGS' },
            '',
            INBOX_SQ_ONLINE_SETTINGS_URL,
          );
        }
        return;
      default:
        break;
    }

    // Match on transcript view url
    const urlMatchTranscriptView = path.match(INBOX_TRANSCRIPT_VIEW_URL_REGEX);
    if (urlMatchTranscriptView) {
      const id = Number.parseInt(urlMatchTranscriptView[1], 10); // Regex extracts ID from path param
      const utteranceId = getUtteranceIdFromQueryString();
      const source =
        getSourceFromQueryString() === 'email' ? 'email' : 'web_link';
      this.openTranscript(id, source, utteranceId);
      window.history.replaceState(
        { page: 'TRANSCRIPT_VIEW', id },
        '',
        getInboxTranscriptViewUrl(id),
      );
    }
    // Match on M+ unit verification url
    const urlMatchUnitVerification = path.match(
      INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_REGEX,
    );
    if (
      urlMatchUnitVerification &&
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS)
    ) {
      const unitToken = urlMatchUnitVerification[1]; // Regex extracts ID from path param

      this.openSheet({ name: 'UNIT_VERIFICATION_FORM', unitToken });
      window.history.replaceState(
        {
          page: 'UNIT_VERIFICATION_FORM',
          id: unitToken,
        },
        '',
        getUnitVerificationFormUrl(unitToken),
      );
    }

    const urlMatchEditVoicemail = path.match(INBOX_EDIT_VOICEMAIL_URL_REGEX);
    const unitToken = urlMatchEditVoicemail?.[1]; // Regex extracts unit token from first path param
    if (
      urlMatchEditVoicemail &&
      unitToken &&
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
      this._stores.subscription.isUnitSubscribed(unitToken) &&
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
    ) {
      this.openSheet({ name: 'EDIT_VOICEMAIL', unitToken });
      window.history.replaceState(
        {
          page: 'EDIT_VOICEMAIL',
          id: unitToken,
        },
        '',
        getEditVoicemailUrl(unitToken),
      );
    }
  };

  /**
   * Helper function to open the secondary view and select the first transcript
   * in the list if none is already selected.
   */
  openSecondaryView = async (): Promise<void> => {
    this.secondary.open();
    if (!this.secondary.hasPages) {
      await this._openFirstTranscriptInList();
    }
    // If there are still no transcripts, navigate to the default page
    if (!this.secondary.hasPages) {
      this.secondary.navigateTo('DEFAULT');
    }
  };

  /**
   * Opens the first transcript in the list if there is one.
   */
  _openFirstTranscriptInList = async (): Promise<void> => {
    try {
      await when(
        () => this._stores.transcriptsLists.active.status === 'SUCCESS',
        {
          timeout: TRANSCRIPT_LIST_TIMEOUT,
        },
      );
      if (this._stores.transcriptsLists.active.size > 0) {
        this.openTranscript(
          this._stores.transcriptsLists.active.ids[0],
          'conversations_list',
        );
      }
    } catch (error) {
      Logger.logWithSentry(
        'MessengerNavigationStore:_openFirstTranscriptInList - Error retrieving transcripts list.',
        'error',
        { error },
      );
    }
  };

  /**
   * Method that navigates the correct view to the customer detail page
   * and sets the corresponding customer token.
   *
   * @param {number} transcriptId
   * The transcript ID that the customer token is loaded for.
   * @param {string} customerToken
   * The customer token to open the customer detail view to.
   */
  openCustomerDetailView = (
    transcriptId: number,
    customerToken: string,
  ): void => {
    const customer = this._stores.customers.get(customerToken);
    customer.loadTranscriptsHistory();
    customer.loadPastAppointments();
    this._conditionallyOpenTertiaryView({
      name: 'CUSTOMER_DETAIL',
      transcriptId,
      customerToken,
    });
  };

  /**
   * Method that navigates the correct view to the past appointments page
   * and sets the corresponding customer token.
   *
   * @param {string} customerToken
   * The customer token to open the list of past appointments for.
   */
  openPastAppointmentsView = (customerToken: string): void => {
    const customer = this._stores.customers.get(customerToken);
    customer.loadPastAppointments();
    this._conditionallyOpenTertiaryView({
      name: 'PAST_APPOINTMENTS',
      customerToken,
    });
  };

  /**
   * Method that navigates the correct view to the conversation history page.
   *
   * @param {string} customerToken
   * The customer token to open the conversation history for.
   * @param {number} transcriptId
   * The transcript ID that the conversation history is loaded for.
   */
  openConversationHistoryView = (
    customerToken: string,
    transcriptId: number,
  ): void => {
    const customer = this._stores.customers.get(customerToken);
    customer.loadTranscriptsHistory();
    this._conditionallyOpenTertiaryView({
      name: 'CONVERSATION_HISTORY',
      customerToken,
      transcriptId,
    });
  };

  /**
   * Method that navigates the tertiary view when the secondary view is open,
   * and otherwise navigates the primary view. The open secondary view indicates
   * we are on desktop.
   *
   * @param {MessengerPage} page
   * The page to open to.
   */
  _conditionallyOpenTertiaryView = (page: MessengerPage): void => {
    if (this.secondary.isOpen) {
      this.tertiary.navigateTo(page);
    } else {
      this.primary.navigateTo(page);
    }
  };

  /**
   * Opens the UI to the unit verification view for the set of unit tokens provided.
   *
   * @param {string[]} unitTokens
   * The list of unit tokens to open the corresponding unit verification view for.
   */
  openUnitVerificationView = (unitTokens: string[]): void => {
    if (unitTokens.length === 1) {
      this.openSheet({
        name: 'UNIT_VERIFICATION_FORM',
        unitToken: unitTokens[0],
      });
      return;
    }
    this.openSheet({
      name: 'UNITS_TO_VERIFY',
      unitTokens,
    });
  };

  /**
   * Opens messenger to a single conversation, i.e. directly to the view page.
   *
   * @deprecated - Only supported for backwards compatibility after the launch of
   * de-threading. Clients should transition to opening by contact ID instead.
   * @param {string} [token] - rolodex token identifying a customer
   */
  openMessengerByCustomerToken = async (token?: string): Promise<void> => {
    this._stores.session.isUsingMessages = true;
    if (token) {
      this.primary.openAndClearNavigation('SINGLE_CONVERSATION');
      try {
        const transcript = await this._stores.transcripts.getByAlternativeId({
          customerToken: token,
        });
        this.openTranscript(transcript.id, 'messages_sdk');
      } catch (error) {
        if (!isAuthError(error)) {
          Logger.logWithSentry(
            'MessengerNavigationStore:openMessengerByCustomerToken - Error attempting to open Messages by customer token.',
            'error',
            { error, token },
          );
        }
        this._handleErrorOpeningConversation(
          t('common.error.try_again'),
          'ERROR',
        );
      }
    } else {
      // We're in SINGLE_CONVERSATION mode, but received a falsy token.
      // This can happen if a Feedback item doesn't have a customer token.
      // Display an error message. If it happens, we open to full Messenger
      // with a banner stating the customer isn't in directory.
      this._handleErrorOpeningConversation(
        t('TranscriptViewPage.error_customer_not_found'),
      );
    }
  };

  /**
   * Open Messenger by transcript ID in SINGLE_CONVERSATION mode.
   * If the associated transcript is orphaned, open the main conversations
   * list.
   *
   * This should only ever be used with the query param transcript_id,
   * and only ever in response to a transcript notification.
   * Otherwise, use openMessenger.
   *
   * This is a special function added specifically for the Android
   * web view integration.
   * TODO(eblaine): Remove this function and the code that looks
   * for the `transcript_id` query param in index.tsx once we have
   * Messages for Android.
   *
   * @param {number} transcriptId - the transcript to search conversations for
   */
  openMessengerByTranscript = (transcriptId: number): void => {
    this._stores.session.isUsingMessages = true;
    this.primary.openAndClearNavigation('SINGLE_CONVERSATION');
    this.openTranscript(transcriptId, 'web_link');
  };

  /**
   * Opens messenger in SINGLE_CONVERSATION mode to the transcript
   * associated with the provided contact ID, and optionally, a unit token.
   * If unit token is not provided, a unit will be chosen arbitrarily.
   *
   * @param {ContactMethod} contactMethod
   * The contact method of the transcript that should be opened.
   */
  openMessengerByContactMethod = async (
    contactMethod: ContactMethod,
  ): Promise<void> => {
    this._stores.session.isUsingMessages = true;
    this.primary.openAndClearNavigation('SINGLE_CONVERSATION');
    try {
      const transcript = await this._stores.transcripts.getByAlternativeId(
        contactMethod,
      );
      this.openTranscript(transcript.id, 'messages_sdk');
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          'MessengerNavigationStore:openMessengerByContactId - Error attempting to open Messages by contact ID and medium pairing.',
          'error',
          { error, contactMethod },
        );
      }
      this._handleErrorOpeningConversation(
        t('common.error.try_again'),
        'ERROR',
      );
    }
  };

  /**
   * Open the unit verification page with the unit tokens as selected
   * units for subscription, and open the subscription modal. Used mainly
   * when we want to return the user back to the onboarding after navigating
   * them away to add payment method.
   *
   * @param {string[]} unitTokens - the units to subscribe
   */
  resumeMessagesPlusOnboarding = (unitTokens: string[]): void => {
    if (unitTokens.length === 1) {
      this.openSheet({
        name: 'UNIT_VERIFICATION_FORM',
        unitToken: unitTokens[0],
      });
      window.history.replaceState(
        {
          page: 'UNIT_VERIFICATION_FORM',
          id: unitTokens[0],
        },
        '',
        getUnitVerificationFormUrl(unitTokens[0]),
      );
    } else {
      this.openSheet({ name: 'UNITS_TO_VERIFY', unitTokens });
      window.history.replaceState(
        { page: 'UNITS_TO_VERIFY', id: unitTokens },
        '',
        INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL,
      );
    }
    this._stores.modal.openMessagesPlusSubscriptionModal(unitTokens);
  };

  /**
   * Check if we have permission to access a page.
   *
   * @param {MessengerSheetPageName | MessengerPage} page
   */
  private _hasPermissionToOpenPage = (
    page: MessengerSheetPageName | MessengerPage,
  ): boolean => {
    const pageName = isMessengerPage(page) ? page.name : page;
    switch (pageName) {
      // When navigating to M+ onboarding pages, check if user has permission
      // to manage M+.
      case 'MESSAGES_PLUS_PRICING':
      case 'MESSAGES_PLUS_START_VERIFICATION':
      case 'UNITS_TO_VERIFY':
      case 'UNIT_VERIFICATION_FORM': {
        /**
         * For SqOne, either of the condition where user cannot access these pages:
         * 1. The user cannot manage subscription, i.e. prohibited or no employee permission
         * 2. Eligible for SqOne but not subscribed yet, or M+ features not launched in that country
         * 3. All units subscribed to SqOne has dedicated numbers
         */
        if (
          this._stores.subscription.isEligibleForSquareOne &&
          (!this._stores.subscription.canManage ||
            this._stores.subscription.isMessagesFeatureDisabledWithSquareOne ||
            !this._stores.subscription.hasSqOneUnitsWithNoNumber)
        ) {
          return false;
        }

        if (
          !this._stores.subscription.isEligibleForSquareOne &&
          !this._stores.subscription.canManage
        ) {
          return false;
        }
        break;
      }
      default:
    }

    return true;
  };
}

export default MessengerNavigationStore;
