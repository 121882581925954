@import "../../styles/mixins/typography.css";

/**
 * This component uses CSS Grid areas to handle the differences in rendering size="small" tiles and
 * size="medium" tiles. The areas are set up to look like this in a medium tile:
 * LA = leading-accessory
 * TA = trailing-accessory
 * C = content
 * ------------------
 * | LA    _     TA |
 * |                |
 * | C     C      C |
 * |________________|
 * But in a small tile, the areas are shifted:
 * ------------------
 * | LA    C     TA |
 * |________________|
 */

:host {
  --item-tile-text-color: black;
  --focus-ring-color:
    color-mix(
      in srgb,
      var(--item-tile-focus-ring-color, var(--core-focus-ring-color)) 50%,
      transparent
    );

  position: relative;
  z-index: 1;
  display: grid;
  grid-template-areas:
    "leading-accessory _ trailing-accessory"
    "content content content";
  grid-template-columns: minmax(0, max-content) 1fr auto;
  box-sizing: border-box;
  width: var(--item-tile-width, 200px);
  height: var(--item-tile-medium-height, 124px);
  padding: var(--item-tile-medium-padding, var(--core-metrics-spacing-150));
  border-radius: var(--item-tile-border-radius, 6px);
  background: var(--item-tile-fill-color, var(--core-fill-40-color));
  user-select: none;

  &([interactive]) {
    cursor: pointer;
  }

  &([size="small"]) {
    grid-template-areas: "leading-accessory content trailing-accessory";
    grid-template-columns: max-content 1fr minmax(0, max-content);
    height: var(--item-tile-small-height, 56px);
    padding:
      var(--item-tile-small-padding-vertical, var(--core-metrics-spacing-50))
      var(--item-tile-small-padding-horizontal, var(--core-metrics-spacing-150));
    column-gap: var(--core-metrics-spacing-100);

    ::slotted([slot="leading-accessory"]),
    .trailing-accessory-container {
      align-items: center;
    }

    .content {
      justify-content: center;
      align-items: start;
    }

    /** When size="small", if position is absolute, the label runs over the remove button */
    .remove-button {
      position: relative;
    }
  }

  &([aria-disabled]) {
    opacity: 20%;
    cursor: not-allowed;

    .remove-button {
      cursor: not-allowed;

      &:hover {
        background: var(--item-tile-remove-button-fill, #fff);
      }
    }
  }

  &([aria-selected="true"]) {
    box-shadow:
      inset 0 0 0 var(--item-tile-selected-value-border-width, 2px)
      var(--item-tile-selected-value-border-color, var(--core-emphasis-fill-color));
  }

  &(:focus-visible) {
    outline: var(--item-tile-focus-ring-border-size, var(--core-focus-ring-border-size)) solid var(--focus-ring-color);
    outline-offset: var(--item-tile-focus-ring-buffer-size, var(--core-focus-ring-buffer-size));
  }
}

.background-image {
  display: none;
}

.background-image.has-slotted-media,
.background-image ::slotted([slot="media"]) {
  --item-tile-text-color: white;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--item-tile-border-radius, 6px);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--item-tile-border-radius, 6px);
    background-image:
      var(
        --item-tile-background-image-gradient,
        linear-gradient(180.98deg, rgb(0 0 0 / 4%) 0.84%, rgb(0 0 0 / 40%) 99.16%)
      );
  }
}

::slotted([slot="leading-accessory"]) {
  z-index: 2;
  display: flex;
  grid-area: leading-accessory;
  align-items: flex-start;
}

.trailing-accessory-container {
  z-index: 3;
  display: flex;
  grid-area: trailing-accessory;
  justify-content: flex-end;

  .remove-button {
    position: absolute;
    width: var(--item-tile-remove-button-width, 24px);
    height: var(--item-tile-remove-button-height, 24px);
    border: none;
    border-radius: 100px;
    background: var(--item-tile-remove-button-fill, var(--core-surface-30-color));
    box-shadow: var(--elevation-10-shadow);
    cursor: pointer;

    &:hover {
      background: var(--item-tile-remove-button-hover-state-background-color, #ffe5ea);
    }

    &:active {
      background: var(--item-time-remove-button-active-state-background-color, #ffccd5);
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      padding: var(--core-metrics-spacing-50);
    }
  }

  ::slotted([slot="indicator"]) {
    @extend %semibold-10;

    height: var(--core-metrics-spacing-200);
    padding: 0 var(--core-metrics-spacing-100);
    border-radius: 100px;
    background: var(--core-fill-10-dark-mode-color);
    color: black;
    box-shadow: var(--elevation-10-shadow);
  }
}

.content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: flex-end;
  min-width: 0;
}

::slotted([slot="hint"]) {
  @extend %display-10;
}

::slotted([slot="label"]) {
  @extend %medium-30;

  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow-y: hidden;
  min-width: 0;
  -webkit-line-clamp: var(--item-tile-label-line-clamp, 1);
  -webkit-box-orient: vertical;
}

::slotted([slot="subtext"]) {
  @extend %paragraph-10;

  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow-y: hidden;
  min-width: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
