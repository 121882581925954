/* TODO: design tokens */

:host {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--swatch-color);
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%);
  transition:
    box-shadow
    var(--core-animation-enter-transition-moderate-speed-duration)
    var(--core-animation-enter-transition-easing);

  .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border: 0;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    transition:
      width
      var(--core-animation-enter-transition-moderate-speed-duration)
      var(--core-animation-enter-transition-easing),
      height
      var(--core-animation-enter-transition-moderate-speed-duration)
      var(--core-animation-enter-transition-easing),
      box-shadow
      var(--core-animation-enter-transition-moderate-speed-duration)
      var(--core-animation-enter-transition-easing),
      border
      var(--core-animation-enter-transition-fast-speed-duration)
      var(--core-animation-enter-transition-easing);
    transform: translate(-50%, -50%);
  }
}

:host(:hover:not([disabled])) .inner-circle {
  width: 8px;
  height: 8px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 15%);
}

:host([selected]) {
  box-shadow: inset 0 0 0 2px #006aff;
}

:host([selected]) .inner-circle,
:host([selected]:hover) .inner-circle {
  width: 32px;
  height: 32px;
  border: 2px solid white;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%);
}

:host(:active:not([disabled])) .inner-circle,
:host([selected]:active) .inner-circle {
  width: 16px;
  height: 16px;
  border: 0;
  background-color: white;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 15%);
}

:host(:hover:not([disabled])) .inner-circle,
:host(:active:not([disabled])) .inner-circle {
  transition:
    width var(--core-animation-exit-transition-moderate-speed-duration),
    height var(--core-animation-exit-transition-moderate-speed-duration),
    box-shadow var(--core-animation-exit-transition-moderate-speed-duration),
    border var(--core-animation-exit-transition-fast-speed-duration);
}
