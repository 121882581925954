/**
 * An icon representing the customer profile.
 */
import React, { ReactElement } from 'react';

function ProfileIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" fill="none">
      <title>Profile</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M10.723 4.326c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2Zm-6 0c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4Zm10 14h2c0-4.41-3.59-8-8-8s-8 3.59-8 8h2c0-3.31 2.69-6 6-6s6 2.69 6 6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ProfileIcon;
