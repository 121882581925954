/* TODO: add design tokens for table components */

:host {
  --transition-duration: 0.2s; /* TODO: design token when design finalizes spec */

  position: relative;
  display: grid;
  grid-column: 1 / -1;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border-bottom: var(--table-border-width, 1px) solid var(--table-row-border-color, var(--core-divider-20-color));
  transition: background-color var(--transition-duration);

  @media (hover: hover) {
    /* media query is to suppress hover styles on mobile devices */
    &([interactive]:hover) ::slotted(.market-table-cell) {
      background-color: var(--table-cell-hover-state-background-color, var(--core-fill-50-color));
    }
  }

  &([header]) {
    border-bottom: var(--table-border-width, 1px) solid var(--table-heading-border-color, var(--core-divider-10-color));
  }

  /* TODO This should be moved into market-table cell and
  use :host-context() for conditional header/footer styling
  when that selector has better brower support */
  &([footer]) ::slotted(.market-table-cell) {
    border-bottom: none;
    font-weight: var(--table-footing-primary-text-font-weight, 500);
    font-size: var(--table-footing-primary-text-font-size, var(--core-type-semibold-20-size));
    line-height: var(--table-footing-primary-text-line-height, var(--core-type-semibold-20-leading));
  }

  /* [interactive] is the interactive version */
  &([interactive]) ::slotted(.market-table-cell) {
    cursor: pointer;
  }

  &([active]) ::slotted(.market-table-cell),
  &([interactive]:active) ::slotted(.market-table-cell) {
    background-color: var(--table-cell-pressed-state-background-color, var(--core-emphasis-40-color));
  }

  &([interactive]:focus) {
    outline: none; /* override default browser focus styles */
  }

  &([interactive]:not([disabled]):focus) ::slotted(.market-table-cell) {
    background-color: var(--table-cell-focus-state-background-color, var(--core-fill-50-color));
  }

  &([interactive][disabled]) {
    pointer-events: none;
  }

  &([disabled]) ::slotted(.market-table-cell) {
    background-color: transparent;
    color: var(--table-cell-disabled-state-text-color, var(--core-text-30-color));
    pointer-events: none;
  }

  &([nested]) ::slotted(.market-table-cell:first-of-type) {
    padding-left:
      calc(
        (
          var(--cell-indent-level) *
          var(--table-row-indentation-padding, 40px)
        ) +
        var(--table-cell-padding, 8px) +
        var(--table-accordion-content-width, 30px)
      );
  }

  &([slot='custom-trigger']) ::slotted(.market-table-cell:first-of-type),
  &([slot='header']) ::slotted(.market-table-cell:first-of-type),
  &([slot='footer']) ::slotted(.market-table-cell:first-of-type) {
    padding-left: var(--table-cell-padding, 8px);
  }
}

:host ::slotted(.market-table-cell:first-of-type) {
  padding-left:
    calc(
      var(--table-cell-padding, 8px) +
      (var(--cell-indent-level) * var(--table-row-indentation-padding, 40px))
    );
}
