import { Component, Host, h, Prop, Element, Event, EventEmitter } from '@stencil/core';

/**
 * When code is split up, represents how many characters are in each group.
 */
const CODE_GROUP_LEN = 4;

@Component({
  tag: 'market-code-display',
  styleUrl: 'market-code-display.css',
  shadow: true,
})
export class MarketCodeDisplay {
  @Element() el: HTMLMarketCodeDisplayElement;

  /**
   * Whether the component should appear in a disabled state.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * A boolean representing whether the code input is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * A string holding the code inputted by the user through a slot.
   */
  private code: string = '';

  /**
   * An array holding spans containing each character in the code, with some blanks.
   * Used for styling and spacing.
   */
  private codeChars: Array<string> = [];

  /**
   * The default 'Copy' button that is always present in any market-code-display.
   * Created and inserted into DOM in componentWillLoad().
   */
  private copyButton: HTMLElement;

  /**
   * Emitted when the 'Copy' button is pressed. Can be used by consumer to create toast.
   */
  @Event() marketCodeCopied: EventEmitter;

  /**
   * Component Lifecycle Event:
   * Grab the code and its len from inputted code slot.
   * Set relevant props and insert the Copy button programmatically so that it appears in the light DOM.
   */
  componentWillLoad() {
    // Set code and codeGroups
    const slottedCodeTag = this.el.querySelector('[slot=code]');
    const code = slottedCodeTag.textContent;
    // Remove whitespace from slotted code
    this.code = code.replace(/\s/g, '');
    this.initCodeChars(this.code);

    // Programmatically insert default Copy button into light DOM
    this.copyButton = document.createElement('button');
    Object.assign(this.copyButton, {
      slot: 'actions',
      type: 'button',
      tabIndex: this.disabled ? -1 : 0,
      onclick: () => this.copyToClipboard(),
    });
    // NOTE: The following code is brittle! Relies on the fact that the consumer
    //       slotted in elements in order of 'code' -> 'copy-text' -> 'actions'
    // If consumer slotted in alt copy-text, place copyButton after code & copy-text
    if (this.el.querySelector('[slot=copy-text]')) {
      // Set text of the Copy button to slotted copy-text
      const slottedCopyText = this.el.querySelector('[slot=copy-text]');
      const copyText = slottedCopyText.textContent;
      this.copyButton.innerHTML = copyText;
      slottedCopyText.insertAdjacentElement('afterend', this.copyButton);
    } else {
      this.copyButton.innerHTML = `Copy`;
      slottedCodeTag.insertAdjacentElement('afterend', this.copyButton);
    }
  }

  /**
   * Inits this.codeChars to an array of <span> tags containing each character in the code string.
   * Additionally contains empty strings at certain indices based on codeLen.
   * By default, blanks are only placed at the front & back of the array.
   *    ['', 'x', 'x', 'x', 'x', '']
   * If codeLen is divisible by 4, put blanks every 4 spots too.
   *    ['', 'x', 'x', 'x', 'x', '', 'x', 'x', 'x', 'x', '']
   * The exception to this is if codeLen===4, in which case we use the default.
   * This codeChars arr is used in render() to space and style characters appropriately.
   * Called only once on componentWillLoad() as to not cause re-renders
   */
  initCodeChars(code) {
    const codeLen = code.length;
    const BLANK_CODE_SPAN = <span class="code-char"></span>;

    // Place blanks every 4 chars
    if (codeLen !== CODE_GROUP_LEN && codeLen % CODE_GROUP_LEN === 0) {
      for (let i = 0; i < codeLen; i++) {
        if (i % CODE_GROUP_LEN === 0) this.codeChars.push(BLANK_CODE_SPAN);
        this.codeChars.push(<span class="code-char">{code[i]}</span>);
      }
    }
    // Only place blank at the front & back
    else {
      this.codeChars.push(BLANK_CODE_SPAN);
      for (let i = 0; i < codeLen; i++) {
        this.codeChars.push(<span class="code-char">{code[i]}</span>);
      }
    }
    this.codeChars.push(BLANK_CODE_SPAN);
  }

  /**
   * When user presses the 'Copy' button, copy code to clipboard & emit an event.
   */
  copyToClipboard() {
    navigator.clipboard.writeText(this.code);
    this.marketCodeCopied.emit();
  }

  render() {
    return (
      <Host class="market-code-display" tabIndex={this.disabled ? null : 0} aria-label={this.code}>
        <span class="code-container">{this.codeChars}</span>
        <span class="actions-container">
          <slot name="actions"></slot>
        </span>
      </Host>
    );
  }
}
