import { h } from '@stencil/core';

export const pencilWritingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 4.17004C17.72 4.17004 18.45 4.45004 19 5.00004C20.1 6.10004 20.1 7.90004 19 9.00004L17 11L8 20H4V16L13 7.00004L15 5.00004C15.55 4.45004 16.28 4.17004 17 4.17004ZM17.01 6.17004C16.88 6.17004 16.63 6.20004 16.42 6.41004L15.84 7.01004L17.01 8.18004L17.6 7.59004C17.81 7.37004 17.84 7.13004 17.84 7.00004C17.84 6.87004 17.82 6.62004 17.6 6.41004C17.38 6.20004 17.14 6.17004 17.01 6.17004ZM6 18H7.17L15.58 9.59004L14.41 8.42004L6 16.83V18ZM12 18.0001H20V20.0001H12V18.0001Z"
    />
  </svg>
);
