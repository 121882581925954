@import "../../styles/mixins/component-base.css";

:host {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  opacity: 0%;

  &([active]) {
    z-index: 1000;
    opacity: 100%;

    /* whether click events pass through or not is managed by market-context's styles */
    pointer-events: none;
  }
}
