:host {
  --divider-thin-variant-height: 1px;

  display: block;
  height: var(--divider-height);
  border-radius: var(--divider-radius);
  background-color: var(--divider-fill-color);

  &([margin="large"]) {
    margin: var(--divider-large-variant-vertical-padding) 0;
  }

  &([margin="medium"]) {
    margin: var(--divider-medium-variant-vertical-padding) 0;
  }

  &([margin="small"]) {
    margin: var(--divider-small-variant-vertical-padding) 0;
  }

  &([size="thin"]) {
    height: var(--divider-thin-variant-height);
  }
}
