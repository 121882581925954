import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketAccessory,
  MarketButton,
  MarketButtonDropdown,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import MoreIcon from 'src/svgs/MoreIcon';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Transcript from 'src/stores/objects/Transcript';
import MediumSwitcher from 'src/components/MediumSwitcher/MediumSwitcher';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import CopyIcon from 'src/svgs/CopyIcon';
import UnreadIcon from 'src/svgs/UnreadIcon';
import PhotoIcon from 'src/svgs/PhotoIcon';
import BlockIcon from 'src/svgs/BlockIcon';
import ProfileIcon from 'src/svgs/ProfileIcon';
import './MoreMenu.scss';

export type MoreMenuProps = {
  transcript: Transcript;
};

/**
 * The '...' menu in the top right corner of the transcript view, listing the actions
 * available to take on the selected transcript.
 *
 * @example <MoreMenu transcript={transcripts.get(id)} />
 * @param {Transcript} transcript
 * The transcript object we are showing the more menu for.
 */
const MoreMenu = observer(({ transcript }: MoreMenuProps): ReactElement => {
  const { t } = useTranslation();
  const { modal, event, user, transcriptView, navigation, customers, status } =
    useMessengerControllerContext();

  const content = [];

  // Copy contact
  if (transcript.contactId) {
    const copyOnClick = (): void => {
      navigator.clipboard.writeText(transcript.contactId as string);
      status.setSuccess({
        type: 'SUCCESS',
        label: t('CodeSnippet.copied'),
        timerMillis: 2000,
      });
    };
    content.push(
      <MarketRow
        key="copy_contact"
        onClick={copyOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, copyOnClick)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <CopyIcon />
        </MarketAccessory>
        <label slot="label">
          {transcript.medium === Medium.SMS
            ? t('TranscriptViewPage.more_menu.copy_number')
            : t('TranscriptViewPage.more_menu.copy_email')}
        </label>
      </MarketRow>,
    );
  }

  // Mark as unread
  if (transcript.isActive) {
    content.push(
      <MarketRow
        key="mark_as_unread"
        onClick={transcriptView.markTranscriptAsUnread}
        onKeyDown={(e) =>
          onKeyDownEnter(e, transcriptView.markTranscriptAsUnread)
        }
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <UnreadIcon />
        </MarketAccessory>
        <label slot="label">
          {t('TranscriptViewPage.more_menu.mark_unread.title')}
        </label>
      </MarketRow>,
    );
  }

  // Medium switcher
  if (
    transcript.customerTokens.length === 1 &&
    transcript.customerDetailsStatus === 'SUCCESS'
  ) {
    const { contact } = customers.get(transcript.customerTokens[0]);
    if (contact) {
      content.push(<MediumSwitcher key="medium_switcher" contact={contact} />);
    }
  }

  // View photos
  if (transcriptView.transcript.photos.length > 0) {
    const openPhotosGallery = (): void => {
      event.track('Click View Photo Gallery');
      modal.openPhotosGallery();
    };
    content.push(
      <MarketRow
        key="view_photo_gallery"
        onClick={openPhotosGallery}
        onKeyDown={(e) => onKeyDownEnter(e, openPhotosGallery)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <PhotoIcon />
        </MarketAccessory>
        <label slot="label">
          {t('TranscriptViewPage.more_menu.view_photo_gallery')}
        </label>
      </MarketRow>,
    );
  }

  // View profile
  if (!navigation.secondary.isOpen && transcript.customerTokens.length > 0) {
    const openDetailsView = (): void => {
      event.track('Click View Details Menu Option');
      if (transcript.customerTokens.length === 1) {
        navigation.openCustomerDetailView(
          transcript.id,
          transcript.customerTokens[0],
        );
        return;
      }
      navigation.primary.navigateTo({
        name: 'CUSTOMER_MERGE_VIEW',
        transcriptId: transcript.id,
      });
    };
    content.push(
      <MarketRow
        key="view_details"
        onClick={openDetailsView}
        onKeyDown={(e) => onKeyDownEnter(e, openDetailsView)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <ProfileIcon />
        </MarketAccessory>
        <label slot="label">{t('MoreMenu.view_details_label')}</label>
      </MarketRow>,
    );
  }

  // Block
  if (!transcript.isBlocked) {
    const blockOnClick = (): void => {
      event.track('Click Block', {
        merchant_token: user.merchantToken,
        transcript_ids: [transcript.id],
      });
      modal.openConfirmBlockModal();
    };
    content.push(
      <MarketRow
        key="block"
        onClick={blockOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, blockOnClick)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <BlockIcon title={t('TranscriptViewPage.more_menu.block')} />
        </MarketAccessory>
        <label slot="label" className="MoreMenu__block">
          {t('TranscriptViewPage.more_menu.block')}
        </label>
      </MarketRow>,
    );
  }

  // Unblock
  if (transcript.isBlocked) {
    const unblockOnClick = (): void => {
      event.track('Click Unblock', {
        merchant_token: user.merchantToken,
        transcript_ids: [transcript.id],
      });
      transcriptView.unblockTranscript();
    };
    content.push(
      <MarketRow
        key="unblock"
        onClick={unblockOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, unblockOnClick)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <BlockIcon title={t('TranscriptViewPage.more_menu.unblock.title')} />
        </MarketAccessory>
        <label slot="label" className="MoreMenu__block">
          {t('TranscriptViewPage.more_menu.unblock.title')}
        </label>
      </MarketRow>,
    );
  }

  return (
    <MarketButtonDropdown
      popoverPlacement="bottom-end"
      data-testid="MoreMenu"
      disabled={transcriptView.isLoading || undefined}
      noCaret
    >
      <MarketButton slot="trigger">
        <MoreIcon slot="icon" />
      </MarketButton>
      <MarketList slot="content">{content}</MarketList>
    </MarketButtonDropdown>
  );
});

export default MoreMenu;
