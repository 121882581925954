import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { LoadingStatus, Transition } from 'src/MessengerTypes';
import { MarketButton, MarketHeader } from 'src/components/Market';
import BackIcon from 'src/svgs/MarketBackIcon';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import './ListPage.scss';

export type ListPageProps = {
  transitionDirection: Transition;
  title: string;
  status: LoadingStatus;
  retry: () => Promise<void>;
  children: ReactNode;
};

/**
 * Common component used for the list view page
 *
 * @param {Transition} transitionDirection
 * The direction of the transition animation.
 * @param {string} title
 * The title of the page.
 * @param {LoadingStatus} status
 * The status of the page.
 * @param {() => Promise<void>} retry
 * The function to retry the action.
 * @param {ReactNode} children
 * The children to render.
 */
const ListPage = observer(
  ({
    transitionDirection,
    title,
    status,
    retry,
    children,
  }: ListPageProps): ReactElement => {
    const { navigation } = useMessengerControllerContext();
    const { t } = useTranslation();

    return (
      <>
        <MarketHeader compact className="MessagesPageHeader ListPage__header">
          <div slot="navigation">
            <MarketButton
              aria-label={t('common.back')}
              onClick={navigation.tertiaryNav.navigateBack}
            >
              <BackIcon slot="icon" />
            </MarketButton>
          </div>
          <h2>{title}</h2>
        </MarketHeader>
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={status === 'LOADING'}
          status={
            status === 'ERROR'
              ? {
                  scope: 'BLADE',
                  display: 'SCREEN',
                  type: 'ERROR',
                  label: t('common.error.try_again'),
                  action: {
                    action: retry,
                  },
                  actionLabel: t('common.retry'),
                }
              : null
          }
        >
          {children}
        </MessengerContent>
      </>
    );
  },
);

export default ListPage;
