import { Component, Host, Element, Method, h, Listen } from '@stencil/core';

// TODO(UI-1153): add design tokens for these
const TOAST_VERTICAL_SPACING = 16;
const TOAST_BOTTOM_MARGIN = 24;
const TOAST_ANIMATION_TRANSITION_DURATION = 200;

@Component({
  tag: 'market-toaster',
  styleUrl: 'market-toaster.css',
  shadow: true,
})
export class MarketToaster {
  @Element() el: HTMLMarketToasterElement;
  toasts: Array<HTMLMarketToastElement> = [];

  /**
   * Add the passed toastEl to market-toaster and make it visible
   **/
  @Method()
  async show(toastEl) {
    this.toasts.unshift(toastEl);
    toastEl.classList.add('use-transitions');
    this.el.appendChild(toastEl);
    window.requestAnimationFrame(this.positionToasts.bind(this));

    await new Promise((resolve) => setTimeout(resolve, TOAST_ANIMATION_TRANSITION_DURATION));
    toastEl.startAutoDismissTimer();
  }

  positionToasts() {
    let offset = TOAST_BOTTOM_MARGIN;
    this.toasts.forEach((toast) => {
      toast.style.transform = `translate(-50%, calc(-100% - ${offset}px))`;
      offset += toast.offsetHeight + TOAST_VERTICAL_SPACING;
    });
  }

  /**
   * Remove the passed toastEl from market-toaster
   **/
  @Method()
  async hide(toastEl) {
    const index = this.toasts.indexOf(toastEl);
    this.toasts.splice(index, 1);
    window.requestAnimationFrame(() => {
      toastEl.style.transform = '';
      this.positionToasts();
    });

    await new Promise((resolve) => setTimeout(resolve, TOAST_ANIMATION_TRANSITION_DURATION));
    toastEl.remove();
  }

  /**
   * Remove all market-toasts from market-toaster
   **/
  @Method()
  removeAll() {
    const allActiveToasts = [...this.toasts];
    return Promise.all(allActiveToasts.map((toast) => this.hide(toast)));
  }

  @Listen('marketToastAutoDismissed')
  toastAutoDismissedEventHandler({ target: toast }) {
    this.hide(toast);
  }

  @Listen('marketToastManuallyDismissed')
  toastManuallyDismissedEventHandler({ target: toast }) {
    this.hide(toast);
  }

  render() {
    return (
      <Host class="market-toaster">
        <slot></slot>
      </Host>
    );
  }
}
