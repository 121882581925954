import Pusher from 'pusher-js';
import {
  Environment,
  NotificationPayload,
  RawNotificationPayload,
} from 'src/MessengerTypes';
import type MessengerController from 'src/MessengerController';
import Logger from 'src/Logger';
import Api from 'src/api/Api';
import {
  isAuthError,
  UNKNOWN_TRANSCRIPT_VERSION,
} from 'src/utils/transcriptUtils';

/**
 * The number of utterances to fetch for a recently updated transcript.
 * Utterances that may have changed include new messages and "soft-deleted" utterances.
 */
const ON_NOTIFICATION_UTTERANCES_PAGE_SIZE = 5;

/**
 * The app key for the Pusher account we should receive notifications on in staging. This is not
 * a secret. Make sure these are consistent with {@link PUSHER_OPTIONS}.
 * See square-conversations-staging: https://dashboard.pusher.com/apps/1665881
 */
const PUSHER_APP_KEY_STAGING = '498ef54ac00dd9e986f2';

/**
 * The app key for the Pusher account we should receive notifications on in production. This is not
 * a secret. Make sure these are consistent with {@link PUSHER_OPTIONS}.
 * See square-conversations-production: https://dashboard.pusher.com/apps/1665867
 */
const PUSHER_APP_KEY_PRODUCTION = 'dc9fbe60e395a7771173';

/**
 * Additional options for creating our Pusher client. Make sure these are consistent
 * with {@link PUSHER_APP_KEY_STAGING} and {@link PUSHER_APP_KEY_PRODUCTION}.
 */
const PUSHER_OPTIONS = {
  cluster: 'us3',
  forceTLS: true,
};

/**
 * The event name for the Transcript Updated event. This is the main event we're
 * listening for on the frontend.
 */
const PUSHER_EVENT_TRANSCRIPT_UPDATED = 'transcript_updated';

/**
 * The event name for the update event to Messages Plus subscription. We listen to this
 * so that we can refresh the subscription details and transcripts, e.g. update which
 * unit has subscribed, and transcript consent status.
 */
const PUSHER_EVENT_SUBSCRIPTION_UPDATED = 'sync-square-messages-subscription';

/**
 * Store that manages updates received from pusher. Uses these updates to keep cached data
 * on the frontend up to date.
 */
class NotificationsStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * A Pusher instance, which we can use to unsubscribe when the session expires.
   */
  private _pusher: Pusher | null = null;

  constructor(stores: MessengerController) {
    this._stores = stores;
    this._api = stores.api;
  }

  /**
   * Initialize Pusher, so we start listening to notifications on the Pusher channel.
   * This should be set up before we start the app proper.
   *
   * @param {Environment} environment
   * We have a different pusher queue for staging vs. production, which we can select between using this parameter.
   */
  init = (environment: Environment): void => {
    // Ensure any running Pusher is unsubscribed before re-subscribing.
    this.stop();

    // Set up Pusher
    this._pusher =
      environment === 'production'
        ? new Pusher(PUSHER_APP_KEY_PRODUCTION, PUSHER_OPTIONS)
        : new Pusher(PUSHER_APP_KEY_STAGING, PUSHER_OPTIONS);
    const merchantToken = this._stores.user.merchantToken;
    // Note: some merchant tokens have format 'merchant:ADATDSAED' with a colon in them. This is
    // not a valid channel name for Pusher, so in these cases we listen on an underscored version
    // instead: 'merchant_ADATDSAED'
    const channel = this._pusher.subscribe(merchantToken.replace(':', '_'));
    channel.bind(
      PUSHER_EVENT_TRANSCRIPT_UPDATED,
      ({
        transcript_id,
        unit_tokens,
        updated_utterance_id,
        utterance_id,
      }: RawNotificationPayload) => {
        const args = {
          transcriptId: transcript_id,
          unitTokens: unit_tokens,
          updatedUtteranceId: updated_utterance_id,
          utteranceId: utterance_id,
        };
        this.onTranscriptUpdate(args);
      },
    );
    channel.bind(PUSHER_EVENT_SUBSCRIPTION_UPDATED, () => {
      this.onSubscriptionUpdate();
    });
  };

  /**
   * Unsubscribe to Pusher updates. This is called when a Multipass session expires.
   */
  stop = (): void => {
    if (this._pusher) {
      // Note: some merchant tokens have format 'merchant:ADATDSAED' with a colon in them. This is
      // not a valid channel name for Pusher, so in these cases we listen on an underscored version
      // instead: 'merchant_ADATDSAED'
      const merchantToken = this._stores.user.merchantToken;
      this._pusher.unsubscribe(merchantToken.replace(':', '_'));
    }
  };

  /**
   * Dispatches an UnreadCountChangeEvent to the window that SDK users can use to listen to the current
   * unread count (e.g. on the Messages Icon on Dashboard)
   */
  dispatchUnreadCountChange = (): void => {
    window.dispatchEvent(
      new CustomEvent('MessagesUnreadCountChangeEvent', {
        detail: {
          unreadCount: this._stores.user.unreadTranscriptsCount,
        },
      }),
    );
  };

  /**
   * Method to handle push updates for transcripts and update the app state accordingly.
   *
   * @param {NotificationPayload} args
   * @param {number} args.transcriptId
   * The ID of the transcript that has changed.
   * @param {string[]} args.unitTokens
   * The list of unit tokens associated with this transcript.
   * @param {number} [args.updatedUtteranceId]
   * The ID of a single utterance that has been updated.
   * If provided, only the utterance with this ID needs to be updated and not the entire transcript.
   * @param {number} [args.utteranceId]
   * The ID of the oldest utterance to update (and all utterances after it)
   */
  onTranscriptUpdate = async ({
    transcriptId,
    unitTokens,
    updatedUtteranceId,
    utteranceId,
  }: NotificationPayload): Promise<void> => {
    if (this._stores.session.isExpired) {
      // Multipass has logged the user out. Skip any further updates to avoid cluttering the logs.
      return;
    }

    this._stores.event.track('Receive Notification');
    Logger.log(
      `Notified of update to transcript ${transcriptId} for units ${unitTokens} and utterance ID ${utteranceId}`,
    );

    // The logged-in user must have access to all the unit tokens associated with the transcript
    const missingUnitTokens = unitTokens.filter(
      (unitToken: string) => !this._stores.user.units.has(unitToken),
    );
    if (missingUnitTokens.length > 0) {
      // This is either a unit the logged-in user is unauthorized to view,
      // or the units haven't been initialized yet. The second is an unfortunate
      // edge case. This step was added to prevent unnecessary spamming of
      // GetConversations for transcripts we can't view.
      Logger.log(
        `Skipping update for unauthorized units ${missingUnitTokens}.  Required units: ${unitTokens}`,
      );
      return;
    }

    try {
      // Update the unread transcript count for the current merchant
      this._stores.user.loadUnreadTranscriptsCount().then(() => {
        this.dispatchUnreadCountChange();
      });

      // If messages has never been opened, skip applying any updates
      // However, updating the unread transcript count must still occur (completed in previous step)
      if (!this._stores.session.isUsingMessages) {
        return;
      }

      // If notification is for a single utterance, update only that utterance and bail out early
      if (updatedUtteranceId) {
        // Check if transcript is loaded. If not, skip update as there is nothing out of sync with the server.
        if (this._stores.transcripts.has(transcriptId)) {
          const transcript = this._stores.transcripts.get(transcriptId);
          await transcript.updateUtterance(updatedUtteranceId);
        }
        return;
      }

      const transcript = this._stores.transcripts.get(transcriptId);

      const [rawTranscript] =
        await this._api.transcripts.getTranscriptWithUtterances({
          id: transcriptId,
          utterancePageSize: !transcript.lastFetchedUtteranceId
            ? ON_NOTIFICATION_UTTERANCES_PAGE_SIZE
            : undefined,
          stopAtUtteranceId: transcript.lastFetchedUtteranceId,
        });

      // If the current transcript version is the same or newer than the one loaded from the server, don't update local state
      const version = rawTranscript.version || UNKNOWN_TRANSCRIPT_VERSION;
      if (version <= transcript.version) {
        this._stores.event.track('Filter Notification');
        return;
      }

      await transcript.applyTranscriptUpdate({
        transcript: {
          ...rawTranscript,
          details: {
            ...rawTranscript.details,
            // In the event there is another page of utterances that has not been loaded, skip the update to utterances
            // as these will be loaded with further paginated cursor calls. However, we still need to update other
            // transcript details like read status, etc.
            utterances: transcript.hasNextPage
              ? []
              : rawTranscript.details?.utterances,
          },
        },
      });

      // Determine the correct list to add the updated transcript to
      const list = transcript.isActive
        ? this._stores.transcriptsLists.active
        : this._stores.transcriptsLists.assistant;

      // If the list does not already contain that transcript, add it
      // The list is automatically sorted by recency so any change in position is already accounted for
      if (!list.has(transcript.id)) {
        list.push(transcript.id);
      }

      // if the transcript changed from the assistant bucket to active bucket, remove it from the assistant list
      if (
        transcript.isActive &&
        this._stores.transcriptsLists.assistant.has(transcript.id)
      ) {
        this._stores.transcriptsLists.assistant.remove(transcript.id);
      }

      // For each customer token in the updated transcript, add it to the corresponding transcript history list
      // if, 1. the list exists, and 2. it does not already contain that transcript ID
      transcript.customerTokens.forEach((customerToken: string) => {
        const { history } = this._stores.customers.get(customerToken);
        if (history.status !== 'NOT_STARTED' && !history.has(transcript.id)) {
          history.push(transcript.id);
        }
      });
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          'NotificationsStore:onUpdate - Error attempting to retrieve and apply updated transcript.',
          'error',
          {
            error,
            transcriptId,
            unitTokens,
            updatedUtteranceId,
            utteranceId,
          },
        );
      }
    }
  };

  /**
   * Function to handle Messages Plus subscription updates from pusher.
   */
  onSubscriptionUpdate = (): void => {
    Logger.log(`Notified of update to Messages Plus subscription`);

    // 1. Re-fetch subscription details
    this._stores.subscription.refresh();

    // 2. Clear the navigation so that no components are using transcript data
    this._stores.navigation.primary.reset();
    this._stores.navigation.secondary.reset();

    // 3. Clear the transcript cache
    this._stores.transcriptsLists.clearAll();
    this._stores.customers.clearAllHistory(); // Clear all customer history lists
    this._stores.transcripts.clear();

    // 4. Re-fetch transcripts that we display on the list
    this._stores.transcriptsLists.active.init();

    // 5. Navigate to list page
    if (this._stores.navigation.primary.isOpen) {
      this._stores.navigation.primary.navigateTo('TRANSCRIPTS_LIST');
      if (this._stores.navigation.secondary.isOpen) {
        this._stores.navigation._openFirstTranscriptInList();
      }
    }
  };
}

export default NotificationsStore;
