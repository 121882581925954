import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import TranscriptsListPageHeader from 'src/pages/TranscriptsListPage/components/TranscriptsListPageHeader/TranscriptsListPageHeader';
import TranscriptsListPage from 'src/pages/TranscriptsListPage/TranscriptsListPage';
import SearchPage from 'src/pages/SearchPage/SearchPage';
import { Transition } from 'src/MessengerTypes';
import SearchBar from 'src/components/SearchBar/SearchBar';
import TranscriptsListFilter from 'src/components/TranscriptsListFilter/TranscriptsListFilter';

export type TranscriptsListPageContainerProps = {
  transitionDirection: Transition;
};

/**
 * Component that contains the pages related to displaying lists of transcripts.
 *
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * If true, the content transitions from the right edge
 * of the drawer into the view. Else, transition from
 * the left edge of the drawer.
 */
const TranscriptsListPageContainer = observer(
  ({
    transitionDirection,
  }: TranscriptsListPageContainerProps): ReactElement => {
    const { navigation, search } = useMessengerControllerContext();

    const isSearchPage = navigation.primary.currentPageName === 'SEARCH';

    const searchBarRef = useRef<HTMLMarketInputTextElement>(null);

    // Clear search query and blur search bar when navigating out of search
    const onNavigateBackFromSearch = useCallback(() => {
      search.setQuery('', 'exit');
      searchBarRef?.current?.blur();
    }, [search]);

    useEffect(() => {
      // On mobile-web, using the browser back button to navigate back
      // should also clear the search query and blur the search bar
      if (navigation.navStoreForUrl.currentPageName === 'TRANSCRIPTS_LIST') {
        onNavigateBackFromSearch();
      }
    }, [navigation.navStoreForUrl.currentPageName, onNavigateBackFromSearch]);

    return (
      <>
        <TranscriptsListPageHeader />
        <SearchBar
          inputRef={searchBarRef}
          onNavigateBack={onNavigateBackFromSearch}
        />
        {!isSearchPage && <TranscriptsListFilter />}
        {isSearchPage ? (
          <SearchPage transitionDirection={transitionDirection} />
        ) : (
          <TranscriptsListPage transitionDirection={transitionDirection} />
        )}
      </>
    );
  },
);

export default TranscriptsListPageContainer;
