import { Component, Host, Prop, h } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';
import { MarketPillVariant } from '../../utils/pill-variant';

@Component({
  tag: 'market-pill',
  styleUrl: 'market-pill.css',
  shadow: true,
})
export class MarketPill {
  /**
   * Sets the visual variant style for the pill.
   */
  @Prop({ reflect: true }) readonly variant: MarketPillVariant = 'normal';

  /**
   * String for setting pill size
   */
  @Prop({ reflect: true }) readonly size: 'medium' | 'small' = 'medium';

  /**
   * Controls whether the pill should display an indicator icon.
   */
  @Prop({ reflect: true }) readonly indicator: boolean = false;

  /**
   * Controls whether the pill should react to hovers/clicks. It is recommended to only set this to true if you have also slotted an icon into the pill.
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Controls the role attribute on the Host
   */
  @Prop({ reflect: true }) readonly role: string;

  renderIndicator() {
    const MarketAccessoryTag = getNamespacedTagFor('market-accessory');
    return this.size === 'medium' ? (
      <MarketAccessoryTag>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="5" />
        </svg>
      </MarketAccessoryTag>
    ) : (
      <MarketAccessoryTag>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="4" />
        </svg>
      </MarketAccessoryTag>
    );
  }

  render() {
    return (
      <Host class="market-pill">
        <slot name="icon">{this.indicator && this.renderIndicator()}</slot>
        <slot></slot>
      </Host>
    );
  }
}
