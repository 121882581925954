import { Component, Element, Event, EventEmitter, h, Host, Method, Prop, State, Watch } from '@stencil/core';
import { isEmpty } from 'lodash-es';

import { classNames } from '../../utils/classnames';
import { Draggable } from '../../utils/draggable';
import { TMarketDragCoords } from '../../utils/gesture/types';
import { getNamespacedTagFor } from '../../utils/namespace';
import { asyncRequestAnimationFrame } from '../../utils/raf';

import { TMarketRowDeselectedEventDetail, TMarketRowSelectedEventDetail } from './events';
import { TMarketRowValidControlElement } from './types';
import { isValidRowControl } from './utils';

/**
 * @slot label - Text label for the row
 * @slot subtext - Secondary text for the row
 * @slot side-label - Additional text label to display on the side of the row
 * @slot side-subtext - Secondary text to display on the side of the row
 * @slot control - An interactive control, intended for use with `<market-checkbox>`, `<market-radio>`, or `<market-toggle>`.
 * The row's `selected` prop will set the control's selection state.
 * @slot leading-accessory - An icon set on the left side of the row; intended for use with `<market-accessory>`
 * @slot trailing-accessory - An icon set on the right side of the row; intended for use with `<market-accessory>`
 * @slot - Default slot can take any content, intended as an "escape hatch" for
 * scenarios where rows need to contain more complex HTML content stylable from
 * the light DOM.
 *
 * @part container - Wraps the main and side areas (see below). The outer padding of the row is specified on this element.
 * @part main - Wraps the label and subtext slots, can be used for styling purposes as needed.
 * @part side - Wraps the side-label and side-subtext slots, can be used for styling purposes as needed.
 */
@Component({
  tag: 'market-row',
  shadow: true,
  styleUrl: './styles/market-row.css',
})
export class MarketRow {
  @State() slottedControlEl: TMarketRowValidControlElement;

  @Element() el: HTMLMarketRowElement;

  /**
   * Whether the row is currently selected. Used by `<market-list>` and `<market-select>`.
   * Also sets the selection state for slotted controls (`<market-checkbox>`, `<market-radio>`, or `<market-toggle>`),
   * if present.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * The value for the row.
   */
  @Prop({ reflect: true }) readonly value: string;

  /**
   * Whether the row is disabled.
   * Also disables slotted controls (`<market-checkbox>`, `<market-radio>`, or `<market-toggle>`), if present.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Determines the form factor of the row.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' = 'medium';

  /**
   * Whether or not the row is interactive. Results in rows receiving hover
   * and active styling when hovered/clicked.
   *
   * Automatically set to `true` when using the drill variant
   * or passing in a slotted control (checkbox/radio/toggle).<br>
   *
   * Automatically be set to reflect the list's `interactive`
   * value if used inside of `<market-list>`.
   */
  @Prop({ reflect: true, mutable: true }) interactive: boolean = false;

  /**
   * When set to `true`, rows will not persist selected state on click.
   * Only takes effect when `interactive` is true.
   */
  @Prop({ reflect: true, mutable: true }) transient: boolean = false;

  /**
   * By default, row selection is toggled on click. There are some cases, such
   * as selects, where we instead want the row to stay active on subsequent
   * clicks. Setting `togglable` to `false` enables this behavior. Can be set
   * by `<market-list>` and `<market-select>`.
   */
  @Prop() readonly togglable: boolean = true;

  /**
   * The style of row you want to use. The default is "regular", which allows
   * you to optionally slot a checkbox, radio, or (in the future) toggle control.
   * The other option is "drill", which functions more like a link that you can
   * use to drill through a series of action card sets.
   */
  @Prop() readonly variant: 'regular' | 'drill' = 'regular';

  /**
   * Gives the row destructive styling.
   */
  @Prop({ reflect: true }) readonly destructive: boolean = false;

  /**
   * Whether the slotted control appears to the left or right of the main content.
   */
  @Prop() readonly controlPosition: 'trailing' | 'leading' = 'trailing';

  /**
   * A link that this row should navigate to on click.
   * If this property is set, an anchor tag will be rendered.
   */
  @Prop() readonly href: string | undefined;

  /**
   * Specifies where to display the linked URL.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details on accepted values.
   */
  @Prop() readonly target: '_blank' | '_self' | '_parent' | '_top' | undefined;

  /**
   * Whether the row is drag & drop enabled
   */
  @Prop({ reflect: true }) readonly dragEnabled: boolean = false;

  /**
   * Whether the drag handle appears to the left or right.
   */
  @Prop({ reflect: true }) readonly dragHandlePosition: 'leading' | 'trailing' = 'trailing';

  /**
   * Fired whenever a row is selected.
   */
  @Event({ bubbles: true, composed: true }) marketRowSelected: EventEmitter<TMarketRowSelectedEventDetail>;

  /**
   * Fired whenever a row is deselected.
   */
  @Event({ bubbles: true, composed: true }) marketRowDeselected: EventEmitter<TMarketRowDeselectedEventDetail>;

  @State() hasSideText: boolean = false;

  /**
   * If a control gets slotted in, set the value to match that of the row
   */
  @Watch('selected')
  selectedWatcher(newValue: boolean) {
    // prevent the row from being selected if it is transient
    const selected = newValue && this.transient ? false : newValue;
    this.selected = selected;
    this.slottedControlEl?.setSelection(selected);
  }

  /**
   * If a control gets slotted in, set the value to match that of the row
   */
  @Watch('disabled')
  disabledWatcher(newValue: typeof this.disabled) {
    this.slottedControlEl?.setDisabled(newValue);
  }

  /**
   * Link rows should not be selectable
   */
  @Watch('href')
  hrefWatcher(newValue: typeof this.href) {
    if (!isEmpty(newValue)) {
      this.transient = true;
    }
  }

  /**
   * Drill rows are interactive
   */
  @Watch('variant')
  variantWatcher(newValue: typeof this.variant) {
    if (newValue === 'drill') {
      this.interactive = true;
    }
  }

  /**
   * @internal
   * @private
   *
   * Used for setting the selection state to true without emiting the `marketRowSelected` event.
   */
  @Method()
  async silentlySelect() {
    this.selected = true;
    return Promise.resolve();
  }

  /**
   * @internal
   * @private
   *
   * Used for setting the selection state to false without emiting the `marketRowDeselected` event.
   */
  @Method()
  async silentlyDeselect() {
    this.selected = false;
    return Promise.resolve();
  }

  /**
   * @internal
   * @private
   *
   * Used for manually setting `selected` to true. Generally speaking, it
   * is preferable to avoid using this method and allow `market-row` to
   * manage its own selection state based on user interaction. It should only
   * be used for parent components that need to manage a group of rows, such as
   * `market-list`.
   */
  @Method()
  select() {
    this.selected = true;
    const { defaultPrevented } = this.marketRowSelected.emit({ value: this.value });
    if (defaultPrevented) {
      this.selected = false;
    }
    return Promise.resolve();
  }

  /**
   * @internal
   * @private
   *
   * Used for manually setting `selected` to false. Generally speaking, it
   * is preferable to avoid using this method and allow `market-row` to
   * manage its own selection state based on user interaction. It should only
   * be used for parent components that need to manage a group of rows, such as
   * `market-list`.
   */
  @Method()
  deselect() {
    this.selected = false;
    const { defaultPrevented } = this.marketRowDeselected.emit({ value: this.value });
    if (defaultPrevented) {
      this.selected = true;
    }
    return Promise.resolve();
  }

  /**
   * @internal
   * @private
   *
   * Used for toggling the row's selected state.
   */
  @Method()
  toggle() {
    return !this.selected ? this.select() : this.deselect();
  }

  handleControlSlotChange() {
    this.querySlots();
    if (this.slottedControlEl) {
      this.interactive = true;
      this.selectedWatcher(this.selected);
      this.disabledWatcher(this.disabled);

      const slottedControlLabel = this.el.querySelector('[slot="label"]')?.textContent;
      this.slottedControlEl.setAttribute('aria-label', slottedControlLabel);
    }
  }

  setControlActive(value: boolean) {
    this.slottedControlEl?.setActive?.(value);
  }

  setControlHover(value: boolean) {
    this.slottedControlEl?.setHover?.(value);
  }

  async handleClick(e: MouseEvent) {
    // clicks on links inside row content shouldn't select the row itself
    if (
      this.disabled ||
      this.transient ||
      !this.interactive ||
      (e.target as Element).tagName === 'A' ||
      (e.target as Element).tagName === getNamespacedTagFor('market-link').toUpperCase()
    ) {
      return;
    }

    if (this.togglable) {
      await this.toggle();
    } else if (!this.selected) {
      await this.select();
    }

    // fixes a weird UI bug where the row keeps its focus when clicked using a mouse
    if (e.type === 'click' && (e as PointerEvent).pointerType === 'mouse') {
      this.el.blur();
    }
  }

  handleKeydown(e: KeyboardEvent) {
    // don't intercept keydown of descendant elements
    // e.g. when typing into nested input fields (gross)
    if (e.target !== this.el) {
      return;
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // spacebar should not scroll page
      this.el.click();
    }
  }

  // market drag utils
  drag: Draggable;

  async onDragStart(e: CustomEvent<TMarketDragCoords>) {
    const { el, dragHandlePosition } = this;
    const coords: TMarketDragCoords = e.detail;
    const anchor = dragHandlePosition === 'leading' ? 'left' : 'right';
    const drag = new Draggable(el, { anchor });
    this.drag = drag;
    await drag.start(coords);
  }

  onDragMove(e: CustomEvent<TMarketDragCoords>) {
    const coords: TMarketDragCoords = e.detail;
    this.drag.move(coords);
  }

  async onDragEnd(e: CustomEvent<TMarketDragCoords>) {
    const coords: TMarketDragCoords = e.detail;
    await this.drag.end(coords);
    this.drag.destroy();
  }

  checkIfSideTextIsPresent() {
    const sideTextEl = this.el.querySelector('[slot="side-label"], [slot="side-subtext"]');
    this.hasSideText = Boolean(sideTextEl);
  }

  querySlots() {
    this.slottedControlEl = [...this.el.querySelectorAll('[slot="control"]')].find(isValidRowControl);
    this.checkIfSideTextIsPresent();
  }

  connectedCallback() {
    this.querySlots();

    this.selectedWatcher(this.selected);
    this.disabledWatcher(this.disabled);
    this.hrefWatcher(this.href);
    this.variantWatcher(this.variant);
  }

  componentWillLoad() {
    this.checkIfSideTextIsPresent();
  }

  async componentDidUpdate() {
    // remove preload class (used to manage slotted control transitions)
    if (this.el.classList.contains('preload')) {
      await asyncRequestAnimationFrame();
      this.el.classList.remove('preload');
    }
  }

  renderDrillIcon() {
    return (
      <svg class="drill-icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.7071 7.29297C12.0976 7.68349 12.0976 8.31666 11.7071 8.70718L5.70711 14.7072L4.29289 13.293L9.58579 8.00008L4.29289 2.70718L5.70711 1.29297L11.7071 7.29297Z"
        />
      </svg>
    );
  }

  render() {
    const {
      controlPosition,
      disabled,
      href,
      interactive,
      selected,
      slottedControlEl,
      target,
      variant,
      hasSideText,
      dragEnabled,
      dragHandlePosition,
    } = this;
    // we include `href` here because we want to tab directly the rendered `a` tag
    // in that case, skipping the `market-row`.
    const tabindex = interactive && !disabled && !href ? '0' : null;
    const ContainerTag: string = href === undefined ? 'div' : 'a';
    const ContainerTagAttrs = ContainerTag === 'a' ? { href, target } : {};
    const leadingControl = controlPosition === 'leading';

    const MarketDragHandleTagName = getNamespacedTagFor('market-drag-handle');

    return (
      <Host
        role={interactive ? 'option' : 'listitem'}
        tabindex={tabindex}
        aria-selected={interactive ? Boolean(selected).toString() : null}
        class={classNames('market-row', 'preload', {
          'has-slotted-control': typeof slottedControlEl !== 'undefined',
          'has-leading-control': leadingControl,
        })}
        onMouseDown={() => this.setControlActive(true)}
        onMouseUp={() => this.setControlActive(false)}
        onMouseEnter={() => this.setControlHover(true)}
        onMouseLeave={() => this.setControlHover(false)}
        onClick={(e: MouseEvent) => this.handleClick(e)}
        onKeydown={(e: KeyboardEvent) => this.handleKeydown(e)}
        onMarketDragHandleDragStart={(e: CustomEvent<TMarketDragCoords>) => this.onDragStart(e)}
        onMarketDragHandleDragMove={(e: CustomEvent<TMarketDragCoords>) => this.onDragMove(e)}
        onMarketDragHandleDragEnd={(e: CustomEvent<TMarketDragCoords>) => this.onDragEnd(e)}
      >
        <ContainerTag part="container" class="container" {...ContainerTagAttrs}>
          {dragEnabled && dragHandlePosition === 'leading' && <MarketDragHandleTagName></MarketDragHandleTagName>}
          {leadingControl && <slot name="control" onSlotchange={() => this.handleControlSlotChange()}></slot>}
          <slot name="leading-accessory"></slot>
          <div class="main" part="main">
            <slot name="label"></slot>
            <slot name="subtext"></slot>
            <slot></slot>
          </div>
          <div part="side" class={classNames('side', { hidden: !hasSideText })}>
            <slot name="side-label" onSlotchange={() => this.checkIfSideTextIsPresent()}></slot>
            <slot name="side-subtext" onSlotchange={() => this.checkIfSideTextIsPresent()}></slot>
          </div>
          <slot name="trailing-accessory"></slot>
          {variant === 'regular' && !leadingControl && (
            <slot name="control" onSlotchange={() => this.handleControlSlotChange()}></slot>
          )}
          {variant === 'drill' && this.renderDrillIcon()}
          {dragEnabled && dragHandlePosition === 'trailing' && <MarketDragHandleTagName></MarketDragHandleTagName>}
        </ContainerTag>
      </Host>
    );
  }
}
