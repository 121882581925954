/**
 *  Enums for market-date-picker-menu slot names used to determine which row was selected
 */
export enum MENU_SLOT_NAMES {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  THIS_YEAR = 'this-year',
  LAST_YEAR = 'last-year',
  CUSTOM = 'custom',
}
