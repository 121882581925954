@import "../../styles/mixins/component-base.css";

svg {
  position: static;
  flex: none;
  fill: currentcolor;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
