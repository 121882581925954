import { Component, Host, h, Element, Prop, State, Method, Watch, Event, EventEmitter } from '@stencil/core';

import { submitFormImplicitly } from '../../utils/forms';

/**
 * @part native-input - the native input element.
 */
@Component({
  tag: 'market-stepper',
  styleUrl: 'market-stepper.css',
  shadow: true,
})
export class MarketStepper {
  @Element() el: HTMLMarketStepperElement;

  inputEl: HTMLInputElement;

  /**
   * The value for the input. This is visually shown on the input
   * and can be edited by the user.
   */
  @Prop({ mutable: true, reflect: true }) value: number;

  /**
   * The ID for the inner input.
   */
  @Prop() readonly inputId: string;

  /**
   * The name for the inner input.
   */
  @Prop() readonly name: string;

  /**
   * The placeholder of the input. Shown before a user attempts to
   * add a value, given no value is already provided.
   */
  @Prop() readonly placeholder: string = '0';

  /**
   * A number specifying the greatest value in the range of permitted values.
   * (See MDN on the [max attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#max))
   */
  @Prop() readonly max: number;

  /**
   * A number specifying the most negative value in the range of permitted values.
   * (See MDN on the [min attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#min))
   */
  @Prop() readonly min: number;

  /**
   * A positive number specifying the increment step.
   * (See MDN on the [step attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#step))
   */
  @Prop() readonly step: number = 1;

  /**
   * Whether the input is readonly or not.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * Whether the input is disabled or not.
   * This visually and functionally disables the input.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether the input is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Whether the input is invalid or not. This represents error states.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * The inner input's aria-label. Localize as needed.
   */
  @Prop({ reflect: true }) readonly inputAriaLabel: string = 'Number';

  /**
   * The decrement button's aria-label. Localize as needed.
   */
  @Prop({ reflect: true }) readonly decrementAriaLabel: string = 'Decrement';

  /**
   * The increment button's aria-label. Localize as needed.
   */
  @Prop({ reflect: true }) readonly incrementAriaLabel: string = 'Increment';

  @State() incrementDisabled: boolean = false;
  @State() decrementDisabled: boolean = false;

  /**
   * Emitted when the value changes.
   */
  @Event() marketStepperValueChange: EventEmitter;

  /**
   * Emitted when the inner `<input>` element is focused.
   */
  @Event() marketStepperInputFocus: EventEmitter;

  @Watch('value')
  valueChangeHandler() {
    // sanitize value in case it was set programmatically
    const sanitized = this.sanitizeValue(this.value);
    if (sanitized !== this.value) {
      this.value = sanitized;
    }
    this.updateButtonDisabledStates();
  }

  /**
   * Toggle focus styling on `<market-stepper>` and focus/blur the inner `<input />`.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.readonly || this.disabled) {
      return Promise.resolve();
    }
    this.focused = value;
    this.focused ? this.inputEl.focus() : this.inputEl.blur();
    return Promise.resolve();
  }

  onChange(): void {
    const previousValue = this.value;
    let nextValue = Number.parseFloat(this.inputEl.value);

    if (Number.isNaN(nextValue)) {
      // not a valid number, so reset it to null/empty
      nextValue = null;
      this.inputEl.value = '';
    } else {
      // a valid number, so sanitize it against min/max/step props
      nextValue = this.sanitizeValue(nextValue);
      this.inputEl.value = nextValue.toString();
    }

    // if value has changed, set it and emit event
    if (nextValue !== previousValue) {
      this.value = nextValue;
      this.emitChangeEvent(previousValue);
    }
  }

  onInputFocus(): void {
    this.emitInputFocusEvent();
  }

  onDecrementClick(): void {
    this.stepValue(-this.step);
  }

  onIncrementClick(): void {
    this.stepValue(this.step);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      submitFormImplicitly(this.el);
    }
  }

  stepValue(step: number): void {
    const previousValue = this.value;
    const nextValue = (previousValue || 0) + step;
    const sanitizedValue = this.sanitizeValue(nextValue);
    if (sanitizedValue !== previousValue) {
      this.value = sanitizedValue;
      this.emitChangeEvent(previousValue);
    }
  }

  sanitizeValue(value: number): number {
    const decimalPlaces = this.step.toString().split('.')[1]?.length || 0;
    const hasMax = this.max !== null && this.max !== undefined;
    const hasMin = this.min !== null && this.min !== undefined;

    // round value to the nearest step
    let sanitized = Math.round(value / this.step) * this.step;
    // correct any floating point math errors
    sanitized = Number.parseFloat(sanitized.toFixed(decimalPlaces));
    // limit value to max
    sanitized = hasMax ? Math.min(sanitized, this.max) : sanitized;
    // limit value to min
    return hasMin ? Math.max(sanitized, this.min) : sanitized;
  }

  updateButtonDisabledStates() {
    const { value, max, min } = this;
    const hasValue = value !== null && value !== undefined;
    this.incrementDisabled = hasValue ? value === max : false;
    this.decrementDisabled = hasValue ? value === min : false;
  }

  emitChangeEvent(previousValue) {
    const { el, value, marketStepperValueChange } = this;
    marketStepperValueChange.emit({ el, value, previousValue });
  }

  emitInputFocusEvent() {
    const { marketStepperInputFocus } = this;
    marketStepperInputFocus.emit();
  }

  componentWillRender() {
    this.updateButtonDisabledStates();
  }

  render() {
    return (
      <Host
        class="market-stepper"
        onFocus={() => {
          this.focused = true;
        }}
        onBlur={() => {
          this.focused = false;
        }}
      >
        <button
          tabindex={this.disabled || this.decrementDisabled ? -1 : null}
          disabled={this.disabled || this.decrementDisabled}
          onClick={() => this.onDecrementClick()}
          aria-label={this.decrementAriaLabel}
        >
          <svg width="10" height="2" viewBox="0 0 10 2" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66667 0.333328H0.333336V1.66666H9.66667V0.333328Z" />
          </svg>
        </button>
        <input
          ref={(el) => (this.inputEl = el)}
          type="number"
          id={this.inputId}
          name={this.name}
          readonly={this.readonly}
          disabled={this.disabled}
          placeholder={this.placeholder}
          step={this.step}
          min={this.min}
          max={this.max}
          value={this.value}
          aria-label={this.inputAriaLabel}
          onChange={() => this.onChange()}
          onFocus={() => this.onInputFocus()}
          onKeyDown={(e) => this.onKeyDown(e)}
          part="native-input"
        />
        <button
          tabindex={this.disabled || this.incrementDisabled ? -1 : null}
          disabled={this.disabled || this.incrementDisabled}
          onClick={() => this.onIncrementClick()}
          aria-label={this.incrementAriaLabel}
        >
          <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66665 9.66666V5.66666H9.66665V4.33333H5.66665V0.333328H4.33331V4.33333H0.333313V5.66666H4.33331V9.66666H5.66665Z" />
          </svg>
        </button>
      </Host>
    );
  }
}
