import { Component, Element, Event, EventEmitter, Host, Method, Prop, h, State, Watch } from '@stencil/core';
import { TMarketTileSelectedChanged } from './events';
import { classNames } from '../../utils/classnames';

/**
 * @slot media - When provided, replaces the solid color background with a background image and applies a slight gradient.
 * @slot leading-accessory - An icon set on the top-left corner of the tile.
 * @slot actions - Optional slot to customize action(s) in the top-right corner of the tile. Renders a remove button by default.
 * @slot indicator - Text to render in a pill-like element on the top-right corner of the slot.
 * Is overridden by `actions`.
 * @slot hint - Large text set in the center of a medium-sized tile. Intended to be one or two letters.
 * @slot label - Text set beneath the hint slot.
 * @slot subtext - Smaller text set beneath the label slot.
 */
@Component({
  tag: 'market-tile',
  styleUrl: 'market-tile.css',
  shadow: true,
})
export class MarketTile {
  @Element() el: HTMLMarketTileElement;
  hasTrailingAccessorySlot = false;
  hasIndicatorTextSlot = false;

  /**
   * Whether to disable the tile.
   */
  @Prop({ reflect: true, attribute: 'aria-disabled' }) readonly disabled: boolean = false;

  /**
   * Enables interactivity.
   */
  @Prop() readonly interactive: boolean = false;

  /**
   * Whether to render the markup in the action slot.
   */
  @Prop() readonly showActions: boolean = false;

  /**
   * What size tile to render.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' = 'medium';

  /**
   * Value for the tile.
   */
  @Prop({ reflect: true }) readonly value: string;

  /**
   * Whether the tile is currently selected
   */
  @Prop({ reflect: true, mutable: true }) selected: boolean = false;

  /**
   * Whether or not `[slot="media"]` is provided
   */
  @State() hasSlottedMedia = false;

  /**
   * Fired whenever the tile is selected.
   */
  @Event() marketTileSelectedChanged: EventEmitter<TMarketTileSelectedChanged>;

  /**
   * Fired whenever the remove button is clicked
   */
  @Event() marketTileRemoveClicked: EventEmitter;

  @Watch('size')
  onSizeChange() {
    this.adjustSlottedLabels();
  }

  /**
   * Allows external elements to set selected value.
   */
  @Method()
  setSelected(newValue: boolean) {
    if (typeof newValue !== 'boolean') {
      return Promise.resolve();
    }

    if (this.selected !== newValue) {
      const { defaultPrevented } = this.marketTileSelectedChanged.emit({
        selected: this.selected,
        value: this.value,
      });

      if (!defaultPrevented) {
        this.selected = newValue;
      }
    }

    return Promise.resolve();
  }

  handleClick(event: MouseEvent | KeyboardEvent) {
    if (this.disabled || !this.interactive) {
      return;
    }

    this.selected = !this.selected;
    event.preventDefault();
    this.marketTileSelectedChanged.emit({ selected: this.selected, value: this.value });
  }

  handleRemoveActionKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        this.handleRemoveActionClick(event);
        break;
      default:
        break;
    }
  }

  handleRemoveActionClick(event: MouseEvent | KeyboardEvent) {
    if (this.disabled || !this.interactive) {
      return;
    }

    event.stopPropagation();
    this.marketTileRemoveClicked.emit();
  }

  renderDefaultRemoveAction() {
    const { disabled, handleRemoveActionClick, handleRemoveActionKeydown } = this;

    return (
      <button
        class="remove-button"
        disabled={disabled}
        onClick={handleRemoveActionClick.bind(this)}
        onKeyUp={handleRemoveActionKeydown.bind(this)}
      >
        <svg
          slot="icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="var(--core-critical-text-color)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8318 7.3335V7.1685H12.6668H3.3335H3.1685V7.3335V8.66683V8.83183H3.3335H12.6668H12.8318V8.66683V7.3335Z"
            fill="var(--core-critical-text-color)"
            stroke="var(--core-critical-text-color)"
            stroke-width="0.33"
          />
        </svg>
      </button>
    );
  }

  handleKeydown(event: KeyboardEvent) {
    // don't intercept keydown of descendant elements
    // e.g. remove button
    if (event.target !== this.el) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case ' ':
        this.handleClick(event);
        break;
      default:
        break;
    }
  }

  /**
   * This function determines the number of lines the `label` slot text should clamp to.
   * It should clamp to a single line if the hint slot exists or if the subtext slot exists
   * and the element `size` prop is set to "small".
   */
  adjustSlottedLabels() {
    const { size, el } = this;
    const hint = el.querySelector('[slot="hint"]');
    const label = el.querySelector('[slot="label"]');
    const subtext = el.querySelector('[slot="subtext"]');

    if (!label) {
      return;
    }

    const labelLineClamp = hint || (subtext && size === 'small') ? 1 : 2;

    el.style.setProperty('--item-tile-label-line-clamp', labelLineClamp.toString());
  }

  checkSlottedMedia() {
    this.hasSlottedMedia = Boolean(this.el.querySelector('[slot="media"]'));
  }

  componentWillLoad() {
    this.checkSlottedMedia();
    this.hasIndicatorTextSlot = Boolean(this.el.querySelector('[slot="indicator"]'));
  }

  render() {
    const { disabled, size, hasSlottedMedia, hasIndicatorTextSlot, interactive, showActions, selected } = this;
    const tabindex = interactive && !disabled ? '0' : null;
    return (
      <Host
        class="market-tile"
        tabindex={tabindex}
        aria-selected={selected.toString()}
        onClick={(e) => this.handleClick(e)}
        onKeydown={(e) => this.handleKeydown(e)}
      >
        <div class={classNames('background-image', { 'has-slotted-media': hasSlottedMedia })}>
          <slot onSlotchange={() => this.checkSlottedMedia()} name="media"></slot>
        </div>
        <slot name="leading-accessory"></slot>
        <div class="trailing-accessory-container">
          {showActions && <slot name="actions">{this.renderDefaultRemoveAction()}</slot>}
          {!showActions && hasIndicatorTextSlot && <slot name="indicator"></slot>}
        </div>
        <div class="content">
          {size !== 'small' && <slot onSlotchange={() => this.adjustSlottedLabels()} name="hint"></slot>}
          <slot onSlotchange={() => this.adjustSlottedLabels()} name="label"></slot>
          <slot onSlotchange={() => this.adjustSlottedLabels()} name="subtext"></slot>
        </div>
      </Host>
    );
  }
}
