import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'market-activity-indicator',
  styleUrl: 'market-activity-indicator.css',
  shadow: true,
})
export class MarketActivityIndicator {
  /**
   * String for setting activity indicator size
   */
  @Prop({ reflect: true }) readonly size: 'large' | 'small' = 'large';

  render() {
    let height, width;

    switch (this.size) {
      case 'large':
        width = 48;
        height = 48;
        break;
      case 'small':
        width = 24;
        height = 24;
        break;
      default:
        width = this.size;
        height = this.size;
    }

    return (
      <Host class="market-activity-indicator">
        {
          <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M42.6667 24C42.6667 13.6907 34.3093 5.33333 24 5.33333V0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24H5.33333C5.33333 34.3093 13.6907 42.6667 24 42.6667C34.3093 42.6667 42.6667 34.3093 42.6667 24Z"
              fill-opacity="0.9"
            />
          </svg>
        }
      </Host>
    );
  }
}
