import { Component, Prop, Element, Host, h } from '@stencil/core';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - The form input, ex. market-input-text.
 * @slot error - Optional error text for the block, displayed below the input when invalid.
 * @slot bottom-accessory - Optional content for the block, displayed below the input.
 * @slot action - Optional action component for the block, displayed below the input.
 */
@Component({
  tag: 'market-field',
  shadow: true,
  styleUrl: 'market-field.css',
})
export class MarketField {
  @Element() el: HTMLMarketFieldElement;

  /**
   * A string specifying a name for the field.
   */
  @Prop({ reflect: true }) readonly name: string;

  /**
   * A boolean representing whether the field is readonly or not.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * A boolean representing whether the field is disabled or not.
   * This visually and functionally will disable the field.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * A boolean representing whether the field is invalid or not.
   * This represents error states.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  getSlottedInputs() {
    const supportedInputTags = [
      getNamespacedTagFor('market-input-text'),
      getNamespacedTagFor('market-input-password'),
      getNamespacedTagFor('market-select'),
      getNamespacedTagFor('market-textarea'),
      getNamespacedTagFor('market-code-input'),
    ];

    // create flattened array of slotted supported input elements based on tag name
    const slottedInputElements = supportedInputTags.flatMap((tag) => {
      return [
        ...(this.el.getElementsByTagName(tag) as HTMLCollectionOf<
          | HTMLMarketInputTextElement
          | HTMLMarketInputPasswordElement
          | HTMLMarketSelectElement
          | HTMLMarketTextareaElement
          | HTMLMarketCodeInputElement
        >),
      ];
    });

    return slottedInputElements;
  }

  handleErrorAriaRole() {
    const errorSlotEl = this.el.querySelector('[slot="error"]');
    if (errorSlotEl) {
      errorSlotEl.setAttribute('role', 'alert');
    }
  }

  render() {
    // check for slotted supported market inputs and pass properties down
    this.getSlottedInputs().forEach((input) => {
      input.name = this.name;
      input.disabled = this.disabled;
      input.invalid = this.invalid;
      input.readonly = this.readonly;
    });

    return (
      <Host class="market-field">
        <slot></slot>

        {this.invalid && !this.disabled && (
          // slot relocation broke in @stencil/core@1.13.0
          // wrapping a slot in a shadowless div as a workaround
          // this should be fixed in 2.1.1
          <div>
            <slot name="error" onSlotchange={() => this.handleErrorAriaRole()}></slot>
          </div>
        )}
        <slot name="bottom-accessory"></slot>
        <slot name="action"></slot>
      </Host>
    );
  }
}
