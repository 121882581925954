import { Component, Host, Element, Watch, Prop, State, h } from '@stencil/core';

/**
 * This is an implementation component only and does not relfect any component in the
 * design spec for Market */

/**
 * @slot - Default slot
 */
@Component({
  tag: 'market-table-area',
  styleUrl: 'market-table-area.css',
  shadow: true,
})
export class MarketTableArea {
  @Element() el: HTMLMarketTableAreaElement;

  /**
   * Whether or not this area is grouping columns (vertical) or rows (horizontal)
   */
  @Prop({ reflect: true }) readonly orientation: 'horizontal' | 'vertical' = 'horizontal';

  /**
   * Sticks this area to an edge of the table
   */
  @Prop({ reflect: true }) readonly stickTo: 'top' | 'bottom' | 'left' | 'right';

  /**
   * Set by the parent market-table or market-row component to determine CSS grid template
   */
  @Prop({ reflect: false }) readonly gridTemplate: Array<string>;

  /**
   * Whether or not this table area is currently visible/has any rows or cells within
   */
  @Prop({ mutable: true, reflect: true }) active: boolean = false;

  /**
   * Sets the element's grid-column CSS property to determine where this particular element
   * falls on the parent grid. Used in vertical orientation table areas
   */
  @Prop({ reflect: false }) readonly placement: Array<number> = [1, -1];

  @State() elements: Array<HTMLMarketTableRowElement | HTMLMarketTableCellElement | HTMLMarketTableColumnElement>;

  /* When the gridTemplate changes, we need to parse it and update this elements's
  grid-template-columns property, which defines the number and widths of columns
  within this area */
  @Watch('gridTemplate')
  assignGridTemplate(newValue: Array<string>, oldValue?: Array<string>) {
    if (newValue && newValue !== oldValue) {
      if (
        newValue.length > 0 ||
        oldValue === undefined // shows content for tables w/o header row
      ) {
        this.active = true;
        this.el.style.gridTemplateColumns = newValue.join(' ');
      } else {
        this.active = false;
        this.el.style.gridTemplateColumns = undefined;
      }
    }
  }

  /* When the placement changes, we need to parse it and update this element's
  grid-column property in order to correctly place it within the parent grid.
  Used to support sticky areas */
  @Watch('placement')
  placementObserver(newValue: Array<number>, oldValue: Array<number>) {
    if (newValue !== oldValue) {
      this.el.style.gridColumn = `${newValue[0]} / span ${newValue[1]}`;
    }
  }

  componentWillLoad() {
    this.assignGridTemplate(this.gridTemplate);
  }

  render() {
    return (
      <Host class="market-table-area">
        <slot></slot>
      </Host>
    );
  }
}
