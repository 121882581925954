@import "../../styles/mixins/component-base.css";

:host {
  display: flex;
  justify-content: center;
  align-items: center;
}

:host([size="icon"]) {
  width: var(--accessory-icon-variant-medium-size-width, 24px);
  height: var(--accessory-icon-variant-medium-size-height, 24px);
}

:host([size="image"]) {
  width: var(--accessory-image-variant-size-medium-width, 40px);
  height: var(--accessory-image-variant-size-medium-height, 40px);
}

:host ::slotted(.market-icon) {
  /* placeholder for styling market-icon component */
}

:host ::slotted(img) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

:host([size="icon"]) ::slotted(img) {
  border-radius: var(--accessory-icon-variant-border-radius);
}

:host([size="image"]) ::slotted(img) {
  border-radius: var(--accessory-image-variant-border-radius);
}
