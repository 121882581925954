import type { Placement, PositioningStrategy } from '@popperjs/core';
import { Component, Element, Event, EventEmitter, Host, h, Listen, Method, Prop, State } from '@stencil/core';

import { classNames } from '../../utils/classnames';
import { getNamespacedTagFor } from '../../utils/namespace';
import { MENU_SLOT_NAMES } from '../market-date-picker/enums/menu';
import { TMarketDateRangeChangedEventDetail } from '../market-date-picker/events';
import { TMarketListSelectionsDidChangeEventDetail } from '../market-list/events';
import { isValueEmpty } from '../market-list/utils';

import {
  TMarketFilterDateRangeValues,
  TMarketFilterExpandedChangeEventDetail,
  TMarketFilterValueDidChangeEventDetail,
} from './events';
import { TMarketFilterType } from './types';

/**
 * @slot label - Filter label, using `<label>`
 * @slot display-value - Overwrites the displayed value or feedback
 * @slot - The `<market-list>` or `<market-date-picker>` element
 */
@Component({
  tag: 'market-filter',
  styleUrl: 'market-filter.css',
  shadow: true,
})
export class MarketFilter {
  @Element() el: HTMLMarketFilterElement;

  /**
   * Filter name
   */
  @Prop() readonly name!: string;

  /**
   * Functionally and visually disables the filter button
   */
  @Prop() readonly disabled?: boolean;

  /**
   * Whether or not the button is focused
   */
  @Prop({ reflect: true, mutable: true }) focused: boolean = false;

  /**
   * String for setting filter button size
   */
  @Prop() readonly size: 'medium' | 'small' = 'medium';

  /**
   * Determines whether the filter is expanded or collapsed
   */
  @Prop({ mutable: true, reflect: true }) expanded: boolean = false;

  /**
   * Defines what types of interaction the dropdown should have
   * (see `market-dropdown` docs for more granular explanation).
   *
   * If not defined and the list is multiselect,
   * the dropdown interaction will be set to `persistent`
   * so that the dropdown won't automatically close after selecting a row.
   */
  @Prop() readonly dropdownInteraction?: HTMLMarketDropdownElement['interaction'];

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is `bottom-start`.
   * https://popper.js.org/docs/v2/constructors/#strategy
   */
  @Prop() readonly popoverPlacement?: Placement = 'bottom-start';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://popper.js.org/docs/v2/constructors//#strategy
   */
  @Prop() readonly popoverStrategy: PositioningStrategy = 'absolute';

  /**
   * @deprecated
   * **DEPRECATED (v4.5.0)** Use `marketFilterExpandedChanged` instead.
   *
   * Fired whenever the filter is closed
   */
  @Event({ bubbles: true, composed: true }) marketFilterClosed: EventEmitter<void>;

  /**
   * @deprecated
   * **DEPRECATED (v4.5.0)** Use `marketFilterExpandedChanged` instead.
   *
   * Fired whenever the filter is opened
   */
  @Event({ bubbles: true, composed: true }) marketFilterOpened: EventEmitter<void>;

  /**
   * Fired whenever the dropdown is expanded/collapsed
   */
  @Event({ bubbles: true, composed: true })
  marketFilterExpandedChanged: EventEmitter<TMarketFilterExpandedChangeEventDetail>;

  /**
   * Fired by the `marketListSelectionsDidChange` listener.
   *
   * @property {string} name - filter name, from `name` prop
   * @property {string | string[] | TMarketFilterDateRangeValues } prevValue - list: selected value(s); date: `[<startDate>, <endDate>]`
   * @property {string | string[] | TMarketFilterDateRangeValues } value - list: selected value(s); date: `[<startDate>, <endDate>]`
   */
  @Event() marketFilterValueDidChange: EventEmitter<TMarketFilterValueDidChangeEventDetail>;

  /**
   * Display value inferred from the `<market-list>` or `<market-date-picker>`
   */
  @State() selectedDisplayValue: string;

  /**
   * Reference to the market-filter-button
   */
  private filterButtonEl: HTMLMarketFilterButtonElement;

  /**
   * The selected row's raw value. This is only used for list types.
   */
  private rawValue: string | string[];

  /**
   * Filter type
   */
  private filterType: TMarketFilterType;

  /**
   * Reference to the slotted `<market-date-picker>`
   */
  private datePickerEl?: HTMLMarketDatePickerElement;

  /**
   * Reference to the slotted `<market-list>`
   */
  private listEl?: HTMLMarketListElement;

  /**
   * **INTERNAL [do not use directly]**
   *
   * Get the filter type
   */
  @Method()
  async getFilterType(): Promise<TMarketFilterType> {
    return Promise.resolve(this.filterType);
  }

  /**
   * Toggle focus on the filter button
   * @param {boolean} [value=true] whether or not focus will be applied or removed
   * @returns {Promise<boolean>} whether or not the filter was focused or blurred
   */
  @Method()
  async setFocus(value: boolean = true): Promise<boolean> {
    this.focused = await this.filterButtonEl.setFocus(value);
    return Promise.resolve(this.focused);
  }

  /**
   * Handle `marketListSelectionsDidChange` emitted by `<market-list>`
   */
  @Listen('marketListSelectionsDidChange')
  handleListSelectionChange({ detail }: CustomEvent<TMarketListSelectionsDidChangeEventDetail>) {
    this.setDisplayValueFromListEvent(detail);

    const prevValue =
      detail.prevSelectionValues.length > 1 ? detail.prevSelectionValues : detail.prevSelectionValues[0];
    const value = (() => {
      if (detail.currentSelectionValues.length === 0) {
        return null;
      } else if (detail.currentSelectionValues.length === 1) {
        return detail.currentSelectionValues[0] as string;
      }
      return detail.currentSelectionValues as string[];
    })();
    this.rawValue = value;

    this.marketFilterValueDidChange.emit({
      name: this.name,
      prevValue: prevValue || null,
      value,
    });

    /**
     * If the `<market-list>` is mutliselect, prevent the dropdown from collapsing after a selection.
     * Also prevent from closing when `dropdownInteraction` is provided.
     */
    if (!this.listEl?.multiselect && !this.dropdownInteraction) {
      this.expanded = false;
    }
  }

  /**
   * Handle `marketDateRangeChanged` emitted by `<market-date-picker>`
   */
  @Listen('marketDateRangeChanged')
  handleDateRangeChange(e: CustomEvent<TMarketDateRangeChangedEventDetail>) {
    const { startDate, endDate, prevStartDate, prevEndDate } = e.detail;
    this.setDisplayValueFromDateEvent(e);

    this.marketFilterValueDidChange.emit({
      name: this.name,
      prevValue: {
        startDate: prevStartDate,
        endDate: prevEndDate,
      },
      value: {
        startDate,
        endDate,
      },
    });
  }

  /**
   * Handle `marketDropdownOpened` emitted by `<market-dropdown>`
   */
  @Listen('marketDropdownOpened')
  handleDropdownOpened(e: Event) {
    const { defaultPrevented } = this.marketFilterExpandedChanged.emit(true);
    if (defaultPrevented) {
      e.preventDefault();
      return;
    }
    // temporary handler for deprecated event
    if (this.marketFilterOpened.emit().defaultPrevented) {
      e.preventDefault();
      return;
    }
    if (!this.dropdownInteraction) {
      this.expanded = true;
    }
  }

  /**
   * Handle `marketDropdownClosed` emitted by `<market-dropdown>`
   */
  @Listen('marketDropdownClosed')
  handleDropdownClosed(e: Event) {
    const { defaultPrevented } = this.marketFilterExpandedChanged.emit(false);
    if (defaultPrevented) {
      e.preventDefault();
      return;
    }
    // temporary handler for deprecated event
    if (this.marketFilterClosed.emit().defaultPrevented) {
      e.preventDefault();
      return;
    }
    if (!this.dropdownInteraction) {
      this.expanded = false;
    }
  }

  /**
   * Handle default slot changes
   */
  handleDefaultSlotChange() {
    this.datePickerEl = this.el.querySelector(getNamespacedTagFor('market-date-picker'));
    this.listEl = this.el.querySelector(getNamespacedTagFor('market-list'));

    if (this.datePickerEl) {
      this.filterType = 'date';
      this.setDisplayValueFromSlottedElement();
    } else if (this.listEl) {
      // make sure that the list is interactive
      if (!this.listEl.interactive) {
        this.listEl.interactive = true;
      }
      this.filterType = 'list';
      this.setDisplayValueFromSlottedElement();
    }
  }

  /**
   * Listens to changes in row content to ensure that if the selected row's content
   * is dynamically updated, those changes will be reflected to `selectedDisplayValue`.
   */
  private initRowObservers() {
    /**
     * Since onSlotchange only fires on changes to the slotted node itself,
     * we need to use mutation observers to listen to changes to market-list's
     * slotted market-rows: https://github.com/ionic-team/stencil/issues/232#issuecomment-397871813
     */
    const syncRowContent = (row: HTMLMarketRowElement) => {
      if (typeof this.rawValue === 'string' && row.value === this.rawValue) {
        this.selectedDisplayValue = this.getTextContentOfRowWithValue(row.value);
      }
    };

    const rows: NodeListOf<HTMLMarketRowElement> = this.el.querySelectorAll(
      `${getNamespacedTagFor('market-list')} ${getNamespacedTagFor('market-row')}`,
    );
    rows.forEach((row) => {
      const observer = new MutationObserver(() => syncRowContent(row));
      observer.observe(row, { characterData: true, subtree: true });
    });
  }

  /**
   * Gets the `.textContent` of the `<market-row>` with the provided `value`.
   * This is only used for list types.
   */
  private getTextContentOfRowWithValue(value: typeof this.rawValue): string {
    const marketRowTag = getNamespacedTagFor('market-row');
    const labelEl = this.listEl.querySelector(`${marketRowTag}[value="${value}"] [slot="label"]`) as HTMLLabelElement;
    return labelEl?.textContent;
  }

  /**
   * Infers the value from the <market-list> or <market-date-picker>
   */
  private setDisplayValueFromSlottedElement() {
    const displayValueEl = this.el.querySelector('[slot="display-value"]');
    const hasDisplayValue = Boolean(displayValueEl);
    if (hasDisplayValue) {
      this.selectedDisplayValue = displayValueEl.textContent;
      return;
    }

    if (this.listEl) {
      if (!this.listEl.value) {
        this.selectedDisplayValue = undefined;
        return;
      }
      this.rawValue = this.listEl.value;

      if (this.listEl.multiselect) {
        // if there's more than 1 value selected, get the count of selected values
        const valueCount = (() => {
          if (typeof this.listEl.value === 'string') {
            return this.listEl.value.split(',').length;
          } else if (Array.isArray(this.listEl.value)) {
            return this.listEl.value.length;
          }
          return undefined; // this will skip the check below and print the raw `value` instead
        })();
        if (valueCount > 1) {
          this.selectedDisplayValue = `${valueCount}`;
          return;
        }
      }
      // get the selected row's label textContent and set that as the display value
      this.selectedDisplayValue = this.getTextContentOfRowWithValue(this.listEl.value);
    } else if (this.datePickerEl) {
      this.selectedDisplayValue = this.formatDate({
        startDate: this.datePickerEl.selectedStartDate,
        endDate: this.datePickerEl.selectedEndDate,
      });
    }
  }

  private formatDate({ startDate, endDate }: TMarketFilterDateRangeValues) {
    const start = startDate ? new Date(startDate) : undefined;
    const end = endDate ? new Date(endDate) : undefined;
    if (!start && !end) {
      return '';
    }
    const locale = this.datePickerEl.locale;

    // If both dates exists and have the same year, show the year only on the end of the range.
    const startAndEndInTheSameYear = Boolean(start && end && start.getFullYear() === end.getFullYear());
    const startDateString =
      start?.toLocaleDateString(
        locale,
        startAndEndInTheSameYear && end ? { day: 'numeric', month: 'numeric' } : { dateStyle: 'short' },
      ) ?? '';
    const endDateString = end?.toLocaleDateString(locale, { dateStyle: 'short' }) ?? '';

    return `${startDateString}${endDateString ? `–${endDateString}` : ''}`;
  }

  /**
   * Calculate the display value from the `marketDateRangeChanged` event of `<market-list>`
   * Formatting is based on design guidelines:
   * https://www.notion.so/marketdesignsystem/Filters-78885543b16446f49d5cfa98c6a56648#bb6aac7e29e04f98890ba32042ddae05
   */
  private setDisplayValueFromDateEvent(e: CustomEvent<TMarketDateRangeChangedEventDetail>) {
    const { menuSelection } = e.detail;
    if (menuSelection === MENU_SLOT_NAMES.CUSTOM) {
      this.selectedDisplayValue = this.formatDate(e.detail);
    } else if (menuSelection) {
      // get the textContent of the menu item
      const presetMenuTextContent = this.datePickerEl?.shadowRoot
        ?.querySelector(`${getNamespacedTagFor('market-date-picker-menu')} slot[name="${menuSelection}"]`)
        ?.textContent?.trim();
      this.selectedDisplayValue = presetMenuTextContent;
    }
  }

  /**
   * Calculate the display value from the `marketListSelectionsDidChange` event of `<market-list>`
   */
  private setDisplayValueFromListEvent({
    currentSelectionValues,
    currentSelections,
  }: TMarketListSelectionsDidChangeEventDetail) {
    const displayValueEl = this.el.querySelector('[slot="display-value"]');
    const hasDisplayValue = Boolean(displayValueEl);
    if (hasDisplayValue) {
      this.selectedDisplayValue = displayValueEl.textContent;
      return;
    }

    if (!currentSelectionValues?.length) {
      // no selection
      this.selectedDisplayValue = undefined;
    } else if (currentSelectionValues.length > 1) {
      // multiple selections: display the count
      this.selectedDisplayValue = `${currentSelectionValues.length}`;
    } else {
      // single selection: display the selected row's label contents
      const labelEl = (currentSelections[0] as HTMLMarketRowElement).querySelector(
        '[slot="label"]',
      ) as HTMLLabelElement;
      this.selectedDisplayValue = labelEl.textContent;
    }
  }

  connectedCallback() {
    this.initRowObservers();
    this.handleDefaultSlotChange();
  }

  render() {
    const {
      datePickerEl,
      disabled,
      dropdownInteraction,
      expanded,
      handleDefaultSlotChange,
      listEl,
      popoverPlacement,
      popoverStrategy,
      selectedDisplayValue,
      size,
    } = this;

    /**
     * Dropdown interaction will be set as 'persistent' by default if:
     * - `dropdownInteraction` is not defined; or
     * - `<market-list>` is provided and is `multiselect`; or
     * - `<market-date-picker>` is provided
     */
    const isMultiselectList = listEl?.multiselect ?? false;
    const hasDatePicker = Boolean(datePickerEl);
    const interaction = dropdownInteraction ?? (hasDatePicker || isMultiselectList ? 'persistent' : undefined);

    const MarketDropdownTagName = getNamespacedTagFor('market-dropdown');
    const MarketPopoverTagName = getNamespacedTagFor('market-popover');
    const MarketFilterButtonTagName = getNamespacedTagFor('market-filter-button');

    return (
      <Host class="market-filter">
        <MarketDropdownTagName
          class="dropdown"
          disabled={disabled}
          expanded={expanded}
          interaction={interaction}
          popoverPlacement={popoverPlacement}
          popoverStrategy={popoverStrategy}
        >
          <MarketFilterButtonTagName
            active={expanded}
            class="filter-button"
            disabled={disabled}
            ref={(el) => (this.filterButtonEl = el)}
            slot="trigger"
            size={size}
          >
            <slot name="label"></slot>
            {!isValueEmpty(selectedDisplayValue) && (
              <span slot="feedback">
                <slot name="display-value">{selectedDisplayValue}</slot>
              </span>
            )}
          </MarketFilterButtonTagName>
          <MarketPopoverTagName class={classNames({ 'date-popover': hasDatePicker })} slot="popover">
            <slot onSlotchange={handleDefaultSlotChange.bind(this)}></slot>
            {/**
             * @deprecated
             * Use the default slot instead of `[slot="list"]`
             */}
            <slot name="list" onSlotchange={handleDefaultSlotChange.bind(this)}></slot>
          </MarketPopoverTagName>
        </MarketDropdownTagName>
      </Host>
    );
  }
}
