/**
 * An icon representing block or unblock action.
 */
import React, { ReactElement } from 'react';

function BlockIcon({ title }: { title: string }): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
      <title>{title}</title>
      <path
        fill="#CC0023"
        d="M9.723.326c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9Zm7 9c0 1.57-.53 3.02-1.4 4.18l-9.78-9.78c1.16-.87 2.61-1.4 4.18-1.4 3.86 0 7 3.14 7 7Zm-14 0c0-1.57.53-3.02 1.4-4.18l9.78 9.78c-1.16.87-2.61 1.4-4.18 1.4-3.86 0-7-3.14-7-7Z"
      />
    </svg>
  );
}

export default BlockIcon;
