import { isCheckboxElement, isRadioElement, isToggleElement } from '../../utils/element-type-guard';

import { TMarketRowValidControlElement } from './types';

/**
 * @param {unknown} el - Element to be checked
 * @returns {boolean} Whether `el` is a `TMarketRowValidControlElement`
 */
export function isValidRowControl(el: unknown): el is TMarketRowValidControlElement {
  return isCheckboxElement(el) || isRadioElement(el) || isToggleElement(el);
}
