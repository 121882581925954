import { MarketQRCodeEncoder } from '@square/qrcode-encoder';
import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'market-qrcode',
  styleUrl: 'market-qrcode.css',
  shadow: true,
})
export class MarketQrcode {
  /**
   * The URL or other content of the QR code. The QR code is generated using
   * [@square/qrcode-encoder](https://github.com/squareup/qrcode/blob/master/packages/square-qrcode-encoder/README.md).
   */
  @Prop({ reflect: true }) readonly content: string = '';

  /**
   * By default, QR code size is dependent on the information being encoded.
   * Use this property to set the QR code's height and width in pixels.
   */
  @Prop({ reflect: true }) readonly size: number;

  /**
   * Include a six data-pixel border around the QR code. When this attribute is
   * present, the QR code will have a background color (default is transparent
   * background).
   */
  @Prop() readonly border: boolean = false;

  /**
   * When true, QR code will render all elements in black & white.
   */
  @Prop({ reflect: true }) readonly monochrome: boolean = false;

  render() {
    const encoder = this.content
      ? new MarketQRCodeEncoder(this.content, {
          border: this.border,
          styleBackground: this.border ? 'class="background"' : '',
          styleForeground: 'class="foreground"',
          styleLogo: 'class="logo"',
        })
      : null;

    const containerStyle =
      this.size !== undefined
        ? {
            height: `${this.size}px`,
            width: `${this.size}px`,
          }
        : null;

    return (
      <Host class="market-qrcode" aria-hidden="true">
        <div innerHTML={encoder ? encoder.svg : ''} style={containerStyle}></div>
      </Host>
    );
  }
}
