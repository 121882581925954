import { SEGMENTED_CONTROL_PADDING_HORIZONTAL } from '@market/market-theme/js/cjs/index.js';
import { Component, Host, Prop, h, Listen, Element, Watch, State, Event, EventEmitter } from '@stencil/core';
import { getNamespacedTagFor } from '../../utils/namespace';

function isValueEmpty(value: unknown): boolean {
  return value === '' || value === ' ' || value === null || value === undefined;
}
@Component({
  tag: 'market-segmented-control',
  styleUrl: 'market-segmented-control.css',
  shadow: true,
})
export class MarketSegmentedControl {
  selectedSlider: HTMLElement;
  @Element() el: HTMLMarketSegmentedControlElement;
  @State() items: NodeListOf<HTMLMarketSegmentElement>;

  /**
   * A string specifying a value for the segmented-control.
   * This value determines which segment is selected based on the segment value.
   */
  @Prop({ mutable: true, reflect: true }) value: string = '';

  /**
   * A boolean representing whether the market-segmented-control is disabled or not.
   * This visually and functionally will disable the control area.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  @Watch('value')
  valueWatcher() {
    this.setSelectionsFromValue();
  }

  /**
   * If a segment gets slotted in, set the value to match that of the row
   */
  @Watch('disabled')
  disabledChangeHandler() {
    this.items?.forEach((item) => (item.disabled = this.disabled));
  }

  /**
   * Fired when the a new segment is selected
   */
  @Event({ bubbles: true, composed: true }) marketSegmentedSelectionDidChange: EventEmitter<{
    selectedSegment: HTMLMarketSegmentElement;
    selectedSegmentValue: string;
    deselectedSegment: HTMLMarketSegmentElement;
    deselectedSegmentValue: string;
  }>;

  /**
   * Select item that corresponds to passed value, or clear all values if value is empty string.
   */
  setSelectionsFromValue() {
    this.items?.forEach((item) => {
      if (this.value === item.value) {
        item.setSelectedState(true);
        this.setSliderPosition(item);
      } else {
        item.setSelectedState(false);
      }
    });
  }

  /**
   * Sets the initial state of the segmented-control by updating and propagating props and setting
   * default value.
   */
  setInternalState() {
    this.items = this.el.querySelectorAll(`${getNamespacedTagFor('market-segment')}`);
    this.selectedSlider = this.el.shadowRoot.getElementById('selected-slider');
    if (this.items.length > 0) {
      if (isValueEmpty(this.value)) {
        this.value = this.items[0].value;
      }
      this.setSelectionsFromValue();
    }
  }

  /**
   * Sets slider size to be the size of a segment
   */
  setSliderSize() {
    this.el.style.setProperty(
      '--selected-slider-width',
      `calc(${100 / this.items?.length}% - ${SEGMENTED_CONTROL_PADDING_HORIZONTAL * 2}px)`,
    );
  }

  /**
   * Sets slider position (left offset) based on the currently selected item
   */
  setSliderPosition(selectedItem = undefined) {
    if (!selectedItem) {
      this.el.style.setProperty('--selected-slider-left', `${SEGMENTED_CONTROL_PADDING_HORIZONTAL}px`);
      return;
    }

    const newSelectionIndex = [...this.items].indexOf(selectedItem);
    this.el.style.setProperty(
      '--selected-slider-left',
      `calc(${(newSelectionIndex / this.items.length) * 100}% + ${SEGMENTED_CONTROL_PADDING_HORIZONTAL}px)`,
    );
  }

  /**
   * Sets the initial state of the segmented-control by updating and propagating props and setting
   * default value.
   */

  defaultSlotchangeHandler() {
    this.setInternalState();
    this.setSliderSize();
  }

  @Listen('marketSegmentSelectedChanged')
  marketSegmentSelectedEventHandler(e: CustomEvent) {
    const newSelection = e.target as HTMLMarketSegmentElement;

    const prevSelection = this.el.querySelector(`${getNamespacedTagFor('market-segment')}[selected]`) as
      | HTMLMarketSegmentElement
      | undefined;

    prevSelection?.setSelectedState(false);
    newSelection.setSelectedState(true);

    this.setSliderPosition(newSelection);

    this.marketSegmentedSelectionDidChange.emit({
      selectedSegment: newSelection,
      selectedSegmentValue: newSelection.value,
      deselectedSegment: prevSelection,
      deselectedSegmentValue: prevSelection?.value,
    });
  }

  render() {
    return (
      <Host class="market-segmented-control">
        <slot onSlotchange={() => this.defaultSlotchangeHandler()}></slot>
      </Host>
    );
  }
}
