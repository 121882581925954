import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  CustomerDetailMessengerPage,
  Status,
  Transition,
} from 'src/MessengerTypes';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import BackIcon from 'src/svgs/MarketBackIcon';
import CustomerInfo from './components/CustomerInfo/CustomerInfo';
import MoreMenu from './components/MoreMenu/MoreMenu';
import TranscriptsHistoryList from './components/TranscriptsHistoryList/TranscriptsHistoryList';
import CustomerDetailSkeletonState from './components/CustomerDetailSkeletonState/CustomerDetailSkeletonState';
import PastAppointmentsList from 'src/components/PastAppointmentsList/PastAppointmentsList';
import Link from 'src/components/Link/Link';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import './CustomerDetailPage.scss';

const CUSTOMER_DETAIL_MAX_PAST_APPTS = 3;

const CUSTOMER_DETAIL_MAX_CONV_HISTORY = 3;

export type CustomerDetailPageProps = {
  transitionDirection: Transition;
};

/**
 * Page used to display information regarding an individual customer.
 * Also contains the conversation history with the customer.
 */
const CustomerDetailPage = observer(
  ({ transitionDirection }: CustomerDetailPageProps): ReactElement | null => {
    const { navigation, transcripts, customers, status } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as CustomerDetailMessengerPage;

    if (!page || !page.transcriptId || !page.customerToken) {
      return null;
    }

    const transcript = transcripts.get(page.transcriptId);
    const { contact, history, pastAppointments } = customers.get(
      page.customerToken,
    );
    const isLoading =
      transcript.customerDetailsStatus === 'LOADING' || !contact;

    let statusValue: Status | null;
    if (transcript.customerDetailsStatus === 'ERROR') {
      statusValue = {
        scope: 'BLADE',
        display: 'SCREEN',
        type: 'ERROR',
        label: t('common.error.try_again'),
        action: {
          action: transcript.loadCustomers,
        },
        actionLabel: t('common.retry'),
      };
    } else {
      // Hide status on the full page as otherwise both views on the full page show separate status banners
      statusValue = !navigation.secondary.isOpen ? status.value : null;
    }

    const isLoadingLists =
      history.status === 'LOADING' || pastAppointments.status === 'LOADING';

    return (
      <>
        <MarketHeader
          className="MessagesPageHeader CustomerDetailPage__header"
          compact
        >
          <div slot="navigation">
            {nav.canNavigateBack && (
              <MarketButton
                aria-label={t('common.back')}
                onClick={nav.navigateBack}
              >
                <BackIcon slot="icon" />
              </MarketButton>
            )}
          </div>
          {!navigation.secondary.isOpen && <h2>{contact?.name}</h2>}
          <div slot="actions">{contact && <MoreMenu contact={contact} />}</div>
        </MarketHeader>
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={isLoading}
          loadingIndicator={<CustomerDetailSkeletonState />}
          status={statusValue}
        >
          <div className="CustomerDetailPage__content-container">
            <div className="CustomerDetailPage__info-container">
              {contact && <CustomerInfo contact={contact} />}
            </div>
            {isLoadingLists ? (
              <div>
                <div className="CustomerDetailSkeletonState__conv-history-title" />
                <TranscriptsListSkeletonState />
              </div>
            ) : (
              <>
                {pastAppointments.list.length > 0 && (
                  <>
                    <h3 className="CustomerDetailPage__conv-history-title">
                      {t('CustomerDetailPage.past_appointments_title')}
                    </h3>
                    <PastAppointmentsList
                      appointments={pastAppointments}
                      maxSize={CUSTOMER_DETAIL_MAX_PAST_APPTS}
                    />
                    {pastAppointments.list.length >
                      CUSTOMER_DETAIL_MAX_PAST_APPTS && (
                      <Link
                        className="CustomerDetailPage__view-all"
                        onClick={() => {
                          navigation.openPastAppointmentsView(
                            page.customerToken,
                          );
                        }}
                      >
                        {t('CustomerDetailPage.view_all_label')}
                      </Link>
                    )}
                  </>
                )}
                {history.size > 0 && (
                  <h3 className="CustomerDetailPage__conv-history-title">
                    {t('CustomerDetailPage.conversation_history_title')}
                  </h3>
                )}
                <TranscriptsHistoryList
                  history={history}
                  maxSize={CUSTOMER_DETAIL_MAX_CONV_HISTORY}
                />
                {history.size > CUSTOMER_DETAIL_MAX_CONV_HISTORY && (
                  <Link
                    className="CustomerDetailPage__view-all"
                    onClick={() => {
                      navigation.openConversationHistoryView(
                        page.customerToken,
                        page.transcriptId,
                      );
                    }}
                  >
                    {t('CustomerDetailPage.view_all_label')}
                  </Link>
                )}
              </>
            )}
          </div>
        </MessengerContent>
      </>
    );
  },
);

export default CustomerDetailPage;
