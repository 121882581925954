@import "../../../../styles/mixins/component-base.css";
@import "../../../../styles/mixins/typography.css";

::slotted([slot="filter-title"]) {
  @extend %heading-20;
}

/**
 * Temporary container so we don't "flash" filters that are being moved around
 * But we don't want to set `display: none` nor `width: 0`
 * since we need to measure the filters' widths
 */
.overflow-filters {
  overflow: hidden;
  height: 0;
}

.popover {
  display: flex;
  flex-direction: column;
}

.selection-header::part(heading) {
  /* TODO: add design token */
  --filter-overflow-heading-margin-top-size: var(--core-metrics-spacing-200);

  margin-top: var(--filter-overflow-heading-margin-top-size);
}

/* width + negative left and right margin added to offset the native scrollbars */
.filter-options-container {
  /* TODO: add design token */
  --filter-overflow-filter-options-container-margin-top-size: var(--core-metrics-spacing-100);

  display: flex;
  flex: 1;
  overflow-y: auto;
  width:
    calc(
      100% +
      var(--modal-popover-wide-viewport-padding-left-size) +
      var(--modal-popover-wide-viewport-padding-right-size)
    );
  margin:
    var(--filter-overflow-filter-options-container-margin-top-size)
    calc(var(--modal-popover-wide-viewport-padding-right-size) * -1)
    calc(var(--modal-popover-wide-viewport-padding-bottom-size) * -1)
    calc(var(--modal-popover-wide-viewport-padding-left-size) * -1);
  padding-left: var(--modal-popover-wide-viewport-padding-left-size);

  ::slotted(.market-list[slot="filter-options"]) {
    width: calc(100% - var(--modal-popover-wide-viewport-padding-right-size));

    /* acts as the bottom padding within the scrollable content */
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: var(--core-metrics-spacing-100);
    }
  }

  ::slotted(.market-date-picker[slot="filter-options"]) {
    width: auto;
    min-width: var(--date-picker-minimum-width);
    padding-right: var(--modal-popover-wide-viewport-padding-right-size);

    /* acts as the bottom padding within the scrollable content */
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: var(--core-metrics-spacing-300);
    }
  }

  ::slotted(.market-date-picker[slot="filter-options"][display-menu]) {
    height: calc(100%);
  }
}
