import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './TranscriptsListFilter.scss';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketFilterButton } from 'src/components/Market';
import { Trans, useTranslation } from 'react-i18next';
import { Bucket } from 'src/gen/squareup/messenger/v3/messenger_service';
import { ASSISTANT_ON_APPOINTMENTS_URL } from 'src/utils/url';

/**
 * A row of filters for the transcripts list, such as inbox, assistant, unread.
 * Also renders any additional description based on the selected filter.
 */
const TranscriptsListFilter = observer((): ReactElement => {
  const { transcriptsLists, event } = useMessengerControllerContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="TranscriptsListFilter">
        <MarketFilterButton
          size="small"
          active={transcriptsLists.filter === Bucket.ACTIVE}
          onClick={() => transcriptsLists.setFilter(Bucket.ACTIVE)}
          data-cy="TranscriptsListFilter__active"
        >
          {t('TranscriptsListFilter.active')}
        </MarketFilterButton>
        <MarketFilterButton
          size="small"
          active={transcriptsLists.filter === Bucket.ASSISTANT}
          onClick={() => {
            transcriptsLists.setFilter(Bucket.ASSISTANT);
            event.track('Click Assistant');
          }}
          data-cy="TranscriptsListFilter__assistant"
        >
          {t('TranscriptsListFilter.assistant')}
        </MarketFilterButton>
      </div>
      {transcriptsLists.filter === Bucket.ASSISTANT && (
        <div
          className="TranscriptsListFilter__assistant-description"
          data-testid="TranscriptsListFilter__assistant-description"
        >
          <Trans
            i18nKey="AssistantTranscriptsListPage.description"
            components={{
              1: (
                <a
                  href={ASSISTANT_ON_APPOINTMENTS_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="AssistantComponent__link"
                  onClick={() => event.track('Click Assistant Link')}
                />
              ),
            }}
          />
        </div>
      )}
    </>
  );
});

export default TranscriptsListFilter;
