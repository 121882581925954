@import "../../styles/mixins/component-base.css";

:host {
  display: inline-block;
}

.dropdown,
.dropdown > .filter-button {
  width: 100%;
}

.date-popover {
  max-width: unset;
  max-height: unset;
}
