import type MessengerController from 'src/MessengerController';
import TranscriptsList from 'src/stores/objects/TranscriptsList';
import { Bucket } from 'src/gen/squareup/messenger/v3/messenger_service';
import { makeAutoObservable } from 'mobx';

class TranscriptsListsStore {
  private _stores: MessengerController;
  active: TranscriptsList;
  assistant: TranscriptsList;
  filter: Bucket;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this.active = new TranscriptsList(stores, {
      bucket: Bucket.ACTIVE,
    });
    this.assistant = new TranscriptsList(stores, {
      bucket: Bucket.ASSISTANT,
    });
    this.filter = Bucket.ACTIVE;
  }

  get current(): TranscriptsList {
    switch (this.filter) {
      case Bucket.ASSISTANT:
        return this.assistant;
      case Bucket.ACTIVE:
      default:
        return this.active;
    }
  }

  setFilter(filter: Bucket): void {
    this.filter = filter;
  }

  clearAll(): void {
    this.active.clear();
    this.assistant.clear();
  }
}

export default TranscriptsListsStore;
