import { Component, Element, Event, EventEmitter, Host, h, Listen, Method, Prop } from '@stencil/core';

import { MENU_SLOT_NAMES } from '../../enums/menu';

import { getNamespacedTagFor } from '../../../../utils/namespace';
import { TMarketDatePickerMenuSelectionChangedEventDetail } from '../../events';
import { TMarketListSelectionsDidChangeEventDetail } from '../../../market-list/events';

/**
 * @slot 'today' - slot for market date picker menu today option;
 * @slot 'yesterday' - slot for market date picker menu yesterday option;
 * @slot 'this-week' - slot for market date picker menu this week option;
 * @slot 'last-week' - slot for market date picker menu last week option;
 * @slot 'this-month' - slot for market date picker menu this month option;
 * @slot 'last-month' - slot for market date picker menu last month option;
 * @slot 'this-year' - slot for market date picker menu this year option;
 * @slot 'last-year' - slot for market date picker menu last year option;
 * @slot 'custom' - slot for market date picker menu custom option;
 */
@Component({
  tag: 'market-date-picker-menu',
  styleUrl: 'market-date-picker-menu.css',
  shadow: true,
})
export class MarketDatePickerMenu {
  @Element() el: HTMLMarketDatePickerMenuElement;

  /**
   * String for setting timeframe type to select which menu items to show
   */
  @Prop({ reflect: true }) readonly timeframe: 'past' | 'present' | 'future' = 'present';

  /**
   * A list of menu items that will be excluded from appearing on the menu list.
   * i.e. `this-year,last-year` or `today,this-week,last-week,custom`
   * The menu names are lowercase and hyphenated strings, found here:
   * https://github.com/squareup/market/blob/main/web/web-components/src/components/market-date-picker/enums/menu.tsx
   *
   * This works in conjunction with timeframe,
   * i.e. "timeframe=past", excludes dates in the future in addition to the ones here.
   * This is written as items separated by ','.
   */
  @Prop() readonly excludeMenuItems: string = '';

  /**
   * Preset menu option passed from the parent. For the menu, this handles visually selecting the menu row.
   */
  @Prop() readonly presetMenuOption: MENU_SLOT_NAMES;

  /**
   * Fired whenever a menu item is selected
   */
  @Event() marketDatePickerMenuSelectionChanged: EventEmitter<TMarketDatePickerMenuSelectionChangedEventDetail>;

  @Listen('marketListSelectionsDidChange')
  handleMarketListSelectionsDidChange(e: CustomEvent<TMarketListSelectionsDidChangeEventDetail>) {
    e.stopPropagation();
    this.marketDatePickerMenuSelectionChanged.emit({
      menuSelection: e.detail.newSelectionValue as `${MENU_SLOT_NAMES}`,
    });
  }

  /**
   * Method to visually select Custom row for use by the date picker component
   * (internal use only)
   */
  @Method()
  _selectCustomRow() {
    const MarketRowTagName = getNamespacedTagFor('market-row');
    this.el.shadowRoot.querySelectorAll(MarketRowTagName).forEach((row) => {
      if (row.querySelector('slot').name === MENU_SLOT_NAMES.CUSTOM) {
        row.click();
      }
    });
    return Promise.resolve();
  }

  render() {
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketRowTagName = getNamespacedTagFor('market-row');
    const excludedItems = this.excludeMenuItems.split(',');

    return (
      <Host class="market-date-picker-menu">
        <MarketListTagName interactive>
          {this.timeframe !== 'past' && !excludedItems.includes(MENU_SLOT_NAMES.TODAY) && (
            <MarketRowTagName value={MENU_SLOT_NAMES.TODAY} selected={this.presetMenuOption === MENU_SLOT_NAMES.TODAY}>
              <slot name={MENU_SLOT_NAMES.TODAY}>Today</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'future' && !excludedItems.includes(MENU_SLOT_NAMES.YESTERDAY) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.YESTERDAY}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.YESTERDAY}
            >
              <slot name={MENU_SLOT_NAMES.YESTERDAY}>Yesterday</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'past' && !excludedItems.includes(MENU_SLOT_NAMES.THIS_WEEK) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.THIS_WEEK}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.THIS_WEEK}
            >
              <slot name={MENU_SLOT_NAMES.THIS_WEEK}>This week</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'future' && !excludedItems.includes(MENU_SLOT_NAMES.LAST_WEEK) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.LAST_WEEK}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.LAST_WEEK}
            >
              <slot name={MENU_SLOT_NAMES.LAST_WEEK}>Last week</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'past' && !excludedItems.includes(MENU_SLOT_NAMES.THIS_MONTH) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.THIS_MONTH}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.THIS_MONTH}
            >
              <slot name={MENU_SLOT_NAMES.THIS_MONTH}>This month</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'future' && !excludedItems.includes(MENU_SLOT_NAMES.LAST_MONTH) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.LAST_MONTH}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.LAST_MONTH}
            >
              <slot name={MENU_SLOT_NAMES.LAST_MONTH}>Last month</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'past' && !excludedItems.includes(MENU_SLOT_NAMES.THIS_YEAR) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.THIS_YEAR}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.THIS_YEAR}
            >
              <slot name={MENU_SLOT_NAMES.THIS_YEAR}>This year</slot>
            </MarketRowTagName>
          )}
          {this.timeframe !== 'future' && !excludedItems.includes(MENU_SLOT_NAMES.LAST_YEAR) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.LAST_YEAR}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.LAST_YEAR}
            >
              <slot name={MENU_SLOT_NAMES.LAST_YEAR}>Last year</slot>
            </MarketRowTagName>
          )}
          {!excludedItems.includes(MENU_SLOT_NAMES.CUSTOM) && (
            <MarketRowTagName
              value={MENU_SLOT_NAMES.CUSTOM}
              selected={this.presetMenuOption === MENU_SLOT_NAMES.CUSTOM}
            >
              <slot name={MENU_SLOT_NAMES.CUSTOM}>Custom</slot>
            </MarketRowTagName>
          )}
        </MarketListTagName>
      </Host>
    );
  }
}
