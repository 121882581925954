import { TEXTAREA_MAXIMUM_HEIGHT } from '@market/market-theme/js/cjs/index.js';
import { Component, Host, h, Element, Prop, Event, EventEmitter, Listen, State, Method } from '@stencil/core';

import { getTextInputAriaLabel, observeAriaAttributes, AriaAttributes } from '../../utils/aria';

/**
 * @slot - The main label for the textarea.
 * @slot textarea - Can be used to slot your own custom textarea element.
 * @part container - The containing div for the textarea and label.
 */
@Component({
  tag: 'market-textarea',
  styleUrl: 'market-textarea.css',
  shadow: true,
})
export class MarketTextarea {
  private slottedTextarea?: HTMLTextAreaElement;

  @Element() el: HTMLMarketTextareaElement;

  /**
   * A string specifying the placeholder of the textarea.
   * This is shown before a user attempts to add a value, given no value is already provided.
   */
  @Prop() readonly placeholder: string;

  /**
   * A string specifying a name for the textarea.
   */
  @Prop() readonly name: string;

  /**
   * A string specifying a value for the textarea. This will be visually shown on the textarea and can be edited by the user.
   */
  @Prop({ mutable: true, reflect: true }) value: string = '';

  /**
   * A string specifying the maximum length of characters for the input value.
   */
  @Prop() readonly maxlength: string;

  /**
   * A boolean representing whether the textarea is readonly or not.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * A boolean representing whether the textarea is disabled or not.
   * This visually and functionally will disable the textarea.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * A boolean representing whether the textarea is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * A boolean representing whether the textarea is invalid or not.
   * This represents error states.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * A string specifying the maximum height in pixels for the textarea. Vertical resizing will be limited to this height. Example value: '200px'.
   *
   * **DEPRECATED**: set `max-height` via CSS
   *
   * @default '320px'
   */
  @Prop() readonly maxHeight: string = `${TEXTAREA_MAXIMUM_HEIGHT}px`;

  /**
   * A boolean representing whether the input should focus on page load.
   * If multiple elements with `autofocus` are present, it is not guaranteed which one
   * will ultimately receive the focus. It is advised that only one at most is present.
   */
  @Prop() readonly autofocus: boolean = false;

  /**
   * Allows a browser to display an appropriate virtual keyboard.
   * [Accepted values](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode).
   */
  @Prop() readonly inputmode: string;

  /**
   * Fired whenever the value of the textarea changes.
   */
  @Event() marketTextareaValueChange: EventEmitter<{ value: string; originalEvent: KeyboardEvent }>;

  @State() ariaAttributes: AriaAttributes;

  mutationObserver: MutationObserver;

  sharedProps: {};

  @Listen('marketDialogLoaded', { target: 'window' })
  handleMarketDialogLoaded() {
    if (this.autofocus) {
      this.setFocus();
    }
  }

  /**
   * Allows passing an alternative light DOM textarea.
   * Sets the this.slottedTextarea value to undefined if there is no slotted element.
   */
  @Method()
  registerSlottedTextarea(slottedTextarea?: HTMLTextAreaElement) {
    this.slottedTextarea =
      slottedTextarea ||
      // textarea slotted into market-textarea
      this.el.querySelector('textarea[slot=textarea]') ||
      // textarea slotted into a higher-level component that uses market-textarea
      (this.el.getRootNode() as ShadowRoot).host?.querySelector('textarea[slot=textarea]');
    if (this.slottedTextarea) {
      this.slottedTextarea.addEventListener('input', (e) => this.textareaValueDidChange(e));
      this.slottedTextarea.addEventListener('focus', () => this.setFocus());
      this.slottedTextarea.addEventListener('blur', () => (this.focused = false));
    }

    return Promise.resolve();
  }

  syncSharedPropsToSlottedTextarea(prevSharedProps) {
    // sync component props to slotted input, if one exists
    if (this.slottedTextarea) {
      const modifiedPropKeys = [...new Set([...Object.keys(prevSharedProps), ...Object.keys(this.sharedProps)])];
      modifiedPropKeys.forEach((key) => {
        if (!(key in this.sharedProps)) {
          // remove properties that have been unset
          this.slottedTextarea.removeAttribute(key);
        } else {
          // boolean attributes can be set using empty strings
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute#javascript
          const attributeValue = this.sharedProps[key] !== true ? this.sharedProps[key] : '';
          this.slottedTextarea.setAttribute(key, attributeValue);
        }
      });
    }
  }

  updateSharedPropsAndSyncSlottedTextarea() {
    const prevSharedProps = { ...this.sharedProps };

    // used by the default shadow DOM native input and to copy component properties to slotted inputs
    // conditionally adding key/value pairs based on whether we want to set them on the <input>
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#individual_attributes
    this.sharedProps = {
      ...(this.name && { name: this.name }),
      ...(this.placeholder && { placeholder: this.placeholder }),
      ...(this.maxlength !== undefined && Number.parseInt(this.maxlength, 10) >= 0 && { maxlength: this.maxlength }),
      ...(this.value !== undefined && { value: this.value }),
      ...(this.readonly && { readonly: this.readonly }),
      ...(this.disabled && { disabled: this.disabled }),
      ...(this.autofocus && { autofocus: this.autofocus }),
      ...(this.inputmode && { inputmode: this.inputmode }),
      ...this.ariaAttributes,
      'aria-label': getTextInputAriaLabel(this.el),
    };

    this.syncSharedPropsToSlottedTextarea(prevSharedProps);
  }

  onMutationObserved = (ariaAttributes: AriaAttributes) => {
    this.ariaAttributes = ariaAttributes;
  };

  componentWillLoad() {
    this.mutationObserver = observeAriaAttributes(this.el, this.onMutationObserved);
    this.registerSlottedTextarea();
    this.updateSharedPropsAndSyncSlottedTextarea();
  }

  componentDidLoad() {
    if (this.maxHeight) {
      // Set the passed max height on the input container, since that's where
      // the drag handle will actually be visible.
      // This will be removed until `maxHeight` prop is fully deprecated.
      this.el.style.maxHeight = this.maxHeight;
    }
  }

  componentWillUpdate() {
    this.updateSharedPropsAndSyncSlottedTextarea();
  }

  textareaValueDidChange(e) {
    // Need to update value for the label, which floats if this value exists.
    this.value = e.target.value;

    this.marketTextareaValueChange.emit({
      value: e.target.value,
      originalEvent: e,
    });
  }

  setFocus(value: boolean = true) {
    if (this.readonly || this.disabled) {
      return;
    }

    const shouldSetElementFocus = value && !this.focused;

    this.focused = value;

    // Set focus on element if not already focused
    if (shouldSetElementFocus) {
      this.slottedTextarea ? this.slottedTextarea.focus() : this.el?.shadowRoot?.querySelector?.('textarea')?.focus?.();
    }
  }

  render() {
    return (
      <Host
        class="market-textarea"
        onBlur={() => {
          this.focused = false;
        }}
        onClick={() => {
          this.setFocus();
        }}
        onFocus={() => {
          this.setFocus();
        }}
        role="textbox"
      >
        <div class="label-input-container" part="container">
          <slot></slot>
          <slot name="textarea" onSlotchange={() => this.registerSlottedTextarea()}>
            {!this.slottedTextarea && (
              <textarea
                {...this.ariaAttributes}
                id={this.name}
                onInput={(e) => this.textareaValueDidChange(e)}
                {...this.sharedProps}
              ></textarea>
            )}
          </slot>
        </div>
      </Host>
    );
  }

  disconnectedCallback() {
    this.mutationObserver?.disconnect();
  }
}
