/* TODO: add design tokens for component */

@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/typography.css";

:host {
  &([invalid]) {
    .label-container {
      border-color: var(--field-normal-state-invalid-validity-border-color);
    }
  }

  &([disabled]) {
    .label-container {
      border-color: var(--field-disabled-state-border-color);
      background-color: var(--field-disabled-state-background-color);
      color: var(--field-disabled-state-empty-phase-label-color);
    }

    button,
    .market-accessory,
    ::slotted(button),
    ::slotted(.market-button),
    ::slotted(.market-link),
    svg {
      opacity: 30%;
    }
  }
}

.label-container {
  border:
    var(--file-upload-border-width, 1px)
    dashed
    var(--file-upload-normal-state-border-color, var(--core-fill-30-color));
  border-style: dashed;
  border-radius: var(--file-upload-border-radius, 6px);

  & > label,
  & > .market-row {
    padding:
      var(--file-upload-vertical-padding, 28px)
      var(--file-upload-button-horizontal-padding, var(--core-metrics-spacing-200));
  }

  & > label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 20px;
      fill: var(--file-upload-label-fill-color, var(--core-fill-10-color));
    }
  }

  /* Overriding market-row margin since it overflows the container natively */
  & > .market-row {
    margin: 0;
  }
}

.is-dragging-over {
  border:
    var(--file-upload-border-width, 1px)
    solid
    var(--file-upload-dragging-state-border-color, var(--core-blue-fill-color));
  background-color: var(--file-upload-dragging-state-background-color, var(--core-emphasis-40-color));
}

.has-file-selected {
  border:
    var(--file-upload-border-width, 1px)
    solid
    var(--file-upload-file-selected-state-border-color, var(--core-fill-30-color));
}

button,
.market-button,
::slotted(button),
::slotted(.market-button),
::slotted(.market-link) {
  @extend %semibold-30;

  padding: var(--file-upload-button-padding, 0);

  /* removes the default button styling */
  border: none;
  background-color: inherit;
  color: var(--file-upload-button-label-color, var(--button-normal-variant-tertiary-rank-normal-state-label-color));
  font-family: inherit;
  text-align: right;
  cursor: pointer;
}

::slotted([slot="bottom-accessory"]) {
  color: var(--file-upload-helper-text-color, var(--core-text-20-color));
}

/* Delete button on each file row */
.market-accessory {
  cursor: pointer;

  svg {
    fill: var(--file-upload-delete-fill-color, var(--core-fill-20-color));
  }
}
