/**
 * An icon representing photo.
 */
import React, { ReactElement } from 'react';

function PhotoIcon(props: { color?: string }): ReactElement {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
      <title>Photo</title>
      <path
        fill={color ?? '#000'}
        fillOpacity=".9"
        d="M10.723 7.326a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      />
      <path
        fill={color ?? '#000'}
        fillOpacity=".9"
        fillRule="evenodd"
        d="M3.723.326a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-14a3 3 0 0 0-3-3h-14Zm14 2h-14a1 1 0 0 0-1 1v11.586l4-4 2.5 2.5 5.5-5.5 4 4V3.326a1 1 0 0 0-1-1Zm1 12.414-4-4-5.5 5.5-2.5-2.5-3.937 3.937a1 1 0 0 0 .937.65h14a1 1 0 0 0 1-1V14.74Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PhotoIcon;
