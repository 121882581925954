import { Component, Element, Event, EventEmitter, Host, Prop, h, Listen, Watch } from '@stencil/core';

import { getNamespacedTagFor } from '../../../../utils/namespace';
import { TMarketTabListSelectedTabChangedEventDetail, TMarketTabSelectedChangedEventDetail } from '../../events';

/**
 * @slot - Default slot for `<market-tab>`s
 */
@Component({
  tag: 'market-tab-list',
  styleUrl: 'market-tab-list.css',
  shadow: true,
})
export class MarketTabList {
  @Element() el: HTMLMarketTabListElement;
  tabEls?: HTMLMarketTabElement[];

  /**
   * Tabs' size
   *
   * @default undefined
   */
  @Prop() readonly size?: 'small' | 'medium' | 'large';

  /**
   * String for the selected tab (i.e. `market-tab`'s `id` attribute)
   *
   * Omitting or setting to empty string will default to the first non-disabled tab
   *
   * @default undefined
   */
  @Prop({ mutable: true, reflect: true }) selectedTab?: string;

  /**
   * String for the default selected tab (i.e. `market-tab`'s `id` attribute)
   *
   * Only used when the component initially loads
   *
   * @default undefined
   */
  @Prop() readonly defaultTab?: string;

  /**
   * Fired when a `market-tab` is selected
   */
  @Event({ bubbles: true, composed: true })
  marketTabListSelectedTabChanged: EventEmitter<TMarketTabListSelectedTabChangedEventDetail>;

  @Listen('marketTabSelectedChanged')
  marketTabSelectedChangedEventHandler(e: CustomEvent<TMarketTabSelectedChangedEventDetail>) {
    const { tabId, value } = e.detail;
    if (!value) {
      return;
    }
    if (this.selectedTab !== tabId) {
      const { defaultPrevented } = this.marketTabListSelectedTabChanged.emit({
        prevValue: this.selectedTab,
        value: tabId,
      });
      if (!defaultPrevented) {
        this.selectedTab = tabId;
      }
    }
    this.tabEls?.forEach((tabEl) => {
      if (tabEl.id !== tabId) {
        tabEl.deselect();
        tabEl.tabIndex = -1;
      } else {
        tabEl.tabIndex = 0;
      }
    });
  }

  @Watch('selectedTab')
  tabWatcher(newTabId: string) {
    this.selectTab(newTabId);
  }

  @Watch('size')
  sizeWatcher(newSize: typeof this.size) {
    this.propagateSizeProp(newSize);
  }

  selectTab(tabId?: string) {
    const tabEl = this.tabEls?.find((el) => tabId === el.id && !el.disabled) || this.tabEls?.find((el) => !el.disabled);
    tabEl?.select();
  }

  propagateSizeProp(size: typeof this.size) {
    this.tabEls.forEach((el) => {
      if (this.size && el.size !== this.size) {
        el.size = size;
      }
    });
  }

  focusOnTab(el: HTMLMarketTabElement) {
    el?.shadowRoot?.querySelector('button')?.focus();
  }

  handleKeyDown(e: KeyboardEvent) {
    if (!this.tabEls || this.tabEls.every((el) => el.disabled)) {
      return;
    }

    /**
     * These keyboard shortcut behaviours are from:
     * https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-manual/#kbd_label
     *
     * Tab, Enter, and Space behaviours should already natively work.
     */
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' || e.key === 'End') {
      e.preventDefault(); // prevent key press from triggering scroll events

      const currentTabIndex = this.tabEls.indexOf(e.target as HTMLMarketTabElement);
      if (currentTabIndex < 0) {
        return;
      }
      const left = this.tabEls.slice(0, currentTabIndex);
      const right = this.tabEls.slice(currentTabIndex + 1);

      switch (e.key) {
        case 'ArrowRight': {
          const focusableTabEls = [...right, ...left].find((el) => !el.disabled);
          this.focusOnTab(focusableTabEls);
          break;
        }
        case 'ArrowLeft': {
          const focusableTabEls = [...right, ...left].reverse().find((el) => !el.disabled);
          this.focusOnTab(focusableTabEls);
          break;
        }
        case 'Home': {
          const focusableTabEls = this.tabEls.find((el) => !el.disabled);
          this.focusOnTab(focusableTabEls);
          break;
        }
        case 'End': {
          const focusableTabEls = this.tabEls.filter((el) => !el.disabled);
          this.focusOnTab(focusableTabEls[focusableTabEls.length - 1]);
          break;
        }
        default:
          break;
      }
    }
  }

  handleSlotChange() {
    this.tabEls = [...this.el.querySelectorAll(getNamespacedTagFor('market-tab'))];
    this.propagateSizeProp(this.size);
  }

  componentWillLoad() {
    this.handleSlotChange();
    this.selectTab(this.selectedTab || this.defaultTab);
  }

  render() {
    return (
      <Host class="market-tab-list" onKeyDown={this.handleKeyDown.bind(this)} role="tablist">
        <slot onSlotchange={() => this.handleSlotChange()}></slot>
      </Host>
    );
  }
}
