import { makeAutoObservable } from 'mobx';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import type MessengerController from 'src/MessengerController';
import {
  EditVoicemailPage as EditVoicemailPageType,
  MessagesSqOneTrust,
  TooltipName,
} from 'src/MessengerTypes';

/**
 * localStorage values
 */
export const TOOLTIP_HIDDEN = 'HIDDEN';
export const TOOLTIP_SHOW = 'SHOW';

/**
 * Tooltip name to old key mapping. Tied to _updateOldKeys().
 * Only includes old keys with 'TOOLTIP' postfix.
 */
const TOOLTIP_NAME_TO_OLD_KEY: Record<string, string> = {
  REVIEWS: 'MESSENGER_REVIEWS_TOOLTIP_',
  FULL_PAGE_INBOX: 'MESSENGER_FULL_PAGE_INBOX_TOOLTIP_',
  VIEW_NUMBER: 'MESSENGER_VIEW_NUMBER_TOOLTIP_',
};

export default class TooltipStore {
  private _stores: MessengerController;

  /**
   * A copy of the SqOneTrust instance from SqOneTrustService.
   */
  private _sqOneTrust: MessagesSqOneTrust;

  /**
   * Internal tooltip states. Maps from the key of the tooltip to the
   * visibility of the tooltip.
   */
  private _tooltipState: Map<string, boolean> = new Map();

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._sqOneTrust = stores.services.sqOneTrust.getSqOneTrust();
  }

  private get _merchantToken(): string {
    return this._stores.user.merchantToken;
  }

  init = (): void => {
    /**
     * Update old keys.
     *
     * Key used in the old TooltipController is slightly different from the
     * new full key generated in this store. This function updates them to the new key.
     * Example:
     * MESSENGER_REVIEWS_TOOLTIP_<MERCHANT_TOKEN> -> MESSENGER_REVIEWS_<MERCHANT_TOKEN>
     *
     * This is created on Jul, 26, 2023, and can be removed after 6 months.
     * TODO(klim): Remove this after a reasonable time period.
     */
    if (window.localStorage) {
      for (const tooltipName in TOOLTIP_NAME_TO_OLD_KEY) {
        const oldValue = window.localStorage.getItem(
          `${TOOLTIP_NAME_TO_OLD_KEY[tooltipName]}${this._merchantToken}`,
        );
        if (oldValue) {
          window.localStorage.setItem(
            this._getFullKey(tooltipName as TooltipName),
            oldValue,
          );
          window.localStorage.removeItem(
            `${TOOLTIP_NAME_TO_OLD_KEY[tooltipName]}${this._merchantToken}`,
          );
        }
      }
    }
  };

  /**
   * Generates the full key of a tooltip.
   *
   * @param {string} name - name of the tooltip
   */
  private _getFullKey = (name: TooltipName): string => {
    let fullKey = `MESSENGER_${name}_${this._merchantToken}`;

    switch (name) {
      case 'SUBSCRIPTION_DELINQUENT_BANNER':
      case 'SUBSCRIPTION_EXPIRING_BANNER':
        fullKey += `_${this._stores.subscription.endAt.toString()}`;
        break;
      case 'SUBSCRIPTION_IN_GRACE_BANNER':
        fullKey += `_${this._stores.subscription.delinquentAt.toString()}`;
        break;
      case 'UNITS_EXPIRING_BANNER':
        fullKey += `_${this._stores.subscription.renewalAt.toString()}`;
        break;
      case 'MERGE_CUSTOMERS': {
        const customerTokensKey =
          this._stores.transcriptView?.transcript?.customerTokens
            ?.slice()
            ?.sort()
            ?.join('_');
        fullKey += `_${customerTokensKey}`;
        break;
      }
      case 'MESSAGES_PLUS_SUCCESS_BANNER': {
        const unitToken = this._stores.transcriptView?.transcript?.sellerKey;
        const dedicatedNumber =
          this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
            ?.dedicatedNumber;
        fullKey += `_${dedicatedNumber}`;
        break;
      }
      case 'VERIFICATION_FAILED_DIALOG': {
        const unitToken =
          this._stores.subscription.unitTokensFailedRetryable[0];
        fullKey += `_${unitToken}`;
        break;
      }
      case 'VERIFICATION_SUCCESS_DIALOG': {
        const dedicatedNumber =
          this._stores.subscription.recentlyVerifiedAndSubscribedNumbers?.[0]
            ?.dedicatedNumber;
        fullKey += `_${dedicatedNumber}`;
        break;
      }
      case 'EDIT_REQUESTED_TEXT_MESSAGE_TIP': {
        const unitToken =
          (this._stores.navigation.sheet.currentPage as EditVoicemailPageType)
            ?.unitToken || '';
        fullKey += `_${unitToken}`;
        break;
      }
      case 'MESSAGES_PLUGIN_LAUNCH_BANNER': {
        const ftuxCategory =
          this._stores.transcriptView.mostRecentMessagesPluginEntryUtterance
            ?.metadata?.messagesPluginEntry?.ftux?.category ??
          Utterance.Metadata.MessagesPluginEntry.FtuxCategory
            .FTUX_CATEGORY_UNRECOGNIZED;
        fullKey += `_FTUX_CATEGORY_${ftuxCategory}`;
        break;
      }
      default:
    }

    return fullKey;
  };

  /**
   * Get the visibility of a specific tooltip. Tooltips are by default
   * visible, and calling dismiss() will hide it permanently.
   *
   * @param {string} name - name of the tooltip
   */
  isVisible = (name: TooltipName): boolean => {
    const fullKey = this._getFullKey(name);

    if (!this._tooltipState.has(fullKey)) {
      const localStorageValue = window.localStorage?.getItem(fullKey);
      if (localStorageValue === TOOLTIP_SHOW || localStorageValue === null) {
        // If it is not present or explicitly set to SHOW, then set map
        // state to true (visible).
        this._tooltipState.set(fullKey, true);
      } else {
        this._tooltipState.set(fullKey, false);
      }
    }

    return this._tooltipState.get(fullKey) as boolean;
  };

  /**
   * Hides a tooltip, setting the localStorage state to hidden.
   *
   * @param {string} name - name of the tooltip
   */
  dismiss = (name: TooltipName): void => {
    const fullKey = this._getFullKey(name);

    this._tooltipState.set(fullKey, false);

    if (window.localStorage && !this._sqOneTrust.functionalityCookiesBlocked) {
      window.localStorage.setItem(fullKey, TOOLTIP_HIDDEN);
    }
  };

  /**
   * Show a tooltip, setting the localStorage state to show. We usually
   * do not have to call this because tooltips are visible by default,
   * unless we explicitly want to control when to show the tooltip.
   *
   * @param {string} name - name of the tooltip
   */
  show = (name: TooltipName): void => {
    const fullKey = this._getFullKey(name);

    this._tooltipState.set(fullKey, true);

    if (window.localStorage && !this._sqOneTrust.functionalityCookiesBlocked) {
      window.localStorage.setItem(fullKey, TOOLTIP_SHOW);
    }
  };
}
