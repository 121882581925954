@import "../../styles/mixins/component-base.css";

:host {
  --filter-group-margin: var(--core-metrics-spacing-200);
  --filter-group-search-width: 280px;

  display: flex;
  margin: var(--filter-group-margin);
}

.content {
  --filter-group-button-spacing: var(--core-metrics-spacing-100);

  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  gap: var(--filter-group-button-spacing, var(--core-metrics-spacing-100));
}

::slotted([slot="search"]) {
  max-width: var(--filter-group-search-width);
}

::slotted([slot="search"]:not([compact])) {
  min-width: var(--filter-group-search-width);
}

::slotted([slot="search"][focused]) {
  flex: 1 1 var(--filter-group-search-width);
}

@media (max-width: 599px) {
  .content {
    justify-content: space-between;
  }

  ::slotted([slot="search"]) {
    max-width: unset;
  }

  ::slotted([slot="search"][focused]) {
    flex: 1;
  }

  ::slotted([slot="filters"]) {
    display: inline-flex;
    flex: 1;
  }
}
