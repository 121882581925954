import { Component, Element, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'market-tab-panel',
  styleUrl: 'market-tab-panel.css',
  shadow: true,
})
export class MarketTabPanel {
  @Element() el: HTMLMarketTabPanelElement;

  /**
   * Whether or not the panel is hidden
   */
  @Prop({ reflect: true, mutable: false }) readonly hidden: boolean = false;

  render() {
    return (
      <Host aria-hidden={this.hidden.toString()} class="market-tab-panel" role="tabpanel" tabindex="0">
        <slot></slot>
      </Host>
    );
  }
}
