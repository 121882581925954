@import "../../styles/mixins/component-base.css";

:host {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  gap: calc(var(--segmented-control-padding-horizontal) * 2);
  align-items: center;
  min-width: 100%;
  padding: var(--segmented-control-padding-vertical) var(--segmented-control-padding-horizontal);
  border-radius: var(--segmented-control-background-radius);
  background: var(--segmented-control-background-normal-state-color);
}

:host([disabled]) {
  background: var(--segmented-control-background-disabled-state-color);
  color: var(--segmented-control-option-label-disabled-state-color);
}

::slotted(.market-segment) {
  z-index: 2;
  width: 100%;
  height: 100%;
}

:host::before {
  content: "";
  position: absolute;
  left: var(--selected-slider-left);
  z-index: 1;
  width: var(--selected-slider-width);
  height: calc(100% - var(--segmented-control-padding-vertical) * 2);
  border-radius: var(--segmented-control-option-background-radius);
  background: var(--segmented-control-option-background-selected-state-color);
  box-shadow: var(--elevation-10-shadow);
  transition: left var(--segmented-control-animation-duration);
}
