@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/typography.css";

:host {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  max-width: var(--toast-maximum-width);
  border-radius: var(--toast-radius);
  box-shadow: var(--elevation-30-shadow);
  font-weight: var(--toast-text-weight);
  font-size: var(--toast-text-size);
  line-height: var(--toast-text-leading);
  letter-spacing: var(--toast-text-tracking);
  text-transform: var(--toast-text-case);
}

.icon-container,
.dismiss-container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.dismiss-container {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.2s;
    appearance: none;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: var(--toast-vertical-padding-size) var(--toast-horizontal-padding-size);
  column-gap: var(--toast-icon-spacing);
}

.main {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: var(--toast-text-multiline-spacing);
  column-gap: var(--toast-content-spacing);
}

section {
  flex-grow: 1;
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &.hidden {
    display: none;
  }
}

/* style slotted buttons and links the same */
::slotted(button),
::slotted(a) {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: var(--toast-button-text-weight);
  font-size: var(--toast-button-text-size);
  font-family: inherit;
  line-height: var(--toast-button-text-leading);
  letter-spacing: var(--toast-button-text-tracking);
  text-decoration: none;
  text-transform: var(--toast-button-text-case);
  cursor: pointer;
  transition: opacity 0.2s;
}

::slotted(button:hover),
::slotted(a:hover) {
  opacity: var(--text-link-hover-state-opacity);
}

::slotted(button:active),
::slotted(a:active) {
  opacity: var(--text-link-pressed-state-opacity);
}

::slotted([slot="action"]) {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* implement nav separator line */
::slotted([slot="action"]:not(:last-child)) {
  /* add extra right margin to place separator */
  margin-right: calc(var(--toast-button-spacing) * 2 + var(--toast-separator-width));

  &::after {
    /* left and right margins will position separator between
      this and the next action, and outside of clickable hit area */
    content: "";
    display: inline-block;
    width: var(--toast-separator-width);
    height: var(--toast-separator-height);
    margin-right: calc(-1 * var(--toast-button-spacing) - var(--toast-separator-width));
    margin-left: var(--toast-button-spacing);
    opacity: var(--toast-separator-opacity);
    pointer-events: none;
  }
}

progress-bar {
  height: var(--toast-progress-bar-height);
}

:host([variant="info"]) {
  background-color: var(--toast-info-variant-background-color);
  color: var(--toast-info-variant-text-color);

  .icon-container svg {
    fill: var(--toast-info-variant-icon-color);
  }

  .dismiss-container svg {
    fill: var(--toast-info-variant-dismiss-button-color);
  }

  progress-bar {
    background-color: var(--toast-info-variant-progress-bar-color);
  }

  ::slotted(a) {
    color: var(--toast-info-variant-text-link-text-color);
  }

  ::slotted(button) {
    color: var(--toast-info-variant-button-text-color);
  }

  ::slotted([slot="action"]:not(:last-child))::after {
    background-color: var(--toast-info-variant-separator-color);
  }
}

:host([variant="success"]) {
  background-color: var(--toast-success-variant-background-color);
  color: var(--toast-success-variant-text-color);

  .icon-container svg {
    fill: var(--toast-success-variant-icon-color);
  }

  .dismiss-container svg {
    fill: var(--toast-success-variant-dismiss-button-color);
  }

  progress-bar {
    background-color: var(--toast-success-variant-progress-bar-color);
  }

  ::slotted(a) {
    color: var(--toast-success-variant-text-link-text-color);
  }

  ::slotted(button) {
    color: var(--toast-success-variant-button-text-color);
  }

  ::slotted([slot="action"]:not(:last-child))::after {
    background-color: var(--toast-success-variant-separator-color);
  }
}

:host([variant="warning"]) {
  background-color: var(--toast-warning-variant-background-color);
  color: var(--toast-warning-variant-text-color);

  .icon-container svg {
    fill: var(--toast-warning-variant-icon-color);
  }

  .dismiss-container svg {
    fill: var(--toast-warning-variant-dismiss-button-color);
  }

  progress-bar {
    background-color: var(--toast-warning-variant-progress-bar-color);
  }

  ::slotted(a) {
    color: var(--toast-warning-variant-text-link-text-color);
  }

  ::slotted(button) {
    color: var(--toast-warning-variant-button-text-color);
  }

  ::slotted([slot="action"]:not(:last-child))::after {
    background-color: var(--toast-warning-variant-separator-color);
  }
}

:host([variant="critical"]) {
  background-color: var(--toast-critical-variant-background-color);
  color: var(--toast-critical-variant-text-color);

  .icon-container svg {
    fill: var(--toast-critical-variant-icon-color);
  }

  .dismiss-container svg {
    fill: var(--toast-critical-variant-dismiss-button-color);
  }

  progress-bar {
    background-color: var(--toast-critical-variant-progress-bar-color);
  }

  ::slotted(a) {
    color: var(--toast-critical-variant-text-link-text-color);
  }

  ::slotted(button) {
    color: var(--toast-critical-variant-button-text-color);
  }

  ::slotted([slot="action"]:not(:last-child))::after {
    background-color: var(--toast-critical-variant-separator-color);
  }
}

:host([variant="insight"]) {
  background-color: var(--toast-insight-variant-background-color);
  color: var(--toast-insight-variant-text-color);

  .icon-container svg {
    fill: var(--toast-insight-variant-icon-color);
  }

  .dismiss-container svg {
    fill: var(--toast-insight-variant-dismiss-button-color);
  }

  progress-bar {
    background-color: var(--toast-insight-variant-progress-bar-color);
  }

  ::slotted(a) {
    color: var(--toast-insight-variant-text-link-text-color);
  }

  ::slotted(button) {
    color: var(--toast-insight-variant-button-text-color);
  }

  ::slotted([slot="action"]:not(:last-child))::after {
    background-color: var(--toast-insight-variant-separator-color);
  }
}
