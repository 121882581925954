/* TODO: add design tokens for table components */

@import "../../styles/mixins/tables.css";

:host {
  @extend %table-cell;

  font-weight: var(--table-heading-primary-text-font-weight, 500);
  font-size: var(--table-heading-primary-text-font-size, var(--core-type-semibold-20-size));
  line-height: var(--table-heading-primary-text-line-height, var(--core-type-semibold-20-leading));

  &([sortable]) {
    cursor: pointer;
    transition:
      background-color
      var(--core-animation-enter-transition-fast-speed-duration)
      var(--core-animation-enter-transition-fast-speed-duration-easing);
  }

  &([sortable]:hover) {
    background-color: var(--table-heading-hover-state-background-color, var(--core-fill-50-color));
  }

  &([sortable]:active) {
    background-color: var(--table-heading-active-state-background-color, var(--core-emphasis-40-color));
  }

  &([sort-order]) .sorting-caret {
    fill: var(--table-heading-sortable-variant-active-state-caret-color, var(--core-text-10-color));
  }

  &([sort-order="descending"]) .sorting-caret {
    transform: rotate(0deg);
  }

  &([hidden]) {
    display: none;
  }
}

.sorting-caret {
  margin-left: var(--table-heading-sort-icon-space-size, 8px);
  fill: var(--table-heading-sortable-variant-normal-state-caret-color, var(--core-text-30-color));
  transition:
    color
    var(--core-animation-enter-transition-fast-speed-duration)
    var(--core-animation-enter-transition-fast-speed-duration-easing);
  transform: rotate(180deg);
}

/* TODO: update pixels to variables once they exist */
::slotted([slot="leading-accessory"][size="image"]) {
  margin-right: 16px;
  margin-left: 12px;
}

::slotted([slot="leading-accessory"][size="icon"]) {
  margin-right: 16px;
  margin-left: 16px;
}

::slotted([slot="trailing-accessory"][size="image"]) {
  margin-right: 12px;
  margin-left: 16px;
}

::slotted([slot="trailing-accessory"][size="icon"]) {
  margin-right: 16px;
  margin-left: 16px;
}
