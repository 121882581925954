@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/field.css";
@import "../../styles/mixins/field-states.css";
@import "../../styles/mixins/number-input.css";

:host {
  @extend %field;
  @extend %field-states;
  @extend %number-input;

  display: flex;
  align-items: center;

  input {
    /* input height is the line-height + padding */
    --stepper-quantity-input-field-height:
      calc(
        var(--stepper-quantity-input-field-value-text-leading) + var(--stepper-padding-size) * 2
      );

    position: relative;
    bottom: 0;
    width: 100%;
    height: var(--stepper-quantity-input-field-height);
    color: var(--stepper-quantity-input-field-value-text-normal-state-color);
    font-weight: var(--stepper-quantity-input-field-value-text-weight);
    font-size: var(--stepper-quantity-input-field-value-text-size);
    font-family: var(--stepper-quantity-input-field-value-text-font-family);
    line-height: var(--stepper-quantity-input-field-value-text-leading);
    letter-spacing: var(--stepper-quantity-input-field-value-text-tracking);
    text-align: center;
    text-transform: var(--stepper-quantity-input-field-value-text-case);
    cursor: text;
    font-feature-settings: "tnum"; /* enable tabular (monospaced) figures */

    &[disabled] {
      color: var(--stepper-quantity-input-field-value-text-disabled-state-color);
    }

    &::placeholder {
      color: var(--stepper-quantity-input-field-value-text-placeholder-color);
    }
  }
}

/* button hit area extends beyond the "visual" button */
button {
  --transition-duration: 0.2s;
  --stepper-button-size: var(--stepper-button-minimum-height);

  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: var(--stepper-button-size);
  height: var(--stepper-button-size);
  padding: var(--stepper-padding-size);
  border: none;
  border-radius: var(--stepper-border-radius);
  background: none;
  cursor: pointer;
  transition: background-color color var(--transition-duration);

  /* the actual "visual" button w/ background */
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    margin: var(--stepper-padding-size);
    border-radius: var(--stepper-button-border-radius);
    background: var(--stepper-button-normal-state-background-color);
  }

  svg {
    position: relative;
    pointer-events: none;
    fill: var(--stepper-button-normal-state-icon-color);
  }

  &:hover {
    &::before {
      background: var(--stepper-button-hover-state-background-color);
    }

    svg {
      fill: var(--stepper-button-hover-state-icon-color);
    }
  }

  &:focus {
    outline: none;

    &::before {
      background: var(--stepper-button-focus-state-background-color);
    }

    svg {
      fill: var(--stepper-button-focus-state-icon-color);
    }
  }

  &:active {
    &::before {
      background: var(--stepper-button-pressed-state-background-color);
    }

    svg {
      fill: var(--stepper-button-pressed-state-icon-color);
    }
  }

  &[disabled] {
    cursor: not-allowed;

    &::before {
      background: var(--stepper-button-disabled-state-background-color);
    }

    svg {
      fill: var(--stepper-button-disabled-state-icon-color);
    }
  }
}
