const DEFAULT_MOBILE_SCREEN_WIDTH = '599px';

export const getMobileMaxWidth = (): string =>
  window
    .getComputedStyle(document.body)
    .getPropertyValue('--core-breakpoint-narrow-max-width') ||
  DEFAULT_MOBILE_SCREEN_WIDTH;

export const isMobile = (): boolean => {
  return window.matchMedia(`(max-width: ${getMobileMaxWidth()})`).matches;
};
