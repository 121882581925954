:host {
  display: block;

  svg {
    .foreground {
      fill: var(--qrcode-normal-variant-foreground-color);
    }

    .background {
      fill: var(--qrcode-normal-variant-background-color);
    }

    .logo {
      fill: var(--qrcode-normal-variant-icon-color);
    }
  }

  &([monochrome]) {
    .foreground {
      fill: var(--qrcode-monochrome-variant-foreground-color);
    }

    .background {
      fill: var(--qrcode-monochrome-variant-background-color);
    }

    .logo {
      fill: var(--qrcode-monochrome-variant-icon-color);
    }
  }

  /* in cases where the size prop is set, the SVG should fill its container */
  &([size]) {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
