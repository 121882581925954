import {
  MODAL_PARTIAL_ANIMATION_ENTER_TRANSITION_DURATION,
  MODAL_PARTIAL_ANIMATION_EXIT_TRANSITION_DURATION,
} from '@market/market-theme/js/cjs/index.js';
import { Component, Host, Prop, Element, Listen, Method, Event, EventEmitter, h, Watch } from '@stencil/core';

import {
  DialogDismissedEvent,
  DialogLoadedEvent,
  DialogType,
  setupDialogCompactHandler,
  getDialogSelector,
} from '../../utils/dialog';
import {
  createAndActivateFocusTrap,
  FocusTrap,
  FocusTrapActivateOptions,
  FocusTrapDeactivateOptions,
  FocusTrapOptions,
} from '../../utils/focus-trap';
import { TMarketHeaderNavigateEventDetail } from '../market-header/events';

@Component({
  tag: 'market-modal-partial',
  styleUrl: 'market-modal-partial.css',
  shadow: true,
})
export class MarketModalPartial {
  @Element() el: HTMLMarketModalPartialElement;
  connectedCallbackTimeout: NodeJS.Timeout;
  type: DialogType = 'modal-partial';
  focusTrap: FocusTrap;

  /**
   * INTERNAL ONLY: Used in CSS to trigger start and stop animations
   */
  @Prop({ mutable: true, reflect: true }) hidden: boolean = false;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal
   */
  @Prop({ reflect: true, attribute: 'data-dialog-id' }) readonly dialogID: string;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal's place
   * in the stack
   */
  @Prop({ reflect: true, attribute: 'data-dialog-index' }) readonly index: number;

  /**
   * Enforces focus trapping on the modal
   */
  @Prop({ mutable: true }) trapFocus: boolean = false;

  /**
   * The duration for the modal enter animation, set from design tokens
   */
  @Prop()
  readonly animationEnterDuration: number = MODAL_PARTIAL_ANIMATION_ENTER_TRANSITION_DURATION;

  /**
   * The duration for the modal exit animation, set from design tokens
   */
  @Prop()
  readonly animationExitDuration: number = MODAL_PARTIAL_ANIMATION_EXIT_TRANSITION_DURATION;

  /**
   * Listen to the headerNavigate event emitted by a market-header child component
   * so we can emit a close event if needed
   */
  @Listen('marketHeaderNavigate')
  headerNavigateEventHandler(event: CustomEvent<TMarketHeaderNavigateEventDetail>) {
    const { detail, target } = event;
    // TODO: 'close' should probably come from an enum of some sort
    if (detail.action === 'close') {
      // only dismiss if this is the first ancestor dialog
      if ((target as HTMLElement).closest(getDialogSelector()) === this.el) {
        this.dismiss();
      }
    }
  }

  /* Instead of doing anything substantial for closing/opening, dialogs just
  emit events that the parent context can listen to in order to do the heavy
  lifting of displaying dialogs */

  /**
   * Triggered when the modal finishes loading
   */
  @Event() marketDialogLoaded: EventEmitter<DialogLoadedEvent>;

  /**
   * Triggered when the dialog is dismissed, handled by context manager
   */
  @Event() marketDialogDismissed: EventEmitter<DialogDismissedEvent>;

  /**
   * Triggered when the dialog is fully dismissed
   */
  @Event() marketDialogDidDismiss: EventEmitter<DialogDismissedEvent>;

  /* The parent context will handle actually removing elements from the DOM,
  All the modal needs to do it emit an event so actually closing it can be
  some other elements problem */

  /**
   * Emits the dismiss event
   * The parent context will handle actually removing elements from the DOM,
   * All the modal needs to do it emit an event so actually closing it can be
   * some other elements problem
   */
  @Method()
  dismiss(dismissOptions?: Partial<DialogDismissedEvent>) {
    const { defaultPrevented } = this.marketDialogDismissed.emit({
      dialog: this.el,
      type: this.type,
      origin: dismissOptions?.origin || this.el,
    });

    if (!defaultPrevented) {
      this.hidden = true;

      /**
       * Emit a marketDialogDidDismiss event when modal gets fully dismissed (after animation).
       */
      setTimeout(() => {
        this.marketDialogDidDismiss.emit({
          dialog: this.el,
          type: this.type,
          origin: this.el,
        });
      }, this.animationExitDuration);
    }

    return Promise.resolve();
  }

  @Watch('trapFocus')
  onTrapFocusChanged(newValue: boolean, oldValue: boolean) {
    // only activate/deactivate when the `trapFocus` prop value changes
    if (newValue !== oldValue) {
      if (newValue) {
        this.activateFocusTrap();
      } else {
        this.deactivateFocusTrap();
      }
    }
  }

  /**
   * Activates the focus trap
   *
   * See [`focus-trap.ts`](../../utils/focus-trap.ts) for default options
   *
   * @param {Object} [options] [focus-trap create options](https://github.com/focus-trap/focus-trap#createoptions)
   * @param {Object} [activateOptions] set options for [onActivate, onPostActivate, and checkCanFocusTrap](https://github.com/focus-trap/focus-trap#trapactivate)
   */
  @Method()
  activateFocusTrap(options?: FocusTrapOptions, activateOptions?: FocusTrapActivateOptions) {
    if (this.focusTrap) {
      this.focusTrap.activate(activateOptions ?? {});
      if (!this.trapFocus) {
        this.trapFocus = true;
      }
    } else {
      this.focusTrap = createAndActivateFocusTrap({
        el: this.el,
        options,
        activateOptions,
      });
    }
    return Promise.resolve();
  }

  /**
   * Deactivates the focus trap
   *
   * @param {FocusTrapDeactivateOptions} [deactivateOptions] set options for [onDeactivate, onPostDeactivate, and checkCanReturnFocus](https://github.com/focus-trap/focus-trap#trapdeactivate)
   */
  @Method()
  deactivateFocusTrap(deactivateOptions: FocusTrapDeactivateOptions = {}) {
    if (this.focusTrap) {
      this.focusTrap.deactivate({
        returnFocus: true,
        checkCanReturnFocus: (trigger) =>
          new Promise((resolve) => {
            if (typeof (trigger as any)?.setFocus === 'function') {
              (trigger as any).setFocus();
            } else {
              resolve(); // node.focus(); will be called by focus-trap
            }
          }),
        ...deactivateOptions,
      });
      this.focusTrap = undefined;
    }
    return Promise.resolve();
  }

  connectedCallback() {
    this.connectedCallbackTimeout = setTimeout(() => {
      /**
       * Emit a dialogLoaded event when the component connects. Need this so
       * the context manager isn't rummaging around it's DOM to try and find the
       * dialog that was just appended
       */
      this.marketDialogLoaded.emit({
        dialog: this.el,
        type: this.type,
      });

      if (this.trapFocus) {
        this.activateFocusTrap();
      }
    }, this.animationEnterDuration);
  }

  componentWillLoad() {
    setupDialogCompactHandler(this.el);
  }

  disconnectedCallback() {
    this.deactivateFocusTrap();

    /**
     * Prevents error caused by race conditions during rapid mounting and
     * unmounting of component by clearing the setTimeout from connectedCallback
     * if it gets called after disconnectedCallback.
     */
    clearTimeout(this.connectedCallbackTimeout);
  }

  render() {
    return (
      <Host class="market-modal-partial" role="dialog">
        <slot></slot>
      </Host>
    );
  }
}
