import { EventEmitter } from '@stencil/core';
import { isDraggable, TMarketDragEventDetail, MarketDraggableElement } from './draggable';
import { isMarketTableV2Group } from '../components/tables-v2/market-table-v2-group/types';

export type TMarketReorderableOptions = 'off' | 'internal' | 'external';

export interface MarketReorderableElement extends HTMLElement {
  reorderable: TMarketReorderableOptions;
}

export function isReorderable(value: unknown): value is MarketReorderableElement {
  return Boolean(value && ['internal', 'external'].includes((value as MarketReorderableElement).reorderable));
}

export type TMarketReorderEventDetail = {
  item: MarketDraggableElement;
  oldIndex: number;
  newIndex: number;
};

/*
  This util class abstracts & encapsulates reorderable functionality for a component.

  In the constructor:
  - `el` is the component element to apply reordering functionality to
  - `accepts` is an array of CSS selectors that are valid draggable elements
  - `event` is the stencil EventEmitter to fire when a reorder event occurs

  Intended to be wired up via drag events fired by a dragged object like so:
  
  ```html
  <Host
    onMarketDragMove={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragMove(e)}
    onMarketDragLeave={() => this.onDragLeave()}
    onMarketDragEnd={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragEnd(e)}
    onMarketDragDrop={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragDrop(e)}
  >
    ...
  </Host>
  ```

  ```js
  this.reorder = new Reorderable({
    el: this.el,
    accepts: ['market-row'],
    event: marketListItemsReordered,
  });
  onDragMove(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragMove(e);
  }
  onDragLeave() {
    this.reorder.dragLeave();
  }
  onDragEnd(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragEnd(e);
  }
  onDragDrop(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragDrop(e);
  }
  ```

  ```css 
  // the drag cursor inserted to show current drop location
  ::slotted(.market-drag-cursor) { ... }
  ```
*/

export class Reorderable {
  el: MarketReorderableElement;
  accepts: Array<string>;
  event: EventEmitter<TMarketReorderEventDetail>;

  // static instance var means only one cursor shared across all Reorderable instances
  static cursor = this.createCursor();
  static createCursor() {
    const cursor = document.createElement('div');
    cursor.classList.add('market-drag-cursor');
    return cursor;
  }

  constructor({ el, accepts, event }: { el: MarketReorderableElement; accepts: Array<string>; event: EventEmitter }) {
    this.el = el;
    this.accepts = accepts;
    this.event = event;
  }

  private isValidDrag(dragged: HTMLElement, target: HTMLElement) {
    const { el, accepts } = this;
    const reorderableSource = dragged.parentElement.closest('[reorderable="internal"], [reorderable="external"]');
    const reorderableTarget = target.closest('[reorderable="internal"], [reorderable="external"]');

    // begin with type checks...
    if (!isDraggable(dragged)) return false;
    if (!isReorderable(reorderableSource)) return false;
    if (!isReorderable(reorderableTarget)) return false;

    // is this an accepted draggable item?
    if (dragged.closest(accepts.join(',')) !== dragged) return false;

    // is this element either the source or destination?
    if (el !== reorderableSource && el !== reorderableTarget) return false;

    // if source & destination elements are different...
    if (reorderableSource !== reorderableTarget) {
      // are they both reorderable externally?
      const bothExternal = reorderableSource.reorderable === 'external' && reorderableTarget.reorderable === 'external';
      // or do they have a common reorderable ancestor?
      const closestReorderable = getCommonAncestor(reorderableSource, reorderableTarget).closest(
        '[reorderable="internal"], [reorderable="external"]',
      );
      if (!bothExternal && !closestReorderable) return false;
    }

    // looks like we're good!
    return true;
  }

  /**
   * Fired on a target element when an item is dragged over the target.
   */
  dragMove(e: CustomEvent<TMarketDragEventDetail>) {
    const { el, accepts } = this;
    const { draggedEl, dragTarget, y } = e.detail;
    const { cursor } = Reorderable;

    // check drag validity
    if (!this.isValidDrag(draggedEl, dragTarget)) return;

    // remove cursor parent class
    cursor.parentElement?.classList.remove('market-drag-cursor-parent');

    // if this el is the drag target itself (not another child)
    if (dragTarget === el) {
      el.append(cursor);
      cursor.parentElement?.classList.add('market-drag-cursor-parent');
      return;
    }

    // otherwise, find the nearest child item target
    const targetSibling = dragTarget.closest(accepts.join(',')) as HTMLElement;

    // do nothing if no target sibling
    if (!targetSibling) return;

    // do nothing if the target sibling is the dragged element
    if (targetSibling === draggedEl) return;

    // determine where to insert cursor based on mouse position
    if (isMarketTableV2Group(targetSibling)) {
      // special case for table groups; look at the parent row
      const parent = targetSibling.querySelector(':scope > [slot="parent"]');
      const { top, height } = parent.getBoundingClientRect();
      if (targetSibling.collapsible && targetSibling.collapsed) {
        const oneThird = Math.round(top + height / 3);
        const twoThirds = Math.round(top + (height * 2) / 3);
        if (y < oneThird) {
          // insert cursor before the group
          targetSibling.before(cursor);
        } else if (y > twoThirds) {
          // insert cursor after the group
          targetSibling.after(cursor);
        } else {
          // insert cursor inside the group after the parent
          parent.after(cursor);
        }
      } else {
        const halfway = Math.round(top + height / 2);
        if (y < halfway) {
          // insert cursor before the group
          targetSibling.before(cursor);
        } else {
          // insert cursor inside the group after the parent
          parent.after(cursor);
        }
      }
    } else {
      const { top, height } = targetSibling.getBoundingClientRect();
      const halfway = Math.round(top + height / 2);
      if (y < halfway) {
        targetSibling.before(cursor);
      } else {
        targetSibling.after(cursor);
      }
    }

    // mark the cursor's parent
    cursor.parentElement?.classList.add('market-drag-cursor-parent');
  }

  /**
   * Fired on a target element when a dragged item leaves the target
   */
  dragLeave() {
    Reorderable.cursor.parentElement?.classList.remove('market-drag-cursor-parent');
    Reorderable.cursor.remove();
  }

  /**
   * Fired on a dragged item when it is released.
   * Useful to determine if an item was dropped externally.
   */
  dragEnd(e: CustomEvent<TMarketDragEventDetail>) {
    const { el, accepts, event } = this;
    const { draggedEl, dragTarget } = e.detail;

    // if cursor is not in the DOM, do nothing
    if (!document.body.contains(Reorderable.cursor)) return;

    // check for drag validity
    if (!this.isValidDrag(draggedEl, dragTarget)) return;

    // do nothing if the source and target are the same reorderable
    const reorderableSource = draggedEl.parentElement.closest(
      '[reorderable="internal"], [reorderable="external"]',
    ) as MarketReorderableElement;
    const reorderableTarget = Reorderable.cursor.closest(
      '[reorderable="internal"], [reorderable="external"]',
    ) as MarketReorderableElement;
    if (reorderableSource === reorderableTarget) return;

    // we now know the draggedEl is being dragged out of its reorderable parent,
    // so we stop propagation on original event so reorder event isn't duped.
    e.stopImmediatePropagation();

    // note: dragging externally means new index = -1
    const items = getReorderableItems(el, accepts);
    const oldIndex = items.indexOf(draggedEl);
    const newIndex = -1;

    // emit the reorder event and check for prevent default
    const { defaultPrevented } = event.emit({
      item: draggedEl,
      oldIndex,
      newIndex,
    });

    // if reorder event was prevented, prevent the drop event
    if (defaultPrevented) e.preventDefault();
  }

  /**
   * Fired on a target element when a dragged item is released over the target.
   */
  dragDrop(e: CustomEvent<TMarketDragEventDetail>) {
    const { el, accepts, event } = this;
    const { draggedEl } = e.detail;
    const { cursor } = Reorderable;

    // if cursor is not in the DOM, do nothing
    if (!document.body.contains(cursor)) return;

    // if this is not the cursor's parent, do nothing and let the event bubble up
    const reorderableTarget = cursor.parentElement as MarketReorderableElement;
    if (el !== reorderableTarget) return;

    // check for drag validity
    if (!this.isValidDrag(draggedEl, reorderableTarget)) return;

    // prevent the event from further bubbling up
    e.stopImmediatePropagation();

    // remove cursor parent class
    reorderableTarget?.classList.remove('market-drag-cursor-parent');

    // if the cursor is a sibling of the dragged element, do nothing
    if ([cursor.previousElementSibling, cursor.nextElementSibling].includes(draggedEl)) {
      cursor.remove();
      return;
    }

    // note: if item is external, then old index is -1
    const items = getReorderableItems(reorderableTarget, accepts);
    const oldIndex = items.indexOf(draggedEl);

    // find new index
    const itemsWithoutDraggedEl = [...items];
    if (oldIndex >= 0) {
      itemsWithoutDraggedEl.splice(oldIndex, 1);
    }
    const newIndex = itemsWithoutDraggedEl.indexOf(cursor.previousElementSibling as MarketDraggableElement) + 1;

    // same index means no reorder event
    if (newIndex === oldIndex) {
      cursor.remove();
      return;
    }

    // emit the reorder event and check for prevent default
    const { defaultPrevented } = event.emit({
      item: draggedEl,
      oldIndex,
      newIndex,
    });

    // if default prevented, remove the drag cursor
    if (defaultPrevented) {
      cursor.remove();
      return;
    }

    // otherwise, insert the item
    cursor.replaceWith(draggedEl);
  }

  // clean up var refs
  destroy() {
    Reorderable.cursor.remove();

    this.el = null;
    this.accepts = null;
    this.event = null;
  }
}

function getReorderableItems(el: MarketReorderableElement, accepts: Array<string>): MarketDraggableElement[] {
  const items = [];
  const scopedSelectors = accepts.map((selector) => `:scope > ${selector}`);
  el.querySelectorAll(scopedSelectors.join(',')).forEach((item) => {
    if (isDraggable(item)) items.push(item);
  });
  return items;
}

function getCommonAncestor(node1: HTMLElement, node2: HTMLElement): HTMLElement {
  let node: HTMLElement = node1;
  while (node) {
    if (node.contains(node2)) {
      return node;
    }
    node = node.parentElement;
  }
  return null;
}
