@import "../../styles/mixins/component-base.css";

:host {
  --focus-ring-color: color-mix(in srgb, var(--toggle-focus-ring-color) 50%, transparent);

  position: relative;
  display: inline-block;

  /* Height is set to ensure focus ring is set to the correct height. */
  height: var(--toggle-height);
}

input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: var(--toggle-border-radius);
  background: transparent;
  outline: none;
  cursor: pointer;
  appearance: none;
}

input:focus-visible {
  outline: var(--toggle-focus-ring-border-size) solid var(--focus-ring-color);
  outline-offset: var(--core-focus-ring-buffer-size); /* --toggle-focus-ring-buffer-size is 0px for some reason? */
}

circle,
rect {
  /* if toggle is slotted into market-row, inherit transition style from row */
  transition: var(--row-slotted-control-transition, cx 0.3s);
}

/* state:normal.value:unselected */
circle {
  fill: var(--toggle-normal-state-unselected-value-thumb-color);
}

rect {
  fill: var(--toggle-normal-state-unselected-value-track-color);
  stroke: var(--toggle-normal-state-unselected-value-border-color);
}

:host([checked]) {
  /* state:normal.value:selected */

  circle {
    fill: var(--toggle-normal-state-selected-value-thumb-color);

    /* Number determined from movement of the toggle thumb in Figma SVGs */

    /* todo: add design token for this number */
    cx: 28px;
  }

  rect {
    fill: var(--toggle-normal-state-selected-value-track-color);
    stroke: var(--toggle-normal-state-selected-value-border-color);
  }
}

:host(:hover),
:host([hovered]) {
  /* state:hover.value:unselected */
  circle {
    fill: var(--toggle-hover-state-unselected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-hover-state-unselected-value-track-color);
    stroke: var(--toggle-hover-state-unselected-value-border-color);
  }
}

:host([checked]:hover),
:host([checked][hovered]) {
  /* state:hover.value:selected */
  circle {
    fill: var(--toggle-hover-state-selected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-hover-state-selected-value-track-color);
    stroke: var(--toggle-hover-state-selected-value-border-color);
  }
}

:host([focused]) {
  /* state:focus.value:unselected */
  circle {
    fill: var(--toggle-focus-state-unselected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-focus-state-unselected-value-track-color);
    stroke: var(--toggle-focus-state-unselected-value-border-color);
  }
}

:host([checked][focused]) {
  /* state:focus.value:selected */
  circle {
    fill: var(--toggle-focus-state-selected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-focus-state-selected-value-track-color);
    stroke: var(--toggle-focus-state-selected-value-border-color);
  }
}

:host(:active),
:host([active]) {
  /* state:pressed.value:unselected */
  circle {
    fill: var(--toggle-pressed-state-unselected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-pressed-state-unselected-value-track-color);
    stroke: var(--toggle-pressed-state-unselected-value-border-color);
  }
}

:host([checked]:active),
:host([checked][active]) {
  /* state:pressed.value:selected */
  circle {
    fill: var(--toggle-pressed-state-selected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-pressed-state-selected-value-track-color);
    stroke: var(--toggle-pressed-state-selected-value-border-color);
  }
}

:host([disabled]) {
  /* state:disabled.value:unselected */
  input {
    cursor: not-allowed;
  }

  circle {
    fill: var(--toggle-disabled-state-unselected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-disabled-state-unselected-value-track-color);
    stroke: var(--toggle-disabled-state-unselected-value-border-color);
  }
}

:host([disabled][checked]) {
  /* state:disabled.value:selected */
  circle {
    fill: var(--toggle-disabled-state-selected-value-thumb-color);
  }

  rect {
    fill: var(--toggle-disabled-state-selected-value-track-color);
    stroke: var(--toggle-disabled-state-selected-value-border-color);
  }
}
