import { getNamespacedTagFor } from './namespace';

/**
 * @param {unknown} el - element to be checked
 * @returns {boolean} whether `el` is a `HTMLMarketCheckboxElement`
 */
export function isCheckboxElement(el: unknown): el is HTMLMarketCheckboxElement {
  return (el as HTMLElement)?.tagName === getNamespacedTagFor('market-checkbox').toUpperCase();
}

/**
 * @param {unknown} el - element to be checked
 * @returns {boolean} whether `el` is a `HTMLMarketRadioElement`
 */
export function isRadioElement(el: unknown): el is HTMLMarketRadioElement {
  return (el as HTMLElement)?.tagName === getNamespacedTagFor('market-radio').toUpperCase();
}

/**
 * @param {unknown} el - element to be checked
 * @returns {boolean} whether `el` is a `HTMLMarketRowElement`
 */
export function isRowElement(el: unknown): el is HTMLMarketRowElement {
  return (el as HTMLElement)?.tagName === getNamespacedTagFor('market-row').toUpperCase();
}

/**
 * @param {unknown} el - element to be checked
 * @returns {boolean} whether `el` is a `HTMLMarketToggleElement`
 */
export function isToggleElement(el: unknown): el is HTMLMarketToggleElement {
  return (el as HTMLElement)?.tagName === getNamespacedTagFor('market-toggle').toUpperCase();
}
