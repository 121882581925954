import { Component, Host, h, Prop, Listen, Method, Watch } from '@stencil/core';

/**
 * @slot - The text used for the filter button label
 * @slot feedback - The text to indicate currently applied filters
 */
@Component({
  tag: 'market-filter-button',
  styleUrl: 'market-filter-button.css',
  shadow: true,
})
export class MarketFilterButton {
  /**
   * String for setting filter button size
   */
  @Prop({ reflect: true }) readonly size: 'medium' | 'small' = 'medium';

  /**
   * Functionally and visually disables the button
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether or not the button is focused
   */
  @Prop({ reflect: true, mutable: true }) focused: boolean = false;

  /**
   * Whether to display icon in place of label
   */
  @Prop({ reflect: true }) readonly iconOnly: boolean = false;

  /**
   * Whether or not the button is in an active state, e.g. filter is selected and dropdown is opened
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  @Listen('click', { capture: true })
  handleClick(event: UIEvent) {
    if (this.disabled) {
      event.stopImmediatePropagation();
    }
  }

  @Watch('disabled')
  handleDisabledChange(newValue: boolean) {
    if (newValue && this.focused) {
      this.focused = false;
    }
  }

  /**
   * Toggle focus on the filter button
   * @param {boolean} [value=true] whether or not focus will be applied or removed
   * @returns {Promise<boolean>} whether or not the button was focused or blurred
   */
  @Method()
  async setFocus(value: boolean = true): Promise<boolean> {
    if (this.disabled) {
      return Promise.resolve(false);
    }
    this.focused = value;
    if (this.focused) {
      this.buttonEl.focus();
    } else {
      this.buttonEl.blur();
    }
    return Promise.resolve(this.focused);
  }

  /**
   * Reference to the button element
   */
  private buttonEl: HTMLButtonElement;

  render() {
    return (
      <Host class="market-filter-button">
        <button aria-disabled={this.disabled} disabled={this.disabled} ref={(el) => (this.buttonEl = el)} type="button">
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H18V2H0V0ZM15 5H3V7H15V5ZM12 10H6V12H12V10Z" />
          </svg>
          <span class="label">
            <slot></slot>
          </span>
          <slot name="feedback"></slot>
        </button>
      </Host>
    );
  }
}
