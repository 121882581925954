import { Component, Event, EventEmitter, Host, h, Listen, Prop } from '@stencil/core';

import { pencilWritingIcon } from './icons';
import { getNamespacedTagFor } from '../../../../utils/namespace';
import { parseToHSVA } from '../../../../utils/color';

@Component({
  tag: 'market-color-picker-input',
  styleUrl: 'market-color-picker-input.css',
  shadow: true,
})
export class MarketColorPickerInput {
  /**
   * Value representing the color shown on the input.
   * This should be in a hexadecimal format (i.e. #ABC123), similarly to native HTML color inputs.
   */
  @Prop({ mutable: true, reflect: true }) value: string;

  /**
   * Fired whenever the color picker input value changes.
   */
  @Event({ cancelable: true }) marketColorPickerInputValueChange: EventEmitter<{ prevValue: string; value: string }>;

  displayLeadingAccessory() {
    const MarketColorSwatchTagName = getNamespacedTagFor('market-color-swatch');
    const parsedValue = parseToHSVA(this.value);

    // If invalid, this will be null
    if (parsedValue.values) {
      return <MarketColorSwatchTagName value={this.value} disabled></MarketColorSwatchTagName>;
    }

    // otherwise return default icon
    return <div class="color-picker-input-icon">{pencilWritingIcon()}</div>;
  }

  @Listen('marketInputValueChange')
  inputValueChange(event: CustomEvent) {
    const { detail } = event;

    const prevValue = this.value;
    // Format for hexadecimal if required
    this.formatAndUpdateValue(detail.value);
    const { defaultPrevented } = this.marketColorPickerInputValueChange.emit({ prevValue, value: this.value });
    if (defaultPrevented) {
      this.value = prevValue;
    }
  }

  formatAndUpdateValue(value: string = this.value) {
    if (!value) return;

    let updatedValue = value;

    if (updatedValue[0] !== '#') {
      updatedValue = `#${updatedValue}`;
    }

    this.value = updatedValue;
  }

  componentWillLoad() {
    this.formatAndUpdateValue();
  }

  render() {
    const MarketInputTextTagName = getNamespacedTagFor('market-input-text');

    return (
      <Host class="market-color-picker-input">
        {/* max length for hexadecimal format */}
        <MarketInputTextTagName value={this.value} id={'color-picker-input-text'} maxlength={7}>
          <div slot="leading-accessory">{this.displayLeadingAccessory()}</div>
          <label htmlFor="color-picker-input-text">
            <slot name="label">Hex</slot>
          </label>
          <slot></slot>
        </MarketInputTextTagName>
      </Host>
    );
  }
}
