import { Component, Host, h, Prop, Element, Event, EventEmitter } from '@stencil/core';
import { CORE_BLUE_FILL_COLOR } from '@market/market-theme/js/cjs/index.js';

@Component({
  tag: 'market-color-swatch',
  styleUrl: 'market-color-swatch.css',
  shadow: true,
})
export class MarketColorSwatch {
  @Element() el: HTMLMarketColorSwatchElement;

  /**
   * Value representing the color of the swatch. This is a string that can represent any [CSS color value](https://developer.mozilla.org/en-US/docs/Web/CSS/color).
   */
  @Prop({ reflect: true }) readonly value: string = CORE_BLUE_FILL_COLOR;

  @Prop({ reflect: true }) readonly name: string;

  @Prop({ reflect: true }) readonly disabled: boolean;

  /**
   * Whether the swatch is selected or not.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * Fired whenever the swatch selection state changes.
   */
  @Event({ cancelable: true }) marketColorSwatchSelectedChange: EventEmitter<{ value: string; selected: boolean }>;

  toggleSelection() {
    if (this.disabled) {
      return;
    }

    const newSelection = !this.selected;
    const swatchValue = { value: this.value, selected: newSelection };

    const { defaultPrevented } = this.marketColorSwatchSelectedChange.emit(swatchValue);

    if (!defaultPrevented) {
      this.selected = newSelection;
    }
  }

  render() {
    return (
      <Host
        class="market-color-swatch"
        role="listitem"
        onClick={() => this.toggleSelection()}
        style={{ '--swatch-color': this.value }}
      >
        <div class="inner-circle"></div>
      </Host>
    );
  }
}
