import { Component, Element, Host, h, Prop, Listen, State, Event, EventEmitter } from '@stencil/core';

import { hsvToHex, parseToHSVA } from '../../utils/color';
import { getNamespacedTagFor } from '../../utils/namespace';

@Component({
  tag: 'market-color-picker',
  styleUrl: 'market-color-picker.css',
  shadow: true,
})
export class MarketColorPicker {
  @Element() el: HTMLMarketColorPickerElement;

  /**
   * Value representing the color shown on the color picker.
   */
  @Prop({ mutable: true, reflect: true }) value: string;

  /**
   * State holding the value to pass into the input. This allows us to handle converting the input value into hexadecimal.
   */
  @State() inputValue: string;

  /**
   * Fired whenever the color picker selection state changes.
   */
  @Event({ cancelable: true }) marketColorPickerValueChange: EventEmitter<{ value: string; prevValue: string }>;

  gradientPicker: HTMLMarketColorPickerGradientElement;
  swatchList: HTMLMarketColorSwatchListElement;
  colorInput: HTMLMarketColorPickerInputElement;

  /**
   * Listener for gradient value change. This only fires when the gradient is dragged manually, which currently will only pass a hex value.
   * Since it's a hex value, we know the value will be formatted for inputs.
   * @param event
   */
  @Listen('marketColorPickerGradientValueChange')
  gradientValueChange(event: CustomEvent) {
    const { detail } = event;
    const { defaultPrevented } = this.marketColorPickerValueChange.emit({
      value: detail.value,
      prevValue: this.value,
    });

    if (!defaultPrevented) {
      this.value = detail.value;
      this.inputValue = detail.value;

      if (this.swatchList) {
        this.swatchList.value = this.value;
      }

      if (this.colorInput) {
        this.colorInput.value = this.inputValue;
      }
    }
  }

  @Listen('marketColorSwatchListValueChange')
  swatchValueChange(event: CustomEvent) {
    const { detail } = event;
    const { defaultPrevented } = this.marketColorPickerValueChange.emit({
      value: detail.value,
      prevValue: this.value,
    });
    if (!defaultPrevented) {
      this.inputValue = this.formatInputValue(detail.value);
      this.value = detail.value;

      if (this.gradientPicker) {
        this.gradientPicker.value = this.value;
      }

      if (this.colorInput) {
        this.colorInput.value = this.inputValue;
      }
    }
  }

  @Listen('marketColorPickerInputValueChange')
  inputValueChange(event: CustomEvent) {
    const { detail } = event;
    const { defaultPrevented } = this.marketColorPickerValueChange.emit({
      value: detail.value,
      prevValue: this.value,
    });
    if (!defaultPrevented) {
      this.value = detail.value;

      if (this.gradientPicker) {
        this.gradientPicker.value = this.value;
      }

      if (this.swatchList) {
        this.swatchList.value = this.value;
      }
    }
  }

  /**
   * Helper function taking a color string and converting it to Hex if it's in rgba format.
   * @param colorString
   * @returns { string } Hex color string
   */
  formatInputValue(colorString: string) {
    const parsedValue = parseToHSVA(colorString);

    // If it is a valid color format, convert to hex
    if (parsedValue.colorType && parsedValue.colorType !== 'hexa') {
      return `#${hsvToHex(parsedValue.values[0], parsedValue.values[1], parsedValue.values[2]).join('')}`;
    }

    return colorString;
  }

  setSubcomponentValues() {
    this.gradientPicker = this.el.querySelector(getNamespacedTagFor('market-color-picker-gradient'));
    this.swatchList = this.el.querySelector(getNamespacedTagFor('market-color-swatch-list'));
    this.colorInput = this.el.querySelector(getNamespacedTagFor('market-color-picker-input'));

    if (this.gradientPicker) {
      this.gradientPicker.value = this.value;
    }

    if (this.swatchList) {
      this.swatchList.value = this.value;
    }

    if (this.colorInput) {
      this.colorInput.value = this.inputValue;
    }
  }

  handleSlotChange() {
    this.setSubcomponentValues();
  }

  componentWillLoad() {
    this.inputValue = this.formatInputValue(this.value);
  }

  componentDidLoad() {
    this.setSubcomponentValues();
  }

  render() {
    return (
      <Host class="market-color-picker">
        <slot onSlotchange={() => this.handleSlotChange()}></slot>
      </Host>
    );
  }
}
