import { Component, Element, Host, h } from '@stencil/core';

/**
 * @slot primary-text - Primary text; `<h3>` element is recommended
 * @slot secondary-text - Secondary text; `<p>` element is recommended
 * @slot media - Media that appears above the primary text
 * @slot actions - Action elements; `<market-button>` is recommended
 * @slot - default slot is available for slotting non-text content and will appear above all other slots
 */
@Component({
  tag: 'market-empty-state',
  styleUrl: 'market-empty-state.css',
  shadow: true,
})
export class MarketEmptyState {
  @Element() el: HTMLMarketEmptyStateElement;

  /**
   * Whether or not `.actions` will be displayed (if `actions` slot is provided)
   */
  showActions: boolean = false;

  handleSlottedContent() {
    this.showActions = Boolean(this.el.querySelector('[slot="actions"]'));
  }

  componentWillRender() {
    this.handleSlottedContent();
  }

  render() {
    return (
      <Host class="market-empty-state">
        <slot></slot>
        <slot name="media"></slot>
        <div class="text">
          <slot name="primary-text"></slot>
          <slot name="secondary-text"></slot>
        </div>
        {this.showActions && (
          <div class="actions">
            <slot name="actions"></slot>
          </div>
        )}
      </Host>
    );
  }
}
