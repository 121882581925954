import { Component, Prop, Element, Listen, Event, EventEmitter, Host, h, Method, State, Watch } from '@stencil/core';

import { observeAriaAttributes, getTextInputAriaLabel, AriaAttributes } from '../../utils/aria';
import { autocompleteWatcher } from '../../utils/autocomplete';
import { classNames } from '../../utils/classnames';
import { submitFormImplicitly } from '../../utils/forms';

/**
 * @slot - The main label for the input.
 * @slot leading-accessory - An icon set on the left side of the input.
 * @slot trailing-accessory - An icon set on the right side of the input.
 *
 * @slot input - Can be used to slot your own HTML input, if needed (ex. if supporting browser
 * autofill)
 * @part native-input - The default inner HTML input.
 */
@Component({
  tag: 'market-input-text',
  shadow: true,
  styleUrl: 'market-input-text.css',
})
export class InputText {
  private nativeInput?: HTMLInputElement;
  private slottedInput?: HTMLInputElement;

  @Element() el: HTMLMarketInputTextElement;

  /**
   * A string specifying the type of control to render. Any native HTML input type would work here.
   */
  @Prop({ reflect: true }) readonly type: string = 'text'; // Any HTML input type
  /**
   * A string specifying an ID for the input.
   */
  @Prop() readonly inputId: string;

  /**
   * A string specifying a name for the input.
   */
  @Prop() readonly name: string;

  /**
   * A string specifying a value for the input. This will be visually shown on the input and can be edited by the user.
   */
  @Prop({ mutable: true, reflect: true }) value: string = '';

  /**
   * A string specifying the placeholder of the input.
   * This is shown before a user attempts to add a value, given no value is already provided.
   */
  @Prop() readonly placeholder: string;

  /**
   * A number specifying the maximum length of characters for the input value.
   * See MDN on the [maxlength attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/maxlength)
   */
  @Prop() readonly maxlength: number;

  /**
   * A number specifying the minimum length of characters for the input value.
   * See MDN on the [minlength attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/minlength)
   */
  @Prop() readonly minlength: number;

  /**
   * String for setting input size.
   * Sizes `small` and `medium` visually hide the label,
   * but you should still provide one for accessibility purposes.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'large';

  /**
   * Specifies the increment step for number and time inputs.
   * See MDN on the [step attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/step)
   */
  @Prop() readonly step: string;

  /**
   * Specifies the minimum value for number and time inputs.
   * See MDN on the [min attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/min)
   */
  @Prop() readonly min: string;

  /**
   * Specifies the maximum value for number and time inputs.
   * See MDN on the [max attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/max)
   */
  @Prop() readonly max: string;

  /**
   * Specifies a [regular expression](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions)
   * to validate the input's value against.
   * See MDN on the [pattern attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern)
   */
  @Prop() readonly pattern: string;

  /**
   * Whether or not the input is required; used to validate the input's value.
   * See MDN on the [required attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/required)
   */
  @Prop() readonly required: boolean;

  /**
   * A boolean representing whether the input is readonly or not.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * A boolean representing whether the input is disabled or not.
   * This visually and functionally will disable the input.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * A boolean representing whether the input is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * A boolean representing whether the input is invalid or not.
   * This represents error states.
   */
  @Prop({ mutable: true, reflect: true }) invalid: boolean = false;

  /**
   * Allows a browser to display an appropriate virtual keyboard.
   * [Accepted values](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode).
   */
  @Prop() readonly inputmode: string;

  /**
   * A boolean representing whether the input should focus on page load.
   * If multiple elements with `autofocus` are present, it is not guaranteed which one
   * will ultimately receive the focus. It is advised that only one at most is present.
   */
  @Prop() readonly autofocus: boolean = false;

  /**
   * Whether or not this input should allow autocompletion by the browser
   * Accepts a boolean, or "true", "false", "on", "off" or an
   * [accepted string value for the autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   */
  @Prop() readonly autocomplete: string | boolean = true;

  /**
   * Whether or not to automatically style this input as invalid based on
   * native input validation attributes: `min`, `max`, `pattern`, `required`, `maxlength`, `minlength`.
   * See MDN articles on [form validation](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation)
   * and [constraint validation](https://developer.mozilla.org/en-US/docs/Web/HTML/Constraint_validation)
   */
  @Prop() readonly autovalidate: boolean = false;

  /**
   * Whether the input is displaying an initial autofill value. Used for
   * styling to ensure the label floats up correctly.
   */
  @Prop({ mutable: true, reflect: true }) autofilled: boolean = false;

  @State() ariaAttributes: AriaAttributes;

  mutationObserver: MutationObserver;

  sharedProps: {}; // properties to set on inner default or slotted <input> elements
  _autocomplete: string; // what will actually get bound to the <input> element

  @Watch('focused')
  focusedChangeHandler(newValue: boolean) {
    if (!this.nativeInput) {
      return;
    }

    if (newValue) {
      this.nativeInput.focus();
    } else {
      this.nativeInput.blur();
    }
  }

  @Watch('autocomplete')
  autocompleteWatcher(newValue: string | boolean) {
    this._autocomplete = autocompleteWatcher(newValue);
  }

  @Listen('marketDialogLoaded', { target: 'window' })
  handleMarketDialogLoaded() {
    if (this.autofocus) {
      this.setFocus();
    }
  }

  /**
   * Emitted whenever the input value changes.
   */
  @Event() marketInputValueChange: EventEmitter<{ value: string; originalEvent: KeyboardEvent }>;

  /**
   * Emitted when `market-input` is first fully rendered.
   */
  @Event() marketInputDidLoad: EventEmitter<{ input: HTMLInputElement }>;

  hasLeadingAccessory: boolean = false;

  hasTrailingAccessory: boolean = false;

  valueDidChange(e) {
    const result = this.marketInputValueChange.emit({
      value: e.target.value,
      originalEvent: e,
    });

    if (result.defaultPrevented) {
      e.target.value = this.value;
      e.preventDefault();
    } else {
      this.value = e.target.value;

      if (this.autovalidate) {
        this.invalid = !this.nativeInput.checkValidity();
      }
    }

    // Once the merchant has entered text, the content is no longer populated
    // via autofill, and should be styled as usual.
    this.autofilled = false;
  }

  handleAutofill(e) {
    // This a hack to detect browser autofill, since there's no event emitted for it.
    // See here for details: https://stackoverflow.com/a/41530164
    if (e.animationName === 'market-input-autofill-start') {
      this.autofilled = true;
    } else if (e.animationName === 'market-input-autofill-cancel' && !this.value) {
      this.autofilled = false;
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      submitFormImplicitly(this.el);
    }
  }

  /**
   * Sets focus styling on `<market-input-text>`. Toggles focus on the inner `<input>` if true, and blurs focus if false.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.readonly || this.disabled) {
      return Promise.resolve();
    }

    /**
     * This will cause the `focusChangedHandler` to be triggered which will focus/blur the native <input /> depending on the value passed.
     */
    this.focused = value;

    return Promise.resolve();
  }

  /**
   * Returns the native `<input>` element used under the hood.
   */
  @Method()
  getInputElement(): Promise<HTMLInputElement> {
    return Promise.resolve(this.nativeInput!);
  }

  /**
   * Allows passing an alternative light DOM input.
   */
  @Method()
  registerSlottedInput(slottedInput?: HTMLInputElement) {
    this.slottedInput =
      slottedInput ||
      // input slotted into market-input-text
      this.el.querySelector('input[slot=input]') ||
      // input slotted into a higher-level component that uses market-input-text
      // (e.g. market-input-password)
      (this.el.getRootNode() as ShadowRoot).host?.querySelector('input[slot=input]');
    if (this.slottedInput) {
      this.slottedInput.addEventListener('input', (e) => this.valueDidChange(e));
      this.slottedInput.addEventListener('focus', () => this.setFocus());
      this.slottedInput.addEventListener('blur', () => (this.focused = false));
      this.slottedInput.addEventListener('animationstart', (e) => this.handleAutofill(e));
      this.nativeInput = this.slottedInput;
    }

    return Promise.resolve();
  }

  updateSharedInputProps() {
    const prevSharedProps = { ...this.sharedProps };

    // used by the default shadow DOM native input and to copy component properties to slotted inputs
    // conditionally adding key/value pairs based on whether we want to set them on the <input>
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#individual_attributes
    this.sharedProps = {
      ...(this.inputId && { id: this.inputId }),
      ...(this.name && { name: this.name }),
      ...(this.type && { type: this.type }),
      ...(this.placeholder && { placeholder: this.placeholder }),
      ...(this.maxlength >= 0 && { maxlength: this.maxlength }),
      ...(this.minlength >= 0 && { minlength: this.minlength }),
      ...(this.step && { step: this.step }),
      ...(this.min && { min: this.min }),
      ...(this.max && { max: this.max }),
      ...(this.required && { required: this.required }),
      ...(this.pattern && { pattern: this.pattern }),
      ...(this.value !== undefined && { value: this.value }),
      ...(this.readonly && { readonly: this.readonly }),
      ...(this.disabled && { disabled: this.disabled }),
      ...(this.autofocus && { autofocus: this.autofocus }),
      ...(this.inputmode && { inputmode: this.inputmode }),
      ...(this._autocomplete && { autocomplete: this._autocomplete }),
      ...this.ariaAttributes,
      'aria-label': getTextInputAriaLabel(this.el),
    };

    // sync component props to slotted input, if one exists
    if (this.slottedInput) {
      const modifiedPropKeys = [...new Set([...Object.keys(prevSharedProps), ...Object.keys(this.sharedProps)])];
      modifiedPropKeys.forEach((key) => {
        if (!(key in this.sharedProps)) {
          // remove properties that have been unset
          this.slottedInput.removeAttribute(key);
        } else {
          // boolean attributes can be set using empty strings
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute#javascript
          const attributeValue = this.sharedProps[key] !== true ? this.sharedProps[key] : '';
          this.slottedInput.setAttribute(key, attributeValue);
        }
      });
    }
  }

  onMutationObserved = (ariaAttributes: AriaAttributes) => {
    this.ariaAttributes = ariaAttributes;
  };

  componentWillLoad() {
    this.hasLeadingAccessory = Boolean([...this.el.children].some((el) => el.slot === 'leading-accessory'));
    this.hasTrailingAccessory = Boolean([...this.el.children].some((el) => el.slot === 'trailing-accessory'));
    this.mutationObserver = observeAriaAttributes(this.el, this.onMutationObserved);
    this.registerSlottedInput();
    this.autocompleteWatcher(this.autocomplete);
    this.updateSharedInputProps();
  }

  componentDidLoad() {
    this.marketInputDidLoad.emit({ input: this.nativeInput });
  }

  componentWillUpdate() {
    this.updateSharedInputProps();
  }

  render() {
    return (
      <Host
        class="market-input-text"
        onBlur={() => {
          this.focused = false;
        }}
        onClick={() => {
          this.setFocus();
        }}
        onFocus={() => {
          this.setFocus();
        }}
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
      >
        <slot name="leading-accessory"></slot>
        <div
          class={classNames('label-input-container', {
            'has-leading-accessory': this.hasLeadingAccessory,
            'has-trailing-accessory': this.hasTrailingAccessory,
          })}
        >
          <slot></slot>
          <slot name="input" onSlotchange={() => this.registerSlottedInput()}>
            {!this.slottedInput && (
              <input
                part="native-input"
                ref={(input) => (this.nativeInput = input)}
                onInput={(e) => this.valueDidChange(e)}
                onAnimationStart={(e) => this.handleAutofill(e)}
                {...this.sharedProps}
              />
            )}
          </slot>
        </div>
        <slot name="trailing-accessory"></slot>
      </Host>
    );
  }

  disconnectedCallback() {
    this.mutationObserver?.disconnect();
  }
}
