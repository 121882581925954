import Services from 'src/services/Services';
import {
  Appointment,
  AppointmentStatusCode,
  TranscriptViewItem,
  GraphQLAppointment,
  ReservationStatusCodeV1,
} from 'src/MessengerTypes';
import {
  ExecuteGraphQLRequest,
  IExecuteGraphQLRequest,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';
import { callV3Rpc } from 'src/utils/apiUtils';

/**
 * The number of days we want to look in the future for contextual events
 */
const CONTEXTUAL_EVENTS_FUTURE_DAYS = 30;

class ContextualEventsApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Returns the nearest future event associated with a given merchant and customer token.
   *
   * @param {string[]} customerTokens
   * The list of customer tokens to search for future events with.
   * @returns {Promise<TranscriptViewItem>}
   * The contextual event in the TranscriptViewItem format.
   */
  getNearestFutureEvent = async (
    customerTokens: string[],
  ): Promise<TranscriptViewItem | null> => {
    // Future contextual events for each customer token
    const contextualEventLists: TranscriptViewItem[][] = await Promise.all(
      customerTokens.map((customerToken) =>
        this.getFutureEventsForCustomer(customerToken),
      ),
    );

    const contextualEvents: TranscriptViewItem[] = contextualEventLists
      .flat()
      .sort((a, b) => a.timestampMillis - b.timestampMillis);

    return contextualEvents.length > 0 ? contextualEvents[0] : null;
  };

  /**
   * Returns the list of future events associated with a given merchant and customer token.
   *
   * @param {string} customerToken
   * The customer token to search for future events with.
   * @returns {Promise<TranscriptViewItem[]>}
   * The list of contextual events in the TranscriptViewItem format.
   */
  getFutureEventsForCustomer = async (
    customerToken: string,
  ): Promise<TranscriptViewItem[]> => {
    const beginTimestampMillisInclusive = Date.now();
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + CONTEXTUAL_EVENTS_FUTURE_DAYS);
    const endTimestampMillisExclusive = futureDate.getTime();

    const idempotencyKey = `${customerToken}_${beginTimestampMillisInclusive}_${endTimestampMillisExclusive}`;

    const request: IExecuteGraphQLRequest = {
      idempotencyKey,
      query: `{
          appointments {
            reservations(
              customerToken: "${customerToken}",
              occurrenceStartAt: ${beginTimestampMillisInclusive * 1000}, 
              occurrenceEndAt: ${endTimestampMillisExclusive * 1000}
            ) {
              dateStart
              statusCode
              reservationId
              unitToken
              schedule {
                rrule
              }
              staffNames
              itemNames
            }
          }
        }`,
    };

    const response = await callV3Rpc({
      name: 'ExecuteGraphQL - Future Contextual Events',
      rpc: (x) => this._services.messagesV3.executeGraphQL(x),
      request: ExecuteGraphQLRequest.create(request),
    });

    const contextualEvents: TranscriptViewItem[] = [];

    if (response != null && response.response != null) {
      try {
        const graphql = JSON.parse(response.response);
        if (!graphql.data) {
          return [];
        }

        if (
          graphql.data.appointments &&
          graphql.data.appointments.reservations
        ) {
          graphql.data.appointments.reservations.forEach(
            (appointment: GraphQLAppointment) => {
              let statusCode: AppointmentStatusCode | undefined;
              // TODO(klim): remove shim when graphlql migration is done
              // Only show appointments that are accepted
              switch (appointment.statusCode) {
                case ReservationStatusCodeV1.ACCEPTED:
                  statusCode = 'ACCEPTED';
                  break;
                default:
                  return;
              }

              const data: Appointment = {
                dateStart: appointment.dateStart
                  ? appointment.dateStart / 1000
                  : Date.now(),
                statusCode,
                reservationId: appointment.reservationId,
                isRecurring: appointment.schedule?.rrule !== '',
                staffNames: appointment.staffNames,
                itemNames: appointment.itemNames,
              };

              const item: TranscriptViewItem = {
                timestampMillis: appointment.dateStart
                  ? appointment.dateStart / 1000
                  : Date.now(),
                unitToken: appointment.unitToken,
                dataType: 'APPOINTMENT',
                componentType: 'GENERAL_EVENT_BANNER',
                data,
              };

              contextualEvents.push(item);
            },
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    }

    return contextualEvents;
  };
}

export default ContextualEventsApi;
