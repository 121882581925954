@import "../../styles/mixins/component-base.css";

:host {
  --error-icon-height: 16px;
  --error-icon-width: 16px;
  --error-icon-offset: calc(calc(var(--field-error-message-text-leading) - var(--error-icon-height)) / 2);

  display: block;
  text-align: start;
}

/* If you change this property also update it's counterpart in styles/components.css */
::slotted([slot]) {
  margin-top: 4px;
}

::slotted([slot="error"]) {
  display: block;

  /* If you change these properties also update their counterparts in styles/components.css */
  color: var(--field-error-message-text-color);
  font-weight: var(--field-error-message-text-weight);
  font-size: var(--field-error-message-text-size);
  line-height: var(--field-error-message-text-leading);
  letter-spacing: var(--field-error-message-text-tracking);

  &::before {
    content: "";
    display: inline-block;
    vertical-align: calc(-1 * var(--error-icon-offset));
    width: var(--error-icon-width);
    height: var(--error-icon-height);

    /* TODO: design tokens for error message icon */
    margin-right:
      calc(
        var(--field-error-message-icon-padding, 2px) +
        var(--field-error-message-icon-margin-right, 4px)
      );
    margin-left: var(--field-error-message-icon-padding, 2px);
    background-color: var(--field-error-message-text-color);
    mask: url("data:image/svg+xml;utf8,<svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M8 15.5C12.1333 15.5 15.5 12.1333 15.5 8C15.5 3.86667 12.1333 0.5 8 0.5C3.86667 0.5 0.5 3.86667 0.5 8C0.5 12.1333 3.86667 15.5 8 15.5ZM8 2.16667C11.2167 2.16667 13.8333 4.78333 13.8333 8C13.8333 11.2167 11.2167 13.8333 8 13.8333C4.78333 13.8333 2.16667 11.2167 2.16667 8C2.16667 4.78333 4.78333 2.16667 8 2.16667ZM8 12.1667C8.5753 12.1667 9.04167 11.7003 9.04167 11.125C9.04167 10.5497 8.5753 10.0833 8 10.0833C7.4247 10.0833 6.95833 10.5497 6.95833 11.125C6.95833 11.7003 7.4247 12.1667 8 12.1667ZM7.16667 3.83333H8.83333V9.25H7.16667V3.83333Z'/></svg>");
    mask-size: cover;
  }
}

::slotted([slot="bottom-accessory"]) {
  display: block;
  margin: var(--field-helper-text-vertical-spacing) var(--field-helper-text-horizontal-spacing);
  color: var(--field-helper-text-text-color);
  font-weight: var(--field-helper-text-weight);
  font-size: var(--field-helper-text-size);
  line-height: var(--field-helper-text-leading);
  letter-spacing: var(--field-helper-text-tracking);
}

::slotted([slot="action"]) {
  display: block;
  padding: var(--field-action-vertical-padding-size) 0;

  /* If you change these properties also update their counterparts in styles/components.css */
  color: var(--field-action-text-color);
  font-weight: var(--field-action-text-weight);
  font-size: var(--field-action-text-size);
  line-height: var(--field-action-text-leading);
  letter-spacing: var(--field-action-text-tracking);
  text-decoration: none;
  cursor: pointer;
}

::slotted(button[slot="action"]) {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  appearance: none;
}
