import { Component, Host, Prop, Element, Event, EventEmitter, State, Method, h } from '@stencil/core';

/**
 * @slot - Default slot for content
 * @slot leading-accessory - Intended for use with a leading accessory.
 * @slot trailing-accessory - Intended for use with a trailing accessory.
 */
@Component({
  tag: 'market-table-cell',
  styleUrl: 'market-table-cell.css',
  shadow: true,
})
export class MarketTableCell {
  @Element() el: HTMLMarketTableCellElement;

  /**
   * Content/text alignment for this cell, default is set based on the alignment of the
   * market-table-column with matching column/name prop
   *
   * **NOTE:** this is set automatically when used in a `market-table` with a corresponding
   * `market-table-column`.
   */
  @Prop({ mutable: true, reflect: false }) align: false | 'left' | 'right' = false;

  /**
   * A key that matches the 'name' prop on the appropriate market-table-column
   *
   * **NOTE:** this is set automatically when used in a `market-table` with a corresponding
   * `market-table-column`.
   */
  @Prop({ mutable: true, reflect: true }) column: string;

  /**
   * Determines how much to indent the cell by.
   * This will be multiplied by the default indentation size (40px) for uniform indentation levels
   *
   * **NOTE:** this is inherited automatically if set on the parent `market-table-row`.
   */
  @Prop({ mutable: true, reflect: false }) leadingIndentation: number = 0;

  /**
   * Whether the cell is currently active.
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * Whether or not the cell is interactive. Results in cell receiving
   * hover and active styling when hovered/clicked.
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Whether the cell is disabled.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Fired whenever an interactive cell is clicked.
   */
  @Event({ bubbles: true, composed: true }) marketTableCellClicked: EventEmitter;

  /**
   * The slot this element was originally placed in
   */
  @State() originalSlot: string;

  /**
   * Whether or not the table cell is hidden.
   *
   * **NOTE:** this is derived from the hidden prop of the corresponding
   * `market-table-column`, if one exists
   */
  @State() hidden: boolean = false;

  /**
   * **INTERNAL [do not use directly]**
   * Forwards appropriate properties from matching header market-table-column element to this cell.
   */
  @Method()
  _updateColumnRelatedProperties(column: HTMLMarketTableColumnElement) {
    if (!column) {
      return Promise.resolve();
    }

    this.column = column.name;
    this.align = column.align;
    this.hidden = column.hidden;

    if (column.stickTo) {
      this._stickSelf(column.stickTo);
    }

    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Sets properties specified in the row element. Leading and trailing
   * accessories are best set on the first or last cell to not mess
   * with the grid structure
   * @param rowEl
   */
  @Method()
  _updateFirstCellProperties(rowEl: HTMLMarketTableRowElement) {
    this.leadingIndentation = rowEl.leadingIndentation || 0;

    // Sets leading accessory level of indentation on current row
    this.el.style.setProperty('--cell-indent-level', `${this.leadingIndentation}`);

    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Moves this column into a slot inside the market-table-area
   * which is fixed to the provided edge (position), allowing
   * for fixed columns
   */
  @Method()
  _stickSelf(position: 'left' | 'right') {
    if (position) {
      this.el.slot = `sticky-${position}`;
    }
    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Moves this column back into it's original slot from a slot
   * within a fixed market-table-area
   */
  @Method()
  _unstickSelf() {
    if (!this.originalSlot) {
      this.el.removeAttribute('slot');
    } else {
      this.el.slot = this.originalSlot;
    }
    return Promise.resolve();
  }

  componentWillLoad() {
    this.originalSlot = this.el.getAttribute('slot');
  }

  handleClick() {
    if (this.interactive) {
      this.marketTableCellClicked.emit();
    }
  }

  handleKeydown(e) {
    // don't intercept keydown of descendant elements
    // e.g. when typing into nested input fields (gross)
    if (e.target !== this.el) {
      return;
    }

    switch (e.key) {
      case 'Enter':
        this.handleClick();
        break;
      case ' ':
        this.handleClick();
        e.preventDefault(); // spacebar should not scroll page
        break;
      default:
        break;
    }
  }

  render() {
    const { disabled, interactive, align, hidden } = this;

    return (
      <Host
        class="market-table-cell"
        role="cell"
        align={align === 'right' && align}
        hidden={hidden}
        tabindex={interactive && !disabled ? '0' : null}
        onClick={() => this.handleClick()}
        onKeydown={(e) => this.handleKeydown(e)}
      >
        <slot name="nested-row-indicator"></slot>
        <slot name="leading-accessory"></slot>
        <slot></slot>
        <slot name="trailing-accessory"></slot>
      </Host>
    );
  }
}
