import { Component, Host, h, Element, Event, EventEmitter, Method, Prop, State } from '@stencil/core';

@Component({
  tag: 'market-segment',
  styleUrl: 'market-segment.css',
  shadow: true,
})
export class MarketSegment {
  @Element() el: HTMLMarketSegmentElement;

  /**
   * Whether the market segment should appear in a disabled state.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * A string specifying a value for the segment.
   */
  @Prop({ mutable: true, reflect: true }) value: string;

  /**
   * A boolean specifying whether the segment is selected or not.
   */
  @State() selected: boolean = false;

  /**
   * Fired when the segment is clicked or otherwise selected
   */
  @Event({ bubbles: true, composed: true }) marketSegmentSelectedChanged: EventEmitter<{
    value: boolean;
    prevValue: boolean;
  }>;

  @Method()
  setSelectedState(state) {
    this.selected = state;
    return Promise.resolve();
  }

  @Method()
  selectSegment() {
    if (this.selected || this.disabled) {
      return Promise.resolve();
    }
    this.marketSegmentSelectedChanged.emit();
    return Promise.resolve();
  }

  render() {
    return (
      <Host selected={this.selected} class="market-segment" onClick={() => this.selectSegment()}>
        <slot></slot>
      </Host>
    );
  }
}
