import { Component, Element, h, Host } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - Displays whatever you put between `<market-popover>` and `</market-popover>`
 * (can be string literals, Market component(s), HTML element(s), or any combination)
 *
 * If slot contains `<market-list>` elements, `initInteractiveList()` will set the
 * `interactive` property on each instance of `<market-list>` to `true`.
 */
@Component({
  tag: 'market-popover',
  shadow: true,
  styleUrl: 'market-popover.css',
})
export class Popover {
  @Element() el: HTMLMarketPopoverElement;

  /* Don't want to force consumers to have to remember to put 'interactive' attribute
    on a <market-list> slotted into this options list, so just set the property on the child
    element here because lists should always be interactive when inside this component */
  initInteractiveList() {
    /* We could just do el.querySelectorAll('market-list') here, but to handle elements that may be multi-slotted
    such as when using this popover inside of a select/filterable element and forwarding the slotted contents into
    the popover, we need to use this assignedElements() method */
    const slot = this.el.querySelector('slot');
    let lists;
    if (slot) {
      lists = slot.assignedElements().filter((el) => el.localName === getNamespacedTagFor('market-list'));
    } else {
      /* .querySelectorAll() returns a NodeList and not an array so we need to convert it
       in order to use .map() below */
      lists = [].slice.call(this.el.querySelectorAll(getNamespacedTagFor('market-list')));
    }
    if (lists) {
      lists.forEach((list) => {
        list.interactive = true;
      });
    }
  }

  render() {
    this.initInteractiveList();
    return (
      <Host class="market-popover">
        <slot></slot>
      </Host>
    );
  }
}
