/* TODO: add design tokens */
@import "../../../../styles/mixins/typography.css";

:host {
  color: var(--tabs-tab-text-color, var(--core-text-20-color));
}

:host [part="button"] {
  position: relative;
  border: 0;
  background: none;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  cursor: pointer;
  transition:
    color
    var(--core-animation-enter-transition-fast-speed-duration)
    var(--core-animation-enter-transition-easing);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-radius:
      var(--tabs-tab-bottom-border-border-radius-top-left, var(--core-metrics-spacing-25))
      var(--tabs-tab-bottom-border-border-radius-top-right, var(--core-metrics-spacing-25))
      var(--tabs-tab-bottom-border-border-radius-bottom-right, 0)
      var(--tabs-tab-bottom-border-border-radius-bottom-left, 0);
    background: var(--tabs-tab-bottom-border-background-color, var(--core-text-10-color));
    transition:
      height
      var(--core-animation-enter-transition-fast-speed-duration)
      var(--core-animation-enter-transition-easing),
      background
      var(--core-animation-enter-transition-fast-speed-duration)
      var(--core-animation-enter-transition-easing);
  }
}

:host(:hover),
:host(:active) {
  color: var(--tabs-tab-hover-state-text-color, var(--core-emphasis-text-color));
  transition:
    color
    var(--core-animation-exit-transition-fast-speed-duration)
    var(--core-animation-exit-transition-easing);
}

/* remove border radius that looks buggy on screens (especially on Chrome) with standard pixel density */
/* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
@media (-webkit-device-pixel-ratio: 1) {
  :host [part="button"]::after {
    border-radius: 0;
  }
}

:host([size="small"]) {
  @extend %medium-20;

  [part="button"] {
    padding:
      var(--tabs-tab-small-size-top-padding-size, 4px)
      0
      var(--tabs-tab-small-size-bottom-padding-size, 10px);
  }
}

:host(:not([size])),
:host([size="medium"]) {
  @extend %heading-20;

  [part="button"] {
    padding:
      var(--tabs-tab-medium-size-top-padding-size, 4px)
      0
      var(--tabs-tab-medium-size-bottom-padding-size, 10px);
  }
}

:host([size="large"]) {
  @extend %heading-30;

  [part="button"] {
    padding:
      var(--tabs-tab-large-size-top-padding-size, 4px)
      0
      var(--tabs-tab-large-size-bottom-padding-size, 12px);
  }
}

:host([selected]) {
  color: var(--tabs-tab-selected-text-color, var(--core-text-10-color));

  [part="button"]::after {
    height: var(--tabs-tab-selected-bottom-border-height, var(--core-metrics-spacing-25));
  }

  &:hover {
    color: var(--tabs-tab-hover-state-selected-text-color, var(--core-text-10-color));
  }

  &:active {
    color: var(--tabs-tab-active-state-selected-text-color, var(--core-emphasis-text-color));

    [part="button"]::after {
      background: var(--tabs-tab-active-state-bottom-border-background-color, var(--core-emphasis-text-color));
    }
  }
}

:host([disabled]) {
  color: var(--tabs-tab-disabled-state-text-color, var(--core-text-30-color));

  &:hover {
    color: var(--tabs-tab-disabled-state-text-color, var(--core-text-30-color));
  }

  [part="button"] {
    cursor: not-allowed;
  }
}
