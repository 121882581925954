import { Component, Element, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'market-activity-indicator-bar',
  styleUrl: 'market-activity-indicator-bar.css',
  shadow: true,
})
export class MarketActivityIndicatorBar {
  @Element() el: HTMLMarketActivityIndicatorBarElement;

  /**
   * The value the activity bar should report
   */
  @Prop() readonly value: number = 0;

  /**
   * The maximum value of the activity bar
   */
  @Prop() readonly max: number = 1;

  render() {
    const max: number = this.max > 0 ? this.max : 1;
    const value: number = this.value > 0 ? Math.min(this.value, this.max) : 0;
    const percentComplete: number = (value / max) * 100;

    return (
      <Host
        class="market-activity-indicator-bar"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax={max}
        aria-valuetext={`${percentComplete}%`}
      >
        <progress value={value} max={max}>
          {/* HTML Fallback: https://css-tricks.com/html5-progress-element/#aa-2-html-fallback */}
          <div class="progress-bar">
            <span style={{ width: `${percentComplete}%` }}></span>
          </div>
        </progress>
      </Host>
    );
  }
}
