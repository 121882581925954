import { Component, Host, h, Prop } from '@stencil/core';

/**
 * @slot - Intended for a slotted image or icon.
 */
@Component({
  tag: 'market-accessory',
  styleUrl: 'market-accessory.css',
  shadow: true,
})
export class MarketAccessory {
  /**
   * The desired size for the leading or trailing accessory, which we expect to
   * be either an image or a <market-icon> component. The available sizes are
   * "icon" (24x24) and "image" (40x40).
   */
  @Prop() readonly size: 'icon' | 'image';

  /* TODO: add optional height/width props for custom sizing of slotted image */

  render() {
    return (
      <Host class="market-accessory" size={this.size}>
        <slot></slot>
      </Host>
    );
  }
}
