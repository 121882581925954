declare type TransformTagName = (tagName: string) => string;

let localTransformTagName: TransformTagName;

// Allows access to the transformTagName function at runtime to transform nested children.
// Stencil does not currently expose this.
export function getNamespacedTagFor<TTagName extends keyof HTMLElementTagNameMap>(tagName: TTagName): TTagName {
  return new Proxy((tag: TTagName): TTagName => tag, {
    apply(target, thisArg, args) {
      return Reflect.apply(localTransformTagName ?? target, thisArg, args);
    },
  })(tagName);
}

export const setTransformTagName = (transform: TransformTagName): void => {
  localTransformTagName = transform;
};

export function isElementWithTagName<TTagName extends keyof HTMLElementTagNameMap>(
  el: Element,
  tagName: TTagName,
): el is HTMLElementTagNameMap[TTagName] {
  return el?.tagName?.toLowerCase?.() === getNamespacedTagFor<TTagName>(tagName);
}
