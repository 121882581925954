import { isRowElement } from '../../utils/element-type-guard';

/**
 * Finds the row that's slotted in the action card
 *
 * @param {HTMLMarketActionCardElement} actionCardEl - the action card element
 * @returns {HTMLMarketRowElement | null} the row element, if found
 */
export function getRowInActionCard(actionCardEl: HTMLMarketActionCardElement): HTMLMarketRowElement | null {
  if (!actionCardEl?.children) {
    return null;
  }
  return [...actionCardEl.children].find(isRowElement);
}
