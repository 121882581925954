import { Component, Element, Event, Host, h, State, Prop, EventEmitter, Watch } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';

@Component({
  tag: 'market-file-upload',
  styleUrl: 'market-file-upload.css',
  shadow: true,
})
export class MarketFileUpload {
  @Element() el: HTMLMarketFileUploadElement;

  /**
   * String that is a list of file types the uploader should accept. This is
   * passed to the internal `<input type="file"/>` tag. For more info, see the
   * [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept).
   */
  @Prop() readonly accept: string = '';

  /**
   * An array of File objects that can be passed in. (If using vanilla JS, this must be set using JS and not in the HTML markup.)
   */
  @Prop() readonly value: Array<File> = [];

  /**
   * Functionally and visually disables the file picker.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Represents whether the input is invalid or not.
   * This represents error states.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * Represents whether the selector allows multiple files
   * to be selected.
   */
  @Prop({ reflect: true }) readonly multiple: boolean = false;

  /**
   * Optional property passed to the delete icons on selected file
   * rows that will function as its aria-label. Defaults to "Delete".
   */
  @Prop({ reflect: true }) readonly deleteButtonAriaLabel: string = 'Delete';

  /**
   * Tracks whether a file is being dragged over the component.
   */
  @State() isDraggingOver: boolean = false;

  /**
   * Files that have been selected by the user either via drag & drop or
   * the file selector.
   */
  @State() files: Array<File>;

  @Watch('value')
  watchValueHandler(newValue: Array<File>) {
    if (Array.isArray(newValue)) {
      this.files = [...newValue];
    }
  }

  /**
   * Triggered when the selected files array changes.
   */
  @Event() marketFileUploadValueChange: EventEmitter<{ value: Array<File> }>;

  /**
   * We want to disable certain functions if multiple is false and a file has been selected
   */
  get hasSingleFileSelected(): boolean {
    return !this.multiple && this.files.length === 1;
  }

  private fileInputElement: HTMLInputElement;

  draggingOver(e) {
    if (this.disabled || this.hasSingleFileSelected) {
      return;
    }

    this.isDraggingOver = true;
    e.preventDefault();
  }

  endDrag(e) {
    e.preventDefault();
    if (this.disabled || this.hasSingleFileSelected) {
      return;
    }

    this.isDraggingOver = false;
    if (e.type === 'drop' && e.dataTransfer) {
      this.addFiles(e.dataTransfer.files);
    }
  }

  handleButtonClick() {
    this.fileInputElement.click();
  }

  handleDeleteKeydown(e, index) {
    if (e.key === 'Enter' && !this.disabled) {
      this.removeFile(index);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.files = [...this.files];
    this.emitFileChange();
  }

  labelContainerClassNames() {
    const classNames = [];
    if (this.isDraggingOver) {
      classNames.push('is-dragging-over');
    }
    if (this.hasSingleFileSelected) {
      classNames.push('has-file-selected');
    }

    return classNames;
  }

  onInputChange(e) {
    this.addFiles(e.target.files);
    // unset the file input value so that we can re-add files
    // if they are removed by the user
    this.fileInputElement.value = '';
  }

  addFiles(files: Array<File>) {
    if (files) {
      if (!this.multiple) {
        this.files = [files[0]];
      } else {
        this.files = [...files, ...this.files];
      }
    }
    this.emitFileChange();
  }

  emitFileChange() {
    this.marketFileUploadValueChange.emit({ value: this.files });
  }

  componentWillLoad() {
    if (this.value) {
      this.files = [...this.value];
    } else {
      this.files = [];
    }
  }

  render() {
    const MarketRowTagName = getNamespacedTagFor('market-row');
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');
    const MarketFieldTagName = getNamespacedTagFor('market-field');

    const fileMarketRows = this.files.map((file, index) => {
      return (
        <MarketRowTagName disabled={this.disabled} interactive={true} transient data-index={index}>
          <label slot="label">{file.name}</label>
          <MarketAccessoryTagName
            slot="trailing-accessory"
            size="icon"
            tabindex={this.disabled ? -1 : 0}
            role="button"
            aria-label={this.deleteButtonAriaLabel}
            onKeyDown={(e) => this.handleDeleteKeydown(e, index)}
            onClick={() => this.removeFile(index)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 5H19H17H15C15 3.35 13.65 2 12 2C10.35 2 9 3.35 9 5H7H5H3V7H5V18.75C5 20.54 6.66 22 8.7 22H15.3C17.34 22 19 20.54 19 18.75V7H21V5ZM12 4C12.55 4 13 4.45 13 5H11C11 4.45 11.45 4 12 4ZM17 18.75C17 19.43 16.22 20 15.3 20H8.7C7.78 20 7 19.43 7 18.75V7H17V18.75ZM15 9H13V18H15V9ZM9 9H11V18H9V9Z" />
            </svg>
          </MarketAccessoryTagName>
        </MarketRowTagName>
      );
    });
    const showUploadLabel = this.multiple || fileMarketRows.length === 0;
    const showList = this.multiple && fileMarketRows.length > 0;

    return (
      <Host
        class="market-file-upload"
        ondragenter={(e) => this.draggingOver(e)}
        ondragover={(e) => this.draggingOver(e)}
        ondragleave={(e) => this.endDrag(e)}
        ondrop={(e) => this.endDrag(e)}
      >
        <MarketFieldTagName disabled={this.disabled} invalid={this.invalid}>
          <div class={`label-container ${this.labelContainerClassNames()}`}>
            {!showUploadLabel && fileMarketRows[0]}
            {showUploadLabel && (
              // the input is correctly wrapped inside its label, but eslint gets confused by label > span > slot > text
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <svg width="14" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 0H3C1.34 0 0 1.34 0 3v14c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V6L8 0Zm3.17 6H8V2.83L11.17 6ZM12 17c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v6h6v9Z" />
                </svg>
                <span>
                  <slot>
                    Drag and drop here or <button onClick={() => this.handleButtonClick()}>browse file</button>
                  </slot>
                </span>
                <input
                  ref={(el) => (this.fileInputElement = el)}
                  onChange={(e) => this.onInputChange(e)}
                  type="file"
                  name="files[]"
                  multiple={this.multiple}
                  accept={this.accept}
                  hidden
                />
              </label>
            )}
          </div>
          <slot name="bottom-accessory">
            <small slot="bottom-accessory"></small>
          </slot>
          <slot name="error">
            <small slot="error"></small>
          </slot>
        </MarketFieldTagName>
        {showList && (
          <MarketListTagName interactive transient>
            {fileMarketRows}
          </MarketListTagName>
        )}
      </Host>
    );
  }
}
