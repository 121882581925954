import { Component, Host, Prop, State, h, Event, EventEmitter, Element } from '@stencil/core';

/**
 * @slot - The text used for the tag label
 * @slot icon - an icon that is to the left of tag text
 */
@Component({
  tag: 'market-tag',
  styleUrl: 'market-tag.css',
  shadow: true,
})
export class MarketTag {
  @Element() el: HTMLMarketTagElement;

  /**
   * Functionally and visually disables the tag
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether or not the tag is in a focused state
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * String for setting tag size
   */
  @Prop({ reflect: true }) readonly size: 'medium' | 'small' = 'small';

  /**
   * Whether or not the tag contains an icon
   */
  @State() hasIcon: boolean = false;

  /**
   * Emitted when the tag's remove indicator is clicked.
   */
  @Event() marketTagDismissed: EventEmitter;

  /* handles click and unclick in tag */
  onFocus() {
    if (this.disabled) {
      return;
    }

    if (!this.focused) {
      this.focused = true;
    } else {
      this.focused = false;
    }
  }

  handleDismissTagEvent(e: Event) {
    this.marketTagDismissed.emit();
    e.stopPropagation();
    this.el.remove();
  }

  componentWillLoad() {
    this.hasIcon = Boolean(this.el.querySelector('[slot="icon"]'));
  }

  render() {
    return (
      <Host
        class={`market-tag ${this.hasIcon ? 'has-icon' : ''}`}
        onClick={() => {
          this.onFocus();
        }}
        onFocus={() => {
          this.onFocus();
        }}
        aria-disabled={this.disabled}
      >
        <span class="icon">
          <slot name="icon"></slot>
        </span>
        <slot></slot>
        <svg
          class="remove-indicator"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e: Event) => this.handleDismissTagEvent(e)}
        >
          <path d="M4.4734 12.4734L8.00007 8.94002L11.5267 12.4734L12.4734 11.5267L8.94007 8.00002L12.4734 4.47335L11.5267 3.52669L8.00007 7.06002L4.4734 3.52669L3.52673 4.47335L7.06007 8.00002L3.52673 11.5267L4.4734 12.4734Z" />
        </svg>
      </Host>
    );
  }
}
