@import "../../styles/mixins/component-base.css";

:host {
  /* TODO: small design tokens */
  --tag-small-size-minimum-height: var(--core-metrics-spacing-400);
  --tag-small-size-horizontal-spacing: var(--core-metrics-spacing-50);
  --tag-small-size-text-weight: var(--core-type-paragraph-10-weight);
  --tag-small-size-text-size: var(--core-type-paragraph-10-size);
  --tag-small-size-text-leading: var(--core-type-paragraph-10-leading);
  --tag-small-size-text-tracking: var(--core-type-paragraph-10-tracking);
  --tag-small-size-text-case: var(--core-type-paragraph-10-case);
  --tag-small-size-basic-format-top-padding: 7px;
  --tag-small-size-basic-format-right-padding: 8px;
  --tag-small-size-basic-format-bottom-padding: 7px;
  --tag-small-size-basic-format-left-padding: 12px;
  --tag-small-size-with-icon-format-top-padding: 7px;
  --tag-small-size-with-icon-format-right-padding: 8px;
  --tag-small-size-with-icon-format-bottom-padding: 7px;
  --tag-small-size-with-icon-format-left-padding: 8px;

  /* TODO: medium design tokens */
  --tag-medium-size-minimum-height: var(--core-metrics-spacing-500);
  --tag-medium-size-horizontal-spacing: var(--core-metrics-spacing-50);
  --tag-medium-size-text-weight: var(--core-type-paragraph-20-weight);
  --tag-medium-size-text-size: var(--core-type-paragraph-20-size);
  --tag-medium-size-text-leading: var(--core-type-paragraph-20-leading);
  --tag-medium-size-text-tracking: var(--core-type-paragraph-20-tracking);
  --tag-medium-size-text-case: var(--core-type-paragraph-20-case);
  --tag-medium-size-basic-format-top-padding: 9px;
  --tag-medium-size-basic-format-right-padding: 8px;
  --tag-medium-size-basic-format-bottom-padding: 9px;
  --tag-medium-size-basic-format-left-padding: 12px;
  --tag-medium-size-with-icon-format-top-padding: 9px;
  --tag-medium-size-with-icon-format-right-padding: 8px;
  --tag-medium-size-with-icon-format-bottom-padding: 9px;
  --tag-medium-size-with-icon-format-left-padding: 8px;

  display: inline-flex;
  align-items: center;
  background-color: var(--tag-normal-state-background-color);
  color: var(--tag-normal-state-label-color);
  cursor: pointer;

  .icon {
    display: none;
  }

  &(.has-icon) {
    .icon {
      display: inline-block;
    }
  }

  &([size="small"]) {
    gap: var(--tag-small-size-horizontal-spacing);
    min-height: var(--tag-small-size-minimum-height);
    padding:
      var(--tag-small-size-basic-format-top-padding)
      var(--tag-small-size-basic-format-right-padding)
      var(--tag-small-size-basic-format-bottom-padding)
      var(--tag-small-size-basic-format-left-padding);
    border-radius: calc(var(--tag-small-size-minimum-height) / 2);
    font-weight: var(--tag-small-size-text-weight);
    font-size: var(--tag-small-size-text-size);
    line-height: var(--tag-small-size-text-leading);
    letter-spacing: var(--tag-small-size-text-tracking);
    text-transform: var(--tag-small-size-text-case);

    /* padding changes when icon is slotted */
    &(.has-icon) {
      padding:
        var(--tag-small-size-with-icon-format-top-padding)
        var(--tag-small-size-with-icon-format-right-padding)
        var(--tag-small-size-with-icon-format-bottom-padding)
        var(--tag-small-size-with-icon-format-left-padding);
    }
  }

  &([size="medium"]) {
    gap: var(--tag-medium-size-horizontal-spacing);
    min-height: var(--tag-medium-size-minimum-height);
    padding:
      var(--tag-medium-size-basic-format-top-padding)
      var(--tag-medium-size-basic-format-right-padding)
      var(--tag-medium-size-basic-format-bottom-padding)
      var(--tag-medium-size-basic-format-left-padding);
    border-radius: calc(var(--tag-medium-size-minimum-height) / 2);
    font-weight: var(--tag-medium-size-text-weight);
    font-size: var(--tag-medium-size-text-size);
    line-height: var(--tag-medium-size-text-leading);
    letter-spacing: var(--tag-medium-size-text-tracking);
    text-transform: var(--tag-medium-size-text-case);

    /* padding changes when icon is slotted */
    &(.has-icon) {
      padding:
        var(--tag-medium-size-with-icon-format-top-padding)
        var(--tag-medium-size-with-icon-format-right-padding)
        var(--tag-medium-size-with-icon-format-bottom-padding)
        var(--tag-medium-size-with-icon-format-left-padding);
    }
  }
}

svg.remove-indicator,
::slotted([slot="icon"]) {
  flex-shrink: 0;
}

::slotted([slot="icon"]) {
  fill: var(--tag-normal-state-icon-color);
}

svg.remove-indicator {
  fill: var(--tag-normal-state-remove-indicator-color);
}

@media (hover: hover) {
  :host(:hover) {
    background-color: var(--tag-hover-state-background-color);
    color: var(--tag-hover-state-label-color);

    ::slotted([slot="icon"]) {
      fill: var(--tag-hover-state-icon-color);
    }

    svg.remove-indicator {
      fill: var(--tag-hover-state-remove-indicator-color);
    }
  }
}

:host(:active) {
  background-color: var(--tag-pressed-state-background-color);
  color: var(--tag-pressed-state-label-color);

  ::slotted([slot="icon"]) {
    fill: var(--tag-pressed-state-icon-color);
  }

  svg.remove-indicator {
    fill: var(--tag-pressed-state-remove-indicator-color);
  }
}

:host(:focus),
:host([focused]) {
  background-color: var(--tag-focus-state-background-color);
  color: var(--tag-focus-state-label-color);

  ::slotted([slot="icon"]) {
    fill: var(--tag-focus-state-icon-color);
  }

  svg.remove-indicator {
    fill: var(--tag-focus-state-remove-indicator-color);
  }
}

:host([disabled]) {
  background-color: var(--tag-disabled-state-background-color);
  color: var(--tag-disabled-state-label-color);

  ::slotted([slot="icon"]) {
    fill: var(--tag-disabled-state-icon-color);
  }

  svg.remove-indicator {
    fill: var(--tag-disabled-state-remove-indicator-color);
  }
}
