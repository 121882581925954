@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/modals.css";

:host {
  @extend %modal;

  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: var(--modal-blade-maximum-width-size);
  height: 100vh;
  padding-top: var(--modal-blade-regular-vertical-size-class-vertical-padding);
  padding-bottom: var(--modal-blade-regular-vertical-size-class-vertical-padding);
  background-color: var(--modal-blade-background-color);
  box-shadow: var(--elevation-20-shadow);

  /* Enter animation */
  animation-name: market-slide-left-enter;
  animation-duration:
    var(
      --blade-animation-enter-transition-duration,
      var(--core-animation-enter-transition-moderate-speed-duration)
    );
  animation-timing-function:
    var(
      --blade-animation-enter-transition-easing,
      var(--core-animation-enter-transition-easing)
    );
  animation-fill-mode: forwards;

  ::slotted(*) {
    padding-right: var(--modal-blade-regular-horizontal-size-class-horizontal-padding);
    padding-left: var(--modal-blade-regular-horizontal-size-class-horizontal-padding);
  }

  /* Exit animation */
  &([hidden]) {
    animation-name: market-slide-left-exit;
    animation-duration:
      var(
        --blade-animation-exit-transition-duration,
        var(--core-animation-exit-transition-moderate-speed-duration)
      );
    animation-timing-function:
      var(
        --blade-animation-exit-transition-easing,
        var(--core-animation-exit-transition-easing)
      );
  }

  /* Used to skip exit animation if blade loads w/ hidden attribute */
  &([hidden].skip-animation) {
    transform: translateX(100%);
    animation: none;
  }
}

@media only screen and (min-width: 1200px) {
  :host {
    max-width: var(--modal-blade-wide-viewport-width-size);
    padding-top: var(--modal-blade-wide-viewport-padding-top-size);
    padding-bottom: var(--modal-blade-wide-viewport-padding-bottom-size);
  }

  ::slotted(*) {
    padding-right: var(--modal-blade-wide-viewport-padding-right-size);
    padding-left: var(--modal-blade-wide-viewport-padding-left-size);
  }
}
