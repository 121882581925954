import { Component, Element, Fragment, Host, h, Listen, Prop, State } from '@stencil/core';

import { classNames } from '../../../../utils/classnames';
import { getNamespacedTagFor } from '../../../../utils/namespace';
import { TMarketDateRangeChangedEventDetail } from '../../../market-date-picker/events';
import { TMarketListSelectionsDidChangeEventDetail } from '../../../market-list/events';

/**
 * @slot search - Search input, using `<market-input-search>`
 * @slot filters - Filters, using `<market-filter>`
 * @slot settings - Filter settings, using `<market-filter>`
 * @slot visible-filters - INTERNAL ONLY: Used by `<market-filter-group>` when programmatically arranging visible vs overflow
 * buttons based on available space.
 */
@Component({
  tag: 'market-filter-dropdown-menu',
  styleUrl: 'market-filter-dropdown-menu.css',
  shadow: true,
})
export class MarketFilterDropdownMenu {
  @Element() el: HTMLMarketFilterDropdownMenuElement;

  /**
   * The `<market-filter>` elements
   */
  private filterEls: HTMLMarketFilterElement[];

  /**
   * The filter rows
   */
  private filterRowEls: HTMLMarketRowElement[];

  /**
   * String for setting filter button size
   */
  @Prop({ reflect: true }) readonly size: 'medium' | 'small' = 'medium';

  /**
   * Display the number of filters with a selected value as feedback
   */
  @State() private filtersWithSelectedValue: number;

  /**
   * Active filter's `name` prop
   */
  @State() private hasSelectedFilter: boolean;

  /**
   * Is market-dropdown active
   */
  @State() private isDropdownActive: boolean;

  /**
   * Handle `marketDropdownOpened` emitted by `<market-dropdown>`
   */
  @Listen('marketDropdownOpened')
  handleDropdownOpened() {
    this.deselectFilter();
    this.isDropdownActive = true;
  }

  /**
   * Handle `marketDropdownClosed` emitted by `<market-dropdown>`
   */
  @Listen('marketDropdownClosed')
  handleDropdownClosed() {
    this.isDropdownActive = false;
  }

  /**
   * Handle `marketHeaderNavigate` emitted by `<market-button>` when clicking the back button
   */
  @Listen('marketHeaderNavigate')
  handleHeaderNavigate() {
    this.deselectFilter();
  }

  /**
   * Deselect filter
   */
  private deselectFilter() {
    this.hasSelectedFilter = false;
  }

  /**
   * Handle overflow-filters slot change
   */
  private handleOverflowFiltersSlotChange() {
    this.filterEls = this.el
      .querySelector<HTMLSlotElement>('[slot="overflow-filters"]')
      .assignedNodes() as HTMLMarketFilterElement[];

    // create <market-row>s; and calculate how many filters have set `values`
    this.createRowsFromFilters();
    this.calculateFiltersWithSelectedValue();
  }

  /**
   * Create rows from filter slots
   */
  private createRowsFromFilters() {
    // no more filters, remove rows if they exist
    if (!this.filterEls?.length) {
      this.filterRowEls?.forEach((filterRowEl) => {
        filterRowEl.remove();
      });
      return;
    }

    const filterRowElsByValue: { [value: string]: HTMLMarketRowElement } = (this.filterRowEls ?? []).reduce(
      (result, rowEl) => {
        result[rowEl.value] = rowEl;
        return result;
      },
      {},
    );
    const filterNames = new Set<string>();
    const rowsByName: { [key: string]: HTMLMarketRowElement } = {};

    this.filterEls.forEach((filterEl) => {
      const name = filterEl.name;
      filterNames.add(name);

      const currentRowEl = filterRowElsByValue[name];
      if (currentRowEl) {
        // recycle filter row with the same name if it exists
        rowsByName[name] = currentRowEl;
      } else {
        // create new row element
        const newRowEl = document.createElement(getNamespacedTagFor('market-row')) as HTMLMarketRowElement;
        newRowEl.variant = 'drill';
        newRowEl.interactive = true;
        newRowEl.transient = true;
        newRowEl.value = name;
        newRowEl.size = this.size;
        newRowEl.addEventListener('click', async () => {
          await this.handleFilterSelection(newRowEl);
        });
        rowsByName[name] = newRowEl;
      }

      // create the row label
      const label = rowsByName[name]?.querySelector('label');
      label?.remove();
      const filterLabelEl = filterEl.querySelector('label');
      if (filterLabelEl) {
        rowsByName[name].appendChild(filterLabelEl.cloneNode(true));
      }

      // disable the row if the filter is disabled
      if (filterEl.disabled) {
        rowsByName[name].disabled = true;
      }

      // assign the slot
      rowsByName[name].setAttribute('slot', 'filter-row');
    });

    // remove rows that should not exist anymore
    this.filterRowEls?.forEach((filterRowEl) => {
      if (!filterNames.has(filterRowEl.value)) {
        filterRowEl.remove();
      }
    });

    // add the new rows to the DOM
    this.filterRowEls = [...this.filterEls].map(({ name }) => rowsByName[name]);
    this.filterRowEls.forEach((filterRowEl) => {
      filterRowEl.setAttribute('slot', 'filter-rows');
      this.el.appendChild(filterRowEl);
    });
  }

  /**
   * When a filter is selected, the popover content will show
   * the selected filter's title and list selection
   */
  private async handleFilterSelection(rowEl: HTMLMarketRowElement) {
    const value = rowEl.value;
    this.hasSelectedFilter = true;

    const filterEl: HTMLMarketFilterElement = this.filterEls.find((filterEl) => filterEl.name === value);

    // clone the label
    const labelEl = filterEl.querySelector('label');
    const clonedLabelEl = labelEl.cloneNode(true) as HTMLLabelElement;
    clonedLabelEl.setAttribute('slot', 'filter-title');

    this.el.querySelector('[slot="filter-title"]')?.remove();
    this.el.appendChild(clonedLabelEl);

    switch (await filterEl.getFilterType()) {
      case 'date': {
        // clone the date picker
        const datePickerEl = filterEl.querySelector<HTMLMarketDatePickerDateElement>(
          getNamespacedTagFor('market-date-picker'),
        );
        const clonedDatePickerEl = datePickerEl.cloneNode(true) as HTMLMarketDatePickerElement;
        clonedDatePickerEl.setAttribute('slot', 'filter-options');
        clonedDatePickerEl.addEventListener(
          'marketDateRangeChanged',
          (e: CustomEvent<TMarketDateRangeChangedEventDetail>) => {
            this.handleDatePickerFilterSelection(e, filterEl);
          },
        );

        this.el.querySelector('[slot="filter-options"]')?.remove();
        this.el.appendChild(clonedDatePickerEl);
        break;
      }
      case 'list': {
        // clone the list
        const listEl = filterEl.querySelector<HTMLMarketListElement>(getNamespacedTagFor('market-list'));
        const isMultiselect = listEl.multiselect;
        const clonedListEl = listEl.cloneNode(true) as HTMLMarketListElement;
        clonedListEl.setAttribute('slot', 'filter-options');
        clonedListEl.addEventListener(
          'marketListSelectionsDidChange',
          (e: CustomEvent<TMarketListSelectionsDidChangeEventDetail>) => {
            this.handleListFilterSelection(e, filterEl, isMultiselect);
          },
        );

        clonedListEl.setAttribute('interactive', '');
        listEl.multiselect && clonedLabelEl.setAttribute('multiselect', '');

        this.el.querySelector('[slot="filter-options"]')?.remove();
        this.el.appendChild(clonedListEl);
        break;
      }
      default:
        break;
    }
  }

  private handleDatePickerFilterSelection(
    e: CustomEvent<TMarketDateRangeChangedEventDetail>,
    filterEl: HTMLMarketFilterElement,
  ) {
    const { startDate, endDate } = e.detail;

    const datePickerEl = filterEl.querySelector<HTMLMarketDatePickerElement>(getNamespacedTagFor('market-date-picker'));
    datePickerEl.selectedStartDate = startDate;
    datePickerEl.selectedEndDate = endDate;

    // after selecting, recalculate what we display as feedback
    this.calculateFiltersWithSelectedValue();
  }

  private handleListFilterSelection(
    e: CustomEvent<TMarketListSelectionsDidChangeEventDetail>,
    filterEl: HTMLMarketFilterElement,
    isMultiselect: boolean,
  ) {
    // set the filter value, then automatically deselect if the list is not multiselect
    const { currentSelectionValues } = e.detail;

    const listEl = filterEl.querySelector<HTMLMarketListElement>(getNamespacedTagFor('market-list'));
    listEl.value = currentSelectionValues.join(',');
    if (!isMultiselect) {
      this.deselectFilter();
    }

    // after selecting, recalculate what we display as feedback
    this.calculateFiltersWithSelectedValue();
  }

  /**
   * Count how many filters with selected value
   * and that count is displayed as feedback.
   */
  private calculateFiltersWithSelectedValue() {
    this.filtersWithSelectedValue = [...(this.filterEls || [])].reduce((count, filterEl) => {
      const list = filterEl.querySelector<HTMLMarketListElement>(getNamespacedTagFor('market-list'));
      if (list?.value) {
        return count + 1;
      }
      const datePicker = filterEl.querySelector<HTMLMarketDatePickerElement>(getNamespacedTagFor('market-date-picker'));
      if (datePicker?.selectedStartDate) {
        return count + 1;
      }
      return count;
    }, 0);
  }

  componentDidLoad() {
    this.handleOverflowFiltersSlotChange();
  }

  render() {
    const { filtersWithSelectedValue, handleOverflowFiltersSlotChange, hasSelectedFilter, isDropdownActive, size } =
      this;

    const MarketButtonTagName = getNamespacedTagFor('market-button');
    const MarketDropdownTagName = getNamespacedTagFor('market-dropdown');
    const MarketFilterButtonTagName = getNamespacedTagFor('market-filter-button');
    const MarketHeaderTagName = getNamespacedTagFor('market-header');
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketPopoverTagName = getNamespacedTagFor('market-popover');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');

    return (
      <Host class={classNames('market-filter-dropdown-menu', { 'show-options': hasSelectedFilter })}>
        <MarketDropdownTagName interaction="persistent" popoverPlacement="bottom-end">
          <MarketFilterButtonTagName active={isDropdownActive} iconOnly size={size} slot="trigger">
            {filtersWithSelectedValue && <span slot="feedback">{filtersWithSelectedValue}</span>}
          </MarketFilterButtonTagName>
          <MarketPopoverTagName class="popover" slot="popover">
            {!hasSelectedFilter ? (
              <MarketListTagName class="filter-list" interactive>
                <slot name="filter-rows"></slot>
              </MarketListTagName>
            ) : (
              <Fragment>
                <MarketHeaderTagName class="selection-header">
                  <MarketButtonTagName rank="secondary" size="small" slot="navigation">
                    <MarketAccessoryTagName slot="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.29289 11.293C3.90237 11.6835 3.90237 12.3167 4.29289 12.7072L11.2929 19.7072L12.7071 18.293L7.41421 13.0001L19 13.0001V11.0001L7.41421 11.0001L12.7071 5.70718L11.2929 4.29297L4.29289 11.293Z"
                        />
                      </svg>
                    </MarketAccessoryTagName>
                  </MarketButtonTagName>
                  <slot name="filter-title"></slot>
                </MarketHeaderTagName>
                <div class="filter-options-container">
                  <slot name="filter-options"></slot>
                </div>
              </Fragment>
            )}
          </MarketPopoverTagName>
        </MarketDropdownTagName>
        {/**
         * This is an invisible container where overflow filters are initially.
         * Then they are "moved" to [slot="filter-rows"] as <market-row>s
         */}
        <div class="overflow-filters">
          <slot name="overflow-filters" onSlotchange={handleOverflowFiltersSlotChange.bind(this)}></slot>
        </div>
      </Host>
    );
  }
}
