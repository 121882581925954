/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "multipass": {
        "nested": {
          "RequestPermissionFlags": {
            "values": {
              "READ": 0,
              "WRITE": 1,
              "MERCHANT_PROFILE_READ": 2,
              "SETTLEMENTS_READ": 3,
              "BANK_ACCOUNTS_READ": 4,
              "ITEMS_READ": 5,
              "ITEMS_WRITE": 6,
              "PAYMENTS_READ": 7,
              "PAYMENTS_WRITE": 8,
              "SQUARE_CASH_READ": 9,
              "SQUARE_CASH_WRITE": 10,
              "ORDERS_READ": 11,
              "ORDERS_WRITE": 12,
              "SQUARE_CASH_SENDER": 13,
              "SQUARE_CASH_RECIPIENT": 14,
              "EMPLOYEES_READ": 15,
              "EMPLOYEES_WRITE": 16,
              "TIMECARDS_READ": 17,
              "TIMECARDS_WRITE": 18,
              "CUSTOMERS_READ": 19,
              "CUSTOMERS_WRITE": 20,
              "ORDERS_V2_READ": 21,
              "ORDERS_V2_WRITE": 22,
              "LOYALTY_READ": 23,
              "LOYALTY_WRITE": 24,
              "COUPON_READ": 25,
              "COUPON_WRITE": 26,
              "DEVICE_CREDENTIAL_MANAGEMENT": 27,
              "PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS": 28,
              "OPENID": 29,
              "INVENTORY_READ": 30,
              "INVENTORY_WRITE": 31,
              "PAYMENTS_WRITE_IN_PERSON": 32,
              "TIMECARDS_REPORTS_READ": 33,
              "TIMECARDS_REPORTS_WRITE": 34,
              "TIMECARDS_SETTINGS_READ": 35,
              "TIMECARDS_SETTINGS_WRITE": 36,
              "PAYMENTS_REPORTS_READ": 37,
              "GIFTCARDS_READ": 38,
              "GIFTCARDS_SETTINGS_READ": 39,
              "GIFTCARDS_SETTINGS_WRITE": 40,
              "LOYALTY_SETTINGS_READ": 41,
              "LOYALTY_SETTINGS_WRITE": 42,
              "PAYROLL_READ": 43,
              "PAYROLL_WRITE": 44,
              "FEEDBACK_READ": 45,
              "FEEDBACK_WRITE": 46,
              "FEEDBACK_SETTINGS_READ": 47,
              "FEEDBACK_SETTINGS_WRITE": 48,
              "APPOINTMENTS_READ": 49,
              "APPOINTMENTS_WRITE": 50,
              "CASH_DRAWER_READ": 51,
              "CASH_DRAWER_WRITE": 52,
              "LEGAL_ENTITIES_READ": 53,
              "LEGAL_ENTITIES_WRITE": 54,
              "CONNECT_API_ACCESS_WRITE": 55,
              "MARKETING_READ": 56,
              "MARKETING_WRITE": 57,
              "INSTRUMENT_READ": 58,
              "INSTRUMENT_WRITE": 59,
              "INFORMATION_REQUEST_FORM_READ": 60,
              "INFORMATION_REQUEST_FORM_WRITE": 61,
              "SERVICE_SETTINGS_READ": 62,
              "SERVICE_SETTINGS_WRITE": 63,
              "REPORT_CONFIGS_READ": 64,
              "REPORT_CONFIGS_WRITE": 65,
              "SUBSCRIPTIONS_READ": 66,
              "SUBSCRIPTIONS_WRITE": 67,
              "MERCHANT_PROFILE_WRITE": 68,
              "FINANCIALS_READ": 69,
              "FINANCIALS_WRITE": 70,
              "INVOICES_READ": 71,
              "INVOICES_WRITE": 72,
              "READERS_SETTINGS_WRITE": 73,
              "BANK_ACCOUNTS_WRITE": 74,
              "DEPOSITS_READ": 75,
              "DEPOSITS_WRITE": 76,
              "DISCOUNT_CODES_READ": 77,
              "DISCOUNT_CODES_WRITE": 78,
              "LOCATION_DETAILS_READ": 79,
              "LOCATION_DETAILS_WRITE": 80,
              "GIFTCARD_OFFERINGS_READ": 81,
              "APPOINTMENTS_BUSINESS_SETTINGS_READ": 82,
              "APPOINTMENTS_BUSINESS_SETTINGS_WRITE": 83,
              "SCHEDULES_READ": 84,
              "SCHEDULES_WRITE": 85,
              "PAYMENT_LIMITS_READ": 86,
              "RESERVES_READ": 87,
              "FINANCIAL_ACCOUNT_READ": 88,
              "FINANCIAL_ACCOUNT_WRITE": 89,
              "PAYMENTS_WRITE_SHARED_ONFILE": 90,
              "RISK_MANAGER_READ": 91,
              "RISK_MANAGER_WRITE": 92,
              "ONLINE_STORE_WRITE": 93,
              "GIFTCARDS_WRITE": 94,
              "LOYALTY_REPORTING_READ": 95,
              "LOYALTY_SUSPICIOUS_ACTIVITY_ALERT_READ": 96,
              "LOYALTY_SUSPICIOUS_ACTIVITY_ALERT_WRITE": 97,
              "REFUNDS_WRITE": 98,
              "CONTRACTS_READ": 99,
              "CONTRACTS_WRITE": 100,
              "BUYER_SUBSCRIPTIONS_READ": 101,
              "BUYER_SUBSCRIPTIONS_WRITE": 102,
              "API_LOGS_READ": 103,
              "MESSENGER_READ": 104,
              "MESSENGER_WRITE": 105,
              "EGIFTCARD_CONFIG_WRITE": 106,
              "INVENTORY_REPORTING_READ": 107,
              "ONLINE_STORE_SNIPPETS_WRITE": 108,
              "ONLINE_STORE_SNIPPETS_READ": 109,
              "ONLINE_STORE_SITE_READ": 110,
              "SHORT_URL_WRITE": 111,
              "DISPUTES_READ": 112,
              "DISPUTES_WRITE": 113,
              "APPOINTMENTS_ALL_READ": 114,
              "APPOINTMENTS_ALL_WRITE": 115,
              "MESSAGING_PREFERENCES_READ": 116,
              "MESSAGING_PREFERENCES_WRITE": 117,
              "BUYERS_READ": 118,
              "BUYERS_WRITE": 119,
              "DEVELOPER_PROFILE_READ": 120,
              "DEVELOPER_PROFILE_WRITE": 121,
              "DEVELOPER_COLLABORATOR_PERMISSIONS_READ": 122,
              "DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE": 123,
              "DEVELOPER_SANDBOX_ACCOUNT_READ": 124,
              "DEVELOPER_SANDBOX_ACCOUNT_WRITE": 125,
              "DEVELOPER_APPLICATION_READ": 126,
              "DEVELOPER_APPLICATION_WRITE": 127,
              "DEVELOPER_APPLICATION_CREDENTIALS_READ": 128,
              "DEVELOPER_APPLICATION_CREDENTIALS_WRITE": 129,
              "DEVELOPER_APPLICATION_OAUTH_READ": 130,
              "DEVELOPER_APPLICATION_OAUTH_WRITE": 131,
              "DEVELOPER_APPLICATION_WEBHOOKS_READ": 132,
              "DEVELOPER_APPLICATION_WEBHOOKS_WRITE": 133,
              "DEVELOPER_APPLICATION_READER_SDK_READ": 134,
              "DEVELOPER_APPLICATION_READER_SDK_WRITE": 135,
              "DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ": 136,
              "DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE": 137,
              "DEVELOPER_APPLICATION_APPLE_PAY_READ": 138,
              "DEVELOPER_APPLICATION_APPLE_PAY_WRITE": 139,
              "DEVELOPER_APPLICATION_REVIEWS_READ": 140,
              "DEVELOPER_APPLICATION_REVIEWS_WRITE": 141,
              "DEVELOPER_APPLICATION_SUBMISSIONS_READ": 142,
              "DEVELOPER_APPLICATION_SUBMISSIONS_WRITE": 143,
              "MANAGE_CUSTOMER_CAMPAIGNS_WRITE": 144,
              "MANAGE_HARDWARE_SETTINGS_READ": 145,
              "MANAGE_HARDWARE_SETTINGS_WRITE": 146,
              "PROJECTS_READ": 147,
              "PROJECTS_WRITE": 148,
              "CUSTOMERBALANCES_READ": 149,
              "CUSTOMERBALANCES_WRITE": 150,
              "VENDOR_READ": 151,
              "VENDOR_WRITE": 152,
              "PAYOUTS_READ": 153,
              "SQUARE_BANKING_ACCOUNTS_READ": 154,
              "ROLES_READ": 155,
              "ROLES_WRITE": 156,
              "POLICIES_READ": 157,
              "POLICIES_WRITE": 158,
              "PAYMENT_METHODS_READ": 159,
              "PAYMENT_METHODS_WRITE": 160,
              "FULFILLMENT_PREFERENCES_READ": 161,
              "FULFILLMENT_PREFERENCES_WRITE": 162,
              "TAX_RATES_READ": 163,
              "PAYMENT_BALANCES_ACCOUNT_WRITE": 164,
              "PAYMENT_BALANCES_ACCOUNT_READ": 165,
              "LEDGER_PAYMENT_BALANCES_ACCOUNT_WRITE": 166,
              "INVENTORY_CYCLE_COUNT_WRITE": 167,
              "INVENTORY_FULL_COUNT_WRITE": 168,
              "INVENTORY_FULL_COUNT_LINE_WRITE": 169,
              "INVENTORY_COUNTING_UNIT_COST_READ": 170,
              "REFERRALS_READ": 171,
              "REFERRALS_WRITE": 172,
              "PAYMENT_BALANCES_READ": 173,
              "RESERVATIONS_READ": 174,
              "RESERVATIONS_WRITE": 175,
              "DEVICES_READ": 176,
              "APPOINTMENTS_PERFORMANCE_REPORTS_READ": 177,
              "APPOINTMENTS_PERFORMANCE_REPORTS_PERSONAL_READ": 178,
              "DEVICE_SECURITY_SETTINGS_READ": 179,
              "DEVICE_SECURITY_SETTINGS_WRITE": 180,
              "CHECKOUT_SETTINGS_READ": 181,
              "CHECKOUT_SETTINGS_WRITE": 182,
              "TIME_TRACKING_SETTINGS_READ": 183,
              "TIME_TRACKING_SETTINGS_WRITE": 184,
              "SHARED_DEVICE_PROFILES_V2_READ": 185,
              "SHARED_DEVICE_PROFILES_V2_WRITE": 186,
              "TAX_SETTINGS_READ": 187,
              "TAX_SETTINGS_WRITE": 188,
              "DEPRECATED_FULFILLMENT_RATE_PROFILES_READ": 189,
              "DEPRECATED_FULFILLMENT_RATE_PROFILES_WRITE": 190,
              "ANNOUNCEMENTS_READ": 191,
              "ANNOUNCEMENTS_WRITE": 192,
              "ALTERNATE_SENDERS_READ": 193,
              "ALTERNATE_SENDERS_WRITE": 194,
              "CONVERSATIONS_WRITE": 195,
              "CONVERSATION_MESSAGES_WRITE": 196,
              "MESSAGES_PUBLIC_READ": 197,
              "MESSAGES_PRIVATE_READ": 198,
              "CONVERSATION_MEMBERSHIP_WRITE": 199,
              "TEAM_MEMBERSHIPS_READ": 200,
              "ORGANIZATION_MEMBERSHIP_METADATA_WRITE": 201,
              "REPORTED_CONTENT_READ": 202,
              "TEAM_FILES_READ": 203,
              "TEAM_FILES_WRITE": 204,
              "TEAM_RESOURCES_WRITE": 205,
              "INBOX_ENTRY_WRITE": 206,
              "TEAM_TASKS_READ": 207,
              "TEAM_TASKS_WRITE": 208,
              "TASK_TEMPLATES_WRITE": 209,
              "TEAM_COMMUNICATION_SETTINGS_WRITE": 210,
              "TEAM_GROUPS_WRITE": 211,
              "TEAM_GROUP_MEMBERSHIPS_WRITE": 212,
              "TEAM_TASKS_TARGET_MEMBERSHIP_WRITE": 213,
              "RESTAURANT_CHECKS_READ": 214,
              "CUSTOMERS_UPDATE": 215,
              "DEPRECATED_DEVELOPER_APPLICATION_EVENTS_READ": 216,
              "PERCENTAGE_PROCESSING_READ": 217,
              "PERCENTAGE_PROCESSING_WRITE": 218,
              "DEPRECATED_DEVELOPER_APPLICATION_EVENTS_WRITE": 219,
              "BNPL_SHOP_DIRECTORY_ENROLLMENT_READ": 220,
              "BNPL_SHOP_DIRECTORY_ENROLLMENT_WRITE": 221,
              "BRAND_READ": 222,
              "BRAND_CREATE": 223,
              "BRAND_UPDATE": 224,
              "BRAND_DELETE": 225,
              "MEMBERSHIPS_READ": 226,
              "MEMBERSHIPS_WRITE": 227,
              "MEMBERSHIPS_SETTINGS_READ": 228,
              "MEMBERSHIPS_SETTINGS_WRITE": 229,
              "CREDIT_PACK_READ": 230,
              "CREDIT_PACK_WRITE": 231,
              "CREDIT_PACK_REDEEM": 232,
              "BRANDS_READ": 233,
              "BRANDS_CREATE": 234,
              "BRANDS_UPDATE": 235,
              "BRANDS_DELETE": 236,
              "CHANNELS_READ": 237,
              "CHANNELS_CREATE": 238,
              "CHANNELS_UPDATE": 239,
              "ONLINE_STORE_CUSTOM_THEME_READ": 240,
              "ONLINE_STORE_CUSTOM_THEME_WRITE": 241,
              "PERSON_CONNECT_API_ACCESS_MANAGE": 242,
              "PERSON_ALIAS_READ": 243,
              "ADDON_CONFIGURATIONS_READ": 244,
              "ADDON_CONFIGURATIONS_WRITE": 245,
              "RESOLUTIONS_READ": 246,
              "RESOLUTIONS_WRITE": 247,
              "PERMISSION_SETS_READ": 248,
              "PERMISSION_SETS_WRITE": 249,
              "TEAM_MEMBER_ONBOARDING_READ": 250,
              "TEAM_MEMBER_ONBOARDING_WRITE": 251,
              "INVENTORY_CYCLE_COUNT_LINES_WRITE": 252,
              "POS_MODE_WRITE": 253,
              "DEVICE_POS_MODE_WRITE": 254,
              "UNLINKED_REFUNDS_WRITE": 255,
              "CLOSED_SALES_READ": 256,
              "DISPUTES_RESPOND": 257
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.multipass?.nested?.RequestPermissionFlags) {
	$root.addJSON(jsonData);
}

/**
 * Permission flags for a user request. Each permission flag is binary, on or off, so we store them
 * in bitfields for compactness.  The value of each enum element represents the index at which that
 * permission is stored.  So READ is stored in the least significant bit (index 0), WRITE in the
 * next bit (index 1), etc.
 *
 * When these permissions are encoded into a byte array, the first byte contains the first 8
 * permissions, in little endian format. So READ is stored in the least significant bit of the first
 * byte, the 8th bit, WRITE in the 7th bit, and so on. The next 8 permissions are stored in the
 * next byte in a similar fashion.
 *
 * There are no gaps allowed in this enumeration. If a gap is created, converting from bit/byte
 * arrays to a list of permissions will cause problems. If one needs to be deprecated, all uses of
 * of the permission should be removed, then the permission renamed to DEPRECATED_XXX.
 *
 * IMPORTANT: please add new permissions at the bottom of the enum. Do not add them anywhere else in
 * the enum that would require existing flags' values to be incremented — this will break permissions
 * across Square.
 *
 * SOURCE RequestPermissionFlags @ squareup/multipass/permissions.proto at 31:1
 */
export const RequestPermissionFlags = $root.lookupEnum('squareup.multipass.RequestPermissionFlags').values;
