// TODO: use blade design tokens for animation speeds when they exist
import {
  CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION,
  CORE_ANIMATION_EXIT_TRANSITION_MODERATE_SPEED_DURATION,
} from '@market/market-theme/js/cjs/index.js';
import { Component, Host, Prop, Element, Listen, Method, Event, EventEmitter, h, Watch } from '@stencil/core';

import {
  DialogDismissedEvent,
  DialogLoadedEvent,
  DialogType,
  setupDialogCompactHandler,
  getDialogSelector,
} from '../../utils/dialog';
import { getNamespacedTagFor } from '../../utils/namespace';
import {
  createAndActivateFocusTrap,
  FocusTrap,
  FocusTrapActivateOptions,
  FocusTrapDeactivateOptions,
  FocusTrapOptions,
} from '../../utils/focus-trap';
import { classNames } from '../../utils/classnames';
import { TMarketHeaderNavigateEventDetail } from '../market-header/events';

/**
 * @slot - The main content of the blade. Recommended `<main>` tag.
 */
@Component({
  tag: 'market-blade',
  styleUrl: 'market-blade.css',
  shadow: true,
})
export class MarketBlade {
  @Element() el: HTMLMarketBladeElement;
  type: DialogType = 'blade';
  focusTrap: FocusTrap;

  /**
   * INTERNAL ONLY: Used in CSS to trigger start and stop animations
   */
  @Prop({ mutable: true, reflect: true }) hidden: boolean = false;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal
   */
  @Prop({ reflect: true, attribute: 'data-dialog-id' }) readonly dialogID: string;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal's place
   * in the stack
   */
  @Prop({ reflect: true, attribute: 'data-dialog-index' }) readonly index: number;

  /**
   * DEPRECATED: The duration for the blade enter/exit animations, set from design tokens
   */
  @Prop() readonly animationDuration: number = CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION;

  /**
   * The duration for the modal enter animation, set from design tokens
   */
  @Prop() readonly animationEnterDuration: number = CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION;

  /**
   * The duration for the modal exit animation, set from design tokens
   */
  @Prop() readonly animationExitDuration: number = CORE_ANIMATION_EXIT_TRANSITION_MODERATE_SPEED_DURATION;

  /**
   * Enforces focus trapping on the modal
   */
  @Prop({ mutable: true }) trapFocus: boolean = false;

  /* Used to skip the exit animation for <market-blade hidden> on load */
  skipAnimation: boolean = false;

  /**
   * Triggered when the blade finishes loading
   */
  @Event() marketDialogLoaded: EventEmitter<DialogLoadedEvent>;

  /**
   * Triggered when the blade is dismissed, handled by context manager
   */
  @Event() marketDialogDismissed: EventEmitter<DialogDismissedEvent>;

  /**
   * Triggered when the dialog is fully dismissed
   */
  @Event() marketDialogDidDismiss: EventEmitter<DialogDismissedEvent>;

  @Watch('hidden')
  reenableAnimation() {
    this.skipAnimation = false;
  }

  /**
   * Listen to the marketHeaderNavigate event emitted by a market-header child component
   * so we can emit a close event if needed
   */
  @Listen('marketHeaderNavigate')
  headerNavigateEventHandler(event: CustomEvent<TMarketHeaderNavigateEventDetail>) {
    const { detail, target } = event;
    // TODO: 'close' should probably come from an enum of some sort
    if (detail.action === 'close') {
      // only dismiss if this is the first ancestor dialog
      if ((target as HTMLElement).closest(getDialogSelector()) === this.el) {
        this.dismiss();
      }
    }
  }

  /**
   * Emits the dismiss event
   * The parent context will handle actually removing elements from the DOM,
   * All the blade needs to do it emit an event so actually closing it can be
   * some other elements problem
   */
  @Method()
  dismiss(dismissOptions?: Partial<DialogDismissedEvent>) {
    const { defaultPrevented } = this.marketDialogDismissed.emit({
      dialog: this.el,
      type: this.type,
      origin: dismissOptions?.origin || this.el,
    });

    if (!defaultPrevented) {
      this.hidden = true;

      /**
       * Emit a marketDialogDidDismiss event when modal gets fully dismissed (after animation).
       */
      setTimeout(() => {
        this.marketDialogDidDismiss.emit({
          dialog: this.el,
          type: this.type,
          origin: this.el,
        });
      }, this.animationExitDuration);
    }

    return Promise.resolve();
  }

  @Watch('trapFocus')
  onTrapFocusChanged(newValue: boolean, oldValue: boolean) {
    // only activate/deactivate when the `trapFocus` prop value changes
    if (newValue !== oldValue) {
      if (newValue) {
        this.activateFocusTrap();
      } else {
        this.deactivateFocusTrap();
      }
    }
  }

  /**
   * Activates the focus trap
   *
   * See [`focus-trap.ts`](../../utils/focus-trap.ts) for default options
   *
   * @param {Object} [options] [focus-trap create options](https://github.com/focus-trap/focus-trap#createoptions)
   * @param {Object} [activateOptions] set options for [onActivate, onPostActivate, and checkCanFocusTrap](https://github.com/focus-trap/focus-trap#trapactivate)
   */
  @Method()
  activateFocusTrap(options?: FocusTrapOptions, activateOptions?: FocusTrapActivateOptions) {
    if (this.focusTrap) {
      this.focusTrap.activate(activateOptions ?? {});
      if (!this.trapFocus) {
        this.trapFocus = true;
      }
    } else {
      this.focusTrap = createAndActivateFocusTrap({
        activateOptions,
        el: this.el,
        options,
      });
    }
    return Promise.resolve();
  }

  /**
   * Deactivates the focus trap
   *
   * @param {FocusTrapDeactivateOptions} [deactivateOptions] set options for [onDeactivate, onPostDeactivate, and checkCanReturnFocus](https://github.com/focus-trap/focus-trap#trapdeactivate)
   */
  @Method()
  deactivateFocusTrap(deactivateOptions?: FocusTrapDeactivateOptions) {
    if (this.focusTrap) {
      this.focusTrap.deactivate({
        returnFocus: true,
        checkCanReturnFocus: (trigger) =>
          new Promise((resolve) => {
            if (typeof (trigger as any)?.setFocus === 'function') {
              (trigger as any).setFocus();
            } else {
              resolve(); // node.focus(); will be called by focus-trap
            }
          }),
        ...deactivateOptions,
      });
      this.focusTrap = undefined;
    }
    return Promise.resolve();
  }

  /**
   * Emit a marketDialogLoaded event when the component connects.
   * Need this so the context manager isn't rummaging around it's DOM
   * to try and find the dialog that was just appended
   */
  connectedCallback() {
    setTimeout(() => {
      this.marketDialogLoaded.emit({
        dialog: this.el,
        type: this.type,
      });

      if (this.trapFocus) {
        this.activateFocusTrap();
      }
    }, this.animationEnterDuration);
  }

  componentWillLoad() {
    const header = this.el.querySelector<HTMLMarketHeaderElement>(getNamespacedTagFor('market-header'));
    if (header) {
      // We want to force the header to be navigable when slotted into blade
      header.showNavigation = true;
    }

    if (this.hidden) {
      this.skipAnimation = true;
    }

    setupDialogCompactHandler(this.el);
  }

  disconnectedCallback() {
    this.deactivateFocusTrap();
  }

  render() {
    return (
      <Host
        role="dialog"
        class={classNames('market-blade', {
          'skip-animation': this.skipAnimation,
        })}
      >
        <slot></slot>
      </Host>
    );
  }
}
