/**
 * An icon representing unread action.
 */
import React, { ReactElement } from 'react';

function UnreadIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" fill="none">
      <title>Unread</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M.813 6.906c1.88-4.06 5.68-6.58 9.91-6.58s8.03 2.52 9.91 6.58c.12.26.12.57 0 .84-1.88 4.06-5.68 6.58-9.91 6.58s-8.03-2.52-9.91-6.58c-.12-.26-.12-.57 0-.84Zm2.02.42c1.61 3.1 4.6 5 7.89 5s6.28-1.9 7.89-5c-1.61-3.1-4.6-5-7.89-5s-6.28 1.9-7.89 5Zm3.89 0c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4Zm2 0c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default UnreadIcon;
