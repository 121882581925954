import { reaction, makeAutoObservable } from 'mobx';
import NavigationStack from 'src/stores/navigation/NavigationStack';
import Logger from 'src/Logger';
import {
  MessengerMode,
  MessengerPageName,
  MessengerPage,
  isMessengerPage,
} from 'src/MessengerTypes';
import type MessengerController from 'src/MessengerController';

/**
 * Class that represents a series of navigation states.
 *
 * @template Section
 */
class NavigationStore<Section = never> {
  private _stores: MessengerController;

  _messengerMode: MessengerMode = 'CLOSED';

  _navigationStacks: Map<MessengerMode, NavigationStack> = new Map();

  /** The section we want to navigate to, if any */
  section?: Section;

  // 'OPEN' represents the default navigation stack
  // 'CLOSED' indicates that the navigation state is not open to any stack.
  // Custom modes are also supported by providing the modes array.
  constructor(stores: MessengerController, modes?: MessengerMode[]) {
    makeAutoObservable(this);

    this._stores = stores;
    // 'CLOSED' mode is explicitly not given a NavigationStack
    // so that all computed variables will default to null/false
    this._navigationStacks.set('OPEN', new NavigationStack());
    modes?.forEach((mode) => {
      this._navigationStacks.set(mode, new NavigationStack());
    });

    reaction(
      () => this.currentPageName,
      (page) => {
        switch (page) {
          case 'TRANSCRIPTS_LIST':
            this._stores.event.track('Open Conversations List');
            break;
          case 'TRANSCRIPT_VIEW':
            // tracking done in MessengerController.selectConversation()
            // because we want to track the conversationId as well.
            break;
          case 'NEW_MESSAGE':
            this._stores.event.track('Click New Message');
            break;
          default:
        }
      },
    );
  }

  /** The Stack associated to current mode, if any. 'CLOSED' mode will always return undefined. */
  get _currentNavigationStack(): NavigationStack | undefined {
    return this._navigationStacks.get(this._messengerMode);
  }

  /** The number of pages in the current stack. */
  get currentPageCount(): number {
    return this._currentNavigationStack
      ? this._currentNavigationStack.currentPageCount
      : 0;
  }

  /**
   * The current page of the current mode.
   * Returns null if mode is 'CLOSED', or there are no pages.
   */
  get currentPageName(): MessengerPageName | null {
    return this._currentNavigationStack?.currentPage
      ? this._currentNavigationStack.currentPage.name
      : null;
  }

  get currentPage(): MessengerPage | null {
    return this._currentNavigationStack?.currentPage ?? null;
  }

  /**
   * The previous page of the current mode.
   * Returns null if the mode is 'CLOSED' or there's no previous page.
   * Useful if page title depends on where we came from.
   */
  get previousPage(): MessengerPage | null {
    return this._currentNavigationStack?.previousPage
      ? this._currentNavigationStack.previousPage
      : null;
  }

  /**
   * True if there is at least 1 page in the current mode.
   * Returns false if mode is 'CLOSED', or there are no pages.
   */
  get hasPages(): boolean {
    return this.currentPageCount > 0;
  }

  /**
   * True if there is a page that can be navigated back to in the current mode.
   * Returns false if mode is 'CLOSED', or if the current page is the last page or null.
   */
  get canNavigateBack(): boolean {
    return this.currentPageCount > 1;
  }

  /**
   * Add a page to the Stack in the current mode.
   *
   * @param {MessengerPageName} page
   * @param {Section} [section]
   * (Optional) The section to navigate to, if any.
   */
  navigateTo = (
    page: MessengerPage | MessengerPageName,
    section?: Section,
  ): void => {
    if (this._currentNavigationStack == null) {
      Logger.warn(
        'Trying to navigate without a navigation stack. Is Messenger closed?',
      );
    } else {
      Logger.log(`Navigating to ${(page as MessengerPage)?.name || page}`);
      const messengerPage: MessengerPage = isMessengerPage(page)
        ? page
        : { name: page };
      this._currentNavigationStack.navigateTo(messengerPage);
      this.section = section;
    }
  };

  /**
   * Removes a page from the Stack in the current mode.
   * Closes the navigation if there are no pages remaining.
   */
  navigateBack: () => void = () => {
    if (this._currentNavigationStack) {
      this._currentNavigationStack.navigateBack();
      this.section = undefined;
      Logger.log(`Navigating to ${this.currentPageName}`);
      if (!this.hasPages) {
        this.close();
      }
    }
  };

  /** Removes all page history in the current mode. */
  clearNavigation: () => void = () => {
    if (this._currentNavigationStack) {
      this._currentNavigationStack.clearNavigation();
    }
    this.section = undefined;
  };

  /**
   * Clear the page history for all modes.
   */
  reset = (): void => {
    this._navigationStacks.forEach((navigationStack) => {
      navigationStack.clearNavigation();
    });
    this.section = undefined;
  };

  /**
   * Flag indicating whether the navigation store is in an open state.
   *
   * @returns {boolean}
   */
  get isOpen(): boolean {
    return this._messengerMode !== 'CLOSED';
  }

  /**
   * Method to open a given messenger mode.
   *
   * @param {MessengerMode} mode
   * The mode the NavigationStore should open to. Defaults to 'OPEN'.
   */
  open = (mode: MessengerMode = 'OPEN'): void => {
    this._messengerMode = mode;
  };

  /**
   * Method to open and clear the navigation stack for a given messenger mode.
   * Mobx reactions don't take effect until after the top most action has resolved,
   * thus this action is necessary to ensure the navigation is cleared after the mode has been opened.
   * Calling open() and clearNavigation() independently will result in a reaction occurring after
   * open() succeeds but before clearNavigation() occurs.
   *
   * @param {MessengerMode} mode
   * The mode the NavigationStore should open to. Defaults to 'OPEN'.
   */
  openAndClearNavigation = (mode: MessengerMode = 'OPEN'): void => {
    this.open(mode);
    this.clearNavigation();
  };

  /**
   * Method to close the NavigationStore.
   */
  close = (): void => {
    this._messengerMode = 'CLOSED';
    this.section = undefined;
  };
}

export default NavigationStore;
