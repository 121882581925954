import { TMarketDragCoords, TMouseOrTouchEvent } from './types';

/**
 * Check if the event is a TouchEvent
 * @param {TMouseOrTouchEvent} event
 */
export function isTouchEvent(event: TMouseOrTouchEvent): event is TouchEvent {
  return Boolean((event as TouchEvent)?.changedTouches);
}

// gets coords depending on touch vs mouse event
export function getCoordsFromEvent(e: TMouseOrTouchEvent): TMarketDragCoords {
  const x = isTouchEvent(e) ? e.changedTouches[0].clientX : e.clientX;
  const y = isTouchEvent(e) ? e.changedTouches[0].clientY : e.clientY;
  return { x, y };
}
