import { makeAutoObservable, runInAction } from 'mobx';
import type MessengerController from 'src/MessengerController';
import {
  Cursor,
  Bucket,
  ITranscript,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { LoadingStatus } from 'src/MessengerTypes';
import { compareTranscripts } from 'src/utils/transcriptUtils';

/**
 * Store containing the state related to a transcript list.
 * One store is instantiated per list of transcripts in the UI.
 * It's possible that multiple lists could contain the same transcript ID.
 * As of Dec 2024, there are three (active (Inbox) ,assistant, unread).
 */
class TranscriptsList {
  private _stores: MessengerController;

  /**
   * The bucket that this list is displaying (i.e. assistant transcripts, active transcripts, etc.).
   */
  readonly bucket?: Bucket;

  /**
   * Customer token to filter the results of the transcripts contained in the list by.
   */
  private _customerToken?: string;

  /**
   * The marker used to tell the server from which index to load further transcripts (i.e. the current position in the list).
   */
  private _cursor?: Cursor;

  /**
   * The initial loading status of the list. This is used to display a loading/error state
   * when the user tries to first load the transcript list.
   */
  status: LoadingStatus = 'NOT_STARTED';

  /**
   * The loading status associated with loading more. This is used to display a loading/error state
   * when the user tries to load more items into the existing list that is already shown.
   */
  loadMoreStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * Used to track the scroll positions of list pages so that we can return to the
   * same position between navigations. This will reset whenever the list changes, for example
   * when new utterances updates the conversations.
   */
  scrollPosition = 0;

  /**
   * The current list of transcript IDs to show the in the UI list for this bucket.
   */
  private _ids: number[] = [];

  constructor(
    stores: MessengerController,
    filters?: { bucket?: Bucket; customerToken?: string },
  ) {
    makeAutoObservable(this);

    this._stores = stores;
    this.bucket = filters?.bucket;
    this._customerToken = filters?.customerToken;
  }

  // WARNING: Feature flags may not have been loaded yet when this method is run
  init = async (): Promise<void> => {
    this.status = 'LOADING';
    try {
      await this._load();
      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  // Loads the list of transcripts for the corresponding bucket at the position of the current cursor.
  // Sets returned transcript objects in the Transcripts Store and updates the list with the new IDs.
  // WARNING: Feature flags may not have been loaded yet when this method is run
  _load = async (): Promise<void> => {
    const [transcripts, newCursor] =
      await this._stores.api.transcripts.getTranscripts({
        bucket: this.bucket,
        cursor: this._cursor,
        customerToken: this._customerToken,
      });
    this._cursor = newCursor;
    const newIds = transcripts.map((transcript: ITranscript) => {
      const id = transcript.id as number;
      if (
        !this._stores.transcripts.has(id) ||
        !this._stores.transcripts.get(id).hasData
      ) {
        // Avoid setting the utterances here to protect against an edge case where
        // empty utterances will overwrite the fetched utterances while contextual events are loading
        this._stores.transcripts.setWithoutUtterances(transcript);
      }
      return id;
    });
    this.push(...newIds);
  };

  loadNextPage = async (): Promise<void> => {
    this.loadMoreStatus = 'LOADING';
    try {
      await this._load();
      this.loadMoreStatus = 'SUCCESS';
    } catch {
      this.loadMoreStatus = 'ERROR';
    }
  };

  // Accepts `n` number of id arguments and adds them to the list.
  // De-dupes the list such that only unique values are stored.
  push = (...ids: number[]): void => {
    this._ids = [...new Set([...this._ids, ...ids])];
  };

  remove = (transcriptId: number): void => {
    this._ids = this._ids.filter((id) => id !== transcriptId);
  };

  // Generate the list of IDs sorted by recency
  get ids(): number[] {
    return [...this._ids].sort((a, b) => {
      const transcriptA = this._stores.transcripts.get(a);
      const transcriptB = this._stores.transcripts.get(b);
      return compareTranscripts(transcriptA, transcriptB);
    });
  }

  get size(): number {
    return this.ids.length;
  }

  get hasNextPage(): boolean {
    return Boolean(this._cursor);
  }

  has = (id: number): boolean => {
    return this._ids.includes(id);
  };

  clear = (): void => {
    this._ids = [];
    this._cursor = undefined;
    this.status = 'NOT_STARTED';
    this.loadMoreStatus = 'NOT_STARTED';
  };

  setScrollPosition = (scrollPosition: number): void => {
    this.scrollPosition = scrollPosition;
  };
}

export default TranscriptsList;
