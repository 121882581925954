import { Component, Host, h } from '@stencil/core';

@Component({
  tag: 'market-content-card',
  styleUrl: 'market-content-card.css',
  shadow: true,
})
export class MarketContentCard {
  render() {
    return (
      <Host class="market-content-card">
        <slot></slot>
      </Host>
    );
  }
}
