import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';
import { FeatureFlagValue, MediumFlags } from 'src/MessengerTypes';
import { when, makeAutoObservable, runInAction } from 'mobx';
import type MessengerController from 'src/MessengerController';
import type Api from 'src/api/Api';
import {
  FeatureRelayClient,
  Project,
  TokenType,
  AnyMockableFeatureFlagConfig,
  createBooleanFlag,
  createFeatureRelayClient,
  createJSONFlag,
  createIntegerFlag,
} from '@square/feature-relay-web-sdk';
import { getOrigin } from 'src/utils/url';

const DEFAULT_CHAR_LIMIT = 1000;

// ///////////////////////////////////////////////
// Permanent Flags                              //
// ///////////////////////////////////////////////

// These flags are expected to be around long-term and should not be cleaned up intermittently.
export const KEY_USE_MESSAGING = 'conversations-use-messaging';
export const KEY_ALLOW_DEV_TOOLS = 'messenger-allow-dev-tools';
export const KEY_ANNOUNCEMENT_BANNER = 'messenger-announcement-banner';
export const KEY_MESSAGES_PLUS = 'messenger-messages-plus';
export const KEY_GATE_MESSAGES_PLUS_ON_IDV = 'messenger-gate-subs-on-idv';
export const KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES =
  'messenger-attachments-max-chunk-size-bytes';
export const KEY_SMS_MAX_ATTACHMENT_SIZE_BYTES =
  'messenger-sms-max-attachment-size-bytes';
export const KEY_EMAIL_MAX_ATTACHMENT_SIZE_BYTES =
  'messenger-email-max-attachment-size-bytes';
export const KEY_SMS_MAX_ATTACHMENT_COUNT =
  'messenger-sms-max-attachment-count';
export const KEY_EMAIL_MAX_ATTACHMENT_COUNT =
  'messenger-email-max-attachment-count';
export const KEY_MAX_OUTBOUND_SMS_MESSAGE_LENGTH_CHARS =
  'messenger-max-outbound-sms-message-length-chars';
export const KEY_MAX_OUTBOUND_EMAIL_MESSAGE_LENGTH_CHARS =
  'messenger-max-outbound-email-message-length-chars';
export const KEY_EMAIL_FILES_MAX_TOTAL_ATTACHMENTS_SIZE_BYTES =
  'messenger-email-files-max-total-attachments-size-bytes';

// ///////////////////////////////////////////////
// Temporary Flags                              //
// ///////////////////////////////////////////////

// These flags are temporary and should be routinely cleaned up
export const KEY_MESSAGES_PLUGIN_CODE_SNIPPET =
  'messenger-messages-plugin-code-snippet';
export const KEY_REQUEST_GOOGLE_REVIEW = 'messenger-request-google-review';
export const KEY_EMAIL_FILES_ENABLED = 'messenger-email-files-enabled';
export const KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED =
  'messenger-sq-online-plugin-setting';
export const KEY_INVOICES_PLUGIN_ENABLED = 'invoices-enable-messages-plugin';
export const KEY_UNREAD = 'messenger-enable-unread-filter-web';
export const KEY_SQ_ONE_FEATURE_ENABLED =
  'messenger-mock-sq-one-feature-enabled';

export type FeatureFlagName =
  | typeof KEY_ALLOW_DEV_TOOLS
  | typeof KEY_USE_MESSAGING
  | typeof KEY_REQUEST_GOOGLE_REVIEW
  | typeof KEY_ANNOUNCEMENT_BANNER
  | typeof KEY_MESSAGES_PLUGIN_CODE_SNIPPET
  | typeof KEY_MESSAGES_PLUS
  | typeof KEY_GATE_MESSAGES_PLUS_ON_IDV
  | typeof KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES
  | typeof KEY_SMS_MAX_ATTACHMENT_SIZE_BYTES
  | typeof KEY_EMAIL_MAX_ATTACHMENT_SIZE_BYTES
  | typeof KEY_SMS_MAX_ATTACHMENT_COUNT
  | typeof KEY_EMAIL_MAX_ATTACHMENT_COUNT
  | typeof KEY_MAX_OUTBOUND_SMS_MESSAGE_LENGTH_CHARS
  | typeof KEY_MAX_OUTBOUND_EMAIL_MESSAGE_LENGTH_CHARS
  | typeof KEY_EMAIL_FILES_ENABLED
  | typeof KEY_EMAIL_FILES_MAX_TOTAL_ATTACHMENTS_SIZE_BYTES
  | typeof KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED
  | typeof KEY_INVOICES_PLUGIN_ENABLED
  | typeof KEY_UNREAD
  | typeof KEY_SQ_ONE_FEATURE_ENABLED;

/**
 * A collection of all the flags, useful to access the flag config with the key.
 * To get:
 * client.getFlagValue(FEATURE_RELAY_FLAGS[flagName]);
 *
 * To test:
 * FEATURE_RELAY_FLAGS[flagName].setMockValue(true);
 * FEATURE_RELAY_FLAGS[flagName].clearMockValue();
 */
export const FEATURE_RELAY_FLAGS: Record<string, AnyMockableFeatureFlagConfig> =
  {
    [KEY_USE_MESSAGING]: createBooleanFlag({
      key: KEY_USE_MESSAGING,
      defaultValue: true,
    }),
    [KEY_ALLOW_DEV_TOOLS]: createBooleanFlag({
      key: KEY_ALLOW_DEV_TOOLS,
      defaultValue: false,
    }),
    [KEY_REQUEST_GOOGLE_REVIEW]: createBooleanFlag({
      key: KEY_REQUEST_GOOGLE_REVIEW,
      defaultValue: false,
    }),
    [KEY_ANNOUNCEMENT_BANNER]: createJSONFlag({
      key: KEY_ANNOUNCEMENT_BANNER,
      defaultValue: {},
    }),
    [KEY_MESSAGES_PLUGIN_CODE_SNIPPET]: createBooleanFlag({
      key: KEY_MESSAGES_PLUGIN_CODE_SNIPPET,
      defaultValue: false,
    }),
    [KEY_MESSAGES_PLUS]: createBooleanFlag({
      key: KEY_MESSAGES_PLUS,
      defaultValue: false,
    }),
    [KEY_GATE_MESSAGES_PLUS_ON_IDV]: createBooleanFlag({
      key: KEY_GATE_MESSAGES_PLUS_ON_IDV,
      defaultValue: false,
    }),
    [KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES]: createIntegerFlag({
      key: KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES,
      defaultValue: 102400,
    }),
    [KEY_SMS_MAX_ATTACHMENT_SIZE_BYTES]: createIntegerFlag({
      key: KEY_SMS_MAX_ATTACHMENT_SIZE_BYTES,
      defaultValue: 102400,
    }),
    [KEY_EMAIL_MAX_ATTACHMENT_SIZE_BYTES]: createIntegerFlag({
      key: KEY_EMAIL_MAX_ATTACHMENT_SIZE_BYTES,
      defaultValue: 102400,
    }),
    [KEY_SMS_MAX_ATTACHMENT_COUNT]: createIntegerFlag({
      key: KEY_SMS_MAX_ATTACHMENT_COUNT,
      defaultValue: 3,
    }),
    [KEY_EMAIL_MAX_ATTACHMENT_COUNT]: createIntegerFlag({
      key: KEY_EMAIL_MAX_ATTACHMENT_COUNT,
      defaultValue: 3,
    }),
    [KEY_MAX_OUTBOUND_SMS_MESSAGE_LENGTH_CHARS]: createIntegerFlag({
      key: KEY_MAX_OUTBOUND_SMS_MESSAGE_LENGTH_CHARS,
      defaultValue: DEFAULT_CHAR_LIMIT,
    }),
    [KEY_MAX_OUTBOUND_EMAIL_MESSAGE_LENGTH_CHARS]: createIntegerFlag({
      key: KEY_MAX_OUTBOUND_EMAIL_MESSAGE_LENGTH_CHARS,
      defaultValue: DEFAULT_CHAR_LIMIT,
    }),
    [KEY_EMAIL_FILES_ENABLED]: createBooleanFlag({
      key: KEY_EMAIL_FILES_ENABLED,
      defaultValue: false,
    }),
    [KEY_EMAIL_FILES_MAX_TOTAL_ATTACHMENTS_SIZE_BYTES]: createIntegerFlag({
      key: KEY_EMAIL_FILES_MAX_TOTAL_ATTACHMENTS_SIZE_BYTES,
      defaultValue: 0,
    }),
    [KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED]: createBooleanFlag({
      key: KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED,
      defaultValue: false,
    }),
    [KEY_INVOICES_PLUGIN_ENABLED]: createBooleanFlag({
      key: KEY_INVOICES_PLUGIN_ENABLED,
      defaultValue: false,
    }),
    [KEY_UNREAD]: createBooleanFlag({
      key: KEY_UNREAD,
      defaultValue: false,
    }),
    [KEY_SQ_ONE_FEATURE_ENABLED]: createBooleanFlag({
      key: KEY_SQ_ONE_FEATURE_ENABLED,
      defaultValue: false,
    }),
  };

/**
 * Store that contains the feature flags and their values for the current merchant.
 *
 * NOTE: Our Launch Darkly feature flags are **named** like
 * <group>/<feature>, but their **keys** turn into <group>-<feature>.
 * So whenever a flag in the "/" format is requested, the "/"
 * will first be converted to a "-". i.e. conversations/use_messaging
 */
export default class FeatureFlagStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * True if the feature flags are completely initialized and we aren't
   * waiting on the current user div or any backend calls.
   * This is a convenience variable that's mostly nice for internal/testing
   * purposes.
   */
  isInitialized = false;

  /**
   * The feature-relay feature flag set, which retrieves flag directly from
   * LaunchedDarkly. This should be the default way to get flags.
   */
  _featureRelayFlags: FeatureRelayClient<typeof FEATURE_RELAY_FLAGS> | null =
    null;

  /**
   * Manual flag overrides we set in the constructor,
   * e.g. for mocking
   */
  _featureFlagOverrides: Record<string, FeatureFlagValue> = {};

  constructor(stores: MessengerController) {
    makeAutoObservable(this, {
      _featureRelayFlags: false,
      _featureFlagOverrides: false,
    });

    this._stores = stores;
    this._api = stores.api;

    when(() => this._stores.user.isInitialized)
      .then(() => {
        this._featureRelayFlags = createFeatureRelayClient({
          userToken: {
            token: this._stores.user.merchantToken,
            type: TokenType.MERCHANT,
          },
          userAttributes: [
            {
              name: 'country',
              string_value: this._stores.user.countryCode,
            },
            {
              name: 'platform',
              string_value: 'web',
            },
            {
              name: 'locale',
              string_value: this._stores.user.locale,
            },
          ],
          project: Project.PIE,
          flagConfigs: FEATURE_RELAY_FLAGS,
          origin: getOrigin(),
          offlineMode: Boolean(process.env.JEST_WORKER_ID),
        });

        return this._featureRelayFlags.loadFlags().catch((error) => {
          Logger.logWithSentry(
            'feature-relay failed to fetch feature flags',
            'error',
            error,
          );
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isInitialized = true;
        });
      });
  }

  /**
   * Sets overrides for the feature flag values. Commonly used for testing.
   *
   * @param {Record<string, FeatureFlagValue>} overrides
   * Mapping of feature flag keys to their corresponding value.
   */
  setOverrides = (overrides: Record<string, FeatureFlagValue>): void => {
    this._featureFlagOverrides = overrides;
  };

  /**
   * Retrieves the value of a feature flag.
   *
   * @param {string} flagName
   * the feature flag name, e.g. conversations/use_messaging
   * @returns {FeatureFlagValue}
   * returns the feature from LD or our manual overrides
   */
  get = (flagName: FeatureFlagName): FeatureFlagValue => {
    const override = this._featureFlagOverrides[flagName];
    if (override != null) {
      return override;
    }
    return this._featureValueInFeatureRelay(flagName);
  };

  /**
   * Get flags for a specific medium.
   *
   * @param {Medium} medium
   * The medium of the flags.
   * @returns {MediumFlags}
   * A set of medium related flags.
   */
  getMediumFlags = (medium: Medium): MediumFlags => {
    switch (medium) {
      case Medium.SMS:
        return {
          maxPhotosCount: this.get(KEY_SMS_MAX_ATTACHMENT_COUNT) as number,
          maxPhotoSizeBytes: this.get(
            KEY_SMS_MAX_ATTACHMENT_SIZE_BYTES,
          ) as number,
          attachmentMaxChunkSizeBytes: this.get(
            KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES,
          ) as number,
          maxOutboundLengthChars: this.get(
            KEY_MAX_OUTBOUND_SMS_MESSAGE_LENGTH_CHARS,
          ) as number,
          maxTotalFilesSizeBytes: 0,
        };
      case Medium.EMAIL:
        return {
          maxPhotosCount: this.get(KEY_EMAIL_MAX_ATTACHMENT_COUNT) as number,
          maxPhotoSizeBytes: this.get(
            KEY_EMAIL_MAX_ATTACHMENT_SIZE_BYTES,
          ) as number,
          attachmentMaxChunkSizeBytes: this.get(
            KEY_ATTACHMENTS_MAX_CHUNK_SIZE_BYTES,
          ) as number,
          maxOutboundLengthChars: this.get(
            KEY_MAX_OUTBOUND_EMAIL_MESSAGE_LENGTH_CHARS,
          ) as number,
          maxTotalFilesSizeBytes: this.get(
            KEY_EMAIL_FILES_MAX_TOTAL_ATTACHMENTS_SIZE_BYTES,
          ) as number,
        };
      default:
        return {
          maxPhotosCount: 0,
          maxPhotoSizeBytes: 0,
          attachmentMaxChunkSizeBytes: 0,
          maxOutboundLengthChars: DEFAULT_CHAR_LIMIT,
          maxTotalFilesSizeBytes: 0,
        };
    }
  };

  /**
   * Look up feature flag from feature-relay, which reads from LaunchDarkly.
   *
   * @param {string} flagName - the feature flag name, e.g.
   * conversations/use_messaging
   * @returns {FeatureFlagValue} - immediately returns
   * the feature flag value or a default
   */
  private _featureValueInFeatureRelay = (
    flagName: FeatureFlagName,
  ): FeatureFlagValue => {
    if (!this._featureRelayFlags || !this.isInitialized) {
      return FEATURE_RELAY_FLAGS[flagName]?.defaultValue ?? false;
    }

    return this._featureRelayFlags.getFlagValue(FEATURE_RELAY_FLAGS[flagName]);
  };
}
