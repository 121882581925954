import { Component, Host, h, Prop, Element, Listen, Watch, EventEmitter, Event, Method } from '@stencil/core';
import { getNamespacedTagFor } from '../../../utils/namespace';
import { TMarketTableV2Selection, MarketTableV2SelectionChangeEventDetail } from '../market-table-v2/types';
import { Draggable } from '../../../utils/draggable';
import type { TMarketDragCoords } from '../../../utils/gesture/types';

/**
 * @slot - Default slot for table cells.
 * @part drag-handle - the drag handle when `dragEnabled` is true.
 */

@Component({
  tag: 'market-table-v2-row',
  styleUrl: 'market-table-v2-row.css',
  shadow: true,
})
export class MarketTableV2Row {
  private firstCell: HTMLMarketTableV2CellElement;
  private drag: Draggable;

  @Element() el: HTMLMarketTableV2RowElement;

  /**
   * Whether the row is currently active.
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * Sets the horizontal alignment.
   * When not set, alignment is inherited from an ancestor table.
   * Likewise, row alignment will be inherited by descendant cells.
   */
  @Prop({ reflect: true }) readonly align: 'left' | 'center' | 'right';

  /**
   * Displays a leading clickable caret in the first cell;
   * intended to be used in conjunction with
   * `<market-table-v2-group>` to support nested rows.
   */
  @Prop({ reflect: true }) readonly caret: 'up' | 'down';

  /**
   * Whether the row is currently disabled.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether the row is drag & drop enabled.
   */
  @Prop({ reflect: true }) readonly dragEnabled: boolean = false;

  /**
   * Whether the drag handle appears always or only on hover
   */
  @Prop({ reflect: true }) readonly dragHandleVisibility: 'always' | 'hover';

  /**
   * Whether the drag handle appears to the left or right.
   */
  @Prop({ reflect: true }) readonly dragHandlePosition: 'leading' | 'trailing';

  /**
   * Styles a row with footer styles.
   */
  @Prop({ reflect: true }) readonly footer: boolean = false;

  /**
   * Styles a row with header styles.
   */
  @Prop({ reflect: true }) readonly header: boolean = false;

  /**
   * Indentation level of the first cell in the row.
   */
  @Prop({ reflect: true }) readonly indent: number;

  /**
   * Whether the row is interactive, which results in hover, focus, & pressed styles.
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Whether the row is selected.
   * Relevant if the row's first cell has a slotted control.
   */
  @Prop({ mutable: true }) selected: TMarketTableV2Selection = 'false';

  /**
   * Makes a row "stick" to the top or bottom of its parent table.
   * Requires an explict height on the table to enable vertical scrolling.
   */
  @Prop({ reflect: true }) readonly sticky: 'top' | 'bottom';

  /**
   * Sets the vertical alignment.
   * When not set, alignment is inherited from an ancestor table.
   * Likewise, row alignment will be inherited by descendant cells.
   */
  @Prop({ reflect: true }) readonly valign: 'bottom' | 'middle' | 'top';

  /**
   * @internal
   * Fired when the row selection state changes. Used internally in table components.
   */
  @Event({ bubbles: true, composed: true })
  marketInternalTableV2RowSelectionChange: EventEmitter<MarketTableV2SelectionChangeEventDetail>;

  /**
   * Fired when the row selection state changes. Used to externally signal selection changes.
   */
  @Event({ bubbles: true, composed: true })
  marketTableV2RowSelectionChange: EventEmitter<MarketTableV2SelectionChangeEventDetail>;

  @Listen('keydown')
  onKeydown(e: KeyboardEvent) {
    const { target, key } = e;
    const { el, disabled, interactive } = this;
    if (disabled) return;
    if (!interactive) return;
    if (target !== el) return;
    if (key === 'Enter' || key === ' ') {
      e.preventDefault();
      el.click();
    }
  }

  @Listen('marketInternalTableV2CellSelectionChange')
  async onMarketInternalTableV2CellSelectionChange(e: CustomEvent<MarketTableV2SelectionChangeEventDetail>) {
    const { detail } = e;
    const { current } = detail;
    e.stopPropagation();
    await this.setSelected(current);
  }

  @Listen('marketDragHandleDragStart')
  async onDragStart(e: CustomEvent<TMarketDragCoords>) {
    const { el, dragHandlePosition } = this;
    if (el.slot === 'parent') return;
    e.stopPropagation();
    const coords: TMarketDragCoords = e.detail;
    const anchor = dragHandlePosition === 'leading' ? 'left' : 'right';
    const drag = new Draggable(el, { anchor });
    this.drag = drag;
    await drag.start(coords);
  }

  @Listen('marketDragHandleDragMove')
  onDragMove(e: CustomEvent<TMarketDragCoords>) {
    if (this.el.slot === 'parent') return;
    e.stopPropagation();
    const coords: TMarketDragCoords = e.detail;
    this.drag.move(coords);
  }

  @Listen('marketDragHandleDragEnd')
  async onDragEnd(e: CustomEvent<TMarketDragCoords>) {
    if (this.el.slot === 'parent') return;
    e.stopPropagation();
    const coords: TMarketDragCoords = e.detail;
    await this.drag.end(coords);
    this.drag.destroy();
  }

  @Watch('caret')
  watchCaret() {
    const { firstCell, caret } = this;
    if (firstCell) firstCell.caret = caret;
  }

  @Watch('indent')
  watchIndent() {
    const { firstCell, indent } = this;
    if (firstCell) firstCell.indent = indent;
  }

  /**
   * Sets selection on the row and propagates the value
   * downwards to the slotted control in its first cell
   * and upwards to any parent groups or tables.
   */
  @Method()
  async setSelected(selected: TMarketTableV2Selection, { silent = false } = {}) {
    const {
      firstCell,
      selected: prevSelected,
      marketTableV2RowSelectionChange,
      marketInternalTableV2RowSelectionChange,
    } = this;

    // return if no values have changed
    if (prevSelected === selected) return Promise.resolve();

    // always fire the external selection event
    const { defaultPrevented } = marketTableV2RowSelectionChange.emit({
      current: selected,
      previous: prevSelected,
    });

    // if default was prevented, reset the cell & control
    if (defaultPrevented) {
      await firstCell?.setSelected(prevSelected, { silent: true });
      return Promise.resolve();
    }

    // fire the internal selection event
    if (!silent) {
      marketInternalTableV2RowSelectionChange.emit({
        current: selected,
        previous: prevSelected,
      });
    }

    // save the state
    this.selected = selected;
    await firstCell?.setSelected(selected, { silent });

    return Promise.resolve();
  }

  private getTabIndex() {
    const { disabled, interactive } = this;
    return interactive && !disabled ? '0' : null;
  }

  private async initFirstCell() {
    const { el, selected } = this;

    const MarketTableV2CellTagName = getNamespacedTagFor('market-table-v2-cell');
    const firstCell = el.querySelector(`${MarketTableV2CellTagName}`);

    if (firstCell) {
      this.firstCell = firstCell;
      if (selected) await firstCell?.setSelected(selected);
    }

    this.watchCaret();
    this.watchIndent();
  }

  async connectedCallBack() {
    await this.initFirstCell();
  }

  private renderDragHandleCell() {
    const MarketDragHandleTagName = getNamespacedTagFor('market-drag-handle');
    const MarketTableV2CellTagName = getNamespacedTagFor('market-table-v2-cell');

    return (
      <MarketTableV2CellTagName class="drag-handle-cell">
        <MarketDragHandleTagName part="drag-handle"></MarketDragHandleTagName>
      </MarketTableV2CellTagName>
    );
  }

  render() {
    const { dragEnabled, dragHandlePosition } = this;

    return (
      <Host role="row" class="market-table-v2-row" tabindex={this.getTabIndex()}>
        {dragEnabled && dragHandlePosition === 'leading' && this.renderDragHandleCell()}
        <slot onSlotchange={() => this.initFirstCell()}></slot>
        {dragEnabled && dragHandlePosition !== 'leading' && this.renderDragHandleCell()}
      </Host>
    );
  }
}
