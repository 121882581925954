/* TODO: add design tokens for this component */

@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/field.css";
@import "../../styles/mixins/field-states.css";

:host {
  @extend %field;
  @extend %field-states;

  display: flex;
  align-items: center;
  height: var(--input-code-height, 64px);

  input {
    font-weight: var(--input-code-font-weight, var(--core-type-bold-weight));
    font-family: var(--core-type-mono-font-family, monospace);
    text-align: center;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 calc(var(--input-code-height, 64px) / 2) var(--field-normal-state-background-color) inset;
  }
}

.code-input-container {
  display: flex; /* container is already displaying children using flexbox */
  justify-content: center;
  width: 100%;
  height: 100%;

  /* add pseudoelements before/after the input elements */
  &::before,
  &::after {
    content: "";
    flex-basis: 100%;
    pointer-events: none;
  }
}

::slotted([slot="trailing-accessory"]) {
  margin-right: var(--input-code-trailing-accessory-spacing, 12px);
}

input::placeholder {
  color: var(--input-code-placeholder-color, var(--core-fill-30-color));
  font-size: var(--input-code-placeholder-font-size, 24px);
}
