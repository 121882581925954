import { Component, Host, h, Prop } from '@stencil/core';
import { getSuccessIcon, getInfoIcon, getWarningIcon, getCriticalIcon } from './icons';

/**
 * @slot - The text for market-inline-status
 * @slot icon - Optional, for use with a custom icon
 */
@Component({
  tag: 'market-inline-status',
  styleUrl: 'market-inline-status.css',
  shadow: true,
})
export class MarketInlineStatus {
  @Prop({ reflect: true }) readonly variant: 'info' | 'success' | 'warning' | 'critical' = 'info';

  render() {
    return (
      <Host class="market-inline-status">
        <slot name="icon">
          {this.variant === 'info' && getInfoIcon()}
          {this.variant === 'success' && getSuccessIcon()}
          {this.variant === 'warning' && getWarningIcon()}
          {this.variant === 'critical' && getCriticalIcon()}
        </slot>
        <slot></slot>
      </Host>
    );
  }
}
