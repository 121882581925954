import { Component, Element, h, Host, Prop, Listen, Watch } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';
import { TMarketTabSelectedChangedEventDetail } from './events';

@Component({
  tag: 'market-tabs',
  styleUrl: 'market-tabs.css',
  shadow: true,
})
export class MarketTabs {
  @Element() el: HTMLMarketTabsElement;
  tabListEl?: HTMLMarketTabListElement;
  panelEls?: HTMLMarketTabPanelElement[];

  /**
   * String for the selected tab (i.e. `market-tab`'s `id` attribute)
   *
   * Omitting or setting to empty string will default to the first non-disabled tab
   *
   * @default undefined
   */
  @Prop({ mutable: true, reflect: true }) selectedTab?: string;

  /**
   * String for the default selected tab (i.e. `market-tab`'s `id` attribute)
   *
   * Only used when the component initially loads
   *
   * @default undefined
   */
  @Prop() readonly defaultTab?: string;

  @Listen('marketTabSelectedChanged')
  marketTabSelectedChangedEventHandler(e: CustomEvent<TMarketTabSelectedChangedEventDetail>) {
    e.stopPropagation();
    const { panelId, tabId, value } = e.detail;
    if (!value) {
      return;
    } else if (this.selectedTab !== tabId) {
      this.selectedTab = tabId;
    }
    this.showPanelWithId(panelId);
  }

  @Watch('selectedTab')
  tabWatcher(newTabId: string) {
    this.setTab(newTabId);
  }

  setTab(tabId: string) {
    if (this.tabListEl) {
      this.tabListEl.selectedTab = tabId;
    }
  }

  showPanelWithId(panelId: string) {
    this.panelEls?.forEach((panelEl) => {
      panelEl.hidden = panelEl.id !== panelId;
    });
  }

  handleSlotChange() {
    /**
     * `market-tab-panel`s aren't hidden by default,
     * but once they become a descendant of `<market-tabs>`, they will be hidden by default.
     * Later on, one will be shown depending on which tab gets selected by default.
     */
    this.panelEls = [...this.el.querySelectorAll(getNamespacedTagFor('market-tab-panel'))].map((panelEl) => {
      panelEl.hidden = true;
      return panelEl;
    });
    this.tabListEl = this.el.querySelector(getNamespacedTagFor('market-tab-list'));
  }

  componentWillLoad() {
    this.handleSlotChange();
    this.setTab(this.selectedTab ?? this.defaultTab);
  }

  render() {
    return (
      <Host class="market-tabs">
        <slot onSlotchange={() => this.handleSlotChange()}></slot>
      </Host>
    );
  }
}
