import { Component, Prop, Element, Event, EventEmitter, Host, h, Method, Watch } from '@stencil/core';
import { JSXBase } from '@stencil/core/internal';

import {
  TMarketInputSearchFocusEventDetail,
  TMarketInputSearchValueChangeEventDetail,
  TMarketInternalInputSearchCompactAnimationEventDetail,
} from './events';
import { autocompleteWatcher } from '../../utils/autocomplete';
import { classNames } from '../../utils/classnames';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot input - Can be used to slot your own HTML input, if needed (ex. if supporting browser
 * autofill)
 * @part native-input - The default inner HTML input.
 */
@Component({
  tag: 'market-input-search',
  shadow: true,
  styleUrl: 'market-input-search.css',
})
export class InputText {
  @Element() el: HTMLMarketInputSearchElement;
  private nativeInputEl?: HTMLInputElement;
  private slottedInputEl?: HTMLInputElement;

  /**
   * A string specifying a value for the input;
   * this will be visually shown on the input and can be edited by the user.
   */
  @Prop({ mutable: true, reflect: true }) value: string = '';

  /**
   * A string specifying the placeholder of the input;
   * this is shown before a user attempts to add a value, given no value is already provided.
   */
  @Prop() readonly placeholder: string = '';

  /**
   * A number specifying the maximum length of characters for the input value
   */
  @Prop() readonly maxlength: number;

  /**
   * A string specifying the size of the input
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' = 'medium';

  /**
   * @deprecated
   * **DEPRECATED (v4.5.0)** Use `size` instead.
   *
   * A string specifying the size of the input
   */
  @Prop({ reflect: true }) readonly variant: 'small' | 'medium' = 'medium';

  /**
   * A boolean representing whether the input is disabled or not;
   * this visually and functionally will disable the input.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * A boolean representing whether the input is focused or not
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * A boolean representing whether the input should focus on page load
   */
  @Prop() readonly autofocus: boolean = false;

  /**
   * Whether or not this input should allow autocompletion by the browser;
   * accepts a boolean, or `"true"`, `"false"`, `"on"`, `"off"` or an
   * [accepted string value for the autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete).
   *
   * Note (source: [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)):
   * In order to provide autocompletion, user-agents might require an input to have a:
   * 1. Have a `name` and/or `id` attribute;
   * 2. Be descendants of a `<form>` element;
   * 3. The form to have a submit button
   */
  @Prop() readonly autocomplete: string | boolean = true;

  /**
   * A string specifying a name for the search input
   */
  @Prop() readonly name: string;

  /**
   * A string representing the input's aria-label; localize as needed
   */
  @Prop() readonly inputAriaLabel: string = 'Search';

  /**
   * A string representing the clear button's aria-label; localize as needed
   */
  @Prop() readonly clearButtonAriaLabel: string = 'Clear';

  /**
   * A string representing the search icon button's aria-label; localize as needed
   */
  @Prop() readonly searchIconButtonAriaLabel: string = 'Search icon';

  /**
   * **INTERNAL [do not use directly]**
   *
   * Used by `market-filter-group` when setting this component to compact mode
   */
  @Prop() readonly compact: boolean = false;

  /**
   * Emitted when input is cleared by clicking the clear button
   */
  @Event() marketInputSearchCleared: EventEmitter;

  /**
   * Emitted whenever the input value changes
   */
  @Event() marketInputSearchValueChange: EventEmitter<TMarketInputSearchValueChangeEventDetail>;

  /**
   * Emitted when the inner `<input>` element is focused or blurred
   */
  @Event() marketInputSearchFocus: EventEmitter<TMarketInputSearchFocusEventDetail>;

  /**
   * **INTERNAL [do not use directly]**
   *
   * Emitted when the compact animation has started or ended
   */
  @Event()
  marketInternalInputSearchCompactAnimation: EventEmitter<TMarketInternalInputSearchCompactAnimationEventDetail>;

  /**
   * Emitted when the component has loaded
   */
  @Event() marketInputSearchDidLoad: EventEmitter;

  /**
   * Properties to set on inner default or slotted <input> elements
   */
  private sharedProps: Partial<JSXBase.InputHTMLAttributes<HTMLInputElement>>;

  /**
   * What will actually get bound to the <input> element
   */
  private _autocomplete: string;

  /**
   * Prevents blurring when the clear button is clicked
   */
  private clearButtonClicked: boolean;

  /**
   * This toggles focus on the inner `<input>`.
   * When input is about to receive focus, force a `tabindex="-1"` on the `<Host>`.
   * Since the focus is already on the inner `<input>`, tabbing into `<Host>` is redundant.
   * When the input loses is focus, the previous `tabindex` value,
   * presumably assigned by the consumer, is assigned back.
   */
  @Watch('focused')
  focusedWatcher(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) {
      return;
    }
    if (newValue) {
      this.nativeInputEl.focus();
    } else {
      this.nativeInputEl.blur();
    }
  }

  @Watch('disabled')
  disabledWatcher(newValue: boolean) {
    // if this component is disabled but focused, make sure to remove focus
    if (newValue && this.focused) {
      this.setFocus(false);
    }
  }

  @Watch('autocomplete')
  autocompleteWatcher(newValue: string | boolean) {
    this._autocomplete = autocompleteWatcher(newValue);
  }

  /**
   * Sets focus styling on `<market-input-search>`;
   * toggles focus on the native `<input>` depending on the value passed
   * @param value new `focused` value
   */
  @Method()
  setFocus(value: boolean = true) {
    // don't do anything when: disabled; or it's already focused/blurred
    if ((this.disabled && value) || this.focused === value) {
      return Promise.resolve();
    }
    const { defaultPrevented } = this.marketInputSearchFocus.emit(value);
    if (!defaultPrevented) {
      // this will cause the `focusedChangeHandler` to be triggered
      this.focused = value;
    }
    return Promise.resolve();
  }

  /**
   * Clears the current input value.
   */
  @Method()
  clearInput(): Promise<void> {
    const clearedEvent = this.marketInputSearchCleared.emit();
    if (clearedEvent.defaultPrevented || this.value === '') {
      // if the value is already '', no need to emit the value change event below
      return Promise.resolve();
    }
    const valueChangeEvent = this.marketInputSearchValueChange.emit({
      current: '',
      prevValue: this.value,
      originalEvent: null,
      value: '',
    });
    if (!valueChangeEvent.defaultPrevented) {
      this.value = '';
    }
    return Promise.resolve();
  }

  /**
   * When the clear (X) button is clicked
   */
  async handleClearButtonClicked() {
    await this.clearInput();
    this.clearButtonClicked = true;
  }

  /**
   * Handle value change from an <input> event
   */
  handleValueChange(e: Event) {
    const target = e.target as HTMLInputElement;
    if (!target) {
      return;
    }
    const { defaultPrevented } = this.marketInputSearchValueChange.emit({
      current: target.value,
      prevValue: this.value,
      originalEvent: e,
      value: target.value,
    });
    if (defaultPrevented) {
      e.preventDefault();
      return;
    }
    this.value = target.value;
  }

  /**
   * Handles `.input-container` animation changes
   */
  handleAnimation(e: AnimationEvent) {
    if (!this.compact) {
      return;
    }
    if (e.animationName === 'market-input-search-compact-enter' && e.type === 'animationstart' && this.focused) {
      this.marketInternalInputSearchCompactAnimation.emit(e.type);
      // re-focus because `this.focused` prop change happens first before this animation even trigger
      window.requestAnimationFrame(() => {
        this.nativeInputEl?.focus();
      });
    } else if (e.animationName === 'market-input-search-compact-exit' && e.type === 'animationend') {
      this.marketInternalInputSearchCompactAnimation.emit(e.type);
    }
  }

  handleAccessoryClicked(e: MouseEvent, isBackIcon: boolean) {
    if (isBackIcon) {
      e.stopPropagation();
      // back button should be displayed, so unfocus
      this.setFocus(false);
    }
  }

  /**
   * Allows passing an alternative light DOM input.
   */
  registerSlottedInput(slottedInput?: HTMLInputElement) {
    this.slottedInputEl =
      slottedInput ||
      // input slotted into market-input-search
      this.el.querySelector('input[slot=input]') ||
      // input slotted into a higher-level component that uses market-input-search
      // (e.g. market-input-password)
      (this.el.getRootNode() as ShadowRoot).host?.querySelector('input[slot=input]');
    if (this.slottedInputEl) {
      this.slottedInputEl.addEventListener('input', (e) => this.handleValueChange(e));
      this.slottedInputEl.addEventListener('focus', () => this.setFocus());
      this.slottedInputEl.addEventListener('blur', () => this.setFocus(false));
      this.nativeInputEl = this.slottedInputEl;
    }
  }

  /**
   * TODO: This should be a common util. -antonn
   */
  updateSharedInputProps() {
    const prevSharedProps = { ...this.sharedProps };

    // used by the default shadow DOM native input and to copy component properties to slotted inputs
    // conditionally adding key/value pairs based on whether we want to set them on the <input>
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#individual_attributes
    this.sharedProps = {
      ...(this._autocomplete && { autocomplete: this._autocomplete }),
      ...(this.autofocus && { autofocus: this.autofocus }),
      ...(this.disabled && { disabled: this.disabled }),
      ...(this.maxlength >= 0 && { maxlength: this.maxlength }),
      ...(this.name && { name: this.name }),
      ...(this.placeholder && { placeholder: this.placeholder }),
      ...(this.value !== undefined && { value: this.value }),
    };

    // sync component props to slotted input, if one exists
    if (this.slottedInputEl) {
      const modifiedPropKeys = [...new Set([...Object.keys(prevSharedProps), ...Object.keys(this.sharedProps)])];
      modifiedPropKeys.forEach((key: keyof typeof this.sharedProps) => {
        if (!(key in this.sharedProps)) {
          // remove properties that have been unset
          this.slottedInputEl.removeAttribute(key);
        } else {
          /**
           * Boolean attributes can be set using empty strings
           * https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute#javascript
           *
           * But for setting properties like `value` (currently the only known one), directly modify the value instead
           * https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute#gecko_notes
           */
          const attributeValue = this.sharedProps[key] !== true ? this.sharedProps[key] : '';
          if (key === 'value') {
            this.slottedInputEl.value = attributeValue;
          } else if (attributeValue === false) {
            this.slottedInputEl.removeAttribute(key);
          } else {
            this.slottedInputEl.setAttribute(key, attributeValue);
          }
        }
      });
    }
  }

  componentWillLoad() {
    this.el.classList.add('preload'); // disables transitions on page load
    this.autocompleteWatcher(this.autocomplete);
    this.registerSlottedInput();
    this.updateSharedInputProps();
  }

  componentWillUpdate() {
    this.updateSharedInputProps();
  }

  componentDidLoad() {
    this.el.classList.remove('preload');
    this.marketInputSearchDidLoad.emit();
  }

  render() {
    const isBackIcon = this.compact && this.focused;

    // remove tabindex from host if inner <input> is already focused
    const tabindex = this.el.querySelector('input:focus') ? -1 : undefined;

    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');

    return (
      <Host
        class="market-input-search"
        onAnimationEnd={(e: AnimationEvent) => this.handleAnimation(e)}
        onAnimationStart={(e: AnimationEvent) => this.handleAnimation(e)}
        onBlur={() => {
          if (this.clearButtonClicked) {
            this.nativeInputEl?.focus();
            this.clearButtonClicked = false;
          } else {
            this.setFocus(false);
          }
        }}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          this.setFocus();
        }}
        onFocus={() => this.setFocus()}
        tabindex={tabindex}
      >
        <button
          class={classNames('leading-accessory', {
            'is-back-icon': isBackIcon,
          })}
          aria-label={this.searchIconButtonAriaLabel}
          onClick={(e: MouseEvent) => this.handleAccessoryClicked(e, isBackIcon)}
          tabindex="-1"
        >
          <slot name="leading-accessory">
            <MarketAccessoryTagName size="icon" tabindex="-1">
              {isBackIcon ? (
                // back icon
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.292894 7.29285C-0.0976308 7.68337 -0.0976307 8.31654 0.292894 8.70706L7.29289 15.7071L8.70711 14.2928L3.41421 8.99995L15 8.99995L15 6.99995L3.41421 6.99995L8.70711 1.70706L7.29289 0.292846L0.292894 7.29285Z"
                  />
                </svg>
              ) : (
                // search icon
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.49999 14.4998C9.06999 14.4998 10.52 13.9698 11.68 13.0998L15.79 17.2098L17.2 15.7998L13.09 11.6898C13.97 10.5198 14.49 9.07983 14.49 7.50983C14.49 3.64983 11.35 0.509827 7.48999 0.509827C3.62999 0.509827 0.48999 3.64983 0.48999 7.50983C0.48999 11.3698 3.63999 14.4998 7.49999 14.4998ZM7.49999 2.49983C10.26 2.49983 12.5 4.73983 12.5 7.49983C12.5 10.2598 10.26 12.4998 7.49999 12.4998C4.73999 12.4998 2.49999 10.2598 2.49999 7.49983C2.49999 4.73983 4.73999 2.49983 7.49999 2.49983Z" />
                </svg>
              )}
            </MarketAccessoryTagName>
          </slot>
        </button>
        <div class="input-container">
          <slot name="input" onSlotchange={() => this.registerSlottedInput()}>
            {!this.slottedInputEl && (
              <input
                aria-label={this.inputAriaLabel}
                onInput={(e) => this.handleValueChange(e)}
                part="native-input"
                ref={(input) => (this.nativeInputEl = input)}
                type="text"
                {...this.sharedProps}
              />
            )}
          </slot>
        </div>
        <slot name="trailing-accessory" />
        <button
          aria-label={this.clearButtonAriaLabel}
          class={classNames('clear-button', { hidden: !this.value || this.disabled })}
          onClick={this.handleClearButtonClicked.bind(this)}
          tabindex="-1"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.71004 13.71L7.00004 8.41004L12.29 13.71L13.71 12.29L8.41004 7.00004L13.71 1.71004L12.29 0.290039L7.00004 5.59004L1.71004 0.290039L0.290039 1.71004L5.59004 7.00004L0.290039 12.29L1.71004 13.71Z" />
          </svg>
        </button>
      </Host>
    );
  }
}
