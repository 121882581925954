@import "../../styles/mixins/component-base.css";

:host {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--pill-normal-size-corner-radius);
  white-space: nowrap;

  svg,
  ::slotted([slot="icon"]) {
    color: inherit;
    fill: currentcolor;
    stroke: inherit;
  }

  &([interactive]) {
    cursor: pointer;
  }

  &([size="medium"]) {
    gap: var(--pill-normal-size-spacing-horizontal);
    padding: var(--pill-normal-size-padding-vertical-size) var(--pill-normal-size-padding-horizontal-size);
    font-weight: var(--pill-normal-size-text-weight);
    font-size: var(--pill-normal-size-text-size);
    line-height: var(--pill-normal-size-text-leading);
    letter-spacing: var(--pill-normal-size-text-tracking);
  }

  &([size="small"]) {
    gap: var(--pill-compact-size-spacing-horizontal);
    padding: var(--pill-compact-size-padding-vertical-size) var(--pill-compact-size-padding-horizontal-size);
    font-weight: var(--pill-compact-size-text-weight);
    font-size: var(--pill-compact-size-text-size);
    line-height: var(--pill-compact-size-text-leading);
    letter-spacing: var(--pill-compact-size-text-tracking);
  }
}

/* default states */

:host([variant="normal"]) {
  background-color: var(--pill-normal-variant-background-color);
  color: var(--pill-normal-variant-text-color);
}

:host([variant="emphasis"]) {
  background-color: var(--pill-emphasis-variant-background-color);
  color: var(--pill-emphasis-variant-text-color);
}

:host([variant="success"]) {
  background-color: var(--pill-success-variant-background-color);
  color: var(--pill-success-variant-text-color);
}

:host([variant="warning"]) {
  background-color: var(--pill-warning-variant-background-color);
  color: var(--pill-warning-variant-text-color);
}

:host([variant="critical"]) {
  background-color: var(--pill-critical-variant-background-color);
  color: var(--pill-critical-variant-text-color);
}

:host([variant="insight"]) {
  background-color: var(--pill-insight-variant-background-color);
  color: var(--pill-insight-variant-text-color);
}

:host([variant="alpha"]) {
  background-color: var(--pill-alpha-variant-background-color);
  color: var(--pill-alpha-variant-text-color);
}

:host([variant="beta"]) {
  background-color: var(--pill-beta-variant-background-color);
  color: var(--pill-beta-variant-text-color);
}

/* hover state */

@media (hover: hover) {
  :host([variant="normal"][interactive]:hover) {
    background-color: var(--pill-normal-variant-pressed-state-background-color);
  }

  :host([variant="emphasis"][interactive]:hover) {
    background-color: var(--pill-emphasis-variant-pressed-state-background-color);
  }

  :host([variant="success"][interactive]:hover) {
    background-color: var(--pill-success-variant-pressed-state-background-color);
  }

  :host([variant="warning"][interactive]:hover) {
    background-color: var(--pill-warning-variant-pressed-state-background-color);
  }

  :host([variant="critical"][interactive]:hover) {
    background-color: var(--pill-critical-variant-pressed-state-background-color);
  }

  :host([variant="insight"][interactive]:hover) {
    background-color: var(--pill-insight-variant-pressed-state-background-color);
  }

  :host([variant="alpha"][interactive]:hover) {
    background-color: var(--pill-alpha-variant-pressed-state-background-color);
  }

  :host([variant="beta"][interactive]:hover) {
    background-color: var(--pill-beta-variant-pressed-state-background-color);
  }
}

/* active state */

:host([variant="normal"][interactive]:active) {
  background-color: var(--pill-normal-variant-pressed-state-background-color);
}

:host([variant="emphasis"][interactive]:active) {
  background-color: var(--pill-emphasis-variant-pressed-state-background-color);
}

:host([variant="success"][interactive]:active) {
  background-color: var(--pill-success-variant-pressed-state-background-color);
}

:host([variant="warning"][interactive]:active) {
  background-color: var(--pill-warning-variant-pressed-state-background-color);
}

:host([variant="critical"][interactive]:active) {
  background-color: var(--pill-critical-variant-pressed-state-background-color);
}

:host([variant="insight"][interactive]:active) {
  background-color: var(--pill-insight-variant-pressed-state-background-color);
}

:host([variant="alpha"][interactive]:active) {
  background-color: var(--pill-alpha-variant-pressed-state-background-color);
}

:host([variant="beta"][interactive]:active) {
  background-color: var(--pill-beta-variant-pressed-state-background-color);
}
