import type { Placement, PositioningStrategy } from '@popperjs/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';

import { supportedDropdownTriggers } from '../../utils/dropdown';
import type { TDropdownTriggerElement } from '../../utils/dropdown';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * Type guard to check if the supported trigger element is a `market-button`
 */
function isMarketButton(el: TDropdownTriggerElement): el is HTMLMarketButtonElement {
  return el.tagName.toLowerCase() === getNamespacedTagFor('market-button');
}

/**
 * @slot trigger - Expects a slotted `market-button` or `market-filter-button`.
 * @slot content - Content slotted here will appear in `market-popover`, which
 * becomes visible when the slotted trigger content is interacted with.
 * @part popover - The inner market-popover.
 */
@Component({
  tag: 'market-button-dropdown',
  styleUrl: 'market-button-dropdown.css',
  shadow: true,
})
export class MarketButtonDropdown {
  @Element() el: HTMLMarketButtonDropdownElement;

  /**
   * Defines what types of interaction the button dropdown should have
   * (see `market-dropdown` docs for more granular explanation)
   */
  @Prop() readonly interaction: 'click' | 'hover' | 'persistent' = 'click';

  /**
   * Functionally and visually disables the button dropdown.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Disabling the up/down caret.
   */
  @Prop() readonly noCaret: boolean = false;

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the preferred placement of the popper.
   * https://popper.js.org/docs/v2/constructors//#placement
   */
  @Prop() readonly popoverPlacement: Placement = 'bottom-end';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://popper.js.org/docs/v2/constructors//#strategy
   */
  @Prop() readonly popoverStrategy: PositioningStrategy = 'absolute';

  /**
   * Disables the default behavior of *not* persisting selections in slotted `market-list`s.
   */
  @Prop({ attribute: 'persist-list' }) readonly persistListSelections: boolean = false;

  /**
   * Fired whenever the button dropdown is opened.
   */
  @Event({ bubbles: true, composed: true }) marketButtonDropdownOpened: EventEmitter;

  /**
   * Fired whenever the button dropdown is closed.
   */
  @Event({ bubbles: true, composed: true }) marketButtonDropdownClosed: EventEmitter;

  @State() dropdownIsActive: boolean = false;

  slottedButton: TDropdownTriggerElement;
  slottedList: HTMLMarketListElement;

  @Listen('marketDropdownOpened')
  dropdownOpenedEventHandler() {
    this.dropdownIsActive = true;
    this.setCaret();
    this.marketButtonDropdownOpened.emit();
  }

  @Listen('marketDropdownClosed')
  dropdownClosedEventHandler() {
    this.dropdownIsActive = false;
    this.setCaret();
    this.marketButtonDropdownClosed.emit();
  }

  @Watch('disabled')
  syncTriggerDisabledState() {
    if (this.slottedButton) {
      this.slottedButton.disabled = this.disabled;
    }
  }

  setCaret() {
    if (this.noCaret || !isMarketButton(this.slottedButton)) {
      return;
    }

    if (this.dropdownIsActive) {
      this.slottedButton.caret = 'up';
    } else {
      this.slottedButton.caret = 'down';
    }
  }

  registerTrigger() {
    this.slottedButton = this.el.querySelector(supportedDropdownTriggers.join(','));
    this.syncTriggerDisabledState();
    this.setCaret();
  }

  componentWillRender() {
    const markeListTagName = getNamespacedTagFor('market-list');

    const list = this.el.querySelector(markeListTagName) as HTMLMarketListElement;
    if (list) {
      // We set this here even though market-popover also sets it,
      // because the slotted market-list's componentWillLoad hook
      // will fire before market-popover's, and we need it to be set
      // before the row processing that happens in that hook.
      list.interactive = true;

      list.transient = !this.persistListSelections;
    }
  }

  render() {
    const MarketDropdownTagName = getNamespacedTagFor('market-dropdown');
    const MarketPopoverTagName = getNamespacedTagFor('market-popover');

    return (
      <Host class="market-button-dropdown" aria-expanded={this.dropdownIsActive}>
        <MarketDropdownTagName
          interaction={this.interaction}
          popover-strategy={this.popoverStrategy}
          popover-placement={this.popoverPlacement}
          disabled={this.disabled}
        >
          <slot name="trigger" slot="trigger" onSlotchange={() => this.registerTrigger()}></slot>
          <MarketPopoverTagName slot="popover" part="popover">
            <slot name="content"></slot>
          </MarketPopoverTagName>
        </MarketDropdownTagName>
      </Host>
    );
  }
}
