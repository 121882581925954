/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './address';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "resources": {
                "nested": {
                  "Card": {
                    "fields": {
                      "id": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "cardBrand": {
                        "type": "squareup.connect.v2.resources.Card.Brand",
                        "id": 2,
                        "rule": "optional"
                      },
                      "last4": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "expMonth": {
                        "type": "int64",
                        "id": 4,
                        "rule": "optional"
                      },
                      "expYear": {
                        "type": "int64",
                        "id": 5,
                        "rule": "optional"
                      },
                      "cardholderName": {
                        "type": "string",
                        "id": 6,
                        "rule": "optional"
                      },
                      "billingAddress": {
                        "type": "squareup.connect.v2.resources.Address",
                        "id": 7,
                        "rule": "optional"
                      },
                      "fingerprint": {
                        "type": "string",
                        "id": 8,
                        "rule": "optional"
                      },
                      "customerId": {
                        "type": "string",
                        "id": 9,
                        "rule": "optional"
                      },
                      "buyerId": {
                        "type": "string",
                        "id": 24,
                        "rule": "optional"
                      },
                      "merchantId": {
                        "type": "string",
                        "id": 26,
                        "rule": "optional"
                      },
                      "referenceId": {
                        "type": "string",
                        "id": 10,
                        "rule": "optional"
                      },
                      "enabled": {
                        "type": "bool",
                        "id": 12,
                        "rule": "optional",
                        "options": {
                          "default": true
                        }
                      },
                      "cardType": {
                        "type": "squareup.connect.v2.resources.Card.Type",
                        "id": 13,
                        "rule": "optional"
                      },
                      "prepaidType": {
                        "type": "squareup.connect.v2.resources.Card.PrepaidType",
                        "id": 14,
                        "rule": "optional"
                      },
                      "bin": {
                        "type": "string",
                        "id": 15,
                        "rule": "optional"
                      },
                      "createdAt": {
                        "type": "string",
                        "id": 16,
                        "rule": "optional"
                      },
                      "disabledAt": {
                        "type": "string",
                        "id": 23,
                        "rule": "optional"
                      },
                      "version": {
                        "type": "int64",
                        "id": 17,
                        "rule": "optional"
                      },
                      "productHistory": {
                        "type": "squareup.connect.v2.resources.Card.SquareProductHistory",
                        "id": 18,
                        "rule": "optional"
                      },
                      "fideliusToken": {
                        "type": "string",
                        "id": 19,
                        "rule": "optional"
                      },
                      "paymentAccountReference": {
                        "type": "string",
                        "id": 20,
                        "rule": "optional"
                      },
                      "panSequenceNumber": {
                        "type": "int64",
                        "id": 21,
                        "rule": "optional"
                      },
                      "verificationPaymentId": {
                        "type": "string",
                        "id": 22,
                        "rule": "optional"
                      },
                      "terminalActionId": {
                        "type": "string",
                        "id": 25,
                        "rule": "optional"
                      },
                      "cardCoBrand": {
                        "type": "squareup.connect.v2.resources.Card.CoBrand",
                        "id": 27,
                        "rule": "optional"
                      },
                      "squareIssuedCard": {
                        "type": "squareup.connect.v2.resources.Card.SquareIssuedCard",
                        "id": 28,
                        "rule": "optional"
                      },
                      "allowToChargeUntil": {
                        "type": "string",
                        "id": 29,
                        "rule": "optional"
                      },
                      "chargedAt": {
                        "type": "string",
                        "id": 30,
                        "rule": "optional"
                      },
                      "verificationPaymentData": {
                        "type": "string",
                        "id": 31,
                        "rule": "optional"
                      },
                      "issuerAlert": {
                        "type": "squareup.connect.v2.resources.Card.IssuerAlert",
                        "id": 32,
                        "rule": "optional"
                      },
                      "issuerAlertAt": {
                        "type": "string",
                        "id": 33,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "Brand": {
                        "values": {
                          "OTHER_BRAND": 0,
                          "VISA": 1,
                          "MASTERCARD": 2,
                          "AMERICAN_EXPRESS": 3,
                          "DISCOVER": 4,
                          "DISCOVER_DINERS": 5,
                          "JCB": 6,
                          "CHINA_UNIONPAY": 7,
                          "SQUARE_GIFT_CARD": 8,
                          "SQUARE_CAPITAL_CARD": 9,
                          "INTERAC": 10,
                          "EFTPOS": 11,
                          "FELICA": 12,
                          "EBT": 13
                        }
                      },
                      "Type": {
                        "values": {
                          "UNKNOWN_CARD_TYPE": 0,
                          "CREDIT": 1,
                          "DEBIT": 2
                        }
                      },
                      "PrepaidType": {
                        "values": {
                          "UNKNOWN_PREPAID_TYPE": 0,
                          "NOT_PREPAID": 1,
                          "PREPAID": 2
                        }
                      },
                      "SquareProduct": {
                        "values": {
                          "UNKNOWN_SQUARE_PRODUCT": 0,
                          "CONNECT_API": 1,
                          "DASHBOARD": 2,
                          "REGISTER_CLIENT": 3,
                          "BUYER_DASHBOARD": 4,
                          "WEB": 5,
                          "INVOICES": 6,
                          "GIFT_CARD": 7,
                          "VIRTUAL_TERMINAL": 8,
                          "READER_SDK": 9,
                          "SQUARE_PROFILE": 10,
                          "SQUARE_LOCAL": 11,
                          "TERMINAL_API": 12
                        }
                      },
                      "SquareProductHistory": {
                        "fields": {
                          "createdBy": {
                            "type": "squareup.connect.v2.resources.Card.SquareProduct",
                            "id": 1,
                            "rule": "optional"
                          },
                          "disabledBy": {
                            "type": "squareup.connect.v2.resources.Card.SquareProduct",
                            "id": 2,
                            "rule": "optional"
                          },
                          "lastUpdatedBy": {
                            "type": "squareup.connect.v2.resources.Card.SquareProduct",
                            "id": 3,
                            "rule": "optional"
                          }
                        }
                      },
                      "CoBrand": {
                        "values": {
                          "UNKNOWN": 0,
                          "AFTERPAY": 1,
                          "CLEARPAY": 2
                        }
                      },
                      "SquareIssuedCard": {
                        "values": {
                          "UNKNOWN_OR_NOT_SQUARE_ISSUED": 0,
                          "CASH_CARD": 1,
                          "SQUARE_CARD": 2,
                          "AFTERPAY_CARD": 3
                        }
                      },
                      "IssuerAlert": {
                        "values": {
                          "ISSUER_ALERT_NOT_SET": 0,
                          "ISSUER_ALERT_CARD_CLOSED": 1
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.Brand && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.Type && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.PrepaidType && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.SquareProduct && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.SquareProductHistory && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.CoBrand && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.SquareIssuedCard && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.Card?.nested?.IssuerAlert) {
	$root.addJSON(jsonData);
}

/**
 * WARNING: please CHECK AND UPDATE card-forgotten-webhook.proto's CardsForgotten proto
 * if there is any change, as CardsForgotten is contains a subset fields of cards
 * --
 * @desc Represents the payment details of a card to be used for payments. These
 * details are determined by the payment token generated by Web Payments SDK.
 * --
 *
 * SOURCE Card @ squareup/connect/v2/resources/card.proto at 28:1
 */
export const Card = $root.lookupType('squareup.connect.v2.resources.Card');
fixType(Card);
Builder.createAndAttachToType(Card);
/**
 * --
 * @desc Indicates a card's brand, such as `VISA` or `MASTERCARD`.
 * --
 *
 * SOURCE Brand @ squareup/connect/v2/resources/card.proto at 40:3
 */
Card.Brand = $root.lookupEnum('squareup.connect.v2.resources.Card.Brand').values;
/**
 * --
 * @desc Indicates a card's type, such as `CREDIT` or `DEBIT`.
 * --
 *
 * SOURCE Type @ squareup/connect/v2/resources/card.proto at 136:3
 */
Card.Type = $root.lookupEnum('squareup.connect.v2.resources.Card.Type').values;
/**
 * --
 * @desc Indicates a card's prepaid type, such as `NOT_PREPAID` or `PREPAID`.
 * --
 *
 * SOURCE PrepaidType @ squareup/connect/v2/resources/card.proto at 153:3
 */
Card.PrepaidType = $root.lookupEnum('squareup.connect.v2.resources.Card.PrepaidType').values;
/**
 * SOURCE SquareProduct @ squareup/connect/v2/resources/card.proto at 203:3
 */
Card.SquareProduct = $root.lookupEnum('squareup.connect.v2.resources.Card.SquareProduct').values;
/**
 * --
 * @desc Square Products that triggered various card events. If set for a given event,
 * value must be a member of the squareup.common.payment.SquareProduct enum.
 * --
 *
 * SOURCE SquareProductHistory @ squareup/connect/v2/resources/card.proto at 225:3
 */
Card.SquareProductHistory = $root.lookupType('squareup.connect.v2.resources.Card.SquareProductHistory');
fixType(Card.SquareProductHistory);
Builder.createAndAttachToType(Card.SquareProductHistory);
/**
 * --
 * @desc Indicates the brand for a co-branded card.
 * --
 *
 * SOURCE CoBrand @ squareup/connect/v2/resources/card.proto at 292:3
 */
Card.CoBrand = $root.lookupEnum('squareup.connect.v2.resources.Card.CoBrand').values;
/**
 * --
 * @desc SquareIssuedCard is an enum mapping to binfo.api.SquareIssuedCard.
 * --
 *
 * SOURCE SquareIssuedCard @ squareup/connect/v2/resources/card.proto at 309:3
 */
Card.SquareIssuedCard = $root.lookupEnum('squareup.connect.v2.resources.Card.SquareIssuedCard').values;
/**
 * SOURCE IssuerAlert @ squareup/connect/v2/resources/card.proto at 380:3
 */
Card.IssuerAlert = $root.lookupEnum('squareup.connect.v2.resources.Card.IssuerAlert').values;
