import * as focusTrap from 'focus-trap';

export type FocusTrap = focusTrap.FocusTrap;
export type FocusTrapOptions = focusTrap.Options;
export type FocusTrapActivateOptions = focusTrap.ActivateOptions;
export type FocusTrapDeactivateOptions = focusTrap.DeactivateOptions;

export const createAndActivateFocusTrap = ({
  activateOptions,
  el,
  options,
}: {
  activateOptions?: FocusTrapActivateOptions;
  el: HTMLElement;
  options?: FocusTrapOptions;
}): FocusTrap | undefined => {
  // Prevent runtime errors when attempting to activate focus trap
  try {
    return focusTrap
      .createFocusTrap(el, {
        allowOutsideClick: true,
        document,
        escapeDeactivates: false,
        ...options,
        tabbableOptions: {
          getShadowRoot: true, // enables shadow DOM support
          ...options?.tabbableOptions,
        },
      })
      .activate(activateOptions);
  } catch (e) {
    console.warn(e); // eslint-disable-line no-console
  }
  return undefined;
};
