@import "../../styles/mixins/component-base.css";

:host {
  --focus-ring-color: color-mix(in srgb, var(--action-card-focus-ring-color) 50%, transparent);

  display: block;
  padding: var(--action-card-padding-vertical-size) var(--action-card-padding-horizontal-size);
  border-radius: var(--action-card-border-radius);
  background-color: var(--action-card-background-color);
  box-shadow:
    inset
    0
    0
    0
    var(--action-card-normal-state-unselected-value-border-width)
    var(--action-card-normal-state-unselected-value-border-color);
  opacity: var(--action-card-normal-state-content-opacity);
  cursor: pointer;

  &(:hover) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-hover-state-unselected-value-border-width)
      var(--action-card-hover-state-unselected-value-border-color);
  }

  &(:active) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-active-state-unselected-value-border-width)
      var(--action-card-active-state-unselected-value-border-color);
  }

  &([disabled]) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-disabled-state-unselected-value-border-width)
      var(--action-card-disabled-state-unselected-value-border-color);
    opacity: var(--action-card-disabled-state-content-opacity);
  }

  &([selected]) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-normal-state-selected-value-border-width)
      var(--action-card-normal-state-selected-value-border-color);
  }

  &([selected]:hover) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-hover-state-selected-value-border-width)
      var(--action-card-hover-state-selected-value-border-color);
  }

  &([selected]:active) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-active-state-selected-value-border-width)
      var(--action-card-active-state-selected-value-border-color);
  }

  &([selected][disabled]) {
    box-shadow:
      inset
      0
      0
      0
      var(--action-card-disabled-state-selected-value-border-width)
      var(--action-card-disabled-state-selected-value-border-color);
  }

  &(.has-slotted-row) {
    padding: 0 var(--row-background-horizontal-outset-padding);

    ::slotted(.market-row) {
      /* since .market-row’s .container already has a padding, add some more */
      padding-right:
        calc(
          var(--action-card-padding-horizontal-size) -
          var(--row-normal-variant-background-horizontal-outset-padding)
        );
      padding-left:
        calc(
          var(--action-card-padding-horizontal-size) -
          var(--row-normal-variant-background-horizontal-outset-padding)
        );
      background-color: transparent;
    }
  }

  &(:focus-visible) {
    outline: var(--action-card-focus-ring-border-size) solid var(--focus-ring-color);
    outline-offset: var(--action-card-focus-ring-buffer-size);
  }
}
