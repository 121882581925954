import { Component, Prop, Element, Host, h, State, Method } from '@stencil/core';

import { submitFormImplicitly } from '../../utils/forms';

import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - The label for the input.
 *
 * @slot input - Can be used to slot your own HTML input, if needed (ex. if supporting browser
 * autofill)
 */
@Component({
  tag: 'market-input-password',
  shadow: true,
  styleUrl: 'market-input-password.css',
})
export class MarketInputPassword {
  @Element() el: HTMLMarketInputPasswordElement;

  /**
   * A string specifying an ID for the input.
   */
  @Prop() readonly inputId: string;

  /**
   * A string specifying a name for the input.
   */
  @Prop() readonly name: string;

  /**
   * A string specifying a value for the input. This will be visually shown on the input and can be edited by the user.
   */
  @Prop() readonly value: string;

  /**
   * A string specifying the placeholder of the input.
   * This is shown before a user attempts to add a value, given no value is already provided.
   */
  @Prop() readonly placeholder: string;

  /**
   * A number specifying the maximum length of characters for the input value.
   */
  @Prop() readonly maxlength: number;

  /**
   * A number specifying the minimum length of characters for the input value.
   */
  @Prop() readonly minlength: number;

  /**
   * String for setting input size.
   * Sizes `small` and `medium` visually hide the label,
   * but you should still provide one for accessibility purposes.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'large';

  /**
   * A boolean representing whether the input is readonly or not.
   */
  @Prop() readonly readonly: boolean;

  /**
   * A boolean representing whether the input is disabled or not.
   * This visually and functionally will disable the input.
   */
  @Prop() readonly disabled: boolean;

  /**
   * A boolean representing whether the input is focused or not.
   */
  @Prop({ mutable: true }) focused: boolean;

  /**
   * A boolean representing whether the input is invalid or not.
   * This represents error states.
   */
  @Prop() readonly invalid: boolean;

  /**
   * Whether or not this input should allow autocompletion by the browser
   * Accepts a boolean, or "true", "false", "on", "off" or an
   * [accepted string value for the autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   */
  @Prop() readonly autocomplete: string | boolean;

  /**
   * Allows a browser to display an appropriate virtual keyboard.
   * [Accepted values](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode).
   */
  @Prop() readonly inputmode: string;

  marketInputText: HTMLMarketInputTextElement;

  /**
   * Type specified for the password input. Toggled by the input's right icon (eye).
   */
  @State() type: 'text' | 'password' = 'password';

  /**
   * Sets focus styling on `<market-input-password>`. Toggles focus on the inner `<input>` if true, and blurs focus if false.
   */
  @Method()
  async setFocus(value: boolean = true) {
    if (this.disabled || this.readonly) {
      return;
    }
    this.focused = value;
    await this.marketInputText.setFocus(value);
  }

  slottedInput: HTMLInputElement;

  registerSlottedInput() {
    // We need to pass in the slotted input here since it's inside market-input-password's
    // light DOM, and market-input-text is unable to find it.
    this.slottedInput = this.el.querySelector('input[slot=input]');
    if (this.slottedInput) {
      this.marketInputText.registerSlottedInput(this.slottedInput);
    }
  }

  togglePasswordVisibility(e: Event) {
    this.type = this.type === 'password' ? 'text' : 'password';
    e.stopPropagation();
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      submitFormImplicitly(this.el);
    }
  }

  renderSvgHidden() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5481 17.9609L16.55 17.96L19.3 20.71L20.71 19.3L18.31 16.9C19.81 15.78 21.06 14.27 21.91 12.43C22.03 12.16 22.03 11.85 21.91 11.59C20.03 7.52004 16.23 5.00004 12 5.00004C10.39 5.00004 8.85 5.37004 7.45 6.04004L4.71 3.29004L3.29361 4.70643L14.0183 15.4311L14.02 15.43L15.01 16.42L15.008 16.4208L16.5481 17.9609ZM11.49 10.07L13.93 12.51C13.97 12.35 14 12.18 14 12C14 10.9 13.1 10 12 10C11.82 10 11.65 10.03 11.49 10.07ZM15.43 14.02L16.86 15.45C18.09 14.6 19.14 13.43 19.89 12C18.28 8.90004 15.29 7.00004 12 7.00004C10.95 7.00004 9.94 7.21004 8.99 7.58004L9.98 8.57004C10.57 8.22004 11.26 8.00004 12 8.00004C14.21 8.00004 16 9.79004 16 12C16 12.74 15.79 13.43 15.43 14.02Z"
        />
        <path d="M11.7512 15.9924L8.0076 12.2488C8.13092 14.2606 9.73948 15.8691 11.7512 15.9924Z" />
        <path d="M5.61696 9.8582C5.03307 10.4841 4.52321 11.2015 4.11 12C5.72 15.1 8.71 17 12 17C12.244 17 12.4859 16.9887 12.7254 16.9666L14.4639 18.7052C13.6686 18.8984 12.8439 19 12 19C7.77 19 3.97 16.48 2.09 12.42C1.97 12.16 1.97 11.85 2.09 11.58C2.63958 10.3904 3.35638 9.33864 4.19985 8.44109L5.61696 9.8582Z" />
      </svg>
    );
  }

  renderSvgVisible() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.09 11.58C3.97 7.52 7.77 5 12 5C16.23 5 20.03 7.52 21.91 11.58C22.03 11.84 22.03 12.15 21.91 12.42C20.03 16.48 16.23 19 12 19C7.77 19 3.97 16.48 2.09 12.42C1.97 12.16 1.97 11.85 2.09 11.58ZM4.11 12C5.72 15.1 8.71 17 12 17C15.29 17 18.28 15.1 19.89 12C18.28 8.9 15.29 7 12 7C8.71 7 5.72 8.9 4.11 12ZM8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12ZM10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12Z"
        />
      </svg>
    );
  }

  render() {
    const MarketInputTextTagName = getNamespacedTagFor('market-input-text');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');

    return (
      <Host
        class="market-input-password"
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
      >
        <MarketInputTextTagName
          type={this.type}
          ref={(el) => (this.marketInputText = el)}
          onBlur={() => {}}
          onClick={() => {}}
          onFocus={() => {}}
          inputId={this.inputId}
          name={this.name}
          placeholder={this.placeholder}
          maxlength={this.maxlength}
          minlength={this.minlength}
          size={this.size}
          value={this.value}
          readonly={this.readonly}
          disabled={this.disabled}
          invalid={this.invalid}
          autocomplete={this.autocomplete}
          inputmode={this.inputmode}
        >
          <slot></slot>
          <slot
            name="input"
            onSlotchange={() => this.registerSlottedInput()}
            // if there is a slotted input, assign it to market-input-text's "input" slot
            slot={this.slottedInput ? 'input' : ''}
          ></slot>
          <MarketAccessoryTagName slot="trailing-accessory" size="image">
            <button
              class="toggle"
              onClick={(e: Event) => {
                this.togglePasswordVisibility(e);
              }}
              onKeyDown={(e) => {
                // don't submit a form if enter is pressed when the toggle is focused
                e.stopPropagation();
              }}
            >
              {this.type === 'password' && this.renderSvgHidden()}
              {this.type === 'text' && this.renderSvgVisible()}
            </button>
          </MarketAccessoryTagName>
        </MarketInputTextTagName>
      </Host>
    );
  }
}
