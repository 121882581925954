import { Component, Element, Event, EventEmitter, Host, h, Listen, Prop, Watch } from '@stencil/core';
import { getNamespacedTagFor } from '../../../../utils/namespace';
import { getDefaultMarketColorSwatches } from '../../utils';

@Component({
  tag: 'market-color-swatch-list',
  styleUrl: 'market-color-swatch-list.css',
  shadow: true,
})
export class MarketColorSwatchList {
  @Element() el: HTMLMarketColorSwatchListElement;

  /**
   * A string specifying a value for the list.
   * For a color swatch to be selected, this value should match the swatch's value.
   * Note: all color swatch values slotted in must be **unique**!
   * An empty string, or '', will clear the selection.
   */
  @Prop({ mutable: true, reflect: true }) value: string;

  /**
   * Fired whenever an item is selected or deselected.
   */
  @Event({ bubbles: true, cancelable: true, composed: true })
  marketColorSwatchListValueChange: EventEmitter<{ value: string; prevValue: string }>;

  @Watch('value')
  valueWatcher() {
    this.setSelectionFromValue();
  }

  /* Listen for the marketColorSwatchSelectedChange event which is emitted by slotted market-color-swatch elements
  when they are clicked */
  @Listen('marketColorSwatchSelectedChange')
  colorSwatchSelectedEventHandler(e: CustomEvent) {
    this.handleItemSelectedEvent(e.detail);
  }

  handleItemSelectedEvent(eventInfo: { value: string; selected: boolean }) {
    const selectedValue = eventInfo.selected ? eventInfo.value : '';

    if (this.value === selectedValue) {
      return;
    }

    const { defaultPrevented } = this.marketColorSwatchListValueChange.emit({
      value: selectedValue,
      prevValue: this.value,
    });

    if (!defaultPrevented) {
      this.value = selectedValue;
    }
  }

  // After selection, the swatches in the list update their selected state
  setSelectionFromValue() {
    // First check for slotted swatches, otherwise use default in shadow dom
    let items = [
      ...this.el.querySelectorAll(getNamespacedTagFor('market-color-swatch')),
    ] as Array<HTMLMarketColorSwatchElement>;
    if (items.length === 0) {
      items = [
        ...this.el.shadowRoot.querySelectorAll(getNamespacedTagFor('market-color-swatch')),
      ] as Array<HTMLMarketColorSwatchElement>;
    }

    items.forEach((item) => (item.selected = this.value === item.value));
  }

  handleSlotChange() {
    this.setSelectionFromValue();
  }

  render() {
    return (
      <Host class="market-color-swatch-list" role="list">
        <slot onSlotchange={() => this.handleSlotChange()}>
          {/* Uses Market Figma default colors for the swatches, unless other colors are slotted in */}
          {getDefaultMarketColorSwatches().map((swatchValue) => {
            return <market-color-swatch value={swatchValue}></market-color-swatch>;
          })}
        </slot>
      </Host>
    );
  }
}
