import { Component, Host, h, Prop, Event, EventEmitter, Element, State, Method } from '@stencil/core';

/**
 * @slot - The text for the toast
 * @slot action - for `<a href>` or `<button>` (not a `<market-button>`)
 */
@Component({
  tag: 'market-toast',
  styleUrl: 'market-toast.css',
  shadow: true,
})
export class MarketToast {
  @Element() el: HTMLMarketToastElement;

  /**
   * toast variant that corresponds to the type of info it is conveying
   */
  @Prop({ reflect: true }) readonly variant: 'info' | 'success' | 'warning' | 'critical' | 'insight' = 'info';

  /**
   * Whether or not the toast persists, ie does not autodismiss after 5s
   */
  @Prop() readonly persistent: boolean = false;

  /**
   * Optional property to pass a string to the dismiss "x"
   * that will function as its aria-label. Defaults to "Dismiss".
   */
  @Prop({ reflect: true }) readonly dismissButtonAriaLabel: string = 'Dismiss';

  /**
   * The progress of the action, progress bar will be rendered for values between 0-100 inclusive
   */
  @Prop({ reflect: true }) readonly progress: number = -1;

  /**
   * The amount of time (ms) until the toast autodismisses
   * TODO(UI-1153): should be a design token
   */
  @State() durationTilAutoDismiss: number = 5000;

  @State() showActionsNav: boolean = false;

  /**
   * Emitted when the toast automatically dismisses.
   */
  @Event() marketToastAutoDismissed: EventEmitter;

  /**
   * Emitted when the toast's dismissed button is clicked.
   */
  @Event() marketToastManuallyDismissed: EventEmitter;

  /**
   * Set toast to disappear after the autodismiss timeout has passed
   */
  @Method()
  startAutoDismissTimer() {
    if (!this.persistent) {
      setTimeout(() => {
        this.marketToastAutoDismissed.emit();
      }, this.durationTilAutoDismiss);
    }
    return Promise.resolve();
  }

  handleManualDismiss() {
    this.marketToastManuallyDismissed.emit();
  }

  componentWillLoad() {
    this.checkIfActionsArePresent();
  }

  checkIfActionsArePresent() {
    const actions = this.el.querySelector('[slot="action"]');
    this.showActionsNav = actions ? true : false;
  }

  renderIcon() {
    if (this.variant === 'success') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 12C3 7.03 7.03 3 12 3C16.97 3 21 7.03 21 12C21 16.97 16.97 21 12 21C7.03 21 3 16.97 3 12ZM5 12C5 15.86 8.14 19 12 19C15.86 19 19 15.86 19 12C19 8.14 15.86 5 12 5C8.14 5 5 8.14 5 12ZM8.71005 10.8L11 13.09L15.3101 8.80005L16.72 10.21L11.72 15.21C11.52 15.4 11.27 15.5 11.01 15.5C10.75 15.5 10.5 15.41 10.3 15.21L7.30005 12.21L8.71005 10.8Z"
          />
        </svg>
      );
    } else if (this.variant === 'info') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 3C7.04 3 3 7.04 3 12C3 16.96 7.04 21 12 21C16.96 21 21 16.96 21 12C21 7.04 16.96 3 12 3ZM12 19C8.14 19 5 15.86 5 12C5 8.14 8.14 5 12 5C15.86 5 19 8.14 19 12C19 15.86 15.86 19 12 19ZM11 10.5V17H13V10.5H11ZM13.25 8.25C13.25 8.94036 12.6904 9.5 12 9.5C11.3096 9.5 10.75 8.94036 10.75 8.25C10.75 7.55964 11.3096 7 12 7C12.6904 7 13.25 7.55964 13.25 8.25Z"
          />
        </svg>
      );
    } else if (this.variant === 'warning') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 9.00005H13V14.5H11V9.00005Z" />
          <path d="M12 18C12.6904 18 13.25 17.4404 13.25 16.75C13.25 16.0596 12.6904 15.5 12 15.5C11.3096 15.5 10.75 16.0596 10.75 16.75C10.75 17.4404 11.3096 18 12 18Z" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.866 2.17944C12.6874 1.87004 12.3573 1.67944 12 1.67944C11.6427 1.67944 11.3126 1.87004 11.134 2.17944L1.13397 19.5C0.955342 19.8094 0.955342 20.1905 1.13397 20.5C1.31261 20.8094 1.64273 21 2 21H22C22.3573 21 22.6874 20.8094 22.866 20.5C23.0447 20.1905 23.0447 19.8094 22.866 19.5L12.866 2.17944ZM12 4.67944L20.268 19H3.73205L12 4.67944Z"
          />
        </svg>
      );
    } else if (this.variant === 'insight') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 23.1701V15.0001H3.07996L13 0.830078V9.00008H20.92L11 23.1701ZM6.91996 13.0001H13V16.8301L17.08 11.0001H11V7.17008L6.91996 13.0001Z" />
        </svg>
      );
    } else {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 21C16.96 21 21 16.96 21 12C21 7.04 16.96 3 12 3C7.04 3 3 7.04 3 12C3 16.96 7.04 21 12 21ZM12 5C15.86 5 19 8.14 19 12C19 15.86 15.86 19 12 19C8.14 19 5 15.86 5 12C5 8.14 8.14 5 12 5ZM12 17C12.6904 17 13.25 16.4404 13.25 15.75C13.25 15.0596 12.6904 14.5 12 14.5C11.3096 14.5 10.75 15.0596 10.75 15.75C10.75 16.4404 11.3096 17 12 17ZM11 7H13V13.5H11V7Z"
          />
        </svg>
      );
    }
  }

  render() {
    const progress = Math.min(this.progress, 100);
    const progressStyle = {
      width: `${progress}%`,
    };

    return (
      <Host class="market-toast" role="alert">
        <div class="content">
          <span class="icon-container">
            {/* this span is the flexbox container to vertically align the icon */}
            {this.renderIcon()}
          </span>
          <div class="main">
            <section>
              <slot></slot>
            </section>
            <nav aria-label="toast-actions" class={this.showActionsNav ? '' : 'hidden'}>
              <slot name="action" onSlotchange={() => this.checkIfActionsArePresent()}></slot>
            </nav>
          </div>
          <nav aria-label="dismiss-container" class="dismiss-container">
            <button type="button" aria-label={this.dismissButtonAriaLabel} onClick={() => this.handleManualDismiss()}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.71004 18.71L12 13.41L17.29 18.71L18.71 17.29L13.41 12L18.71 6.71004L17.29 5.29004L12 10.59L6.71004 5.29004L5.29004 6.71004L10.59 12L5.29004 17.29L6.71004 18.71Z" />
              </svg>
            </button>
          </nav>
        </div>
        {this.progress >= 0 && (
          <progress-bar
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={progressStyle}
          ></progress-bar>
        )}
      </Host>
    );
  }
}
